import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AccessControlHistoryService {
  
  constructor(
    private http: HttpClient
  ) { }
  
  GetAccessControlById(id: any) {
    return this.http.get(environment.api_url + '/enclosures/get-access-control-by-id/' + id);
  }
  AccessControlHistory(filter_id: any) {
    return this.http.get(environment.api_url + '/enclosures/access-control-history/' + filter_id);
  }
  AccessControlHistoryById(parent_id: any) {
    return this.http.get(environment.api_url + '/enclosures/access-control-enclosure-history/' + parent_id);
  }
}
