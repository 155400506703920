import { Injectable } from '@angular/core';
import {DeleteForwarding  } from './forward-delivery-list.modal';
import {ForwardDelivery  } from '../enclosure-details/enclosure-details.modal';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ForwardDeliveryListService {
  DeleteForwardingFormData:DeleteForwarding;
  ForwardDeliveryFormData: ForwardDelivery;
  constructor(private http: HttpClient) { }

  getForwardedDeliveries(data,type) {
    if(type == 'Incoming'){
      return this.http.get(environment.api_url + '/forward-deliveries/in/'+data.enclosure_id+'/'+data.forward_type+'/'+data.owner_user_id);
    }
    else{
      return this.http.get(environment.api_url + '/forward-deliveries/out/'+data.enclosure_id+'/'+data.forward_type+'/'+data.owner_user_id);
    }
  }
  forwardDeliveryResponse(eid,status,token){
    return this.http.get(environment.api_url + '/users/forward-deliveries/'+eid+'/'+status+'/'+token);
  }
  deleteForwarding(data){
    return this.http.delete(environment.api_url + '/forward-deliveries/'+data._id);
  }
  deleteForwardingPermanent(data){
    return this.http.delete(environment.api_url + '/forward-deliveries/permanent/'+data._id);
  }
  addForwardDelivery(data){
    return this.http.post(environment.api_url + '/forward-deliveries',data);
  }
}
