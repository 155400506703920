<div class="auth">
  <div class="auth-wrap">
    <div class="auth-block">
      <div class="auth-img"><img src="assets/images/arrive-map.png" alt="" /></div>
    </div>
    <div class="auth-block">
      <div class="auth-logo">
        <img src="assets/images/arrive_logo_black.png" alt="Logo" width="260" />
      </div>
      <h2 class="auth-title text-left w-100">Login to your account below:</h2>
      <form
        #loginForm="ngForm"
        (ngSubmit)="doLogin(loginForm)"
        class="auth-form"
      >
        <div class="auth-form-body auth-form-login-body">
          <div class="auth-form-inner-body auth-form-login-inner-body">
            <label>Email Address<sup>*</sup></label>
            <mat-form-field floatLabel="never" appearance="standard">
              <input
                maxlength="64"
                matInput
                name="user_email"
                #user_email="ngModel"
                [(ngModel)]="service.LoginFormData.user_email"
                placeholder="Ex. abc@gmail.com"
                [ngClass]="{
                  'is-invalid':
                    !user_email.valid &&
                    (user_email.dirty || user_email.touched || isSubmit)
                }"
                required
              />
              <mat-error
                *ngIf="
                  !user_email.valid &&
                  (user_email.dirty || user_email.touched || isSubmit)
                "
              >
                <div *ngIf="user_email.errors.required">
                  Please enter email address
                </div>
                <div *ngIf="user_email.errors.pattern">
                  Enter valid email address
                </div>
              </mat-error>
            </mat-form-field>
            <label>Password<sup>*</sup></label>
            <mat-form-field floatLabel="never" appearance="standard">
              <input
                maxlength="25"
                matInput
                placeholder="Ex. qk*%yEn8Q"
                [type]="hide ? 'password' : 'text'"
                name="user_password"
                #user_password="ngModel"
                [(ngModel)]="service.LoginFormData.user_password"
                required
                [ngClass]="{
                  'is-invalid':
                    !user_password.valid &&
                    (user_password.dirty || user_password.touched || isSubmit)
                }"
                required
              />
              <mat-error
                *ngIf="
                  !user_password.valid &&
                  (user_password.dirty || user_password.touched || isSubmit)
                "
              >
                Please enter password
              </mat-error>
              <mat-icon matSuffix class="mr-4" (click)="hide = !hide"
                >{{ hide ? "visibility_off" : "visibility" }}
              </mat-icon>
            </mat-form-field>
          </div>
        </div>
        <button [disabled]="isLoading" class="auth-btn">Login</button>
      </form>
      <div class="auth-form-bottom">
        <div class="auth-form-bottom-inner">
          <mat-checkbox style="visibility: hidden">Remember Me</mat-checkbox>
          <button routerLink="/forgot-password" class="mx-auto">
            Forgot Password?
          </button>
        </div>
      </div>
      <p>
        Don't have an account?
        <button routerLink="/register">Register here</button>
      </p>
    </div>
  </div>
</div>

