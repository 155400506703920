import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'parcelStatus',
    pure: true
})
export class parcelStatusPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        var text = 'N/A';
        if (value == 1) {
            text = 'Accepted';
        }
        else if (value == 2) {
            text = 'Rejected';
        }
        else if (value == 3) {
            text = 'Released';
        }
        else if (value == 4) {
            text = 'Scanned';
        }
        else if (value == 5) {
            text = 'Unlocked';
        }
        else if (value == 6) {
            text = 'Locked';
        }
        else if (value == 7) {
            text = 'Delivered';
        }
        else if (value == 8) {
            text = 'Parcel is not delivered';
        }
        else if (value == 9) {
            text = 'Delivery Error';
        }
        else{
            text = 'N/A';
        }
        return text;
    }
}
