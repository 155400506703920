/**
 * @Author: Jitesh Prajapati <Jitesh_Prajapati>
 * @Date:   2021-03-18T15:28:19+05:30
 * @Email:  jitesh.prajapati@teksun.com
 * @Filename: auth-guard.ts
 * @Last modified by:   Jitesh_Prajapati
 * @Last modified time: 2021-03-24T10:51:10+05:30
 * @Copyright: Teksun INC <https://teksun.com/>
 */



import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {}
  canActivate(): boolean {
    if ( sessionStorage.getItem('token') ){
      return true
    } else  {
      this.router.navigate( ["/login"] );
      return false
    }
  }
}
