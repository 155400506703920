<div class="container-fluid">
  <div class="main mx-2 mb-4">
    <div class="row">
      <div class="col-12">
        <div class="title-wrapper title-border-bottom mt-2">
          <h1 class="title-1">
            <button class="back-btn" (click)="backClicked()">
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
            Access Control List
          </h1>
          <div class="grant-wrap">
            <button class="view-btn" (click)="viewAccessControlHistory()" title="Deleted and Deactivated Access Control" >
              <span class="material-icons">history</span>
              <span class="pt-1">View History</span>
            </button>
            <button class="delete-btn grant-btn" (click)="AddAccessControlModal()" [disabled]="enclosure_details.internet_connectivity == 0">
              <em class="material-icons">add</em>
              <span class="pt-1">Add Access Control</span>
            </button>
          </div>
        </div>
        <div class="warning-alert">
          <span class="material-icons">warning_amber</span>
          <p class="warning-alert-text"><strong>Warning:</strong>You won't be able to add/edit/delete access control if the internet connection is lost.</p>
        </div>
        <div class="fault-block" *ngIf="enclosure_details.internet_connectivity == 0">
          <div class="fault-title">
            <img src="assets/images/error-close.svg" alt="" />Error!
          </div>
          <div class="fault-info">
            <span class="fault-text">Internet connection lost!</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-eq-height">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="app-card app-card-100 mt-0 mx-2">
        <div class="app-card-body">
          <div class="table-responsive">
            <mat-form-field appearance="standard" class="search-filter">
              <span class="material-icons">search</span>
              <input matInput (keyup)="applyFilter($event)" placeholder="Search by Access Control Name, Primary Key & Secondary Key" #input />
            </mat-form-field>
            <table mat-table [dataSource]="dataSource" matSort class="app-table dashboard-app-table" aria-describedby="myTable">
              <ng-container matColumnDef="position">
                <th scope="col" mat-header-cell *matHeaderCellDef>No.</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize }}
                </td>
              </ng-container>
              <ng-container matColumnDef="access_control_name">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Access Control Name</th>
                <td mat-cell *matCellDef="let element" style="width:200px;">
                  <span *ngIf="element?.access_control_name" class="text-wrap text-break">{{ element?.access_control_name }}</span>
                  <span *ngIf="!element?.access_control_name">-</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="primary_auth_type">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Primary Auth</th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.primary_auth_type }}
                </td>
              </ng-container>
              <ng-container matColumnDef="secondary_auth_type">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Secondary Auth</th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.secondary_auth_type }}
                </td>
              </ng-container>
              <ng-container matColumnDef="start_date">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.start_date * 1000 | date : "d MMMM, y h:mm a" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="end_date">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.end_date * 1000 | date : "d MMMM, y h:mm a" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="uses_remaining">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Uses Remaining</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="(element?.uses_remaining || (element?.uses_remaining == 0)) && (element?.uses_remaining != -1)">{{ element?.uses_remaining }}</span>
                  <span *ngIf="element?.uses_remaining == -1">Unlimited</span>
                  <!-- <span *ngIf="!element?.uses_remaining">-</span> -->
                </td>
              </ng-container>
              <ng-container matColumnDef="actuator_type">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>User Door Opening</th>
                <td mat-cell *matCellDef="let element" style="width:200px;">
                  <span *ngIf="element?.actuator_type == 1" class="text-wrap text-break">Automatic</span>
                  <span *ngIf="element?.actuator_type == 2" class="text-wrap text-break">Manual</span>
                  <span *ngIf="!element?.actuator_type">-</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th scope="col" mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <button title="View Access Control" class="btn blue-btn" (click)="ViewAccessControlModal(element)">
                      <span class="material-icons">visibility</span>
                    </button>
                    <button title="Update Access Control" class="btn blue-btn" (click)="EditAccessControlModal(element)" [disabled]="enclosure_details.internet_connectivity == 0">
                      <img src="assets/images/edit.svg" alt="" />
                    </button>
                    <button title="Delete Access Control" class="btn red-btn" (click)="DeleteAccessControlModal(element)" [disabled]="enclosure_details.internet_connectivity == 0">
                      <img src="assets/images/delete.svg" alt="" />
                    </button>
                    <button title="Edited Access Control" class="btn yellow-btn" (click)="viewAccessControlHistoryById(element)"><span class="material-icons">history</span></button>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div class="drone-nodata-box" *ngIf="dataSourceEmpty">
              <div class="drone-nodata-box-wrap">
                <div class="drone-nodata-box-cover">
                  <div class="drone-nodata-cover">
                    <span class="material-icons">error_outline</span>
                    <p class="drone-nodata-text">No records found!</p>
                  </div>
                </div>
              </div>
            </div>
            <mat-paginator [pageSize]="5" class="app-paginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons> </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- View Access Control Modal -->
  <ngx-smart-modal #viewAccessControlModal [identifier]="'viewAccessControlModal'" [dismissable]="false" customClass="nsm-dialog-animation-ttb nsm-centered modal viewenclosure-modal">
    <h2 class="modal-title">View Access Control</h2>
    <div class="modal-form addenclosure-form viewenclosure-form">
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">person</span>Device Id</div>
          <div class="viewuser-box-subtitle">{{ singleAccessControlData?.enclosure_id }}</div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">person</span>Access Control Name</div>
          <div class="viewuser-box-subtitle" *ngIf="singleAccessControlData?.access_control_name">{{ singleAccessControlData?.access_control_name }}</div>
          <div class="viewuser-box-subtitle" *ngIf="!singleAccessControlData?.access_control_name">-</div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">height</span>Primary Auth Type</div>
          <div class="viewuser-box-subtitle">{{ singleAccessControlData?.primary_auth_type.name }}</div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">check_circle</span>Primary Auth Key</div>
          <div class="viewuser-box-subtitle">{{ singleAccessControlData?.primary_auth_key }}</div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">height</span>Secondary Auth Type</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleAccessControlData?.secondary_auth_type">{{ singleAccessControlData?.secondary_auth_type.name }}</span>
            <span *ngIf="!singleAccessControlData?.secondary_auth_type">-</span>
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">check_circle</span>Secondary Auth Key</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleAccessControlData?.secondary_auth_key">{{ singleAccessControlData?.secondary_auth_key }}</span>
            <span *ngIf="!singleAccessControlData?.secondary_auth_key">-</span>
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">date_range</span>Start Date</div>
          <div class="viewuser-box-subtitle">
            {{ singleAccessControlData?.start_date * 1000 | date : "d MMMM, y h:mm a" }}
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">date_range</span>End Date</div>
          <div class="viewuser-box-subtitle">
            {{ singleAccessControlData?.end_date * 1000 | date : "d MMMM, y h:mm a" }}
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">person</span>Finite Uses</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleAccessControlData?.finite_uses == 'Y'">Yes</span>
            <span *ngIf="singleAccessControlData?.finite_uses == 'N'">No</span>
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">description</span>Uses Remaining</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="(singleAccessControlData?.uses_remaining || (singleAccessControlData?.uses_remaining == 0)) && (singleAccessControlData?.uses_remaining != -1)">{{ singleAccessControlData?.uses_remaining }}</span>
            <span *ngIf="singleAccessControlData?.uses_remaining == -1">Unlimited</span>
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">person</span>User Door Opening</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleAccessControlData?.actuator_type == 1">Automatic</span>
            <span *ngIf="singleAccessControlData?.actuator_type == 2">Manual</span>
            <span *ngIf="!singleAccessControlData?.actuator_type">-</span>
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">description</span>Access Method</div>
          <div class="viewuser-box-subtitle">{{ singleAccessControlData?.access_method }}</div>
        </div>
      </div>
    </div>
  </ngx-smart-modal>
  <!-- Add Access Control Modal -->
  <ngx-smart-modal #addAccessControlModal [identifier]="'addAccessControlModal'" [dismissable]="false" customClass="nsm-dialog-animation-ttb nsm-centered modal addenclosure-modal address-add-enclosure addenclosuretype-modal">
    <h2 class="modal-title">Add Access Control</h2>
    <form class="access-control-modal" [formGroup]="addAccessControlForm" (ngSubmit)="AddAccessControl()">
      <div class="access-modal-input fullwidth">
        <label>Access Control Name <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input matInput name="access_control_name" formControlName="access_control_name" placeholder="Enter Access Control Name" required />
          <mat-error *ngIf="!addAccessControlForm.get('access_control_name').valid && (addAccessControlForm.get('access_control_name').dirty || addAccessControlForm.get('access_control_name').touched || isSubmit)">
            <div *ngIf="addAccessControlForm.get('access_control_name').errors.required">Please enter Access Control Name</div>
            <div *ngIf="addAccessControlForm.get('access_control_name').errors.pattern">Please enter valid Access Control Name</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input">
        <label>Primary Authentication Type <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="fill">
          <mat-select formControlName="primary_auth_type" placeholder="Select Primary Type" (selectionChange)="onPrimaryChange($event)">
            <mat-option [value]="option._id" title="{{ option.name }}" *ngFor="let option of primaryTypeList" [disabled]="option._id === primaryDisable">{{ option.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!addAccessControlForm.get('primary_auth_type').valid && (addAccessControlForm.get('primary_auth_type').dirty || addAccessControlForm.get('primary_auth_type').touched || isSubmit)">
            <div *ngIf="addAccessControlForm.get('primary_auth_type').hasError('required')">Please select Primary Authentication Type</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input position-relative">
        <label>Primary Authentication Key <sup>*</sup></label>
        <button type="button" class="info-outlined" [matTooltip]="tooltipContent" matTooltipClass="multi-line-tooltip">
          <img src="../../assets/images/info-outlined.svg" alt="Info">
        </button>
        <mat-form-field floatLabel="never" appearance="fill">
          <input matInput name="primary_auth_key" formControlName="primary_auth_key" placeholder="Enter Primary Key" required />
          <mat-error *ngIf="!addAccessControlForm.get('primary_auth_key').valid && (addAccessControlForm.get('primary_auth_key').dirty || addAccessControlForm.get('primary_auth_key').touched || isSubmit)">
            <div *ngIf="addAccessControlForm.get('primary_auth_key').errors.required">Please enter Primary Authentication Key</div>
            <div *ngIf="addAccessControlForm.get('primary_auth_key').errors.pattern">Please enter valid Primary Authentication Key</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input fullwidth">
        <mat-checkbox formControlName="isSecondary" class="example-full-width" [checked]="checkSecAuth" (change)="isSecondaryCheck($event)">Would you like to keep Secondary Authentication?</mat-checkbox>
      </div>
      <div class="access-modal-input" *ngIf="addAccessControlForm.get('isSecondary').value">
        <label>Secondary Authentication Type <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="fill">
          <mat-select formControlName="secondary_auth_type" placeholder="Select Secondary Type" (selectionChange)="onSecondaryChange($event)">
            <mat-option [value]="option._id" title="{{ option.name }}" *ngFor="let option of secondaryTypeList" [disabled]="option._id === secondaryDisable">{{ option.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="addAccessControlForm.get('secondary_auth_type').invalid && (addAccessControlForm.get('secondary_auth_type').dirty || addAccessControlForm.get('secondary_auth_type').touched || isSubmit)">
            <div *ngIf="addAccessControlForm.get('secondary_auth_type').errors.required">Please select Secondary Authentication Type</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input position-relative" *ngIf="addAccessControlForm.get('isSecondary').value">
        <label>Secondary Authentication Key <sup>*</sup></label>
        <button type="button" class="info-outlined" [matTooltip]="tooltipContent" matTooltipClass="multi-line-tooltip">
          <img src="../../assets/images/info-outlined.svg" alt="Info">
        </button>
        <mat-form-field floatLabel="never" appearance="fill">
          <input matInput name="secondary_auth_key" formControlName="secondary_auth_key" (input)="bluetoothFormat()" placeholder="Enter Secondary Key" required />
          <mat-error *ngIf="!addAccessControlForm.get('secondary_auth_key').valid && (addAccessControlForm.get('secondary_auth_key').dirty || addAccessControlForm.get('secondary_auth_key').touched || isSubmit)">
            <div *ngIf="addAccessControlForm.get('secondary_auth_key').errors.required">Please enter Secondary Authentication Key</div>
            <div *ngIf="addAccessControlForm.get('secondary_auth_key').errors.pattern">Please enter valid Secondary Authentication Key</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input">
        <label>Start Date <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input matInput formControlName="start_date" [min]="minDate" [ngxMatDatetimePicker]="add_start_datepicker" disabled placeholder="Select Start Date" />
          <mat-datepicker-toggle matIconSuffix [for]="add_start_datepicker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #add_start_datepicker disabled="false"></ngx-mat-datetime-picker>
          <mat-error *ngIf="!addAccessControlForm.get('start_date').valid && (addAccessControlForm.get('start_date').dirty || addAccessControlForm.get('start_date').touched || isSubmit)">
            <div *ngIf="addAccessControlForm.get('start_date').errors.required">Please select Start Date</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input">
        <label>End Date <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input matInput formControlName="end_date" [min]="addAccessControlForm.get('start_date').value" [ngxMatDatetimePicker]="add_end_datepicker" disabled placeholder="Select End Date" />
          <mat-datepicker-toggle matIconSuffix [for]="add_end_datepicker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #add_end_datepicker disabled="false"></ngx-mat-datetime-picker>
          <mat-error *ngIf="!addAccessControlForm.get('end_date').valid && (addAccessControlForm.get('end_date').dirty || addAccessControlForm.get('end_date').touched || isSubmit)">
            <div *ngIf="addAccessControlForm.get('end_date').errors.required">Please select End Date</div>
            <div *ngIf="checkDate">End Date must succeed Start Date</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input fullwidth">
        <div class="note-access-date"><strong>Note:</strong> Delivering across time zones? Convert the desired delivery time to your local time zone first, then set access control based on that local time for smooth scheduling!</div>
        <div class="timezone-access-date">Your Timezone: <span>{{timezone}}</span></div>
      </div>
      <div class="access-modal-input" [ngClass]="{ fullwidth: addAccessControlForm.get('finite_uses').value !== 'Y' }">
        <label>Finite Uses <sup>*</sup></label>
        <mat-radio-group formControlName="finite_uses" (change)="isFiniteCheck($event)" class="modal-radio-group">
          <mat-radio-button value="Y">Yes</mat-radio-button>
          <mat-radio-button value="N" class="ml-3">No</mat-radio-button>
          <mat-error *ngIf="!addAccessControlForm.get('finite_uses').valid && (addAccessControlForm.get('finite_uses').dirty || addAccessControlForm.get('finite_uses').touched || isSubmit)">
            <div *ngIf="addAccessControlForm.get('finite_uses').errors.required">Please select Finite Uses</div>
          </mat-error>
        </mat-radio-group>
      </div>
      <div class="access-modal-input" *ngIf="addAccessControlForm.get('finite_uses').value === 'Y'">
        <label>Maximum Uses <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input matInput formControlName="uses_remaining" type="number" placeholder="Enter Maximum Uses" name="uses_remaining" required />
          <mat-error *ngIf="!addAccessControlForm.get('uses_remaining').valid && (addAccessControlForm.get('uses_remaining').dirty || addAccessControlForm.get('uses_remaining').touched || isSubmit)">
            <div *ngIf="addAccessControlForm.get('uses_remaining').errors.required">Please enter Maximum Uses</div>
            <div *ngIf="addAccessControlForm.get('uses_remaining').errors.min">Maximum Uses should exceed 0</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input fullwidth">
        <label>User Door Opening <sup>*</sup></label>
        <mat-radio-group formControlName="actuator_type" class="modal-radio-group">
          <mat-radio-button value=1>Automatic</mat-radio-button>
          <mat-radio-button value=2 class="ml-3">Manual</mat-radio-button>
          <mat-error *ngIf="!addAccessControlForm.get('actuator_type').valid && (addAccessControlForm.get('actuator_type').dirty || addAccessControlForm.get('actuator_type').touched || isSubmit)">
            <div *ngIf="addAccessControlForm.get('actuator_type').errors.required">Please select User Door Opening</div>
          </mat-error>
        </mat-radio-group>
      </div>
      <div class="access-modal-input">
        <button class="add-btn" type="submit">Submit</button>
      </div>
    </form>
  </ngx-smart-modal>
  <!-- Edit Access Control Modal -->
  <ngx-smart-modal #editAccessControlModal [identifier]="'editAccessControlModal'" [dismissable]="false" customClass="nsm-dialog-animation-ttb nsm-centered modal addenclosure-modal address-add-enclosure addenclosuretype-modal">
    <h2 class="modal-title">Update Access Control</h2>
    <form class="access-control-modal" [formGroup]="editAccessControlForm" (ngSubmit)="EditAccessControl()">
      <div class="access-modal-input">
        <label>Start Date <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input matInput formControlName="start_date" [ngxMatDatetimePicker]="edit_start_datepicker" disabled placeholder="Select Start Date" />
          <mat-datepicker-toggle matIconSuffix [for]="edit_start_datepicker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #edit_start_datepicker disabled="false"></ngx-mat-datetime-picker>
          <mat-error *ngIf="!editAccessControlForm.get('start_date').valid && (editAccessControlForm.get('start_date').dirty || editAccessControlForm.get('start_date').touched || isSubmit)">
            <div *ngIf="editAccessControlForm.get('start_date').errors.required">Please select Start Date</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input">
        <label>End Date <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input matInput formControlName="end_date" [min]="(minDate > editAccessControlForm.get('start_date').value) ? (minDate) : (editAccessControlForm.get('start_date').value)" [ngxMatDatetimePicker]="edit_end_datepicker" disabled placeholder="Select End Date" />
          <mat-datepicker-toggle matIconSuffix [for]="edit_end_datepicker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #edit_end_datepicker disabled="false"></ngx-mat-datetime-picker>
          <mat-error *ngIf="!editAccessControlForm.get('end_date').valid && (editAccessControlForm.get('end_date').dirty || editAccessControlForm.get('end_date').touched || isSubmit)">
            <div *ngIf="editAccessControlForm.get('end_date').errors.required">Please select End Date</div>
            <div *ngIf="checkDate">End Date must succeed Start Date</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input" [ngClass]="{ fullwidth: editAccessControlForm.get('finite_uses').value !== 'Y' }">
        <label>Finite Uses <sup>*</sup></label>
        <mat-radio-group formControlName="finite_uses" (change)="isFiniteCheck($event)" class="modal-radio-group">
          <mat-radio-button value="Y">Yes</mat-radio-button>
          <mat-radio-button value="N" class="ml-3">No</mat-radio-button>
          <mat-error *ngIf="!editAccessControlForm.get('finite_uses').valid && (editAccessControlForm.get('finite_uses').dirty || editAccessControlForm.get('finite_uses').touched || isSubmit)">
            <div *ngIf="editAccessControlForm.get('finite_uses').errors.required">Please select Finite Uses</div>
          </mat-error>
        </mat-radio-group>
      </div>
      <div class="access-modal-input" *ngIf="editAccessControlForm.get('finite_uses').value === 'Y'">
        <label>Maximum Uses <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input matInput formControlName="uses_remaining" placeholder="Enter Maximum Uses" name="uses_remaining" required />
          <mat-error *ngIf="!editAccessControlForm.get('uses_remaining').valid && (editAccessControlForm.get('uses_remaining').dirty || editAccessControlForm.get('uses_remaining').touched || isSubmit)">
            <div *ngIf="editAccessControlForm.get('uses_remaining').errors.required">Please enter Maximum Uses</div>
            <div *ngIf="editAccessControlForm.get('uses_remaining').errors.min">Maximum Uses should exceed 0</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input fullwidth">
        <label>User Door Opening <sup>*</sup></label>
        <mat-radio-group formControlName="actuator_type" class="modal-radio-group">
          <mat-radio-button value=1>Automatic</mat-radio-button>
          <mat-radio-button value=2 class="ml-3">Manual</mat-radio-button>
          <mat-error *ngIf="!editAccessControlForm.get('actuator_type').valid && (editAccessControlForm.get('actuator_type').dirty || editAccessControlForm.get('actuator_type').touched || isSubmit)">
            <div *ngIf="editAccessControlForm.get('actuator_type').errors.required">Please select User Door Opening</div>
          </mat-error>
        </mat-radio-group>
      </div>
      <div class="access-modal-input">
        <button class="add-btn" type="submit">Submit</button>
      </div>
    </form>
  </ngx-smart-modal>
  <!-- Delete Access Control Modal -->
  <ngx-smart-modal #deleteAccessControlModal [identifier]="'deleteAccessControlModal'" [dismissable]="false" customClass="nsm-dialog-animation-ttb nsm-centered modal viewuser-modal">
    <h2 class="modal-title">Delete Confirmation</h2>
    <div class="modal-form addenclosure-form viewuser-form viewuser-access delete-enclosure-form">
      <div class="delete-confirmation">Do you really want to delete this Access Control?</div>
      <div class="w-100 d-flex justify-content-end align-items-center pt-4">
        <button type="button" class="delete-confirmation-cancel" (click)="CancelModal()">Cancel</button>
        <button type="submit" class="add-btn delete-enclosure" (click)="DeleteAccessControl()">Delete</button>
      </div>
    </div>
  </ngx-smart-modal>
</div>
