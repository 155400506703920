import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styles: [
  ]
})
export class ChangePasswordComponent implements OnInit {
  hide = true;
  nhide = true;
  chide = true;
  public isSubmit: boolean;
  public isLoading: boolean = false;
  confirmPwdErr: any = false;
  @ViewChild('changePasswordForm', { static: true }) changePasswordForm: NgForm;

  constructor(private _location: Location, private toastr: ToastrService, public service: AuthService, public router: Router) { }
  ngOnInit(): void {
    this.resetForm();
  }
  backClicked() {
    this._location.back();
  }

  onKey(event: any, pwd) {
    if (event.target.value && event.target.value != '') {
      if (event.target.value == pwd) {
        this.confirmPwdErr = false;
      } else {
        this.confirmPwdErr = true;
      }
    }
  }
  changePassword(form?: NgForm) {
    if (!form.valid || form.value.new_user_password != form.value.new_user_confirm_password) {
      this.isSubmit = true;
      if (form.value.new_user_password != form.value.new_user_confirm_password) {
        this.confirmPwdErr = true;
      }
      else {
        this.confirmPwdErr = false;
      }
      return;
    }
    else {
      this.isLoading = true;
      this.service.changePassword(form.value).subscribe((res) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          setTimeout(() => {
            this.isLoading = false;
          }, 5000);
          this.changePasswordForm.resetForm();
        }
        else {
          this.isLoading = false;
          this.toastr.error(res['msg'], '');
        }
      })
    }
    this.confirmPwdErr = false;
  }
  resetForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.ChangePasswordFormData = {
      new_user_confirm_password: '',
      old_user_password: '',
      new_user_password: ''
    };
  }
}
