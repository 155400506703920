import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {LoadingService} from '../app/services/loading.service'
import { delay } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{

  color = '#3DCC93';
  percentage = 90;
  title = 'dronedek-webapp';
  loading: boolean = false;

  constructor(
    public http: HttpClient,
    private _loading: LoadingService
  ) { }

  ngOnInit() {
    this.listenToLoading();
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
}
