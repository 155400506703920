import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { SharedAccessService } from '../shared-access/shared-access.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ota-access-enc',
  templateUrl: './ota-access-enc.component.html'
})
export class OtaAccessEncComponent implements OnInit {
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[2]);

  constructor(public accessService: SharedAccessService, private toastr: ToastrService, public service: AuthService, public route: ActivatedRoute, public router: Router) { }
  errMsg: string;
  isSubmit: boolean = false;
  isLoading: boolean = false;
  @ViewChild('checkOtpForm', { static: true }) checkOtpForm: NgForm;
  ngOnInit() {
    const enc_id = this.route.snapshot.params.id;
    this.resetOTPFrom();
    // console.log(enc_id,"get enclosure id>>>>>>>");
    this.service.otaAccessFormData.enclosure_id = enc_id
  }

  resetOTPFrom(form?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.service.otaAccessFormData = {
      user_otp: '',
      enclosure_id: '',
    };
  }
  checkOtp(form: NgForm) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.isLoading = true;
    this.accessService.checkOTP(form.value).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 3000);
      }
      if (res['status'] == 0) {
        this.isLoading = false;
        this.toastr.error(res['msg'], '');
      }
    });
  }
}
