import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { EnclosureDetailsService } from '../enclosure-details/enclosure-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import videojs from "video.js";
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-live-video-streaming',
  templateUrl: './live-video-streaming.component.html'
})

export class LiveVideoStreamingComponent implements OnInit, AfterViewInit, OnDestroy {

  public enclosure_id: any;
  private player: any;
  public isStreamAvailable = true;
  public isStreamContinuous = false;
  minutes: any;
  seconds: number = 0;
  timer: any;
  private timerSubscription: Subscription;
  private intervalId: any;
  url: any = '';

  constructor(
    private _location: Location,
    public enc_service: EnclosureDetailsService,
    public route: ActivatedRoute,
    public router: Router,
    private toastr: ToastrService,
  ) {
    this.intervalId = setInterval(() => this.checkStreaming(), 10000); // Check every 10 seconds
  }

  ngOnInit(): void {
    this.enclosure_id = atob(this.route.snapshot.params.eid);
    this.GetVideoStreaming();
  }

  backClicked() {
    this._location.back();
  }

  ngAfterViewInit() {
    this.TimerDetails();
  }

  handlePlayStream() {
    const options = {
      fluid: true,
      sources: [
        {
          src: this.url,
          type: 'application/x-mpegURL',
        },
      ],
      autoplay: true,
      controlBar: {
        pictureInPictureToggle: false
      }
    };
    this.player = videojs('my-video', options);
    this.player.on('error', () => {
      this.isStreamAvailable = false;
    });
    this.player.on('play', () => {
      this.isStreamAvailable = true;
    });
  }

  checkStreaming() {
    fetch(this.url)
      .then(response => {
        if (response.ok) {
          this.handlePlayStream();
          this.isStreamContinuous = true;
          if (this.intervalId) {
            clearInterval(this.intervalId); // Clear the interval when the component is destroyed
          }
        } else {
          this.isStreamContinuous = false;
        }
      })
      .catch(error => {
        console.error('Error checking streaming status:', error);
      });
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
    if (this.intervalId) {
      clearInterval(this.intervalId); // Clear the interval when the component is destroyed
    }

    if (this.timer) {
      clearInterval(this.timer); // Clear the timer interval
    }

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe(); // Unsubscribe from the timer observable
    }
  }

  GetVideoStreaming() {
    this.url = environment.video_streaming_url + this.enclosure_id + '.m3u8'
  }

  TimerDetails() {
    this.minutes = this.enc_service.sharedData;

    const totalSeconds = this.minutes * 60 + this.seconds;
    let currentSeconds = totalSeconds;

    if (this.isStreamAvailable == true){
      this.timer = setInterval(() => {
        if (currentSeconds <= 0) {
          clearInterval(this.timer);
          this._location.back();
          this.toastr.error('Live streaming retrieval expired. Restart to resume.', '');
        } else {
          currentSeconds--;
          this.minutes = Math.floor(currentSeconds / 60);
          this.seconds = currentSeconds % 60;
        }
      }, 1000);
    }
  }
}