import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ActuatorLogsService {

  constructor(
    public http: HttpClient
  ) { }

  // getActuatorLogsHistory(_id: any) {
  //   return this.http.get(environment.api_url + '/enclosures/enclosure_logs_readByid/' + _id);
  // }

  getActuatorLogsHistory(_id: any, page: number, limit: number) {
    return this.http.get(environment.api_url + '/enclosures/enclosure_logs_readByid/' + _id, { params: { page: page.toString(), limit: limit.toString() }
    });
  }

  

}
