<div class="container-fluid">
  <div class="main mx-2 mb-4">
    <div class="row">
      <div class="col-12">
        <div class="title-wrapper title-border-bottom mt-2">
          <h1 class="title-1">
            <button class="back-btn" (click)="backClicked()">
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
            Deliveries
          </h1>
          <div class="sub-title-wrapper">
            <div class="encl-name-subtitle">{{ enclosure_id }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="app-card">
          <div class="app-card-body">
            <div class="table-responsive">
              <mat-form-field
                floatLabel="never"
                appearance="standard"
                class="search-filter"
              >
                <span class="material-icons">search</span>
                <input
                  matInput
                  (keyup)="applyFilter($event)"
                  placeholder="Search"
                  #input
                />
              </mat-form-field>
              <table
                mat-table
                [dataSource]="dataSource"
                matSort
                class="app-table dashboard-app-table"
                aria-describedby="Device History"
              >
                <ng-container matColumnDef="position">
                  <th
                    scope="col"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    No.
                  </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{
                      this.paginator.pageIndex == 0
                        ? i + 1
                        : 1 +
                          i +
                          this.paginator.pageIndex * this.paginator.pageSize
                    }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="delivery_id">
                  <th
                    scope="col"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Delivery ID
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.delivery_id }}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="parcel_point_origin">
                  <th
                    scope="col"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Shipping Location
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.parcel_point_origin }}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="parcel_status">
                  <th
                    scope="col"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Package Status
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="orderstatus-wrap">
                      <span
                        class="orderstatus-msg"
                        [ngClass]="[
                          element?.parcel_status
                            | ParcelDeliveryStatus: 3:element?.parcel_status
                        ]"
                      >
                        {{
                          element?.parcel_status
                            | ParcelDeliveryStatus: 1:element?.parcel_status
                        }}
                      </span>
                      <span class="orderstatus-info">
                        {{
                          element?.parcel_status
                            | ParcelDeliveryStatus: 2:element?.parcel_status
                        }}
                      </span>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th
                    scope="col"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                  >
                    Action
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex">
                      <button
                        class="btn parcel-view-btn"
                        (click)="openParcelModal(element)"
                      >
                        <span class="material-icons">visibility</span>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
              <div class="drone-nodata-box" *ngIf="dataSourceEmpty">
                <div class="drone-nodata-box-wrap">
                  <div class="drone-nodata-box-cover">
                    <div class="drone-nodata-cover">
                      <span class="material-icons">error_outline</span>
                      <p class="drone-nodata-text">No records found!</p>
                    </div>
                  </div>
                </div>
              </div>
              <mat-paginator
                [pageSize]="10"
                class="app-paginator"
                [pageSizeOptions]="[10, 20, 30]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-smart-modal
  #viewparcel
  [identifier]="'viewparcel'"
  [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal viewparcel-modal"
>
  <h2 class="modal-title">
    Parcel Details
    <span>{{
      specificUserData?.parcel_status
        | parcelStatus: 2:specificUserData?.parcel_status
    }}</span>
  </h2>
  <div class="parcel-modal-topcover">
    <h3 class="modal-title-encl">
      <span class="modal-subtitle-encl">
        <img src="assets/images/encl-id.svg" alt="" />Device ID
      </span>
      <span class="modal-subtitle-encl-name">{{
        specificUserData?.enclosure_id
      }}</span>
    </h3>

    <h3 class="modal-title-encl">
      <span class="modal-subtitle-encl">
        <img src="assets/images/person-name.svg" alt="" />Name
      </span>
      <span class="modal-subtitle-encl-name">{{ userData?.user_name }}</span>
    </h3>
  </div>

  <h3 class="modal-title-encl">
    <span class="modal-subtitle-encl">
      <img src="assets/images/email-envelope.svg" alt="" />Email
    </span>
    <span class="modal-subtitle-encl-name">{{ userData?.user_email }}</span>
  </h3>

  <div class="viewparcel-box-block">
    <p class="viewparcel-box">
      <span class="viewparcel-box-title"
        ><img src="assets/images/time.svg" alt="" />Delivery ID:
      </span>
      <span class="viewparcel-box-info">{{
        specificUserData?.delivery_id
      }}</span>
    </p>
    <p class="viewparcel-box">
      <span class="viewparcel-box-title"
        ><img src="assets/images/required-temp.svg" alt="" />Required
        Temperature:</span
      >
      <span class="viewparcel-box-info"
        >Between: {{ specificUserData?.min_required_temp }}°C -
        {{ specificUserData?.max_required_temp }}°C</span
      >
    </p>
    <p class="viewparcel-box" *ngIf="specificUserData?.parcel_status != 2">
      <span class="viewparcel-box-title"
        ><img src="assets/images/time.svg" alt="" />Take Off Time:
      </span>
      <span
        class="viewparcel-box-info"
        *ngIf="specificUserData?.take_off_time"
        >{{
          specificUserData?.take_off_time * 1000
            | date: "d MMM, y h:mm
                a"
        }}</span
      >
      <span class="viewparcel-box-info" *ngIf="!specificUserData?.take_off_time"
        >Drone is not released yet!</span
      >
    </p>
    <p class="viewparcel-box" *ngIf="specificUserData?.parcel_status != 2">
      <span class="viewparcel-box-title"
        ><img src="assets/images/time.svg" alt="" />Estimated Time:
      </span>
      <span class="viewparcel-box-info" *ngIf="specificUserData?.take_off_time"
        >{{ specificUserData?.estimated_time }} Minutes</span
      >
      <span class="viewparcel-box-info" *ngIf="!specificUserData?.take_off_time"
        >Drone is not released yet!</span
      >
    </p>

    <p class="viewparcel-box">
      <span class="viewparcel-box-title"
        ><img src="assets/images/address.svg" alt="" />Address:</span
      >
      <span class="viewparcel-box-info">{{
        specificUserData?.address_line_a
      }}</span>
      <span class="viewparcel-box-info">{{
        specificUserData?.address_line_b
      }}</span>
      <span class="viewparcel-box-info"
        >{{ specificUserData?.city }}, {{ specificUserData?.state }},
        {{ specificUserData?.country }}, {{ specificUserData?.zipcode }}</span
      >
    </p>
    <p class="viewparcel-box">
      <span class="viewparcel-box-title"
        ><img src="assets/images/origin.svg" alt="" />Origin:</span
      >
      <span class="viewparcel-box-info">{{
        specificUserData?.parcel_point_origin
      }}</span>
    </p>
    <p class="viewparcel-status">
      <span
        class="viewparcel-status-box-title"
        [ngClass]="[
          specificUserData?.parcel_status
            | ParcelDeliveryStatus: 3:specificUserData?.parcel_status
        ]"
      >
        <span class="material-icons">
          {{
            specificUserData?.parcel_status
              | ParcelDeliveryStatus: 4:specificUserData?.parcel_status
          }}
        </span>
        {{
          specificUserData?.parcel_status
            | ParcelDeliveryStatus: 1:specificUserData?.parcel_status
        }}
      </span>

      <span
        class="viewparcel-status-box-info"
        *ngIf="specificUserData?.parcel_status"
      >
        {{
          specificUserData?.parcel_status
            | ParcelDeliveryStatus: 2:specificUserData?.parcel_status
        }}
      </span>
      <span
        class="viewparcel-status-box-info"
        *ngIf="
          specificUserData?.parcel_status == 2 ||
          specificUserData?.parcel_status == 9
        "
      >
        Reason: {{ specificUserData?.failure_reason }}
      </span>
    </p>
  </div>
</ngx-smart-modal>
