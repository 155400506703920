import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ThemePalette } from '@angular/material/core';
import { ForwardDeliveryListService } from '../forward-delivery-list/forward-delivery-list.service';
import { AuthService } from '../auth/auth.service';
import { EnclosureDetailsService } from '../enclosure-details/enclosure-details.service';
import * as moment from 'moment-timezone';
import { FormControl, FormGroup, Validators } from '@angular/forms';




@Component({
  selector: 'app-forward-delivery-list-history',
  templateUrl: './forward-delivery-list-history.component.html',
  styles: [
  ]
})
export class ForwardDeliveryListHistoryComponent implements OnInit {
  @ViewChild('deleteOutgoingForwardForm', { static: true }) deleteOutgoingForwardForm: NgForm;


  constructor(public encService: EnclosureDetailsService, private toastr: ToastrService, public ngxSmartModalService: NgxSmartModalService, private _location: Location, public authService: AuthService, public router: Router, public service: ForwardDeliveryListService, public route: ActivatedRoute,) { }
  public enclosure_id: any;
  public user_access: any;
  public type: any;
  public type_delivery: any;
  public owneruid: any;
  public enclosure_details: any;
  public forwarded_deliveries: any
  public isDelForwardSubmit: boolean = false;
  public isDelForwardLoading: boolean = false;
  public isForwardLoading: boolean = false;
  public isForwardSubmit: boolean = false;
  public disabled = false;
  public color: ThemePalette = 'primary';
  public showSpinners = true;

  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  currentDate: any = new Date();
  ngOnInit(): void {
    this.enclosure_id = atob(this.route.snapshot.params.eid);
    this.user_access = atob(this.route.snapshot.params.access);
    this.type = atob(this.route.snapshot.params.type);
    if (this.type == 'Incoming') {
      this.type_delivery = 'Incoming';
    } else {
      this.type_delivery = 'Outgoing';
    }
    this.owneruid = atob(this.route.snapshot.params.owneruid);
    this.getEnclosureDetail(this.enclosure_id);
    this.getForwardedDeliveries(this.enclosure_id);
    this.reseOutgoingForwardForm();
    this.getLoggedinUser();
    this.resetForwardForm();

    const now = new Date();
    this.minDate = new Date();
    this.minDate.setDate(now.getDate() - 0);
  }
  backClicked() {
    this._location.back();
  }
  resetForwardForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.ForwardDeliveryFormData = {
      enclosure_id: '',
      forward_enclosure_id: '',
      forward_user_email: '',
      forward_start_time: '',
      forward_end_time: ''
    };
  }
  getLoggedinUser() {
    this.authService.getLoggedinUser().subscribe(res => {
      if (res['status'] == 1) {
        let uData = res['data'];
        this.authService.UpdateProfileFormData = uData;
      }
      else {
        this.toastr.error(res['msg'], '');
        sessionStorage.removeItem('token');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 3000);
      }
    });


  }
  public del_id: any;
  openDeleteForwardModal(id) {
    console.log(id, "Delte id");
    this.del_id = id;
    this.deleteOutgoingForwardForm.resetForm();
    this.ngxSmartModalService.getModal('deleteoutgoingforward').open();
  }

  getEnclosureDetail(eid) {
    const send_data = { enclosure_id: eid };
    this.encService.getEncViewById(eid).subscribe((res) => {
      if (res['status'] == 1) {
        this.enclosure_details = res['data'];
      }
    })
  }

  getForwardedDeliveries(eid) {
    const send_data = { enclosure_id: eid, owner_user_id: (this.owneruid), forward_type: 2 };
    this.service.getForwardedDeliveries(send_data, this.type).subscribe((res) => {
      if (res['status'] == 1) {
        if (res['data'].length > 0) {
          this.forwarded_deliveries = res['data'];
        }
        else {
          this.forwarded_deliveries = [];
        }
      }
      else {
        this.forwarded_deliveries = [];
      }
    })
  }

  reseOutgoingForwardForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.DeleteForwardingFormData = {
      type_delete: ''
    };
  }

  public accessDetail: any;

  deleteForwardPer(form?: NgForm) {
    if (!form.valid) {
      this.isDelForwardSubmit = true;
      this.isDelForwardLoading = false;
      return;
    }
    else {
      this.isDelForwardLoading = true;
      if (form.value.type_delete == 'DELETE') {
        this.service.deleteForwarding({ _id: this.del_id }).subscribe((res) => {
          this.ngxSmartModalService.getModal('deleteoutgoingforward').close();
          if (res['status'] == 1) {
            this.isDelForwardLoading = false;
            this.toastr.success(res['msg'], '');
            this.getForwardedDeliveries(this.enclosure_id);
          }
          else {
            this.isDelForwardLoading = false;
            this.toastr.error(res['msg'], '');
          }
        });
      }
      else if (form.value.type_delete != 'DELETE') {
        this.toastr.error('Please enter DELETE keyword in input!', '');
        this.isDelForwardLoading = false;
        return
      }
    }
  }
  openPerDeleteModal(element) {
    this.accessDetail = element;
    this.ngxSmartModalService.getModal('perDeleteAccess').open()
  }
  openForwardDeliveryModal(element) {
    this.ngxSmartModalService.getModal('perDeleteAccess').close();
    this.ngxSmartModalService.getModal('forwarddelivery').open();
    this.getTimezone(element.forward_enclosure_id);
    this.service.ForwardDeliveryFormData.forward_enclosure_id = element.forward_enclosure_id;
    this.service.ForwardDeliveryFormData.forward_user_email = element.forward_user_email;
  }
  addForward(form?: NgForm) {
    if (form.value.forward_enclosure_id == this.enclosure_id) {
      this.toastr.error('You can not configure the forward delivery to your own device', '');
      this.isForwardLoading = false;
      return;
    }
    if (!form.valid) {
      this.isForwardSubmit = true;
      this.isForwardLoading = false;
      return;
    }
    else {
      this.isForwardLoading = true;
      form.value.forward_start_time = new Date(form.value.forward_start_time).getTime();
      form.value.forward_end_time = new Date(form.value.forward_end_time).getTime();
      if (form.value.forward_start_time.toString().length == 13) {
        form.value.forward_start_time = form.value.forward_start_time / 1000;
      }
      if (form.value.forward_end_time.toString().length == 13) {
        form.value.forward_end_time = form.value.forward_end_time / 1000;
      }
      form.value.enclosure_id = this.enclosure_id;
      this.service.addForwardDelivery(form.value).subscribe((res) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.getEnclosureDetail(this.enclosure_id);
          this.ngxSmartModalService.getModal('forwarddelivery').close();
        }
        else {
          this.isForwardLoading = false;
          this.toastr.error(res['msg'], '');
        }
      })
    }
  }
  permanentDeleteAccess(data) {
    this.service.deleteForwardingPermanent({ _id: data }).subscribe((res) => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        this.ngxSmartModalService.getModal('perDeleteAccess').close();
        this.getForwardedDeliveries(this.enclosure_id);
      }
      else {
        this.toastr.error(res['msg'], '');
      }
    })
  }
  public forwardedEncTimezone = '';
  public forwardTimeFormat: any = '';
  getTimezone(forwardedEnc) {
    if (forwardedEnc.length == 11) {
      this.encService.getEnclosureTimezone(forwardedEnc).subscribe(res => {
        if (res['status'] == 1) {
          this.forwardedEncTimezone = res['data'].timezone;
          this.forwardTimeFormat = moment().tz(res['data'].timezone).format('yy, M, DD, H, mm, ss').split(",", 6);
          this.service.ForwardDeliveryFormData.forward_start_time = new FormControl(new Date(this.forwardTimeFormat[0], this.forwardTimeFormat[1] - 1, this.forwardTimeFormat[2], this.forwardTimeFormat[3], this.forwardTimeFormat[4], this.forwardTimeFormat[5]));
          this.service.ForwardDeliveryFormData.forward_end_time = new FormControl(new Date(this.forwardTimeFormat[0], this.forwardTimeFormat[1] - 1, this.forwardTimeFormat[2], this.forwardTimeFormat[3], this.forwardTimeFormat[4], this.forwardTimeFormat[5]));
        }
        else {
          this.toastr.error(res['msg'], '');
        }
      });
    }
  }
}
