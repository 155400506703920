<div class="container-fluid">
  <div class="main mx-2 mb-4">
    <div class="row">
      <div class="col-12">
        <div class="title-wrapper title-border-bottom mt-2">
          <h1 class="title-1">
            <button class="back-btn" (click)="backClicked()">
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
            <em class="app-card-icon app-card-icon-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="13.655"
                viewBox="0 0 18 13.655"
              >
                <defs>
                  <style>
                    .a {
                      fill: #0E48CA;
                    }
                  </style>
                </defs>
                <g transform="translate(0 -73.862)">
                  <g transform="translate(0 73.862)">
                    <path
                      class="a"
                      d="M1.862,73.862a1.862,1.862,0,1,0,1.862,1.862A1.862,1.862,0,0,0,1.862,73.862Zm0,4.965A1.862,1.862,0,1,0,3.724,80.69,1.862,1.862,0,0,0,1.862,78.827Zm0,4.966a1.862,1.862,0,1,0,1.862,1.862A1.862,1.862,0,0,0,1.862,83.793Zm4.966-6.828h9.931a1.241,1.241,0,0,0,0-2.483H6.828a1.241,1.241,0,1,0,0,2.483Zm9.931,2.483H6.828a1.241,1.241,0,1,0,0,2.483h9.931a1.241,1.241,0,0,0,0-2.483Zm0,4.966H6.828a1.241,1.241,0,1,0,0,2.483h9.931a1.241,1.241,0,1,0,0-2.483Z"
                      transform="translate(0 -73.862)"
                    />
                  </g>
                </g>
              </svg>
            </em>
            Deleted/Expired Shared Access
          </h1>
          <div class="sub-title-wrapper">
            <div class="encl-name-subtitle">
              {{ enclosure_details?.enclosure_name }}
            </div>
            <div class="title-access">
              <span
                class="access-card"
                [ngClass]="{
                  'access-green': user_access == 1,
                  'access-orange': user_access == 2,
                  'access-purple': user_access == 3,
                  'access-gray': user_access == 4
                }"
              >
                {{ user_access | accessNamePipe: user_access }}</span
              >
            </div>
          </div>
        </div>
        <div class="warning-alert">
          <span class="material-icons">warning_amber</span>
          <p class="warning-alert-text">
            <strong>Warning:</strong>Recently deleted/expired access will be
            permanently delete after 30 days!.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="app-card">
          <div class="app-card-body">
            <div class="table-responsive">
              <mat-form-field
                floatLabel="never"
                appearance="standard"
                class="search-filter"
              >
                <span class="material-icons">search</span>
                <input
                  matInput
                  (keyup)="applyFilter($event)"
                  placeholder="Search"
                  #input
                />
              </mat-form-field>
              <table
                mat-table
                [dataSource]="dataSource"
                matSort
                class="app-table"
                #TableOneSort="matSort"
                aria-describedby="accessHistory"
              >
                <ng-container matColumnDef="user_name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="myId3">
                    Name
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="!element?.user_name" class="table-span"
                      >---</span
                    >
                    <span *ngIf="element?.user_name" class="table-span"
                      >{{ element?.user_name }}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="user_email">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="myId4">
                    Email / Mobile
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element?.user_email }} {{ element?.user_mobile }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="user_access">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="myId5">
                    Name
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      class="access-card"
                      [ngClass]="{
                        'primary-access': element.user_access == 1,
                        'secondary-access': element.user_access == 2,
                        'temporary-access': element.user_access == 3,
                        'onetime-access': element.user_access == 4
                      }"
                    >
                      {{
                        element.user_access
                          | accessNamePipe: element.user_access
                      }}</span
                    >
                  </td>
                </ng-container>

                <ng-container matColumnDef="duration">
                  <th mat-header-cell *matHeaderCellDef id="myId6">
                    Duration
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      class="duration-card access-card primary-access"
                      *ngIf="element.user_access == 1"
                      >---</span
                    >
                    <span
                      class="duration-card access-card secondary-access"
                      *ngIf="element.user_access == 2"
                    >
                      ---</span
                    >
                    <span
                      class="duration-card access-card temporary-access"
                      *ngIf="element.user_access == 3"
                    >
                      <span
                        ><strong>From:</strong>
                        {{
                          element?.start_time * 1000
                            | date
                              : "d MMM, y
                                                h:mm a"
                        }}</span
                      >
                      <span
                        ><strong>Until:</strong>
                        {{
                          element?.end_time * 1000
                            | date
                              : "d MMM, y h:mm
                                                a"
                        }}</span
                      >
                    </span>
                    <span
                      class="duration-card access-card onetime-access"
                      *ngIf="element.user_access == 4"
                    >
                      <span
                        ><strong>From:</strong>
                        {{
                          element?.start_time * 1000
                            | date
                              : "d MMM, y
                                                h:mm a"
                        }}</span
                      >
                      <span
                        ><strong>Until:</strong>
                        {{
                          element?.end_time * 1000
                            | date
                              : "d MMM, y h:mm
                                                a"
                        }}</span
                      >
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="access_status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="myId7">
                    Status
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      class="access-card primary-access"
                      *ngIf="element.access_status == 0"
                    >
                      Inactive</span
                    >
                    <span
                      class="access-card secondary-access"
                      *ngIf="element.access_status == 2"
                    >
                      Expired
                    </span>
                    <span
                      class="access-card deleted-access"
                      *ngIf="element.access_status == 3"
                    >
                      Deleted</span
                    >
                  </td>
                </ng-container>

                <ng-container matColumnDef="deleted">
                  <th mat-header-cell *matHeaderCellDef id="myId8">
                    Deleted/Expired On
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="table-span"
                      >{{ element.updated_at * 1000 | date: "d MMM y, h:mm a" }}
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef id="myId9">
                    Action
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table-btn-wrap">
                      <button
                        *ngIf="
                          element.user_access != 1 &&
                          (user_access == 1 ||
                            (user_access == 2 && element?.user_access == 3) ||
                            (user_access == 2 && element?.user_access == 4))
                        "
                        class="btn-delivered btn-table"
                        (click)="openReAssignModal(element)"
                      >
                        <span class="svg-icon"
                          ><img src="assets/images/forward-access.svg" alt=""
                        /></span>
                      </button>
                      <button
                        *ngIf="
                          element.user_access != 1 &&
                          (user_access == 1 ||
                            (user_access == 2 && element?.user_access == 3) ||
                            (user_access == 2 && element?.user_access == 4))
                        "
                        class="btn-delete btn-table"
                        (click)="openPerDeleteModal(element)"
                      >
                        <span class="material-icons">delete_forever</span>
                      </button>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
              <div class="drone-nodata-box" *ngIf="dataSourceEmpty">
                <div class="drone-nodata-box-wrap">
                  <div class="drone-nodata-box-cover">
                    <div class="drone-nodata-cover">
                      <span class="material-icons">error_outline</span>
                      <p class="drone-nodata-text">No records found!</p>
                    </div>
                  </div>
                </div>
              </div>
              <mat-paginator
                *ngIf="!dataSourceEmpty"
                class="app-paginator"
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-smart-modal
  #perDeleteAccess
  [identifier]="'perDeleteAccess'"
  [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal delete-modal"
>
  <p class="modal-text">
    Are you sure you want to permanently delete this history data?
  </p>
  <div class="d-flex justify-content-center delete-bottom">
    <button
      class="delete-btns yes-btn mr-2"
      (click)="permanentDeleteAccess(accessDetail)"
    >
      Yes
    </button>
    <button
      class="delete-btns no-btn"
      (click)="ngxSmartModalService.getModal('perDeleteAccess').close()"
    >
      No
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #secondaryaccess
  [identifier]="'secondaryaccess'"
  [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal forwarddelivery-modal"
>
  <h2 class="modal-title">Secondary Access</h2>
  <form
    #secondaryAccessForm="ngForm"
    (ngSubmit)="addSecondaryAccess(secondaryAccessForm)"
    class="modal-form forwarddelivery-form"
  >
    <div class="modal-input">
      <label> Name<sup class="sup">*</sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input
          readonly
          matInput
          placeholder="Ex. John Doe"
          name="user_name"
          #user_name="ngModel"
          [(ngModel)]="encDetailService.SecondaryAccessFormData.user_name"
          [ngClass]="{
            'is-invalid':
              !user_name.valid &&
              (user_name.dirty || user_name.touched || isSecondarySubmit)
          }"
          required
        />
        <mat-error
          *ngIf="
            !user_name.valid &&
            (user_name.dirty || user_name.touched || isSecondarySubmit)
          "
        >
          Please enter name
        </mat-error>
      </mat-form-field>
    </div>
    <div class="modal-input">
      <label> Email<sup class="sup">*</sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input
          readonly
          maxlength="30"
          pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
          matInput
          placeholder="Ex. abc@gmail.com"
          name="user_email"
          #user_email="ngModel"
          [(ngModel)]="encDetailService.SecondaryAccessFormData.user_email"
          [ngClass]="{
            'is-invalid':
              !user_email.valid &&
              (user_email.dirty || user_email.touched || isSecondarySubmit)
          }"
          required
        />
        <mat-error
          *ngIf="
            !user_email.valid &&
            (user_email.dirty || user_email.touched || isSecondarySubmit)
          "
        >
          <div *ngIf="user_email.errors.required">
            Please enter email address
          </div>
          <div *ngIf="user_email.errors.pattern">Enter valid email address</div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="modal-input">
      <label>Device ID<sup class="sup">*</sup></label>
      <span class="dis_enc_id">{{
        encDetailService.SecondaryAccessFormData.enclosure_id
      }}</span>
    </div>
    <div class="d-flex justify-content-center">
      <button [disabled]="isSecondaryLoading" class="add-btn">
        <em class="mr-2"><img src="assets/images/temp-access.svg" alt="" /></em>
        Give Secondary Access
      </button>
    </div>
  </form>
</ngx-smart-modal>

<ngx-smart-modal
  #temporaryaccess
  [identifier]="'temporaryaccess'"
  [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal forwarddelivery-modal"
>
  <h2 class="modal-title">Temporary Access</h2>
  <form
    #temporaryAccessForm="ngForm"
    (ngSubmit)="addTemporaryAccess(temporaryAccessForm)"
    class="modal-form forwarddelivery-form"
  >
    <div class="modal-input">
      <label> Name<sup class="sup">*</sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input
          matInput
          placeholder="Ex. John Doe"
          name="user_name"
          #user_name="ngModel"
          [(ngModel)]="encDetailService.TemporaryAccessFormData.user_name"
          [ngClass]="{
            'is-invalid':
              !user_name.valid &&
              (user_name.dirty || user_name.touched || isTemporarySubmit)
          }"
          required
        />
        <mat-error
          *ngIf="
            !user_name.valid &&
            (user_name.dirty || user_name.touched || isTemporarySubmit)
          "
        >
          Please enter name
        </mat-error>
      </mat-form-field>
    </div>
    <div class="modal-input">
      <label> Email<sup class="sup">*</sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input
          maxlength="30"
          pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
          matInput
          placeholder="Ex. abc@gmail.com"
          name="user_email"
          #user_email="ngModel"
          [(ngModel)]="encDetailService.TemporaryAccessFormData.user_email"
          [ngClass]="{
            'is-invalid':
              !user_email.valid &&
              (user_email.dirty || user_email.touched || isTemporarySubmit)
          }"
          required
        />
        <mat-error
          *ngIf="
            !user_email.valid &&
            (user_email.dirty || user_email.touched || isTemporarySubmit)
          "
        >
          <div *ngIf="user_email.errors.required">
            Please enter email address
          </div>
          <div *ngIf="user_email.errors.pattern">Enter valid email address</div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="modal-input">
      <label> Device ID </label>
      <span class="dis_enc_id">{{
        encDetailService.TemporaryAccessFormData.enclosure_id
      }}</span>
    </div>

    <div class="modal-input">
      <label> Device Timezone </label>
      <span class="modal-input-subtext">{{ enclosure_details?.timezone }}</span>
    </div>

    <div class="modal-input">
      <label> Set Start Date &amp; Time<sup class="sup">*</sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input
          matInput
          [ngxMatDatetimePicker]="temp_startpicker"
          placeholder="Ex. 12/15/2020, 4:05 PM"
          [formControl]="encDetailService.TemporaryAccessFormData.start_time"
          [min]="minDate"
          [max]="maxDate"
          [disabled]="disabled"
        />
        <mat-datepicker-toggle matSuffix [for]="temp_startpicker">
        </mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #temp_startpicker
          [showSpinners]="showSpinners"
          [showSeconds]="showSeconds"
          [stepHour]="stepHour"
          [stepMinute]="stepMinute"
          [stepSecond]="stepSecond"
          [touchUi]="touchUi"
          [color]="color"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>

    <div class="modal-input">
      <label> Set End Date &amp; Time<sup class="sup">*</sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input
          matInput
          [ngxMatDatetimePicker]="temp_endpicker"
          placeholder="Ex. 12/15/2020, 4:05 PM"
          [formControl]="encDetailService.TemporaryAccessFormData.end_time"
          [min]="minDate"
          [max]="maxDate"
          [disabled]="disabled"
        />
        <mat-datepicker-toggle matSuffix [for]="temp_endpicker">
        </mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #temp_endpicker
          [showSpinners]="showSpinners"
          [showSeconds]="showSeconds"
          [stepHour]="stepHour"
          [stepMinute]="stepMinute"
          [stepSecond]="stepSecond"
          [touchUi]="touchUi"
          [color]="color"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-center">
      <button [disabled]="isTemporaryLoading" class="add-btn">
        <em class="mr-2"><img src="assets/images/temp-access.svg" alt="" /></em>
        Give Temporary Access
      </button>
    </div>
  </form>
</ngx-smart-modal>

<ngx-smart-modal
  #existingTempDetail
  [identifier]="'existingTempDetail'"
  [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal duration-modal"
>
  <h2 class="duration-modal-title">
    These users have temporary access to your device for the same duration.
  </h2>
  <form
    #existingOTAForm="ngForm"
    class="modal-form duration-form"
    (ngSubmit)="addTempAfterChecked()"
  >
    <div class="duration-modal-body">
      <div class="duration-modal-info" *ngFor="let data of existingTempAccess">
        <h3 class="duration-modal-username">{{ data.user_name }}</h3>
        <div class="duration-modal-datewrap">
          <label class="duration-modal-date">{{
            data.start_time * 1000 | date: "d MMM, y h:mm a"
          }}</label>
          <label class="duration-modal-date">{{
            data.end_time * 1000 | date: "d MMM, y h:mm a"
          }}</label>
        </div>
      </div>
      <p class="duration-modal-text">
        Still you want to give temporary access to
        {{ encDetailService.TemporaryAccessFormData.user_name }}?
      </p>
    </div>
    <div class="d-flex justify-content-center duration-bottom">
      <button class="delete-btns yes-btn mr-2" type="submit">
        Give Access
      </button>
      <button
        type="button"
        class="delete-btns no-btn"
        (click)="closeExistingTempModal()"
      >
        Cancel
      </button>
    </div>
  </form>
</ngx-smart-modal>

<ngx-smart-modal
  #onetimeaccess
  [identifier]="'onetimeaccess'"
  [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal forwarddelivery-modal"
>
  <h2 class="modal-title">One Time Access</h2>
  <form
    #OTAAccessForm="ngForm"
    (ngSubmit)="addOTAAccess(OTAAccessForm)"
    class="modal-form forwarddelivery-form"
  >
    <div class="modal-input">
      <label>Device ID</label>
      <span class="dis_enc_id">{{
        encDetailService.OTAAccessFormData.enclosure_id
      }}</span>
    </div>

    <div class="modal-input">
      <label> Device Timezone </label>
      <span class="modal-input-subtext">{{ enclosure_details?.timezone }}</span>
    </div>

    <div class="modal-input">
      <label>
        <em>
          <img src="assets/images/calendar.svg" alt="" />
        </em>
        Set Start Date &amp; Time<sup class="sup">*</sup></label
      >
      <mat-form-field floatLabel="never" appearance="standard">
        <input
          matInput
          [ngxMatDatetimePicker]="ota_startpicker"
          placeholder="Ex. 12/15/2020, 4:05 PM"
          [formControl]="encDetailService.OTAAccessFormData.start_time"
          [min]="minDate"
          [max]="maxDate"
          [disabled]="disabled"
        />
        <mat-datepicker-toggle matSuffix [for]="ota_startpicker">
        </mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #ota_startpicker
          [showSpinners]="showSpinners"
          [showSeconds]="showSeconds"
          [stepHour]="stepHour"
          [stepMinute]="stepMinute"
          [stepSecond]="stepSecond"
          [touchUi]="touchUi"
          [color]="color"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>

    <div class="modal-input">
      <label>
        <em>
          <img src="assets/images/calendar.svg" alt="" />
        </em>
        Set End Date &amp; Time<sup class="sup">*</sup></label
      >

      <mat-form-field floatLabel="never" appearance="standard">
        <input
          matInput
          [ngxMatDatetimePicker]="ota_endpicker"
          placeholder="Ex. 12/15/2020, 4:05 PM"
          [formControl]="encDetailService.OTAAccessFormData.end_time"
          [min]="minDate"
          [max]="maxDate"
          [disabled]="disabled"
        />
        <mat-datepicker-toggle matSuffix [for]="ota_endpicker">
        </mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #ota_endpicker
          [showSpinners]="showSpinners"
          [showSeconds]="showSeconds"
          [stepHour]="stepHour"
          [stepMinute]="stepMinute"
          [stepSecond]="stepSecond"
          [touchUi]="touchUi"
          [color]="color"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
    <div class="modal-input">
      <label>
        <em>
          <img src="assets/images/phone.svg" alt="" class="modal-email-icon" />
        </em>
        Contact Number<sup class="sup">*</sup><br />
      </label>
      <div class="phoneform phoneform-relative ota-phoneform">
        <ngx-intl-tel-input
          [cssClass]="'custom'"
          [separateDialCode]="false"
          [enableAutoCountrySelect]="false"
          [enablePlaceholder]="true"
          [searchCountryFlag]="false"
          [searchCountryField]="[
            SearchCountryField.Iso2,
            SearchCountryField.Name
          ]"
          [selectFirstCountry]="false"
          [selectedCountryISO]="countrySortName"
          [maxLength]="20"
          [tooltipField]="TooltipLabel.Name"
          [phoneValidation]="true"
          name="user_mobile"
          [(ngModel)]="encDetailService.OTAAccessFormData.user_mobile"
          required
          (ngModelChange)="
            checkNumber($event, encDetailService.OTAAccessFormData.user_mobile)
          "
        >
        </ngx-intl-tel-input>
        <div class="ngx-error">
          <strong
            *ngIf="
              !(
                OTAAccessForm.form.controls['user_mobile']?.invalid == true &&
                phoneError == true
              ) &&
              OTAAccessForm.form.controls['user_mobile']?.touched &&
                OTAAccessForm.form.controls['user_mobile']?.invalid == true
            "
            >Please enter valid contact number</strong
          >
          <strong
            *ngIf="
              OTAAccessForm.form.controls['user_mobile']?.invalid == true &&
              phoneError == true
            "
            >Please enter valid contact number</strong
          >
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button class="add-btn">
        <em class="mr-2"><img src="assets/images/temp-access.svg" alt="" /></em>
        Give One Time Access
      </button>
    </div>
  </form>
</ngx-smart-modal>

<ngx-smart-modal
  #existingOTADetail
  [identifier]="'existingOTADetail'"
  [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal delete-modal onetime-access-modal"
>
  <p class="modal-text">
    These users have one time access to your device for the same duration.
  </p>
  <form
    #existingOTAForm="ngForm"
    class="modal-form delete-form"
    (ngSubmit)="addOTAAfterChecked()"
  >
    <div class="app-card-body">
      <div class="app-card-info" *ngFor="let data of existingOTAAccess">
        <p class="app-card-name">
          <em class="app-card-nameicon">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 15.2"
              style="enable-background: new 0 0 16 15.2"
              xml:space="preserve"
            >
              <style type="text/css">
                .st0 {
                  fill: none;
                  stroke: #8d99ae;
                  stroke-width: 2;
                }
              </style>
              <g transform="translate(1.002 1)">
                <g transform="translate(0 0)">
                  <path
                    class="st0"
                    d="M0.6,13.2h12.9c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.2,0.1-0.4c-0.3-2.3-1.7-4.3-3.7-5.4
			c-1.8,1.8-4.7,1.8-6.5,0c0,0,0,0,0,0c-2,1.1-3.4,3.1-3.7,5.4c0,0.1,0,0.3,0.1,0.4C0.2,13.1,0.4,13.2,0.6,13.2z"
                  />
                  <path
                    class="st0"
                    d="M4.1,6.8C4.2,6.8,4.3,6.9,4.3,7c1.5,1.4,3.8,1.4,5.3,0c0.1-0.1,0.2-0.1,0.2-0.2s0.1-0.2,0.2-0.2
			c1.4-1.7,1.1-4.2-0.6-5.6S5.3-0.2,3.9,1.5c-1.2,1.5-1.2,3.6,0,5C4,6.6,4,6.7,4.1,6.8z"
                  />
                </g>
              </g>
            </svg>
          </em>
          <span>{{ data.user_mobile }}</span>
        </p>
        <div class="app-card-body-insidewrap">
          <p class="app-card-time">
            {{ data.start_time * 1000 | date: "d MMM, y h:mm a" }}
          </p>
          <p class="app-card-time">
            {{ data.end_time * 1000 | date: "d MMM, y h:mm a" }}
          </p>
        </div>
      </div>
      <p class="modal-body-text">
        Still you want to give one time access to
        <span class="modal-id-text">{{
          encDetailService.OTAAccessFormData.user_mobile
        }}</span
        >?
      </p>
    </div>
    <div class="d-flex justify-content-center delete-bottom">
      <button class="delete-btns yes-btn mr-2" type="submit">
        Give Access
      </button>
      <button
        type="button"
        class="delete-btns no-btn cancel-btn"
        (click)="closeExistingOTAModal()"
      >
        Cancel
      </button>
    </div>
  </form>
</ngx-smart-modal>
