<div class="container-fluid">
  <div class="main mx-2 mb-4">
    <div class="row">
      <div class="col-12">
        <div class="title-wrapper title-border-bottom mt-2">
          <h1 class="title-1">
            <button class="back-btn" (click)="backClicked()">
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
            <em class="app-card-icon app-card-icon-title">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13.655" viewBox="0 0 18 13.655">
                <defs>
                  <style>
                    .a {
                      fill: #83E349;
                    }
                  </style>
                </defs>
                <g transform="translate(0 -73.862)">
                  <g transform="translate(0 73.862)">
                    <path class="a"
                      d="M1.862,73.862a1.862,1.862,0,1,0,1.862,1.862A1.862,1.862,0,0,0,1.862,73.862Zm0,4.965A1.862,1.862,0,1,0,3.724,80.69,1.862,1.862,0,0,0,1.862,78.827Zm0,4.966a1.862,1.862,0,1,0,1.862,1.862A1.862,1.862,0,0,0,1.862,83.793Zm4.966-6.828h9.931a1.241,1.241,0,0,0,0-2.483H6.828a1.241,1.241,0,1,0,0,2.483Zm9.931,2.483H6.828a1.241,1.241,0,1,0,0,2.483h9.931a1.241,1.241,0,0,0,0-2.483Zm0,4.966H6.828a1.241,1.241,0,1,0,0,2.483h9.931a1.241,1.241,0,1,0,0-2.483Z"
                      transform="translate(0 -73.862)" />
                  </g>
                </g>
              </svg>
            </em>
            {{ enclosure_details?.enclosure_name }}
          </h1>
          <div class="grant-wrap">
            <button class="delete-btn grant-btn" *ngIf="enclosure_details?.external_humidity == 108"
              (click)="viewLiveTrackingIcon()">
              <em class="delete-btn-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.592" viewBox="0 0 18 18.592">
                  <defs>
                    <style>
                      .a {
                        fill: #ffffff;
                      }
                    </style>
                  </defs>
                  <path class="a"
                    d="M12.232,11.159l7.433,7.433H4.8Zm8.215-2.7v9.348l-7.433-7.433L14.5,8.892l-.085-.1c-.2-.245-.409-.5-.609-.765L4.017,17.811V2.531a.368.368,0,0,1,.368-.368h8.948A4.718,4.718,0,0,1,22.017,4.72,5.754,5.754,0,0,1,20.447,8.462ZM11.264,5.927H8.533v1.04h1.608a1.69,1.69,0,1,1-1.608-2.21,1.674,1.674,0,0,1,1.03.35l.634-.824a2.7,2.7,0,0,0-1.664-.566,2.73,2.73,0,1,0,2.73,2.73v-.52ZM20.912,4.72a3.615,3.615,0,0,0-7.23,0c0,3,3.394,3.861,3.394,7.436h.443C17.518,8.581,20.912,7.721,20.912,4.72ZM17.3,3.067a1.409,1.409,0,1,0,1.409,1.409A1.409,1.409,0,0,0,17.3,3.067Z"
                    transform="translate(-4.017)" />
                </svg>
              </em>
              <span class="pt-1">Drone</span>
            </button>
            <button class="delete-btn grant-btn" (click)="viewAccessControl()">
              <em class="delete-btn-icon">
                <img src="../../assets/images/access-control.svg" alt="Access" width="20">
              </em>
              <span class="pt-1">Access Control</span>
            </button>
            <button class="delete-btn grant-btn" (click)="viewLiveTracking()">
              <em class="delete-btn-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.592" viewBox="0 0 18 18.592">
                  <defs>
                    <style>
                      .a {
                        fill: #ffffff;
                      }
                    </style>
                  </defs>
                  <path class="a"
                    d="M12.232,11.159l7.433,7.433H4.8Zm8.215-2.7v9.348l-7.433-7.433L14.5,8.892l-.085-.1c-.2-.245-.409-.5-.609-.765L4.017,17.811V2.531a.368.368,0,0,1,.368-.368h8.948A4.718,4.718,0,0,1,22.017,4.72,5.754,5.754,0,0,1,20.447,8.462ZM11.264,5.927H8.533v1.04h1.608a1.69,1.69,0,1,1-1.608-2.21,1.674,1.674,0,0,1,1.03.35l.634-.824a2.7,2.7,0,0,0-1.664-.566,2.73,2.73,0,1,0,2.73,2.73v-.52ZM20.912,4.72a3.615,3.615,0,0,0-7.23,0c0,3,3.394,3.861,3.394,7.436h.443C17.518,8.581,20.912,7.721,20.912,4.72ZM17.3,3.067a1.409,1.409,0,1,0,1.409,1.409A1.409,1.409,0,0,0,17.3,3.067Z"
                    transform="translate(-4.017)" />
                </svg>
              </em>
              <span class="pt-1">Drone Tracking</span>
            </button>
            <button *ngIf="
                enclosure_details?.user_own_access == 1 ||
                enclosure_details?.user_own_access == 2
              " class="delete-btn grant-btn" (click)="openGrantAccessModal()">
              <em class="delete-btn-icon">
                <svg id="Group_269" data-name="Group 269" xmlns="http://www.w3.org/2000/svg" width="15.209"
                  height="18.25" viewBox="0 0 15.209 18.25">
                  <g id="Group_268" data-name="Group 268">
                    <path id="Path_110" data-name="Path 110"
                      d="M306.271,274.255a.753.753,0,0,1-.3-.064c-.3-.129-7.3-3.261-7.3-9.659v-5.378a.762.762,0,0,1,.509-.718l6.844-2.389a.768.768,0,0,1,.5,0l6.844,2.389a.762.762,0,0,1,.509.718v5.378c0,6.4-7,9.53-7.3,9.659a.753.753,0,0,1-.3.064Zm-6.083-14.561v4.838c0,4.722,4.81,7.474,6.082,8.118,1.271-.646,6.085-3.41,6.085-8.118v-4.838l-6.084-2.123Z"
                      transform="translate(-298.667 -256.005)" fill="#fff" />
                  </g>
                  <path id="Path_111" data-name="Path 111"
                    d="M354.277,343.262a.349.349,0,0,1-.041,0,.757.757,0,0,1-.56-.294l-2.49-3.217a.761.761,0,0,1,1.2-.932l1.951,2.523,3.744-3.872a.76.76,0,1,1,1.093,1.057l-4.354,4.5a.766.766,0,0,1-.547.233Z"
                    transform="translate(-347.295 -331.447)" fill="#fff" />
                </svg>
              </em>
              <span class="pt-1">Grant Device Access</span>
            </button>
            <button (click)="opneSettingsModal(enclosure_details)" class="grant-btn settings-btn">
              <em class="settings-btn-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="12.618" height="12.972" viewBox="0 0 12.618 12.972">
                  <path id="Path_366" data-name="Path 366"
                    d="M13.795,9.521a4.779,4.779,0,0,0,.041-.635,3.9,3.9,0,0,0-.047-.635L15.16,7.183a.331.331,0,0,0,.081-.412l-1.3-2.243a.33.33,0,0,0-.4-.149l-1.615.649a4.773,4.773,0,0,0-1.095-.635l-.243-1.716a.327.327,0,0,0-.324-.277H7.674a.32.32,0,0,0-.318.277L7.113,4.393a4.878,4.878,0,0,0-1.095.635L4.4,4.38a.323.323,0,0,0-.4.149L2.715,6.771a.307.307,0,0,0,.081.412L4.167,8.251a3.759,3.759,0,0,0-.014,1.27L2.782,10.589A.331.331,0,0,0,2.7,11L4,13.244a.33.33,0,0,0,.4.149l1.615-.649a4.773,4.773,0,0,0,1.095.635l.243,1.716a.332.332,0,0,0,.324.277h2.594a.315.315,0,0,0,.318-.277l.243-1.716a4.634,4.634,0,0,0,1.095-.635l1.615.649a.323.323,0,0,0,.4-.149L15.234,11a.313.313,0,0,0-.081-.412Zm-4.824,1.8A2.432,2.432,0,1,1,11.4,8.886,2.439,2.439,0,0,1,8.971,11.318Z"
                    transform="translate(-2.662 -2.4)" fill="#ffffff" />
                </svg>
              </em>
              <span class="pt-1">Device Settings</span>
            </button>
            <button mat-button [matMenuTriggerFor]="menu" class="delete-btn grant-btn history-btn" title="History">
              <span class="material-icons">history</span>
              <span class="material-icons">arrow_drop_down</span>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="parcelDeliveryDetails(enclosure_details)" class="parcel-btn">
                <em class="parcel-btn-icon">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 17.7 12.2"
                  style="enable-background: new 0 0 17.7 12.2" xml:space="preserve">
                  <style type="text/css">
                    .st0 {
                      fill: #0E48CA;
                    }
                  </style>
                  <g transform="translate(-0.001 -80)">
                    <g transform="translate(3.321 80)">
                      <g transform="translate(0 0)">
                        <path class="st0"
                          d="M12.2,0h-11C0.5,0,0,0.5,0,1.1s0.5,1.1,1.1,1.1h11c0.6,0,1.1-0.5,1.1-1.1S12.8,0,12.2,0z" />
                      </g>
                    </g>
                    <g transform="translate(0.001 80.005)">
                      <g transform="translate(0 0)">
                        <path class="st0" d="M2.2,1.1C2.2,0.5,1.7,0,1.1,0S0,0.5,0,1.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
				c0,0.1,0.1,0.1,0.1,0.2C0.4,1.9,0.4,2,0.5,2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0
				c0.3,0,0.6-0.1,0.8-0.3C1.9,1.8,2,1.8,2,1.7c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2C2.2,1.2,2.2,1.2,2.2,1.1z" />
                      </g>
                    </g>
                    <g transform="translate(0.007 84.975)">
                      <g transform="translate(0 0)">
                        <path class="st0" d="M2.2,1.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2
				c-0.4-0.4-1.1-0.4-1.6,0C0.1,0.5,0,0.8,0,1.1c0,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.1,0.1,0.2C0.4,1.9,0.4,2,0.5,2
				c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
				C1.8,2,1.8,2,1.9,1.9C1.9,1.9,2,1.8,2,1.7c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2C2.2,1.2,2.2,1.2,2.2,1.1z" />
                      </g>
                    </g>
                    <g transform="translate(0.007 89.946)">
                      <path class="st0" d="M2.2,1.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2
			c-0.4-0.4-1.1-0.4-1.6,0c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2C0,1,0,1,0,1.1
			c0,0.3,0.1,0.6,0.3,0.8C0.4,1.9,0.4,2,0.5,2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
			c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1C1.8,2,1.8,2,1.9,1.9C1.9,1.9,2,1.8,2,1.7c0-0.1,0.1-0.1,0.1-0.2
			c0-0.1,0.1-0.2,0.1-0.2C2.2,1.2,2.2,1.2,2.2,1.1z" />
                    </g>
                    <g transform="translate(3.321 84.971)">
                      <g transform="translate(0 0)">
                        <path class="st0"
                          d="M13.3,0H1.1C0.5,0,0,0.5,0,1.1c0,0.6,0.5,1.1,1.1,1.1h12.2c0.6,0,1.1-0.5,1.1-1.1C14.4,0.5,13.9,0,13.3,0z" />
                      </g>
                    </g>
                    <g transform="translate(3.321 89.941)">
                      <g transform="translate(0 0)">
                        <path class="st0"
                          d="M8.3,0H1.1C0.5,0,0,0.5,0,1.1c0,0.6,0.5,1.1,1.1,1.1l0,0h7.2c0.6,0,1.1-0.5,1.1-1.1C9.4,0.5,8.9,0,8.3,0z" />
                      </g>
                    </g>
                  </g>
                </svg>
              </em>
              Deliveries
              </button>
              <button mat-menu-item (click)="userDoorHistory()" class="parcel-btn">
                <span class="material-icons parcel-btn-icon">collections</span>
                User Door History
              </button>
              <button mat-menu-item (click)="goToActuatorLogs()" class="parcel-btn">
                <img src="./assets/images/actuator-logo.png" class="mr-2 h-50" alt="">
                Actuators log
              </button>
            </mat-menu>
            <button class="delete-btn grant-btn video-btn" (click)="openVideoStreamingModal()" title="Live View" [disabled]="enclosure_details.internet_connectivity == 0">
              <span class="material-icons">videocam</span>
            </button>
            <button class="delete-btn grant-btn video-btn" (click)="RefreshFirmware()" title="Refresh Firmware" [disabled]="enclosure_details.internet_connectivity == 0">
              <img src="../assets/images/refresh_firmware.svg" alt="Refresh">
            </button>
            <button *ngIf="enclosure_details?.user_own_access == 1" class="delete-btn grant-btn"
              (click)="openDeleteEncModal()" title="Remove Device">
              <em class="delete-btn-icon mr-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="10.5" height="12" viewBox="0 0 10.5 12">
                  <path id="Path_507" data-name="Path 507"
                    d="M6.281-3.375h.563a.281.281,0,0,0,.281-.281V-8.719A.281.281,0,0,0,6.844-9H6.281A.281.281,0,0,0,6-8.719v5.063A.281.281,0,0,0,6.281-3.375Zm3.844-7.875H8.194l-.8-1.329a1.247,1.247,0,0,0-.965-.546H4.068a1.247,1.247,0,0,0-.965.546l-.8,1.329H.375A.375.375,0,0,0,0-10.875v.375a.375.375,0,0,0,.375.375H.75V-2.25A1.125,1.125,0,0,0,1.875-1.125h6.75A1.125,1.125,0,0,0,9.75-2.25v-7.875h.375A.375.375,0,0,0,10.5-10.5v-.375A.375.375,0,0,0,10.125-11.25Zm-6.1-.682A.156.156,0,0,1,4.148-12h2.2a.156.156,0,0,1,.121.068l.409.682H3.619Zm4.6,9.682H1.875v-7.875h6.75ZM3.656-3.375h.563A.281.281,0,0,0,4.5-3.656V-8.719A.281.281,0,0,0,4.219-9H3.656a.281.281,0,0,0-.281.281v5.063A.281.281,0,0,0,3.656-3.375Z"
                    transform="translate(0 13.125)" fill="#fff" />
                </svg>
              </em>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="fault-block" *ngIf="
        enclosure_details.enclosure_fault == 1 ||
        enclosure_details.internet_connectivity == 0
      ">
      <div class="fault-title">
        <img src="assets/images/error-close.svg" alt="" />Error!
      </div>
      <div class="fault-info">
        <span class="fault-text" *ngIf="enclosure_details.internet_connectivity == 0">Internet connection lost!</span>
        <span class="fault-text" *ngIf="enclosure_details.enclosure_fault == 1">Device fault!<br /></span>
        <span class="fault-text" *ngIf="enclosure_details.user_door_lock_fault == 1">User door lock fault!<br /></span>
        <span class="fault-text" *ngIf="enclosure_details.drone_door_motor_fault == 1">Drone door motor
          fault!<br /></span>
        <span class="fault-text" *ngIf="enclosure_details.drone_door_lock_fault == 1">Drone door lock
          fault!<br /></span>
        <span class="fault-text" *ngIf="enclosure_details.heater_fault == 1">Heater fault!<br /></span>
      </div>
    </div>
    <div *ngIf="enclosure_details.tof_sensor">
      <div *ngIf="enclosure_details.tof_sensor.includes(0)" class="fault-block">
        <div class="fault-title">
          <img src="assets/images/error-close.svg" alt="" /> TOF Error!
        </div>
        <div class="fault-info">
          <span class="fault-text">Sensor
            <ng-container *ngFor="let sensor of enclosure_details.tof_sensor; let i = index">
              <ng-container *ngIf="sensor === 0">
                {{i + 1}}{{!last ? ',' : ''}}
              </ng-container>
            </ng-container>
            are not working
          </span>
        </div>
      </div>
    </div>

    <div class="fault-block" *ngIf="enclosure_details.theft_detection == 1">
      <span class="fault-text">Theft Detection!</span>
    </div>

    <div class="row row-eq-height">
      <div class="col-md-6">
        <div class="row row-eq-height">
          <div class="col-lg-6 col-md-12 col-sm-12 app-box">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">Battery Status</h2>
              </div>
              <div class="access-door-body">
                <em class="battery-status-icon battery-new-icon"><img src="assets/images/battery-general.svg"
                    alt="" /></em>
                <span class="battery-status">{{ enclosure_details?.battery_percentage }}
                  <span>%</span></span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 app-box">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">Power Supply</h2>
              </div>
              <div class="access-door-body">
                <em class="battery-status-icon forward-delivery-icon"><img
                    *ngIf="enclosure_details?.power_supply != 'AC'" src="assets/images/battery.svg" alt="" />
                  <img *ngIf="enclosure_details?.power_supply == 'AC'" src="assets/images/plug.png" alt="" /></em>
                <span class="battery-status" *ngIf="enclosure_details?.power_supply != 'AC'">{{
                  enclosure_details?.power_supply | titlecase }}</span>

                <span class="battery-status" *ngIf="enclosure_details?.power_supply == 'AC'">
                  AC</span>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 app-box">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">Internal Temperature</h2>
              </div>
              <div class="access-door-body">
                <em class="
                    battery-status-icon
                    forward-delivery-icon
                    heater-status-icon
                  "><img src="assets/images/{{
                      enclosure_details?.temp_type
                        | TempStatus: 2:enclosure_details?.temp_type
                    }}" alt="" /></em>
                <span class="battery-status" *ngIf="
                    enclosure_details?.internal_temp &&
                    enclosure_details?.internal_temp != '' &&
                    enclosure_details?.internal_temp != 'NA'
                  ">{{ enclosure_details?.internal_temp }}°{{
                  enclosure_details?.temp_type
                  | TempStatus: 1:enclosure_details?.temp_type
                  }}</span>

                <span *ngIf="
                    !enclosure_details?.internal_temp ||
                    enclosure_details?.internal_temp == '' ||
                    enclosure_details?.internal_temp == 'NA'
                  " class="battery-status">NA °{{
                  enclosure_details?.temp_type
                  | TempStatus: 1:enclosure_details?.temp_type
                  }}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 app-box">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">External Temperature</h2>
              </div>
              <div class="access-door-body">
                <em class="battery-status-icon forward-delivery-icon">
                  <img src="assets/images/{{
                      enclosure_details?.temp_type
                        | TempStatus: 2:enclosure_details?.temp_type
                    }}" alt="" /></em>
                <span *ngIf="
                    enclosure_details?.external_temp &&
                    enclosure_details?.external_temp != '' &&
                    enclosure_details?.external_temp != 'NA'
                  " class="battery-status">{{ enclosure_details?.external_temp }}°{{
                  enclosure_details?.temp_type
                  | TempStatus: 1:enclosure_details?.temp_type
                  }}</span>

                <span *ngIf="
                    !enclosure_details?.external_temp ||
                    enclosure_details?.external_temp == '' ||
                    enclosure_details?.external_temp == 'NA'
                  " class="battery-status">NA °{{
                  enclosure_details?.temp_type
                  | TempStatus: 1:enclosure_details?.temp_type
                  }}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 app-box">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">External Humidity</h2>
              </div>
              <div class="access-door-body">
                <em class="battery-status-icon forward-delivery-icon"><img src="assets/images/humidity.svg" alt="" /></em>
                <span class="battery-status">{{ enclosure_details?.external_humidity }}%</span>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 col-sm-12 app-box" *ngIf="
              enclosure_details?.user_own_access == 1 ||
              enclosure_details?.user_own_access == 2
            ">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">Shared Access</h2>
              </div>
              <div class="app-card-body">
                <div class="access-door-body">
                  <em class="battery-status-icon shared-access-icon"><img src="assets/images/group.svg" alt="" /></em>
                  <span class="battery-status">{{ shared_access_count }}</span>
                  <button (click)="sharedEnclosureList()" class="more-btn">
                    <span class="material-icons">reply</span>View More
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 app-box">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">Snow Detection</h2>
              </div>
              <div class="access-door-body">
                <em class="
                    battery-status-icon
                    forward-delivery-icon
                    heater-status-icon
                  "><img src="assets/images/snow.svg" alt="" /></em>
                <span class="battery-status" *ngIf="enclosure_details?.heater_status == 1">Detected</span>
                <span class="battery-status" *ngIf="
                    enclosure_details?.heater_status == 0 ||
                    !enclosure_details?.heater_status
                  ">Not Detected</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 app-box">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">Heater Status</h2>
              </div>
              <div class="access-door-body">
                <em class="
                    battery-status-icon
                    forward-delivery-icon
                    heater-status-icon
                  "><img src="assets/images/heater-status.svg" alt="" /></em>
                <span class="battery-status" *ngIf="enclosure_details?.heater_status == 1">On</span>
                <span class="battery-status" *ngIf="
                    enclosure_details?.heater_status == 0 ||
                    !enclosure_details?.heater_status
                  ">Off</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 app-box">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">Device's Actual Height</h2>
              </div>
              <div class="access-door-body">
                <div class="battery-percentage-block" *ngIf="isDataLoaded">
                  <battery-percentage [color]="color" [percentage]="100"></battery-percentage>
                </div>
                <span class="battery-status">
                  {{ this.space_type == 'CM' ? enclosure_details?.enclosure_height : this.space_type == 'cm' ? enclosure_details?.enclosure_height : (enclosure_details?.enclosure_height * 0.39370).toFixed(2) }}
                  <span>{{ space_type }}</span></span>
              </div>
            </div>
          </div>


          <div class="col-lg-6 col-md-12 app-box">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">Available Height</h2>
              </div>
              <div class="access-door-body">
                <div class="battery-percentage-block" *ngIf="isDataLoaded">
                  <battery-percentage [color]="color" [percentage]="heightPercentage"></battery-percentage>
                </div>
                <span class="battery-status">
                  {{ enclosure_details?.available_height }}
                  <span>{{ space_type }}</span></span>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 app-box">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">Parcel Delivery</h2>
              </div>
              <div class="access-door-body">
                <em class="battery-status-icon forward-delivery-icon"><img src="assets/images/available-space.svg"
                    alt="" /></em>
                <span class="battery-status" *ngIf="
                    (space_type == 'CM' &&
                      enclosure_details?.available_height >= 30) ||
                    (space_type != 'CM' &&
                      enclosure_details?.available_height >= 11.81)
                  ">Acceptable
                </span>

                <span class="battery-status" *ngIf="
                    (space_type == 'CM' &&
                      enclosure_details?.available_height < 30) ||
                    (space_type != 'CM' &&
                      enclosure_details?.available_height < 11.81)
                  ">Not Acceptable
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 app-box" *ngIf="enclosure_details?.user_own_access == 1">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">Incoming Forward Delivery</h2>
              </div>
              <div class="access-door-body">
                <em class="battery-status-icon forward-delivery-icon"><img src="assets/images/receive.svg" alt="" /></em>
                <span class="battery-status">{{
                  in_forwarded_deliveries
                  }}</span>
                <button (click)="
                    forwardedDeliveriesList('Incoming', in_forwarded_deliveries)
                  " class="more-btn">
                  <span class="material-icons">reply</span>View Status
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="enclosure_details?.user_own_access == 1" class="col-lg-6 col-md-12 app-box">
            <div class="app-card">
              <div class="app-card-top">
                <h2 class="app-card-title">Outgoing Forward Delivery</h2>
              </div>
              <div class="access-door-body">
                <em class="battery-status-icon forward-delivery-icon"><img src="assets/images/order.svg" alt="" /></em>
                <span class="battery-status">{{
                  out_forwarded_deliveries
                  }}</span>
                <button (click)="
                    forwardedDeliveriesList(
                      'Out Going',
                      out_forwarded_deliveries
                    )
                  " class="more-btn">
                  <span class="material-icons">reply</span>View Status
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="app-card">
          <div class="app-card-top d-flex">
            <h2 class="app-card-title">Device Details</h2>
            <span class="access-title" [ngClass]="{
                'access-green': enclosure_details?.user_own_access == 1,
                'access-orange': enclosure_details?.user_own_access == 2,
                'access-purple': enclosure_details?.user_own_access == 3,
                'access-gray': enclosure_details?.user_own_access == 4
              }">
              {{
              enclosure_details?.user_own_access
              | accessNamePipe: enclosure_details?.user_own_access
              }}</span>
          </div>
          <div class="app-card-body">
            <div class="app-card-info app-card-dates" *ngIf="enclosure_details?.user_own_access == 3">
              <label>
                <em class="app-info-icon"><img src="assets/images/calendar.svg" alt="" /></em>
                Start Date &amp; Time
                <div class="start-date">
                  {{
                  enclosure_details?.start_time * 1000
                  | date: "d MMM, y h:mm a"
                  }}
                </div>
              </label>
              <label>
                <em class="app-info-icon"><img src="assets/images/calendar.svg" alt="" /></em>
                End Date &amp; Time
                <div class="end-date">
                  {{
                  enclosure_details?.end_time * 1000 | date: "d MMM, y h:mm a"
                  }}
                </div>
              </label>
            </div>

            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/wifi.svg" alt="" /></em>Internet Connection</label>
              <button class="access-door-btn door-closed" *ngIf="enclosure_details?.internet_connectivity == 1">
                Connected
              </button>
              <button class="access-door-btn door-open" *ngIf="
                  !enclosure_details?.internet_connectivity ||
                  enclosure_details?.internet_connectivity == 0
                ">
                Disconnected
              </button>
            </div>
            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/wiFi.svg" alt="" /></em>Network Strength</label>
              <span> {{ enclosure_details?.nw_strength }}</span>
            </div>

            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/Mail.svg" alt="" /></em>Mail Door Status</label>
              <button class="access-door-btn door-open" *ngIf="enclosure_details?.mailbox_status == 1">
                Open
              </button>
              <button class="access-door-btn door-closed" *ngIf="
                  !enclosure_details?.mailbox_status ||
                  enclosure_details?.mailbox_status == 0
                ">
                Close
              </button>
            </div>
            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/RF.svg" alt="" /></em>Hotspot Status</label>
              <button class="access-door-btn door-closed" *ngIf="enclosure_details?.hotspot_status == 1">
                On
              </button>
              <button class="access-door-btn door-open" *ngIf="
                  !enclosure_details?.hotspot_status ||
                  enclosure_details?.hotspot_status == 0
                ">
                Off
              </button>
            </div>
            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/checked.svg" alt="" /></em>Device Status</label>
              <button class="access-door-btn door-open" *ngIf="enclosure_details?.enclosure_fault == 1">
                Error
              </button>
              <button class="access-door-btn door-closed" *ngIf="
                  !enclosure_details?.enclosure_fault ||
                  enclosure_details?.enclosure_fault == 0
                ">
                No Error
              </button>
            </div>
            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/drone.svg" alt="" /></em>Drone Door Status</label>
              <button class="access-door-btn door-open" *ngIf="enclosure_details?.drone_door_status == 1">
                <span class="material-icons">lock_open</span>Unlock
              </button>
              <button class="access-door-btn door-closed" *ngIf="
                  !enclosure_details?.drone_door_status ||
                  enclosure_details?.drone_door_status == 0
                ">
                <span class="material-icons">lock</span>Locked
              </button>
            </div>
            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/lock.svg" alt="" /></em>User Door Status</label>
              <button class="access-door-btn door-open" *ngIf="enclosure_details?.user_door_status == 1">
                <span class="material-icons">lock_open</span>Unlock
              </button>
              <button class="access-door-btn door-closed" *ngIf="enclosure_details?.user_door_status == 0">
                <span class="material-icons">lock</span>Locked
              </button>
            </div>
            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/tap.svg" alt="" /></em>Buzzer Status</label>
              <button class="access-door-btn door-open buzzer-on" *ngIf="enclosure_details?.buzzer_status == 1">
                On
              </button>
              <button class="access-door-btn door-closed buzzer-off" *ngIf="
                  enclosure_details?.buzzer_status == 0 ||
                  !enclosure_details?.buzzer_status
                ">
                Off
              </button>
            </div>

            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/led-light.svg" alt="" /></em>LED Status</label>
              <button class="access-door-btn door-open buzzer-on" *ngIf="enclosure_details?.led_status == 1">
                On
              </button>
              <button class="access-door-btn door-closed buzzer-off" *ngIf="
                  enclosure_details?.led_status == 0 ||
                  !enclosure_details?.led_status
                ">
                Off
              </button>
            </div>

            <div *ngIf="enclosure_details?.led_status == 1" class="app-card-info-bt">
              <div class="app-card-info">
                <label><em class="app-info-icon"><img src="assets/images/led-light-blue.svg" alt="" />
                  </em>LED Color</label>
                <span> {{ enclosure_details?.led_color | titlecase }}</span>
              </div>

              <div class="app-card-info">
                <label><em class="app-info-icon"><img src="assets/images/led-mode-steady.svg" alt="" />
                  </em>LED Mode</label>
                <span> {{ enclosure_details?.led_mode | titlecase }}</span>
              </div>
            </div>

            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/enclosure-name.svg" alt="" /></em>Device
                Name</label>
              <span> {{ enclosure_details?.enclosure_name }}</span>
            </div>
            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/enclosure-id.svg" alt="" /></em>Device
                ID</label>
              <span> {{ enclosure_details?.enclosure_id }}</span>
            </div>
            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/enclosure-owner.svg" alt="" /></em>Device
                Owner</label>
              <span> {{ enclosure_details?.user_name }}</span>
            </div>
            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/email.svg" alt="" /></em>Owner Email</label>
              <span> {{ enclosure_details?.user_email }}</span>
            </div>
            <div class="app-card-info address-card-info">
              <label><em class="app-info-icon"><img src="assets/images/street-address.svg" alt="" /></em>Address</label>
              <span>
                {{ enclosure_details?.address_line_a }},{{
                enclosure_details?.address_line_b
                }},{{ enclosure_details?.city }},{{
                enclosure_details?.state
                }},{{ enclosure_details?.country }},{{
                enclosure_details?.zipcode
                }}</span>
            </div>
            <div class="app-card-info">
              <label><em class="app-info-icon"><img src="assets/images/firmware.svg" alt="" /></em>
                Firmware Version</label>
              <span> {{ enclosure_details?.fw_version }}</span>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <ngx-smart-modal #grantenclosureaccess [identifier]="'grantenclosureaccess'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal enclosuresettings-modal">
    <h2 class="modal-title">Grant Device Access</h2>
    <form class="modal-form enclosuresettings-form grant-form">
      <button *ngIf="enclosure_details?.user_own_access == 1" class="modal-input" (click)="openAddSecondaryModal()">
        <label>
          <em>
            <img src="assets/images/secondary-access.svg" alt="" />
          </em>
          Secondary Access
        </label>
        <div><img src="assets/images/arrow.svg" alt="" /></div>
      </button>
      <button *ngIf="
          enclosure_details?.user_own_access == 1 ||
          enclosure_details?.user_own_access == 2
        " class="modal-input" (click)="openAddTemporaryModal()">
        <label>
          <em>
            <img src="assets/images/temporary-access.svg" alt="" />
          </em>
          Temporary Access</label>
        <div><img src="assets/images/arrow.svg" alt="" /></div>
      </button>
      <button *ngIf="enclosure_details?.user_own_access == 1" class="modal-input" (click)="openAddOTAModal()">
        <label>
          <em>
            <img src="assets/images/onetime-access.svg" alt="" />
          </em>
          One Time Access</label>
        <div><img src="assets/images/arrow.svg" alt="" /></div>
      </button>
    </form>
  </ngx-smart-modal>

  <ngx-smart-modal #secondaryaccess [identifier]="'secondaryaccess'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal forwarddelivery-modal">
    <h2 class="modal-title">Grant Secondary Access</h2>
    <form #secondaryAccessForm="ngForm" (ngSubmit)="addSecondaryAccess(secondaryAccessForm)"
      class="modal-form forwarddelivery-form">
      <div class="modal-input">
        <label> Name<sup class="sup">*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input matInput placeholder="Ex. John Doe" maxlength="25" name="user_name" #user_name="ngModel"
            [(ngModel)]="service.SecondaryAccessFormData.user_name" [ngClass]="{
              'is-invalid':
                !user_name.valid &&
                (user_name.dirty || user_name.touched || isSecondarySubmit)
            }" required />
          <mat-error *ngIf="
              !user_name.valid &&
              (user_name.dirty || user_name.touched || isSecondarySubmit)
            ">
            <div *ngIf="user_name.errors.required">Please enter name</div>
            <div *ngIf="user_name.errors.maxlength">
              name should be maximum 25 characters
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input">
        <label> Email<sup class="sup">*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input maxlength="64" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" matInput
            placeholder="Ex. abc@gmail.com" name="user_email" #user_email="ngModel"
            [(ngModel)]="service.SecondaryAccessFormData.user_email" [ngClass]="{
              'is-invalid':
                !user_email.valid &&
                (user_email.dirty || user_email.touched || isSecondarySubmit)
            }" required />
          <mat-error *ngIf="
              !user_email.valid &&
              (user_email.dirty || user_email.touched || isSecondarySubmit)
            ">
            <div *ngIf="user_email.errors.required">
              Please enter email address
            </div>
            <div *ngIf="user_email.errors.pattern">
              Enter valid email address
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input">
        <label>Device ID<sup class="sup">*</sup></label>
        <span class="dis_enc_id">{{
          service.SecondaryAccessFormData.enclosure_id
          }}</span>
      </div>
      <div class="d-flex justify-content-center">
        <button [disabled]="isSecondaryLoading" class="add-btn">
          <em class="mr-2"><img src="assets/images/temp-access.svg" alt="" /></em>
          Give Secondary Access
        </button>
      </div>
    </form>
  </ngx-smart-modal>

  <ngx-smart-modal #temporaryaccess [identifier]="'temporaryaccess'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal forwarddelivery-modal">
    <h2 class="modal-title">Grant Temporary Access</h2>
    <form #temporaryAccessForm="ngForm" (ngSubmit)="addTemporaryAccess(temporaryAccessForm)"
      class="modal-form forwarddelivery-form">
      <div class="modal-input">
        <label> Name<sup class="sup">*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input matInput placeholder="Ex. John Doe" name="user_name" maxlength="25" #user_name="ngModel"
            [(ngModel)]="service.TemporaryAccessFormData.user_name" [ngClass]="{
              'is-invalid':
                !user_name.valid &&
                (user_name.dirty || user_name.touched || isTemporarySubmit)
            }" required />
          <mat-error *ngIf="
              !user_name.valid &&
              (user_name.dirty || user_name.touched || isTemporarySubmit)
            ">
            <div *ngIf="user_name.errors.required">Please enter name</div>
            <div *ngIf="user_name.errors.maxlength">
              name should be maximum 25 characters
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input">
        <label> Email<sup class="sup">*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input maxlength="64" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" matInput
            placeholder="Ex. abc@gmail.com" name="user_email" #user_email="ngModel"
            [(ngModel)]="service.TemporaryAccessFormData.user_email" [ngClass]="{
              'is-invalid':
                !user_email.valid &&
                (user_email.dirty || user_email.touched || isTemporarySubmit)
            }" required />
          <mat-error *ngIf="
              !user_email.valid &&
              (user_email.dirty || user_email.touched || isTemporarySubmit)
            ">
            <div *ngIf="user_email.errors.required">
              Please enter email address
            </div>
            <div *ngIf="user_email.errors.pattern">
              Enter valid email address
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input">
        <label> Device ID </label>
        <span class="dis_enc_id">{{
          service.TemporaryAccessFormData.enclosure_id
          }}</span>
      </div>

      <div class="modal-input">
        <label>
          <em>
            <img src="assets/images/encl-timezone.svg" alt="" />
          </em>
          Device Timezone<sup>*</sup></label>
        <div class="modal-input-subtext">{{ enclosure_details?.timezone }}</div>
      </div>
      <div class="modal-input">
        <label> Set Start Date &amp; Time<sup class="sup">*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input matInput [ngxMatDatetimePicker]="temp_startpicker" placeholder="Ex. 12/15/2020, 4:05 PM"
            [formControl]="service.TemporaryAccessFormData.start_time" [min]="minDate" [disabled]="disabled" />
          <mat-datepicker-toggle matSuffix [for]="temp_startpicker">
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #temp_startpicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
            [color]="color">
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>

      <div class="modal-input">
        <label> Set End Date &amp; Time<sup class="sup">*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input matInput [ngxMatDatetimePicker]="temp_endpicker" placeholder="Ex. 12/15/2020, 4:05 PM"
            [formControl]="service.TemporaryAccessFormData.end_time" [min]="minDate" [disabled]="disabled" />
          <mat-datepicker-toggle matSuffix [for]="temp_endpicker">
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #temp_endpicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
            [color]="color">
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>

      <div class="d-flex justify-content-center">
        <button [disabled]="isTemporaryLoading" class="add-btn">
          <em class="mr-2"><img src="assets/images/temp-access.svg" alt="" /></em>
          Give Temporary Access
        </button>
      </div>
    </form>
  </ngx-smart-modal>

  <ngx-smart-modal #onetimeaccess [identifier]="'onetimeaccess'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal forwarddelivery-modal">
    <h2 class="modal-title">Grant One Time Access</h2>
    <form #OTAAccessForm="ngForm" (ngSubmit)="addOTAAccess(OTAAccessForm)" class="modal-form forwarddelivery-form">
      <div class="modal-input">
        <label>Device ID</label>
        <span class="dis_enc_id">{{
          service.OTAAccessFormData.enclosure_id
          }}</span>
      </div>
      <div class="modal-input">
        <label>
          <em>
            <img src="assets/images/encl-timezone.svg" alt="" />
          </em>
          Device Timezone<sup>*</sup></label>
        <div class="modal-input-subtext">{{ enclosure_details?.timezone }}</div>
      </div>
      <div class="modal-input">
        <label>
          <em>
            <img src="assets/images/calendar.svg" alt="" />
          </em>
          Set Start Date &amp; Time<sup class="sup">*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input matInput [ngxMatDatetimePicker]="ota_startpicker" placeholder="Ex. 12/15/2020, 4:05 PM"
            [formControl]="service.OTAAccessFormData.start_time" [min]="minDate" [max]="maxDate" [disabled]="disabled"
            required />

          <mat-error *ngIf="startTimeError">
            Please enter start time
          </mat-error>

          <mat-datepicker-toggle matSuffix [for]="ota_startpicker">
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #ota_startpicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
            [color]="color">
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>

      <div class="modal-input">
        <label>
          <em>
            <img src="assets/images/calendar.svg" alt="" />
          </em>
          Set End Date &amp; Time<sup class="sup">*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input required matInput [ngxMatDatetimePicker]="ota_endpicker" placeholder="Ex. 12/15/2020, 4:05 PM"
            [formControl]="service.OTAAccessFormData.end_time" [min]="minDate" [max]="maxDate" [disabled]="disabled" />
          <mat-error *ngIf="endTimeError"> Please end end time </mat-error>
          <mat-datepicker-toggle matSuffix [for]="ota_endpicker">
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #ota_endpicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
            [color]="color">
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>


      <div class="modal-input">
        <label>
          <em>
            <img src="assets/images/phone.svg" alt="" class="modal-email-icon" />
          </em>
          Contact Number<sup class="sup">*</sup><br />
        </label>
        <div class="phoneform phoneform-relative ota-phoneform">
          <ngx-intl-tel-input [cssClass]="'custom'" [separateDialCode]="false" [enableAutoCountrySelect]="false"
            [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]" [selectFirstCountry]="false" [selectedCountryISO]="countrySortName" [maxLength]="15"
            [tooltipField]="TooltipLabel.Name" [phoneValidation]="true" name="user_mobile"
            [(ngModel)]="service.OTAAccessFormData.user_mobile" required (ngModelChange)="
              checkNumber($event, service.OTAAccessFormData.user_mobile)
            ">
          </ngx-intl-tel-input>
          <div class="ngx-error">
            <strong *ngIf="
                !(
                  OTAAccessForm.form.controls['user_mobile']?.invalid == true &&
                  phoneError == true
                ) &&
                OTAAccessForm.form.controls['user_mobile']?.touched &&
                OTAAccessForm.form.controls['user_mobile']?.invalid == true
              ">Please enter valid contact number</strong>
            <strong *ngIf="
                OTAAccessForm.form.controls['user_mobile']?.invalid == true &&
                phoneError == true
              ">Please enter valid contact number</strong>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button [disabled]="isOTALoading" class="add-btn">
          <em class="mr-2"><img src="assets/images/temp-access.svg" alt="" /></em>
          Give One Time Access
        </button>
      </div>
    </form>
  </ngx-smart-modal>

  <ngx-smart-modal #deleteencl [identifier]="'deleteencl'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal delete-modal">
    <p class="modal-text">Are you sure you want to delete this device?</p>
    <form #deleteEncForm="ngForm" class="modal-form delete-form" (ngSubmit)="deleteEnclosure(deleteEncForm)">
      <div class="modal-input">
        <mat-form-field floatLabel="never" appearance="standard">
          <input matInput placeholder="Write DELETE here to complete device deletion" name="type_delete" #type_delete="ngModel"
            [(ngModel)]="service.DeleteEncFormData.type_delete" [ngClass]="{
              'is-invalid':
                !type_delete.valid &&
                (type_delete.dirty || type_delete.touched || isDelEncSubmit)
            }" required />
          <mat-error *ngIf="
              !type_delete.valid &&
              (type_delete.dirty || type_delete.touched || isDelEncSubmit)
            ">
            Please enter DELETE keyword in input.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="d-flex justify-content-center delete-bottom">
        <button [disabled]="isDelEncLoading" class="delete-btns yes-btn mr-2" type="submit">
          Yes
        </button>
        <button type="button" class="delete-btns no-btn" (click)="deleteEnclosureModelClose()">
          No
        </button>
      </div>
    </form>
  </ngx-smart-modal>

  <ngx-smart-modal #existingTempDetail [identifier]="'existingTempDetail'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal duration-modal">
    <h2 class="duration-modal-title">
      These users have temporary access to your device for the same duration.
    </h2>
    <form #existingOTAForm="ngForm" class="modal-form duration-form" (ngSubmit)="addTempAfterChecked()">
      <div class="duration-modal-body">
        <div class="duration-modal-info" *ngFor="let data of existingTempAccess">
          <h3 class="duration-modal-username">{{ data.user_name }}</h3>
          <div class="duration-modal-datewrap">
            <label class="duration-modal-date">{{
              data.start_time * 1000 | date: "d MMM, y h:mm a"
              }}</label>
            <label class="duration-modal-date">{{
              data.end_time * 1000 | date: "d MMM, y h:mm a"
              }}</label>
          </div>
        </div>
        <p class="duration-modal-text">
          Still you want to give temporary access to
          {{ service.TemporaryAccessFormData.user_name }}?
        </p>
      </div>
      <div class="d-flex justify-content-center duration-bottom">
        <button [disabled]="isTemporaryLoading" class="delete-btns yes-btn mr-2" type="submit">
          Give Access
        </button>
        <button type="button" class="delete-btns no-btn" (click)="closeExistingTempModal()">
          Cancel
        </button>
      </div>
    </form>
  </ngx-smart-modal>

  <ngx-smart-modal #existingOTADetail [identifier]="'existingOTADetail'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal delete-modal onetime-access-modal">
    <p class="modal-text">
      These users have one time access to your device for the same duration.
    </p>
    <form #existingOTAForm="ngForm" class="modal-form delete-form" (ngSubmit)="addOTAAfterChecked()">
      <div class="app-card-body">
        <div class="app-card-info" *ngFor="let data of existingOTAAccess">
          <p class="app-card-name">
            <em class="app-card-nameicon">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 15.2"
                style="enable-background: new 0 0 16 15.2" xml:space="preserve">
                <style type="text/css">
                  .st0 {
                    fill: none;
                    stroke: #8d99ae;
                    stroke-width: 2;
                  }
                </style>
                <g transform="translate(1.002 1)">
                  <g transform="translate(0 0)">
                    <path class="st0" d="M0.6,13.2h12.9c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.2,0.1-0.4c-0.3-2.3-1.7-4.3-3.7-5.4
			c-1.8,1.8-4.7,1.8-6.5,0c0,0,0,0,0,0c-2,1.1-3.4,3.1-3.7,5.4c0,0.1,0,0.3,0.1,0.4C0.2,13.1,0.4,13.2,0.6,13.2z" />
                    <path class="st0" d="M4.1,6.8C4.2,6.8,4.3,6.9,4.3,7c1.5,1.4,3.8,1.4,5.3,0c0.1-0.1,0.2-0.1,0.2-0.2s0.1-0.2,0.2-0.2
			c1.4-1.7,1.1-4.2-0.6-5.6S5.3-0.2,3.9,1.5c-1.2,1.5-1.2,3.6,0,5C4,6.6,4,6.7,4.1,6.8z" />
                  </g>
                </g>
              </svg>
            </em>
            <span>{{ data.user_email }}</span>
          </p>
          <div class="app-card-body-insidewrap">
            <p class="app-card-time">
              {{ data.start_time * 1000 | date: "d MMM, y h:mm a" }}
            </p>
            <p class="app-card-time">
              {{ data.end_time * 1000 | date: "d MMM, y h:mm a" }}
            </p>
          </div>
        </div>
        <p class="modal-body-text">
          Still you want to give one time access to
          <span class="modal-id-text">{{
            service.OTAAccessFormData.user_mobile
            }}</span>?
        </p>
      </div>
      <div class="d-flex justify-content-center delete-bottom">
        <button [disabled]="isDelEncLoading" class="delete-btns yes-btn mr-2" type="submit">
          Give Access
        </button>
        <button type="button" class="delete-btns no-btn cancel-btn" (click)="closeExistingOTAModal()">
          Cancel
        </button>
      </div>
    </form>
  </ngx-smart-modal>

  <ngx-smart-modal #enclosuresettings [identifier]="'enclosuresettings'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal enclosuresettings-modal">
    <h2 class="modal-title">Device Settings</h2>
    <div class="error-infobox" *ngIf="enclosure_details?.internet_connectivity == 0">
      <div class="fault-title">
        <img src="assets/images/error-close.svg" alt="" />Error!
      </div>
      <div class="fault-info">Internet connection lost</div>
    </div>
    <h3 class="modal-title-encl">
      <span class="modal-subtitle-encl"><img src="assets/images/enclosure-name.svg" alt="" />Device
        Name</span>
      <span class="modal-subtitle-encl-name">{{
        enclosure_details?.enclosure_name
        }}</span>
    </h3>
    <form class="modal-form enclosuresettings-form">
      <div [ngClass]="
          enclosure_details?.internet_connectivity == 1 ? '' : 'disable_ui'
        " class="enclosuresettings-inner-form">
        <hr>
        <div class="bg-light rounded mb-3 p-2 modal-input d-flex flex-column">
          <div class="access-modal-input fullwidth">
            <label for="">User Door Opening</label>
            <mat-radio-group class="modal-radio-group mb-2" (change)="onUserDoorOpeningMethodChange($event)">
              <mat-radio-button [disabled]="enclosure_details?.user_door_status == 1" [checked]="userDoorOpeningMethodType == 1"
                value=1>Automatic</mat-radio-button>
              <mat-radio-button [disabled]="enclosure_details?.user_door_status == 1" [checked]="userDoorOpeningMethodType == 2"
                value=2 class="ml-3">Manual</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="modal-input modal-input border-0 mb-0 pb-0">
            <hr>
            <label>
              <em>
                <img src="assets/images/access-door.svg" alt="" class="door-icon-width" />
              </em>
              User Door -
              <span class="status-onoff" *ngIf="enclosure_details?.user_door_status == 1">Unlock</span>
              <span class="status-onoff" *ngIf="enclosure_details?.user_door_status == 0">Lock</span>
            </label>
            <button (click)="userDoorOpenClose(enclosure_details)" [ngClass]="
                enclosure_details?.user_door_status == 1
                  ? 'door_closed'
                  : 'door_open'
              ">
              <span *ngIf="enclosure_details?.user_door_status == 1"></span>
              <span *ngIf="enclosure_details?.user_door_status == 0"></span>
            </button>
          </div>
        </div>
        <div class="modal-input px-2">
          <label>
            <em>
              <img src="assets/images/access-door.svg" alt="" class="door-icon-width" />
            </em>
            Drone Door -
            <span class="status-onoff" *ngIf="enclosure_details?.drone_door_status == 1">Unlock</span>
            <span class="status-onoff" *ngIf="enclosure_details?.drone_door_status == 0">Lock</span>
          </label>
          <button (click)="droneDoorOpenClose(enclosure_details)" [ngClass]="
              enclosure_details?.drone_door_status == 1
                ? 'door_closed'
                : 'door_open'
            ">
            <span *ngIf="enclosure_details?.drone_door_status == 1"></span>
            <span *ngIf="enclosure_details?.drone_door_status == 0"></span>
          </button>
        </div>
        <div class="modal-input">
          <label>
            <em>
              <img src="assets/images/tap.svg" alt="" class="door-icon-width" />
            </em>
            Buzzer -
            <span class="status-onoff" *ngIf="enclosure_details?.buzzer_status == 1">
              On</span>
            <span class="status-onoff" *ngIf="enclosure_details?.buzzer_status == 0">
              Off</span>
          </label>
          <button (click)="stopBuzzer(enclosure_details)" [ngClass]="
              enclosure_details?.buzzer_status == 1
                ? 'door_open'
                : 'door_closed'
            "></button>
        </div>

        <div class="modal-input" *ngIf="
            enclosure_details?.user_own_access == 1 ||
            enclosure_details?.user_own_access == 2
          ">
          <label>
            <em>
              <img src="assets/images/tap.svg" alt="" class="door-icon-width" />
            </em>
            LED -
            <span class="status-onoff" *ngIf="enclosure_details?.led_status == 1">
              On</span>
            <span class="status-onoff" *ngIf="enclosure_details?.led_status == 0">
              Off</span>
          </label>
          <button (click)="changeLEDStatus(enclosure_details?.led_status)" [ngClass]="
              enclosure_details?.led_status == 1 ? 'led_open' : 'led_closed'
            "></button>
        </div>
        <div *ngIf="
            (enclosure_details?.user_own_access == 1 ||
              enclosure_details?.user_own_access == 2) &&
            enclosure_details?.led_status == 1
          ">
          <div class="modal-input">
            <label>
              <em>
                <img src="assets/images/led.svg" alt="" class="led-icon-width" />
              </em>
              LED Color
            </label>

            <form #setLEDColorForm="ngForm">
              <mat-form-field floatLabel="never" class="reminder-select led-select">
                <mat-label>LED Color -</mat-label>

                <mat-select name="led_color" #led_color="ngModel" [(ngModel)]="service.EnclosureListFormData.led_color"
                  required (ngModelChange)="
                    changeLedColor(service.EnclosureListFormData.led_color)
                  " [ngClass]="{
                    'is-invalid':
                      !led_color.valid && (led_color.dirty || led_color.touched)
                  }" disableOptionCentering panelClass="reminder-select-panel led-select-panel">
                  <mat-select-trigger><span class="led" [class]="service.EnclosureListFormData.led_color"></span>{{
                    led_color.value | titlecase }}
                  </mat-select-trigger>
                  <mat-option *ngFor="let area of ledColors" value="{{ area.value }}">
                    <span [class]="area.className"></span>{{ area.value | titlecase }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </form>
          </div>
          <div class="modal-input" *ngIf="enclosure_details?.led_mode != 'none'">
            <label>
              <em>
                <img src="assets/images/led.svg" alt="" class="led-icon-width" />
              </em>
              LED Mode
            </label>

            <form #setLEDModeForm="ngForm">
              <mat-form-field floatLabel="never" class="reminder-select">
                <mat-label>LED Mode</mat-label>
                <mat-select name="led_mode" #led_mode="ngModel" [(ngModel)]="service.EnclosureListFormData.led_mode"
                  required (ngModelChange)="
                    changeLedMode(service.EnclosureListFormData.led_mode)
                  " [ngClass]="{
                    'is-invalid':
                      !led_mode.valid && (led_mode.dirty || led_mode.touched)
                  }" disableOptionCentering panelClass="reminder-select-panel">
                  <mat-option *ngFor="let area of ledMode" value="{{ area.value }}">
                    {{ area.value | titlecase }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-input" *ngIf="enclosure_details?.user_own_access == 1">
        <label>
          <em>
            <img src="assets/images/set-reminder.svg" alt="" />
          </em>
          Set Reminder</label>
        <form #setReminderForm="ngForm">
          <mat-form-field floatLabel="never" class="reminder-select">
            <mat-label>Set Time</mat-label>
            <mat-select name="enclosure_reminder" #enclosure_reminder="ngModel"
              [(ngModel)]="service.EnclosureReminderFormData.enclosure_reminder" required
              (ngModelChange)="setReminder(enclosure_reminder.value)" [ngClass]="{
                'is-invalid':
                  !enclosure_reminder.valid &&
                  (enclosure_reminder.dirty || enclosure_reminder.touched)
              }" disableOptionCentering panelClass="reminder-select-panel">
              <mat-option *ngFor="let area of minutes" value="{{ area.value }}">
                {{ area.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>

      <div class="modal-input modal-input-cursor" *ngIf="enclosure_details?.user_own_access == 1"
        (click)="openForwardModal()">
        <label>
          <em>
            <img src="assets/images/forward-delivery.svg" alt="" />
          </em>
          Forward Delivery</label>
        <button><img src="assets/images/arrow.svg" alt="" /></button>
      </div>
      <div class="modal-input modal-input-cursor" *ngIf="enclosure_details?.user_own_access == 1"
        (click)="openEditEncModal()">
        <label>
          <em>
            <img src="assets/images/edit-enclosure.svg" alt="" />
          </em>
          Update Device</label>
        <button><img src="assets/images/arrow.svg" alt="" /></button>
      </div>
    </form>
  </ngx-smart-modal>

  <ngx-smart-modal #ledColor [identifier]="'ledColor'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal enclosuresettings-modal ledcolor-modal">
    <form class="modal-form enclosuresettings-form ledcolor-modal-form">
      <div class="modal-input">
        <mat-form-field floatLabel="never" class="reminder-select">
          <mat-label>Select Color</mat-label>
          <mat-select name="led_color" #led_color="ngModel" [(ngModel)]="service.EnclosureListFormData.led_color"
            required (ngModelChange)="changeLedColor(led_color.value)" [ngClass]="{
              'is-invalid':
                !led_color.valid && (led_color.dirty || led_color.touched)
            }" disableOptionCentering panelClass="reminder-select-panel"
            (click)="ngxSmartModalService.getModal('setStage').open()">
            <mat-option *ngFor="let area of ledColors" value="{{ area.value }}">
              <span class="{{ area.className }}"></span>{{ area.value | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </ngx-smart-modal>

  <ngx-smart-modal #setStage [identifier]="'setStage'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal enclosuresettings-modal setstage-modal">
    <h2 class="modal-title">Set LED Stage</h2>
    <form class="modal-form enclosuresettings-form setstage-modal-form">
      <div class="modal-radio">
        <mat-radio-group aria-label="Select an option" class="modal-radio-group">
          <mat-radio-button value="1">Steady</mat-radio-button>
          <mat-radio-button value="2">Flashing</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="modal-radio-buttons">
        <button class="modal-radio-btn modal-radio-cancel-btn" (click)="setStage.close()">
          Cancel
        </button>
        <button class="modal-radio-btn modal-radio-save-btn">Ok</button>
      </div>
    </form>
  </ngx-smart-modal>

  <ngx-smart-modal #forwarddelivery [identifier]="'forwarddelivery'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal forwarddelivery-modal">
    <h2 class="modal-title">Forward Delivery</h2>

    <form #addForwardForm="ngForm" (ngSubmit)="addForward(addForwardForm)" class="modal-form forwarddelivery-form">
      <div class="modal-input">
        <label>
          <em>
            <img src="assets/images/forward-email-address.svg" alt="" />
          </em>
          Forwarded Email Address <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input maxlength="64" type="email" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
            matInput placeholder="Ex. abc@gmail.com" name="forward_user_email" #forward_user_email="ngModel"
            [(ngModel)]="service.ForwardDeliveryFormData.forward_user_email" [ngClass]="{
              'is-invalid':
                !forward_user_email.valid &&
                (forward_user_email.dirty ||
                  forward_user_email.touched ||
                  isForwardSubmit)
            }" required />
          <mat-error *ngIf="
              !forward_user_email.valid &&
              (forward_user_email.dirty ||
                forward_user_email.touched ||
                isForwardSubmit)
            ">
            <div *ngIf="forward_user_email.errors.required">
              Please enter email address
            </div>
            <div *ngIf="forward_user_email.errors.pattern">
              Enter valid email address
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input">
        <label>
          <em>
            <img src="assets/images/forward-enclosure-id.svg" alt="" />
          </em>
          Forwarded Device ID <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input (keyup)="
              getTimezone(
                $event,
                service.ForwardDeliveryFormData.forward_enclosure_id
              )
            " class="mask-input" type="text" [specialCharacters]="['-']" [dropSpecialCharacters]="false"
            mask="000-000-000" matInput placeholder="Ex. 00-14-22-01-23-45" name="forward_enclosure_id"
            #forward_enclosure_id="ngModel" [(ngModel)]="service.ForwardDeliveryFormData.forward_enclosure_id"
            [ngClass]="{
              'is-invalid':
                !forward_enclosure_id.valid &&
                (forward_enclosure_id.dirty ||
                  forward_enclosure_id.touched ||
                  isForwardSubmit)
            }" required />
          <mat-error *ngIf="
              !forward_enclosure_id.valid &&
              (forward_enclosure_id.dirty ||
                forward_enclosure_id.touched ||
                isForwardSubmit)
            ">
            Please enter device id
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input" *ngIf="forwardedEncTimezone">
        <label>
          <em>
            <img src="assets/images/encl-timezone.svg" alt="" />
          </em>
          Device Timezone<sup>*</sup></label>
        <div class="modal-input-subtext">{{ forwardedEncTimezone }}</div>
      </div>
      <div class="modal-input" [ngClass]="{
          disabled: service.ForwardDeliveryFormData.forward_enclosure_id
        }">
        <label>
          <em>
            <img src="assets/images/calendar.svg" alt="" />
          </em>
          Forward <span class="clr-green">From</span> <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input matInput [ngxMatDatetimePicker]="picker4" placeholder="Ex. 12/15/2020, 4:05 PM"
            name="forward_start_time" [formControl]="service.ForwardDeliveryFormData.forward_start_time" [min]="minDate"
            [disabled]="disabled" />
          <mat-datepicker-toggle matSuffix [for]="picker4">
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker4 [showSpinners]="showSpinners" [showSeconds]="showSeconds"
            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
            [color]="color">
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
      <div class="modal-input">
        <label>
          <em>
            <img src="assets/images/calendar.svg" alt="" />
          </em>
          Forward <span class="clr-red">Until</span> <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input matInput [ngxMatDatetimePicker]="forward_endpicker" placeholder="Ex. 12/15/2020, 4:05 PM"
            [formControl]="service.ForwardDeliveryFormData.forward_end_time" [min]="minDate" [disabled]="disabled" />
          <mat-datepicker-toggle matSuffix [for]="forward_endpicker">
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #forward_endpicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
            [color]="color">
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>


      <div class="d-flex justify-content-center">
        <button [disabled]="isForwardLoading" class="add-btn">
          Send Request
        </button>
      </div>
    </form>
  </ngx-smart-modal>

  <ngx-smart-modal #addenclosure [identifier]="'addenclosure'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal addenclosure-modal address-add-enclosure">
    <h2 class="modal-title">Update Device</h2>
    <form #addEnclosureForm="ngForm" (ngSubmit)="updateEnclosure(addEnclosureForm)"
      class="modal-form addenclosure-form">
      <input hidden matInput name="_id" #_id="ngModel" [(ngModel)]="service.EnclosureListFormData._id" />
      <div class="modal-input add-input-50 modal-margin-right">
        <label>
          <em>
            <img src="assets/images/enclosure-name.svg" alt="" />
          </em>
          Device Name <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input minlength="3" maxlength="25" pattern="^[^\s].+[^\s]$" matInput placeholder="Ex. Dan's Device"
            name="enclosure_name" #enclosure_name="ngModel" [(ngModel)]="service.EnclosureListFormData.enclosure_name"
            [ngClass]="{
              'is-invalid':
                !enclosure_name.valid &&
                (enclosure_name.dirty || enclosure_name.touched || isSubmit)
            }" required />
          <mat-error *ngIf="
              !enclosure_name.valid &&
              (enclosure_name.dirty || enclosure_name.touched || isSubmit)
            ">
            <div *ngIf="enclosure_name.errors.required">
              Please enter device name
            </div>
            <div *ngIf="enclosure_name.errors.minlength">
              Have at least 3 characters
            </div>
            <div *ngIf="enclosure_name.errors.maxlength">
              Maximum of 25 characters
            </div>
            <div *ngIf="
                !enclosure_name.errors.minlength &&
                enclosure_name.errors.pattern
              ">
              You can not enter blank space after or before the input value
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input add-input-50" [ngClass]="{ 'enc-disabled': addUpdateModal != 'Add Device' }">
        <label>
          <em><img src="assets/images/enclosure-id.svg" alt="" /></em>Device ID
          <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input [dropSpecialCharacters]="false" mask="000-000-000" matInput placeholder="Ex. 123-456-789"
            name="enclosure_id" #enclosure_id="ngModel" [(ngModel)]="service.EnclosureListFormData.enclosure_id"
            [ngClass]="{
              'is-invalid':
                !enclosure_id.valid &&
                (enclosure_id.dirty || enclosure_id.touched || isSubmit)
            }" required readonly />
          <mat-error *ngIf="
              !enclosure_id.valid &&
              (enclosure_id.dirty || enclosure_id.touched || isSubmit)
            ">
            <div *ngIf="enclosure_id.errors.required">
              Please enter device id
            </div>
            <div *ngIf="
                !enclosure_id.errors.minlength && enclosure_id.errors.pattern
              ">
              You can not enter blank space after or before the input value
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input modal-address-title w-100">
        <label>
          <em> <img src="assets/images/street-address.svg" alt="" /> </em>Enter
          Address
        </label>
      </div>
      <div class="modal-input modal-address-padding-bottom modal-margin-right">
        <label>Address Line 1 <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input minlength="3" pattern="^[^\s].+[^\s]$" maxlength="200" matInput placeholder="Ex. 123 Main Street"
            name="address_line_a" #address_line_a="ngModel" [(ngModel)]="service.EnclosureListFormData.address_line_a"
            [ngClass]="{
              'is-invalid':
                !address_line_a.valid &&
                (address_line_a.dirty || address_line_a.touched || isSubmit)
            }" required />
          <mat-error *ngIf="
              !address_line_a.valid &&
              (address_line_a.dirty || address_line_a.touched || isSubmit)
            ">
            <div *ngIf="address_line_a.errors.required">
              Please enter address line 1
            </div>
            <div *ngIf="address_line_a.errors.minlength">
              Have at least 3 characters
            </div>
            <div *ngIf="
                !address_line_a.errors.minlength &&
                address_line_a.errors.pattern
              ">
              You can not enter blank space after or before the input value
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="modal-input modal-address-padding-bottom modal-margin-right">
        <label>Address Line 2 <sup></sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input pattern="^[^\s].+[^\s]$" minlength="3" maxlength="200" matInput placeholder="Ex. Apt # or Suite #"
            name="address_line_b" #address_line_b="ngModel"
            [(ngModel)]="service.EnclosureListFormData.address_line_b" />

          <mat-error *ngIf="
              !address_line_b.valid &&
              (address_line_b.dirty || address_line_b.touched || isSubmit)
            ">
            <div *ngIf="address_line_b.errors.required">
              Please enter address line 2
            </div>
            <div *ngIf="address_line_b.errors.minlength">
              Have at least 3 characters
            </div>
            <div *ngIf="
                !address_line_b.errors.minlength &&
                address_line_b.errors.pattern
              ">
              You can not enter blank space after or before the input value
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input modal-address-padding-bottom">
        <label>City <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input minlength="3" pattern="^[^\s].+[^\s]$" maxlength="200" matInput placeholder="Ex. Indianapolis" name="city"
            #city="ngModel" [(ngModel)]="service.EnclosureListFormData.city" [ngClass]="{
              'is-invalid':
                !city.valid && (city.dirty || city.touched || isSubmit)
            }" required />
          <mat-error *ngIf="!city.valid && (city.dirty || city.touched || isSubmit)">
            <div *ngIf="city.errors.required">Please enter city</div>
            <div *ngIf="city.errors.minlength">Have at least 3 characters</div>
            <div *ngIf="!city.errors.minlength && city.errors.pattern">
              You can not enter blank space after or before the input value
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input modal-address-padding-bottom modal-margin-right">
        <label>State <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input minlength="2" pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="200" matInput placeholder="Ex. Michigan"
            name="state" #state="ngModel" [(ngModel)]="service.EnclosureListFormData.state" [ngClass]="{
              'is-invalid':
                !state.valid && (state.dirty || state.touched || isSubmit)
            }" required />
          <mat-error *ngIf="!state.valid && (state.dirty || state.touched || isSubmit)">
            <div *ngIf="state.errors.required">Please enter state</div>
            <div *ngIf="state.errors.minlength">Have at least 2 characters</div>
            <div *ngIf="!state.errors.minlength && state.errors.pattern">
              You can not enter blank space after or before the input value
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input modal-address-padding-bottom modal-margin-right">
        <label>Country <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input minlength="3" pattern="^[^\s].+[^\s]$" maxlength="200" matInput placeholder="Ex. United States"
            name="country" #country="ngModel" [(ngModel)]="service.EnclosureListFormData.country" [ngClass]="{
              'is-invalid':
                !country.valid && (country.dirty || country.touched || isSubmit)
            }" required />
          <mat-error *ngIf="
              !country.valid && (country.dirty || country.touched || isSubmit)
            ">
            <div *ngIf="country.errors.required">Please enter country</div>
            <div *ngIf="country.errors.minlength">
              Have at least 3 characters
            </div>
            <div *ngIf="!country.errors.minlength && country.errors.pattern">
              You can not enter blank space after or before the input value
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input modal-address-padding-bottom">
        <label>Zipcode <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input (keypress)="numberOnly($event)" pattern="^[0-9]{0,}$" minlength="4" maxlength="8" matInput
            placeholder="Ex. 656379" name="zipcode" #zipcode="ngModel"
            [(ngModel)]="service.EnclosureListFormData.zipcode" [ngClass]="{
              'is-invalid':
                !zipcode.valid && (zipcode.dirty || zipcode.touched || isSubmit)
            }" required />
          <mat-error *ngIf="
              !zipcode.valid && (zipcode.dirty || zipcode.touched || isSubmit)
            ">
            <div *ngIf="zipcode.errors.required">Please enter zipcode</div>
            <div *ngIf="zipcode.errors.pattern || zipcode.errors.minlength">
              Enter valid zipcode
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input modal-address-padding-bottom modal-margin-right">
        <label>Device Latitude <sup></sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input pattern="-?\d{1,3}\.\d+" minlength="6" maxlength="20" matInput placeholder="23.0283"
            name="enclosure_latitude" #enclosure_latitude="ngModel"
            [(ngModel)]="service.EnclosureListFormData.enclosure_latitude" [ngClass]="{
              'is-invalid':
                !enclosure_latitude.valid && (enclosure_latitude.dirty || enclosure_latitude.touched || isSubmit)
            }" />
          <mat-error *ngIf="
              !enclosure_latitude.valid && (enclosure_latitude.dirty || enclosure_latitude.touched || isSubmit)
            ">
            <div *ngIf="enclosure_latitude.errors.required">Please enter device latitude</div>
            <div *ngIf="enclosure_latitude.errors.minlength">Have at least 6 characters</div>
            <div *ngIf="!enclosure_latitude.errors.minlength && enclosure_latitude.errors.pattern">
              Please Enter Valide Device Latitude
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="modal-input modal-address-padding-bottom modal-margin-right">
        <label>Device Longitude <sup></sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input minlength="6" maxlength="20" pattern="-?\d{1,3}\.\d+" matInput placeholder="23.0283"
            name="enclosure_longitude" #enclosure_longitude="ngModel"
            [(ngModel)]="service.EnclosureListFormData.enclosure_longitude" [ngClass]="{
              'is-invalid':
                !enclosure_longitude.valid && (enclosure_longitude.dirty || enclosure_longitude.touched || isSubmit)
            }" />
          <mat-error *ngIf="
              !enclosure_longitude.valid && (enclosure_longitude.dirty || enclosure_longitude.touched || isSubmit)
            ">
            <div *ngIf="enclosure_longitude.errors.minlength">Have at least 6 characters</div>
            <div *ngIf="!enclosure_longitude.errors.minlength && enclosure_longitude.errors.pattern">
              Please Enter Valide Device Longitude
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="d-flex justify-content-center modal-btn-update">
        <button [disabled]="isLoading" class="add-btn">
          <em class="add-btn-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.001" height="12.001" viewBox="0 0 12.001 12.001">
              <g id="Group_234" data-name="Group 234" transform="translate(0)">
                <path id="Path_48" data-name="Path 48"
                  d="M133.709,129.628c-4.092-.033-10.081,0-10.081,0a.96.96,0,1,1,0-1.92h10.081a.96.96,0,1,1,0,1.92Z"
                  transform="translate(-122.668 -122.668)" fill="#ffffff" />
                <path id="Path_190" data-name="Path 190"
                  d="M5.041,1.92C.949,1.887.96,1.92.96,1.92A.96.96,0,0,1,.96,0H5.041a.96.96,0,1,1,0,1.92Z"
                  transform="translate(6.96 6) rotate(90)" fill="#ffffff" />
                <path id="Path_191" data-name="Path 191"
                  d="M3.041,1.92c-4.092-.033-2.081,0-2.081,0A.96.96,0,0,1,.96,0H3.041a.96.96,0,1,1,0,1.92Z"
                  transform="translate(6.96 0) rotate(90)" fill="#ffffff" />
              </g>
            </svg> </em>{{ addUpdateModal }}
        </button>
      </div>
    </form>
  </ngx-smart-modal>

  <!-- Live Video Streaming Modal -->
  <ngx-smart-modal #videoStreaming [identifier]="'videoStreaming'" [dismissable]="false" customClass="nsm-dialog-animation-ttb nsm-centered modal addenclosure-modal video-streaming-modal">
    <h2 class="modal-title">Live Video Streaming</h2>
    <form class="access-control-modal" [formGroup]="videoStreamingForm" (ngSubmit)="VideoStreaming()">
      <div class="access-modal-input fullwidth">
        <label>Timer <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="fill">
          <mat-select formControlName="timer" placeholder="Select Timer">
            <mat-option [value]="option._id" title="{{ option.name }}" *ngFor="let option of timerList">{{ option.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!videoStreamingForm.get('timer').valid && (videoStreamingForm.get('timer').dirty || videoStreamingForm.get('timer').touched || issSubmit)">
            <div *ngIf="videoStreamingForm.get('timer').hasError('required')">Please Select Timer</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input fullwidth">
        <label>Resolution <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="fill">
          <mat-select formControlName="resolution" placeholder="Select Resolution">
            <mat-option [value]="option._id" title="{{ option.name }}" *ngFor="let option of resolutionList">{{ option.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!videoStreamingForm.get('resolution').valid && (videoStreamingForm.get('resolution').dirty || videoStreamingForm.get('resolution').touched || issSubmit)">
            <div *ngIf="videoStreamingForm.get('resolution').hasError('required')">Please Select Resolution</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="access-modal-input fullwidth">
        <button class="add-btn" type="submit">Start Streaming</button>
      </div>
    </form>
  </ngx-smart-modal>
</div>