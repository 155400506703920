<mat-sidenav-container class="ins-sidenav">
    <mat-sidenav #appDrawer mode="over" opened="false" class="mat-sidenav-custom">
        <div class="ins-logo d-flex justify-content-center align-items-center">
            <img src="assets/images/arrive_logo_white.png" alt="Logo" width="100" />
        </div>
        <mat-nav-list>
            <app-menu-list-item *ngFor="let item of navItems" [item]="item"></app-menu-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <app-top-nav></app-top-nav>
    <div class="app-wrapper">
        <router-outlet></router-outlet>
    </div>
</mat-sidenav-container>