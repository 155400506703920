import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedAccessService } from '../shared-access/shared-access.service';
import { EnclosureDetailsService } from '../enclosure-details/enclosure-details.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
export interface PeriodicElement {
  user_name: string;
  user_email: string;
  user_access: any;
  duration: any;
  status: any;
  action: any;
}

const ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: 'app-shared-access',
  templateUrl: './shared-access.component.html',
  styles: [
  ]
})
export class SharedAccessComponent implements OnInit {
  displayedColumns: string[] = ['user_name', 'user_email', 'user_access', 'duration', 'status', 'action'];
  public dataSourceEmpty: any = false;
  public enclosure_id: any;
  public user_access: any;
  public enclosure_details: any;

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private _location: Location, private toastr: ToastrService, public encDetailService: EnclosureDetailsService, public service: SharedAccessService, public router: Router, public route: ActivatedRoute, public ngxSmartModalService: NgxSmartModalService,) {

  }

  ngOnInit() {
    this.enclosure_id = atob(this.route.snapshot.params.eid);
    this.user_access = atob(this.route.snapshot.params.access);
    this.getAccessViewByUid(this.enclosure_id);
    this.getEnclosureDetail(this.enclosure_id);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.dataSourceEmpty = true;
    } else {
      this.dataSourceEmpty = false;
    }
  }
  backClicked() {
    this._location.back();
  }
  getEnclosureDetail(eid) {
    const send_data = { enclosure_id: eid };
    this.encDetailService.getEncViewById(eid).subscribe((res) => {
      if (res['status'] == 1) {
        this.enclosure_details = res['data'];
      } else {
      }
    })
  }
  public accessDetail: any;
  openDeleteModal(element) {
    this.isLoading = false;
    if (element.user_access == 1) {
      this.toastr.error('You can not delete primary member!', '');
    }
    else if (element.user_access == 2 && (this.user_access != 1)) {
      this.toastr.error('Permission denied!', '');
    }
    else {
      this.accessDetail = element;
      this.ngxSmartModalService.getModal('deleteencl').open()
    }
  }
  getAccessViewByUid(eid) {
    // arg 1 = access mst tbl 2= temp tbl
    this.service.getAccessViewByUserid(eid, 1).subscribe((res) => {
      if (res['status'] == 1) {
        this.dataSource = new MatTableDataSource();
        this.dataSource.data = res['data'];
        if (res['data'].length == 0) {
          this.dataSourceEmpty = true;
        }
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.router.navigate(['/app/enclosure-list']);
      }
    })
  }
  sharedAccessList() {
    this.router.navigate(['/app/shared-access-history/' + btoa(this.enclosure_id) + '/' + btoa(this.user_access)]);
  }
  public isLoading: boolean = false;
  deleteAccess(data) {
    this.isLoading = true;
    this.service.deleteAccess(data.tmp_id).subscribe((res) => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        this.ngxSmartModalService.getModal('deleteencl').close();
        this.getAccessViewByUid(this.enclosure_id);
      }
      else {
        this.isLoading = false;
        this.toastr.error(res['msg'], '');
      }
      this.getAccessViewByUid(this.enclosure_id);
    })
  }
}
