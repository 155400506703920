import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { EnclosureListService } from './enclosure-list.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import jwt_decode from "jwt-decode";
import { tap, finalize } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Socket } from 'ngx-socket-io';

interface Minute {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-enclosure-list',
  templateUrl: './enclosure-list.component.html',
  styles: [
  ]
})
export class EnclosureListComponent implements OnInit {

  minutes: Minute[] = [
    { value: '0', viewValue: '10 min' },
    { value: '1', viewValue: '15 min' },
    { value: '2', viewValue: '30 min' },
    { value: '3', viewValue: '45 min' },
    { value: '4', viewValue: '1 hour' }
  ];
  static liveTracking: any;


  [x: string]: any;
  enclosure_id: string;
  constructor(
    public authService: AuthService,
    private _location: Location,
    private toastr: ToastrService,
    public service: EnclosureListService,
    public router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    public socket: Socket
  ) { }
  public encList: any = [];
  public isSubmit: boolean = false;
  public isLoading: boolean = false;
  public isForwardSubmit: boolean = false;
  public isForwardLoading: boolean = false;
  public addUpdateModal: any = 'Add Device';
  public tokenData: any;
  public request_status: any;
  public forward_user_email: any;
  public forward_start_time: any;
  public forward_end_time: any;
  public forward_enclosure_id: any;
  public enc_id: any;
  public login_user_name: any;
  loggedInUserId:any;
  @ViewChild('addEnclosureForm', { static: true }) addEnclosureForm: NgForm;
  @ViewChild('addForwardForm', { static: true }) addForwardForm: NgForm;

  currentDate = new Date();

  ngOnInit(): void {
    
    this.socket.on('receive_data_from_device', (device) => {
      this.encList.forEach(element => {
        if (device['enclosure_id'] && device['enclosure_id'] == element.enclosure_id) {
          if (device['type'] == 'internet_connection_lost') {
            element.internet_connectivity = 0;
          }
          else {
            element.enclosure_fault = device['enclosure_fault'];
            element.theft_detection = device['theft_detection'];
            element.user_door_status = device['user_door_status'];
            element.user_door_lock_fault = device['user_door_lock_fault'];
            element.drone_door_motor_fault = device['drone_door_motor_fault'];
            element.drone_door_lock_fault = device['drone_door_lock_fault'];
            element.heater_fault = device['heater_fault'];
            element.internet_connectivity = 1;
            element.fw_version = device['fw_version'];
          }
        }
      });
    });
    this.resetForm();
    this.getEncListByUid();
    this.getLoggedinUser();
    const token = sessionStorage.getItem('token');
    this.tokenData = jwt_decode(token);
  }
  backClicked() {
    this._location.back();
  }
  getLoggedinUser() {
    this.authService.getLoggedinUser().subscribe(res => {
      if (res['status'] == 1) {
        let uData = res['data'];
        this.loggedInUserId = uData.user_id;
        this.authService.UpdateProfileFormData = uData;
      }
      else {
        this.toastr.error(res['msg'], '');
        sessionStorage.removeItem('token');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 3000);
      }
    });
  }
  viewForwardModal(element) {
    this.ngxSmartModalService.getModal('addenclosure').close();
    this.ngxSmartModalService.getModal('viewForward').open();
    this.request_status = element.request_status;
    this.forward_enclosure_id = element.forward_enclosure_id;
    this.enc_id = element.enclosure_id;
    this.forward_enclosure_id = element.forward_enclosure_id;
    this.forward_user_email = element.forward_user_email;
    this.forward_start_time = element.forward_start_time;
    this.forward_end_time = element.forward_end_time;
  }
  openAddEncModal() {
    this.isLoading = false;
    this.resetForm();
    this.addEnclosureForm.resetForm();
    this.addUpdateModal = 'Add Device';
    this.ngxSmartModalService.getModal('addenclosure').open();
  }
  resetForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.EnclosureListFormData = {
      enclosure_id: '',
      enclosure_name: '',
      address_line_a: '',
      address_line_b: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      enclosure_latitude: '',
      enclosure_longitude: '',
      _id: ''
    };
  }
  getEncListByUid() {
    this.service.getEnclosureListByUserId().subscribe((res) => {
      if (res['status'] == 1) {
        this.encList = res['data'];
      }
      else {
        this.toastr.error(res['msg'], '');
      }
    })
  }
  numberOnly(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  addEnclosure(form?: NgForm) {

    form.value.added_by = this.loggedInUserId;
    console.log("form/....",form.value);
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    else {
      this.isLoading = true;
      form.value.enclosure_id = this.service.EnclosureListFormData.enclosure_id;
      this.service.addEclosure(form.value)
        .pipe(
          tap(x => console.log('tap', x)),
          finalize(() => {
          })
        )
        .subscribe((res) => {
          if (res['status'] == 1) {
            this.toastr.success(res['msg'], '');
            this.getEncListByUid();
            this.ngxSmartModalService.getModal('addenclosure').close();
          }
          else {
            this.isLoading = false;
            this.toastr.error(res['msg'], '');
          }
        })
    }
  }
  checkEID(event) {
    const pattern = /[0-9]/;
    var charCode = event.keyCode;
    if (charCode != 46 && charCode != 45 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
    }
    else {
    }
  }
  checkEIsD(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    console.log(charCode);
    if (charCode != 46 && charCode != 45 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
    }
  }
  deliveryDetail(eid, access, owneruid, ename) {
    this.router.navigate(['/app/parcel-delivery-details/' + btoa(eid)]);
  }
  enclosureMoreDetail(eid, access, owneruid) {
    this.router.navigate(['/app/enclosure-details/' + btoa(eid)]);
  }

  doorOpenClose(edata) {
    if (edata['user_door_status'] == 0 && edata['internet_connectivity'] == 1) {
      this.service.openEdoor(edata).subscribe((res) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.getEnclosureDetail(this.enclosure_id);
          this.ngxSmartModalService.getModal('enclosuresettings').close();
        }
        else {
          this.toastr.error(res['msg'], '');
        }
      })
    } else if (edata['user_door_status'] == 1 && edata['internet_connectivity'] == 1) {
      this.service.closeEdoor(edata).subscribe((res) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.getEnclosureDetail(this.enclosure_id);
          this.ngxSmartModalService.getModal('enclosuresettings').close();
        }
        else {
          this.toastr.error(res['msg'], '');
        }
      })
    }
    else {
      if (edata['user_door_status'] == 1) {
        this.toastr.error('Door is already unlocked', '');
      }
      else {
        this.toastr.error('Internet connection lost', '');
      }
    }
  }
}
