/**
 * @Author: Jitesh Prajapati <jiteshprajapati>
 * @Date:   2021-03-18T15:28:19+05:30
 * @Email:  jitesh.prajapati@teksun.com
 * @Filename: ensure.ts
 * @Last modified by:   jiteshprajapati
 * @Last modified time: 2021-03-19T14:11:11+05:30
 * @Copyright: Teksun
 */
import { Injectable, NgModule } from '@angular/core';
import { HttpResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponseBase } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import jwt_decode from "jwt-decode";

@Injectable()
export class Globals {
    role = '';
}

export class AuthInterceptor implements HttpInterceptor {
    constructor() { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // loaderService.show();
        const token = sessionStorage.getItem('token');
        if (token) {
            var tkDecode = jwt_decode(token);
            if (tkDecode['exp'] < Math.round((new Date).getTime() / 1000)) {
                console.log('token expired');
                sessionStorage.removeItem('token');
                window.location.href = environment.login_page;
            }
            else {
                const authReq = req.clone({
                    headers: req.headers.set(
                        'access_token',
                        token
                    )
                });
                req = authReq;
            }
        }
        return next.handle(req).pipe(
            tap(
                event => this.handleResponse(req, event),
                error => this.handleError(req, error)
            )
        );
    }

    handleResponse(req: HttpRequest<any>, event) {
        if (event instanceof HttpResponse) {
        }
    }

    handleError(req: HttpRequest<any>, event) {
        let errorMessage = 'Unknown error!';
        if (event.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${event.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${event.status}`;
        }
        if (event.status == 403 || event.status == 401 || event.status == 404 || event.status == 500) {
            sessionStorage.removeItem('token');
            window.location.href = environment.login_page;
        }
    }
}
