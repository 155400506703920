import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserDoorHistoryService {

  constructor(
    public http: HttpClient
  ) { }

  getUserDoorHistory(_id:any) {
    return this.http.get(environment.api_url + '/enclosures/access-image-log-history/' + _id);
  }
  getEncViewById(id: any) {
    return this.http.get(environment.api_url + '/enclosures/' + id);
  }
}
