<div class="container-fluid">
    <div class="main mx-2 mb-4">
        <div class="row">
            <div class="col-12">
                <div class="title-wrapper title-border-bottom mt-2">
                    <h1 class="title-1">
                        <button class="back-btn" (click)="backClicked()"><span
                                class="material-icons">keyboard_arrow_left</span></button>
                        Live Tracking {{encData?.enclosure_name}} <span *ngIf="pending_time > 0"><span style="margin-left: 5px;"></span>({{pending_time | number: '1.0-0'}}
                            Minutes Left)</span>
                    </h1>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="no-data-wrap" *ngIf='!hideTable || GPSError'>
                    <div class="no-data-delivery" *ngIf='!hideTable'>
                        <span class="no-data-image"><img src="assets/images/enclosure-raw.svg" alt="" /></span>
                        <span class="no-data-title">No delivery yet</span>
                    </div>
                    <div class="gps-error-block" *ngIf="GPSError">
                        <span class="gps-error">Device GPS not configured</span>
                        <button (click)="backClicked()" class="gps-ok-btn">OK</button>
                    </div>
                </div>
            </div>

            <div class="col-md-12" *ngIf="!GPSError">
                <div class="app-card">
                    <div class="app-card-body">
                        <div id="map" class="marker"></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
