import { Injectable, EventEmitter } from '@angular/core';    
    
@Injectable({    
  providedIn: 'root'    
})    
export class EventEmitterService {    
  invokeTopNavComponent = new EventEmitter();
  invokeNotificationComponent = new EventEmitter();
  constructor() { }    
  onProfileUpdate(data) {  
    this.invokeTopNavComponent.emit(data);    
  }
  NotificationCount(){
    this.invokeNotificationComponent.emit();
  }
}    