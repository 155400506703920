/**
 * @Author: Jitesh Prajapati <jiteshprajapati>
 * @Date:   2021-03-18T16:45:41+05:30
 * @Email:  jitesh.prajapati@teksun.com
 * @Filename: login.component.ts
 * @Last modified by:   jiteshprajapati
 * @Last modified time: 2021-03-19T16:37:11+05:30
 * @Copyright: Teksun
 */


import { Component, OnInit } from '@angular/core';
import { AuthService } from './../auth.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  CookieService
} from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
  ]
})
export class LoginComponent implements OnInit {
  public message: any;
  public nonce: any = '35A90B73C39D97';
  public path: any;
  public privateKey: any = "4AF8AC8E124B6484BDDC5A9EB3D49";
  public secretKey: any = "PXGuKjNtjzLiyLRE0GzykvGcaZO5uWE0";
  public timestamp: any = 1615971782;

  hide = true;
  public isSubmit: boolean;
  public isLoading: boolean = false;
  constructor(private cookieService: CookieService, public service: AuthService, public router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    /*

    var hmac = CryptoJS.HmacSHA256(this.secretKey+this.timestamp+this.nonce,this.privateKey).toString(CryptoJS.enc.Hex);
    console.log(this.privateKey, "this.privateKey");
    console.log(this.secretKey+this.timestamp+this.nonce,'this.secretKey+this.timestamp+this.nonce');
    console.log(hmac, "hmac Angular");
    console.log('a656747cd0b3eb3f9e36025e6c732d695de0c710d5291ad4e6c2e4f7c02e004a','Backend');
    */
    this.resetForm();
  }
  doLogin(form?: NgForm) {
    if (!form.valid) {
    }
    else {
      this.isLoading = true;
      form.value.type = 'web';
      form.value.user_type = 2;
      this.service.doLogin(form.value).subscribe((res) => {
        if (res['status'] == 1) {
          if (res['token'] == null || !res['token']) {
            this.isLoading = false;
            this.toastr.error(res['msg'], '');
          }
          else {
            this.toastr.success(res['msg'], '');
            sessionStorage.setItem('token', res['token']);
             this.isLoading=false;

            setTimeout(() => {
              this.router.navigate(['/app/enclosure-list']);
            }, 2000);
          }
        }
        else {
          this.isLoading = false;
          this.toastr.error(res['msg'], '');
        }
      })
    }
  }
  resetForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.LoginFormData = {
      user_email: '',
      user_password: ''
    };
  }
}
