import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Register, Login, ForgotPassword,ResetPassword, ChangePassword,UpdateProfile,NotificationSettings, OtaAccess ,MetricsSpace,MetricsTemp} from './auth.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public jwtHelper: JwtHelperService = new JwtHelperService();
  RegisterFormData: Register;
  LoginFormData: Login;
  ForgotPasswordFormData: ForgotPassword;
  ChangePasswordFormData: ChangePassword;
  UpdateProfileFormData: UpdateProfile;
  resetPasswordFormData:ResetPassword;
  otaAccessFormData:OtaAccess;
  NotiSettingsFormData:NotificationSettings;
  metricsTempFormData:MetricsTemp;
  metricsSpaceFormData:MetricsSpace;
  constructor(private http: HttpClient) {}

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('token');
      return !this.jwtHelper.isTokenExpired(token);
  }

  doRegister(data) {
    return this.http.post(environment.api_url + '/users', data);
  }

  getLoggedinUser(){
    return this.http.get(environment.api_url + '/users/profile');
  }

  doLogin(data) {
    return this.http.post(environment.api_url + '/users/login', data);
  }
 
  forgotPassword(data) {
    return this.http.get(environment.api_url + '/users/forgot-password/'+data.user_email+'/'+data.user_type,{});
  }
  changePassword(data) {
    return this.http.put(environment.api_url + '/users/change-password', data);
  }
  updateProfile(data) {
    return this.http.put(environment.api_url + '/users/', data);
  }
  userEmailVerification(token){
    return this.http.put(environment.api_url + '/users/verify-email/'+token,{});
  }
  checkToken(data) {
    return this.http.get(environment.api_url + '/users/verify-reset-password-link/'+data);
  }
  Reset(data) {
    return this.http.put(environment.api_url + '/users/reset-password/'+data.token_id, data);
  }

  //Metrics management
  getMetricsDetails(){
    return this.http.get(environment.api_url+"/dashboard/metrics");
  }
  updateMetricDetils(data){
    return this.http.put(environment.api_url+"/dashboard/metrics",data);
  }
}
