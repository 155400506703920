import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { LoaderService } from '../services/loader.service';
@Component({
  selector: 'app-userverify',
  templateUrl: './userverify.component.html'
})
export class UserverifyComponent implements OnInit {
  loading: boolean;
  constructor(private loaderService: LoaderService, public route: ActivatedRoute, public router: Router, public service: AuthService) {
    this.loaderService.isLoading.subscribe((val: boolean) => {
      this.loading = val;
    });
  }
  public msg: any;
  public loginUrl: any = environment.host + "/login";
  ngOnInit() {
    const token = this.route.snapshot.params.token;
    const expire_time = atob(this.route.snapshot.params.expire_time);
    const epochNow = Math.round((new Date).getTime() / 1000);//(new Date).getTime()/1000;
    // return;
    if (epochNow < parseInt(expire_time)) {
      this.service.userEmailVerification(token).subscribe(res => {
        if (res['status'] == 1) {
          this.msg = res;
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 5000);
        } else {
          this.msg = res;
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 5000);
        }
      });
    } else {
      this.msg = { status: 0, msg: environment.token_expire };//"Token has been expired! please try again";
      setTimeout(() => {
        this.router.navigate(['login']);
      }, 5000);
    }
  }
}
