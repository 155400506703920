/**
 * @Author: Jitesh Prajapati <Jitesh_Prajapati>
 * @Date:   2021-03-18T15:28:19+05:30
 * @Email:  jitesh.prajapati@teksun.com
 * @Filename: get_user_access_name_pipe.ts
 * @Last modified by:   Jitesh_Prajapati
 * @Last modified time: 2021-03-24T10:30:39+05:30
 * @Copyright: Teksun INC <https://teksun.com/>
 */



import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'accessNamePipe' })

export class GetUserAccessNamePipe implements PipeTransform {

    transform(value: number) {

        if (value && !isNaN(value)) {
            if (value == 0) {
                return '---';
            }
            if (value == 1) {
                return 'Primary Access';
            } else if (value == 2) {

                return 'Secondary Access';
            }
            else if (value == 3) {

                return 'Temporary Access';
            }
            else if (value == 4) {

                return 'One Time Access';
            }
        } else {
            //console.log(value);
            //return 'Primary';
        }
        return;
    }
}
