import { Component, OnInit } from '@angular/core';
import { AuthService } from './../auth.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: [
  ]
})
export class ForgotPasswordComponent implements OnInit {
  public isSubmit: true;
  public isLoading: any = false;
  constructor(public service: AuthService, public router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.resetForm();
  }
  sendEmail(form?: NgForm) {
    if (!form.valid) {
      this.isSubmit = true;
      this.isLoading = false;
      return;
    }
    else {
      this.isLoading = true;
      form.value.user_type = 2;
      this.service.forgotPassword(form.value).subscribe((res) => {
        if (res['status'] == 1) {
          this.toastr.success('If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.', '', {
            timeOut: 5000
          });
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 4000);
        }
        else {
          this.isLoading = false;
          this.toastr.error(res['msg'], '');
        }
      })
    }
  }
  resetForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.ForgotPasswordFormData = {
      user_email: '',
    };
  }
}
