import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AccessControlService {

  constructor(
    private http: HttpClient
  ) { }
  
  getEncViewById(id) {
    return this.http.get(environment.api_url + '/enclosures/' + id);
  }
  GetAccessControlType() {
    return this.http.get(environment.api_url + '/enclosures/get-access-control-type');
  }
  GetAccessControl(filter_id: any) {
    return this.http.get(environment.api_url + '/enclosures/get-all-access-control/' + filter_id);
  }
  GetAccessControlById(id: any) {
    return this.http.get(environment.api_url + '/enclosures/get-access-control-by-id/' + id);
  }
  AddAccessControl(data: any) {
    return this.http.post(environment.api_url + '/enclosures/add-access-control', data);
  }
  EditAccessControl(data: any, id: any) {
    return this.http.post(environment.api_url + '/enclosures/update-access-control/' + id, data);
  }
  DeleteAccessControl(data: any, id: any) {
    return this.http.post(environment.api_url + '/enclosures/delete-access-control/' + id, data);
  }
}
