<div class="container-fluid">
  <div class="main mx-2 mb-4">
    <div class="row">
      <div class="col-12">
        <div class="title-wrapper title-border-bottom mt-2">
          <h1 class="title-1">
            <button class="back-btn" (click)="backClicked()">
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
            Actuator Logs History
          </h1>
          <div class="sub-title-wrapper">
            <div class="encl-name-subtitle">{{ enclosure_id }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="app-card">
          <div class="app-card-body">
            <div class="table-responsive">
              <mat-form-field floatLabel="never" appearance="standard" class="search-filter">
                <span class="material-icons">search</span>
                <input matInput (keyup)="applyFilter($event)" placeholder="Search by Door Type and Status" #input />
              </mat-form-field>

              <table mat-table [dataSource]="dataSource" matSort class="app-table dashboard-app-table" aria-describedby="Device History">
                
                <ng-container matColumnDef="position">
                  <th scope="col" mat-header-cell *matHeaderCellDef>No.</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{ (pageIndex * pageSize) + (i + 1) }}
                  </td>
                </ng-container>

                <!-- <ng-container matColumnDef="door_type">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Door Type</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.door_type || '-' }}</span>
                  </td>
                </ng-container> -->

                <ng-container matColumnDef="status">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span class="status">{{ element.status }}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="captured_time">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Captured Time</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.captured_time | date : "d MMMM, y h:mm a" }}</span>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>

              <div class="drone-nodata-box" *ngIf="this.dataSource?.filteredData?.length <= 0">
                <div class="drone-nodata-box-wrap">
                  <div class="drone-nodata-box-cover">
                    <div class="drone-nodata-cover">
                      <span class="material-icons">error_outline</span>
                      <p class="drone-nodata-text">No records found!</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <mat-paginator [pageSize]="10" class="app-paginator" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons> </mat-paginator> -->
              <mat-paginator [length]="totalLogs" [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 30]" [pageIndex]="pageIndex" 
                  (page)="onPageChange($event)" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
