<div class="auth resetpassword">
  <div class="auth-wrap">
    <div class="auth-block">
      <div class="auth-logo">
        <img src="assets/images/logo-dronedek.png" alt="" />
      </div>
      <form
        class="auth-form"
        #checkOtpForm="ngForm"
        (submit)="checkOtp(checkOtpForm)"
      >
        <div class="auth-form-body auth-form-login-body">
          <div class="auth-form-inner-body auth-form-login-inner-body">
            <mat-form-field floatLabel="never" appearance="standard">
              <input
                [dropSpecialCharacters]="false"
                mask="000-000-000"
                matInput
                placeholder="Ex. 123-456-789"
                name="enclosure_id"
                #enclosure_id="ngModel"
                [(ngModel)]="service.otaAccessFormData.enclosure_id"
                [ngClass]="{
                  'is-invalid':
                    !enclosure_id.valid &&
                    (enclosure_id.dirty || enclosure_id.touched || isSubmit)
                }"
                required
              />

              <mat-error
                *ngIf="
                  !enclosure_id.valid &&
                  (enclosure_id.dirty || enclosure_id.touched || isSubmit)
                "
              >
                <div *ngIf="enclosure_id.errors.required">
                  Please enter device id
                </div>

                <div
                  *ngIf="
                    !enclosure_id.errors.minlength &&
                    enclosure_id.errors.pattern
                  "
                >
                  You can not enter blank space after or before the input value
                </div>
              </mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="never" appearance="standard">
              <label>OTP <sup class="sup">*</sup></label>
              <input
                minlength="6"
                matInput
                name="user_otp"
                #user_otp="ngModel"
                [(ngModel)]="service.otaAccessFormData.user_otp"
                placeholder="Enter OTP"
                [ngClass]="{
                  'is-invalid':
                    !user_otp.valid &&
                    (user_otp.dirty || user_otp.touched || isSubmit)
                }"
                required
              />
              <mat-error
                *ngIf="
                  !user_otp.valid &&
                  (user_otp.dirty || user_otp.touched || isSubmit)
                "
              >
                <div *ngIf="user_otp.errors.required">OTP is required</div>
                <div
                  *ngIf="!user_otp.errors.required && user_otp.errors.minlength"
                >
                  Have at least 6 characters
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="auth-form-bottom">
            <div class="auth-form-bottom-inner">
              <button
                [disabled]="isLoading"
                class="changepass-btn"
                type="submit"
              >
                Check OTP
              </button>
            </div>
            <div class="auth-form-bottom-inner">
              <p class="auth-form-bottom-text">
                Any problems? Don't worry, we're here.<a>Contact Us</a>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
