import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { LiveTrackingIconService } from '../live-tracking-icon/live-tracking-icon.service';
import { HttpClient } from '@angular/common/http';
import { EnclosureDetailsService } from '../enclosure-details/enclosure-details.service';
import { ToastrService } from 'ngx-toastr';
import { Socket } from 'ngx-socket-io';
import { Message } from '../_helper/message';
import { isEmpty } from 'rxjs/operators';
@Component({
  selector: 'app-live-tracking-icon',
  templateUrl: './live-tracking-icon.component.html'
})
export class LiveTrackingIconComponent implements OnInit {

  public enclosure_id: any;
  public user_access: any;
  public enclosure_name: any;
  public canvas: any;
  map: mapboxgl.Map;
  public destination: any = [];
  public origin: any = [];
  public rts: any;
  public point: any;
  public arc: any = [];
  public counter = 0;
  public layer_id = 0;
  public steps: any = 0;
  public mkr: any;
  public isAddressSubmit: boolean = false;
  public isLatLangSubmit: boolean = false;
  public isDeliverySubmit: boolean = false;
  public toAddressLayer: any;
  public lineLayer: any;
  public fromAddressLayer: any;
  public routeLayer: any;
  public pointLayer: any;
  public currentZoom: any = 9;
  public distance: any = 0;
  public lastZoom: any;
  public released_time: number;
  public estimation_time: any;
  public encData: any;
  public hideTable: boolean = false;
  public totalStep: any;
  public skipStep: any;
  public addressJson: any = { 'attribution': '', 'features': [], 'query': [], 'type': '' };
  public latlngJson: any = { 'attribution': '', 'features': [], 'query': [], 'type': '' };
  public GPSError: boolean = false;
  Mapbox_accessToken_URL =  "";

  constructor(
    public route: ActivatedRoute,
    private _location: Location,
    public service: LiveTrackingIconService,
    private httpClient: HttpClient,
    public serviceEnclosure: EnclosureDetailsService,
    public router: Router,
    private toastr: ToastrService,
    private socket: Socket,
  ) {
    this.Mapbox_accessToken_URL = Message.dec(environment.mapbox.accessToken)
  }

  ngOnInit(): void {
    this.enclosure_id = atob(this.route.snapshot.params.eid);
    this.socket.on('socket_drone_released', (data) => {
      if (data.enclosure_id == this.enclosure_id) {
        this.getEnclosureAndParcelDetails(this.enclosure_id);
      }
    });
    this.user_access = atob(this.route.snapshot.params.access);
    this.getEnclosureAndParcelDetails(this.enclosure_id);
  }
  getEnclosureAndParcelDetails(enclosure_id) {
    this.serviceEnclosure.getEnclosureAndParcelDetails(enclosure_id).subscribe((res) => {
      if (res['status'] == 1) {
        this.encData = res['data'];
        if (this.encData.enclosure_latitude.replace(/\s/g, "") == "" || this.encData.enclosure_longitude.replace(/\s/g, "") == "") {
          this.GPSError = true;
          return;
        }
        this.destination = [parseFloat(this.encData.enclosure_longitude), parseFloat(this.encData.enclosure_latitude)];
        if (this.encData.deliveryDetail.length > 0) {
          this.hideTable = true;
          this.released_time = res['data'].deliveryDetail[0].take_off_time;
          let now_time = Math.round((new Date).getTime() / 1000);
          this.encData.now = now_time;
          this.steps = this.encData.deliveryDetail[0].estimated_time * 60;//estimation time is in mintue
          this.skipStep = (new Date(now_time).getTime() - new Date(this.released_time).getTime());
          this.encData.release_before = Math.floor(this.skipStep / 60);
          this.origin = [parseFloat(this.encData.deliveryDetail[0].source_longitude), parseFloat(this.encData.deliveryDetail[0].source_latitude)];
          setTimeout(() => {
            this.loadTrackingMap();
          }, 1000);
          setTimeout(() => {
            this.startDrone();
            this.display_time();
          }, 2000);
        } else {
          setTimeout(() => {
            this.loadNormalMap();
          })
        }
      }
      else {
        this.router.navigate(['app/enclosure-list'])
      }
    })
  }

  public complete_time: any;
  public pending_time: any;
  public dis_fn: any;
  display_time() {
    let now_time = Math.round((new Date).getTime() / 1000);
    this.complete_time = new Date(now_time).getTime() - new Date(this.released_time).getTime();
    this.pending_time = Math.round(this.encData.deliveryDetail[0].estimated_time - (this.complete_time / 60));

    if (this.pending_time < 0) {
      this.pending_time = 0;
      this.ngOnDestroy();
      return;
    }
    this.dis_fn = setInterval(() => {
      let now_time = Math.round((new Date).getTime() / 1000);
      this.complete_time = new Date(now_time).getTime() - new Date(this.released_time).getTime();
      this.pending_time = Math.round(this.encData.deliveryDetail[0].estimated_time) - Math.round(this.complete_time) / 60;
      if (this.pending_time < 0) {
        this.pending_time = 0;
        this.ngOnDestroy();
      }
    }, 59 * 1000)
  }

  ngOnDestroy() {
    clearInterval(this.dis_fn);
  }

  startDrone() {
    this.clearMap();
    this.map.setZoom(10);
    setTimeout(() => {
      this.drawLine();
      this.map.setZoom(10);
    }, 1000);
  }

  drawLine() {
    this.toAddressLayer = Math.floor(Math.random() * 111111).toString();
    this.fromAddressLayer = Math.floor(Math.random() * 111111).toString();
    this.routeLayer = Math.floor(Math.random() * 111111).toString();
    this.pointLayer = Math.floor(Math.random() * 111111).toString();
    this.lineLayer = Math.floor(Math.random() * 111111).toString();
    this.rts = {
      'type': 'FeatureCollection',
      'features': [
        {
          'type': 'Feature',
          'geometry': {
            'type': 'LineString',
            'coordinates': [this.origin, this.destination]
          }
        }
      ]
    };
    // A simple line from origin to destination.
    this.point = {
      'type': 'FeatureCollection',
      'features': [
        {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'Point',
            'coordinates': this.origin
          }
        }
      ]
    };
    // Calculate the distance in kilometers between route start/end point.
    var lineDistance = turf.length(this.rts.features[0]);
    this.distance = turf.distance(this.origin, this.destination, {
      units: 'kilometers'
    });
    // Number of steps to use in the arc and animation, more steps means
    // a smoother arc and animation, but too many steps will result in a
    // low frame rate
    var arc = [];
    var cnt = 1;
    // Draw an arc between the `origin` & `destination` of the two points

    for (var i = 0; i < lineDistance; i += lineDistance / this.steps) {
      var segment = turf.along(this.rts.features[0], i);
      arc.push(segment.geometry.coordinates);
      cnt++;
    }
    this.map.addSource(this.routeLayer, {
      'type': 'geojson',
      'data': this.rts
    });

    this.map.addSource(this.pointLayer, {
      'type': 'geojson',
      'data': this.point
    });
    this.map.addLayer({
      'id': this.lineLayer,
      'source': this.routeLayer,
      'type': 'line',
      'paint': {
        'line-width': 2,
        'line-color': 'red',
        'line-dasharray': [2, 1],
      }
    });
    this.drawMarker(arc);
  }

  drawMarker(arc) {
    //Draw dynamic marker start and end point
    var elms1 = document.createElement('p');
    elms1.className = 'markerend';
    new mapboxgl.Marker(elms1)
      .setLngLat([this.origin[0], this.origin[1]])
      .addTo(this.map);
    var elms2 = document.createElement('p');
    elms2.className = 'markerstart';
    new mapboxgl.Marker(elms2)
      .setLngLat([this.destination[0], this.destination[1]])
      .addTo(this.map);

    var el = document.createElement('div');
    var removeIcon = document.getElementsByClassName('markerIcon');
    var markerstart = document.getElementsByClassName('markerstart');
    var markerend = document.getElementsByClassName('markerend');

    var n = 0;
    if (this.skipStep != 0) {
      n = this.skipStep;
    }
    timeout(this.map);
    function timeout(map) {
      if (arc[n]) {
        map.setCenter([arc[n][0], arc[n][1]]);
        el.className = 'markerIcon';
        el.style.backgroundImage = 'url(assets/images/drone_parcel_new.svg)';
        el.style.width = '60px';
        el.style.height = '60px';
        new mapboxgl.Marker(el)
          .setLngLat([arc[n][0], arc[n][1]])
          .addTo(map);
        if (arc.length != n - 1) {
          setTimeout(function () {
            // Do Something Here
            // Then recall the parent function to
            // create a recursive loop.
            n = n + 1;
            timeout(map);
          }, 1000);
        }
      }
    }
  }

  clearMap() {
    if (this.map.getLayer(this.toAddressLayer) != undefined) {
      this.map.removeLayer(this.toAddressLayer);
    }
    if (this.map.getLayer(this.lineLayer) != undefined) {
      this.map.removeLayer(this.lineLayer);
    }
    if (this.map.getLayer(this.fromAddressLayer) != undefined) {
      this.map.removeLayer(this.fromAddressLayer);
    }
    if (this.map.getSource(this.routeLayer) != undefined) {
      this.map.removeSource(this.routeLayer);
    }
    if (this.map.getSource(this.pointLayer) != undefined) {
      this.map.removeSource(this.pointLayer);
    }
  }

  loadNormalMap() {
    (mapboxgl as any).accessToken = this.Mapbox_accessToken_URL;
    this.map = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/streets-v10',//mapbox://styles/mapbox/light-v10
      center: [this.destination[0], this.destination[1]], // starting position
      zoom: 18, // starting zoom,
      pitch: 60, // pitch in degrees
      bearing: -60, // bearing in degrees
      antialias: true
    });
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.FullscreenControl());
    var elms2 = document.createElement('p');
    elms2.className = 'markerstart';

    new mapboxgl.Marker(elms2)
      .setLngLat([this.destination[0], this.destination[1]])
      .addTo(this.map);
  }

  loadTrackingMap() {
    (mapboxgl as any).accessToken = this.Mapbox_accessToken_URL;
    this.map = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/streets-v10',//mapbox://styles/mapbox/light-v10
      center: [this.origin[0], this.origin[1]], // starting position
      zoom: this.currentZoom, // starting zoom,
      pitch: 60, // pitch in degrees
      bearing: -60, // bearing in degrees
      antialias: true
    });
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.on('load', () => {
      var layers = this.map.getStyle().layers;
      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol') {// && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }
      this.map.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#aaa',

          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 0.6
        }
      },
        labelLayerId
      );
    });
  }

  backClicked() {
    this._location.back();
  }
}