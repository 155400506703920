/**
 * @Author: Nidhi Bhatt
 * @Date:   2021-03-15T09:56:01+05:30
 * @Email:  nidhi.bhatt@teksun.com
 * @Last modified by:   ankitarakholiya
 * @Last modified time: 2021-07-22T14:09:02+05:30
 * @Copyright: Teksun
 */



import { Component, OnInit, ViewChild, NgZone, } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { ThemePalette } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnclosureDetailsService } from '../enclosure-details/enclosure-details.service';
import { AuthService } from '../auth/auth.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { tap, finalize } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';
import jwt_decode from "jwt-decode";
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { LoaderService } from '../services/loader.service';

interface Minute {
  value: Number;
  viewValue: string;
}

interface LEDColors {
  value: String;
  className: string;
}

interface LEDMode {
  value: String;
  className: string;
}

@Component({
  selector: 'app-enclosure-details',
  templateUrl: './enclosure-details.component.html'
})
export class EnclosureDetailsComponent implements OnInit {
  public heightPercentage: Number;

  [x: string]: any;

  minutes: Minute[] = [
    { value: 10, viewValue: '10 min' },
    { value: 15, viewValue: '15 min' },
    { value: 30, viewValue: '30 min' },
    { value: 45, viewValue: '45 min' },
    { value: 60, viewValue: '1 hour' }
  ];

  ledColors: LEDColors[] = [
    { value: 'green', className: 'led green' },
    { value: 'red', className: 'led red' },
    { value: 'blue', className: 'led blue' },
    { value: 'yellow', className: 'led yellow' },
    { value: 'pink', className: 'led pink' },
    { value: 'aqua', className: 'led aqua' },
    { value: 'white', className: 'led white' },
    { value: 'police', className: 'led green-dark' },
    { value: 'ambulance', className: 'led blue-light' },
    { value: 'fire', className: 'led gred-orange' }
  ];

  ledMode: LEDMode[] = [
    { value: 'steady', className: 'steady' },
    { value: 'flashing', className: 'flashing' },
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('picker') picker: any;
  @ViewChild('secondaryAccessForm', { static: true }) secondaryAccessForm: NgForm;
  @ViewChild('temporaryAccessForm', { static: true }) temporaryAccessForm: NgForm;
  @ViewChild('OTAAccessForm', { static: true }) OTAAccessForm: NgForm;
  @ViewChild('deleteEncForm', { static: true }) deleteEncForm: NgForm;
  @ViewChild('addForwardForm', { static: true }) addForwardForm: NgForm;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });


  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];

  public listColors = ['primary', 'accent', 'warn'];

  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];
  public issSubmit: boolean = false;
  public isSubmit: boolean = false;
  public isLoading: boolean = false;
  public enclosure_id: any;
  public user_access: any;
  public owneruid: any;
  public enclosure_details: any = {
    available_height: '',
    battery_percentage: 'NA',
  };
  public access_details: any;
  public isSecondarySubmit: boolean = false;
  public isSecondaryLoading: boolean = false;
  public isTemporarySubmit: boolean = false;
  public isTemporaryLoading: boolean = false;
  public isOTASubmit: boolean = false;
  public isOTALoading: boolean = false;
  public isDelEncSubmit: boolean = false;
  public isDelEncLoading: boolean = false;
  public shared_access_count: any = 0;
  public in_forwarded_deliveries: any = 0;
  public out_forwarded_deliveries: any = 0;
  public del_enclosure_id: any;
  public existingOTA: any = false;
  public existingTemp: any = false;
  public isForwardSubmit: boolean = false;
  public isForwardLoading: boolean = false;
  public forwardedEncTimezone: any = '';
  currentDate: any = new Date();
  newDate: any = new Date();
  public userData: any;
  public existingTempAccess: any = [];
  public addTempDetail: any;
  public existingOTAAccess: any = [];
  public addOTADetail: any;
  public temp_type: any;
  public space_type: any;
  public space_value: any;
  public phoneError: any = false;
  loggedInUserId:any;
  userDoorOpeningMethodType = 1;
  string = "11,-1,60,-1";

  arr: any;
  //mobile
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  countrySortName: any = 'US';
  public phno: any;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });
  loading: boolean;

  videoStreamingForm: FormGroup;
  timerList: any[] = [
    { _id: "1", name: "1 Minute" },
    { _id: "3", name: "3 Minute" },
    { _id: "5", name: "5 Minute" }
  ];
  resolutionList: any[] = [
    { _id: "1080", name: "1080 p" },
    { _id: "720", name: "720 p" },
    { _id: "600", name: "600 p" },
    { _id: "480", name: "480 p" },
    { _id: "360", name: "360 p" },
    { _id: "240", name: "240 p" }
  ];

  constructor(private loaderService: LoaderService, private socket: Socket, public authService: AuthService,
    private _location: Location, private toastr: ToastrService,
    public router: Router, public service: EnclosureDetailsService,
    public route: ActivatedRoute, public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient, private zone: NgZone) {
    this.loaderService.isLoading.subscribe((val: boolean) => {
      this.loading = val;
    });
  }

  public forwardTimeFormat: any = '';
  public tempTimeFormat: any = '';
  public otaTimeFormat: any = '';
  isDataLoaded = false;
  ngOnInit() {
    //  this.heightPercentage=50;
    const token = sessionStorage.getItem('token');
    if (token) {
      this.userData = jwt_decode(token);
    }
    this.enclosure_id = atob(this.route.snapshot.params.eid);
    this.getEnclosureDetail(this.enclosure_id);

    this.socket.on('receive_data_from_device', (device) => {
      if (device['enclosure_id'] && device['enclosure_id'] == this.enclosure_id) {
        if (device['type'] == 'internet_connection_lost') {
          this.enclosure_details.internet_connectivity = 0;
        }
        else {
          this.enclosure_details.battery_percentage = device['battery_percentage'];
          this.enclosure_details.power_suply = device['power_suply'] || 'NA';
          this.enclosure_details.internal_temp = this.temp_type == 'C' ? device['internal_temp'] : ((device['internal_temp'] * 9 / 5) + 32);
          this.enclosure_details.external_temp = this.temp_type == 'C' ? device['external_temp'] : ((device['external_temp'] * 9 / 5) + 32);
          this.enclosure_details.available_height = this.space_type == 'CM' ? device['available_height'] : this.space_type == 'cm' ? device['available_height'] : (device['available_height'] * 0.39370).toFixed(2);
          this.enclosure_details.external_humidity = device['external_humidity'];
          this.enclosure_details.heater_status = device['heater_status'];
          this.enclosure_details.enclosure_fault = device['enclosure_fault'];
          this.enclosure_details.drone_door_status = device['drone_door_status'];
          this.enclosure_details.user_door_status = device['user_door_status'];
          this.enclosure_details.buzzer_status = device['buzzer_status'];
          this.heightPercentage = Number(100 - Number(device['available_height']));

          this.enclosure_details.user_door_lock_fault = device['user_door_lock_fault'];
          this.enclosure_details.drone_door_motor_fault = device['drone_door_motor_fault'];
          this.enclosure_details.drone_door_lock_fault = device['drone_door_lock_fault'];
          this.enclosure_details.heater_fault = device['heater_fault'];
          this.enclosure_details.internet_connectivity = 1;
          this.enclosure_details.nw_strength = device['nw_strength'];
          this.enclosure_details.theft_detection = device['theft_detection'];
          this.enclosure_details.fw_version = device['fw_version'];
          this.enclosure_details.led_color = device['led_color'];
          this.enclosure_details.led_mode = device['led_mode'];
          this.enclosure_details.led_status = device['led_status'];
          this.enclosure_details.hotspot_status = device['hotspot_status'];
          this.enclosure_details.mailbox_status = device['mailbox_status'];
          this.enclosure_details.tof_sensor = device['tof_sensor'];
        }
      }
    });

    this.resetSecondaryForm();
    this.resetTemporaryForm();
    this.resetOTAForm();
    this.reseEncDelForm();
    this.resetForwardForm();
    this.resetEnclosureReminderForm();
    this.resetEncForm();
    this.getLoggedinUser();
    // this.calcAvailableHeight();
    this.videoStreamingForm = new FormGroup({
      timer: new FormControl('', [Validators.required]),
      resolution: new FormControl('', [Validators.required]),
    });
  }
 
  ngOnDestroy() {
    this.socket.removeAllListeners();
  }

  openCalender() {
    this.picker.nativeElement.click();
    setTimeout(() => {
      if (this.picker) {
        this.picker.nativeElement.focus();
        this.picker.nativeElement.click();
      }
    }, 100)
  }
  getLoggedinUser() {
    this.authService.getLoggedinUser().subscribe(res => {
      if (res['status'] == 1) {
        let uData = res['data'];
        this.loggedInUserId = uData.user_id;
        this.authService.UpdateProfileFormData = uData;
      }
      else {
        this.toastr.error(res['msg'], '');
        sessionStorage.removeItem('token');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 3000);
      }
    });
  }
  backClicked() {
    this._location.back();
  }
  public settingsInfo = {};
  opneSettingsModal(data) {
    this.settingsInfo = data;
    this.service.EnclosureListFormData = data;
    this.service.EnclosureReminderFormData.enclosure_reminder = data.enclosure_reminder?.toString();
    this.ngxSmartModalService.getModal('enclosuresettings').open()
  }
  public addUpdateModal: any = 'Update Device';
  checkNumber(event: any, phoneNumber) {
    if (phoneNumber != null) {
      let Data: any = phoneNumber;
      //https://stackoverflow.com/questions/54899200/which-event-will-work-in-ngx-intl-tel-input-in-angular
      if (Data.internationalNumber != '') {
        this.service.OTAAccessFormData.user_mobile = Data.internationalNumber;
      }
    }
  }
  openEditEncModal() {
    this.isLoading = false;
    this.ngxSmartModalService.getModal('enclosuresettings').close()
    this.addUpdateModal = 'Update Device';
    this.ngxSmartModalService.getModal('addenclosure').open()
    this.service.EnclosureListFormData._id = this.enclosure_details._id;
    this.service.EnclosureListFormData.enclosure_id = this.enclosure_id;
    this.service.EnclosureListFormData.enclosure_name = this.enclosure_details.enclosure_name;
    this.service.EnclosureListFormData.address_line_a = this.enclosure_details.address_line_a;
    this.service.EnclosureListFormData.address_line_b = this.enclosure_details.address_line_b;
    this.service.EnclosureListFormData.city = this.enclosure_details.city;
    this.service.EnclosureListFormData.state = this.enclosure_details.state;
    this.service.EnclosureListFormData.country = this.enclosure_details.country;
    this.service.EnclosureListFormData.zipcode = this.enclosure_details.zipcode;
    this.service.EnclosureListFormData.enclosure_latitude = this.enclosure_details.enclosure_latitude;
    this.service.EnclosureListFormData.enclosure_longitude = this.enclosure_details.enclosure_longitude;
  }
  openAddSecondaryModal() {
    this.isSecondaryLoading = false;
    this.secondaryAccessForm.resetForm();
    this.ngxSmartModalService.getModal('grantenclosureaccess').close();
    this.ngxSmartModalService.getModal('secondaryaccess').open();
    this.service.SecondaryAccessFormData.enclosure_id = this.enclosure_id;
  }
  openAddTemporaryModal() {
    this.tempTimeFormat = moment().tz(this.enclosure_details.timezone).format('yy, M, DD, H, mm, ss').split(",", 6);
    this.service.TemporaryAccessFormData.start_time = new FormControl(new Date(Number(this.tempTimeFormat[0]), Number(this.tempTimeFormat[1] - 1), Number(this.tempTimeFormat[2]), Number(this.tempTimeFormat[3]), Number(this.tempTimeFormat[4]), Number(this.tempTimeFormat[5])));
    this.service.TemporaryAccessFormData.end_time = new FormControl(new Date(Number(this.tempTimeFormat[0]), Number(this.tempTimeFormat[1] - 1), Number(this.tempTimeFormat[2]), Number(this.tempTimeFormat[3]), Number(this.tempTimeFormat[4]), Number(this.tempTimeFormat[5])));
    this.isTemporaryLoading = false;
    this.temporaryAccessForm.resetForm();
    this.ngxSmartModalService.getModal('grantenclosureaccess').close();
    this.service.TemporaryAccessFormData.enclosure_id = this.enclosure_id;
    this.ngxSmartModalService.getModal('temporaryaccess').open();
  }
  openAddOTAModal() {
    this.isOTASubmit = false;
    this.phoneError = false;
    this.otaTimeFormat = moment().tz(this.enclosure_details.timezone).format('yy, M, DD, H, mm, ss').split(",", 6);
    const now = new Date(this.otaTimeFormat[0], this.otaTimeFormat[1] - 1, this.otaTimeFormat[2], this.otaTimeFormat[3], this.otaTimeFormat[4], this.otaTimeFormat[5]);
    this.minDate = new Date(new Date(this.otaTimeFormat[0], this.otaTimeFormat[1] - 1, this.otaTimeFormat[2], this.otaTimeFormat[3], this.otaTimeFormat[4], this.otaTimeFormat[5]));
    this.minDate.setDate(now.getDate() - 0);
    this.service.OTAAccessFormData.start_time = new FormControl(new Date(Number(this.otaTimeFormat[0]), Number(this.otaTimeFormat[1] - 1), Number(this.otaTimeFormat[2]), Number(this.otaTimeFormat[3]), Number(this.otaTimeFormat[4]), Number(this.otaTimeFormat[5])));
    this.service.OTAAccessFormData.end_time = new FormControl(new Date(Number(this.otaTimeFormat[0]), Number(this.otaTimeFormat[1] - 1), Number(this.otaTimeFormat[2]), Number(this.otaTimeFormat[3]), Number(this.otaTimeFormat[4]), Number(this.otaTimeFormat[5])));
    this.isOTALoading = false;
    this.OTAAccessForm.resetForm();
    this.ngxSmartModalService.getModal('grantenclosureaccess').close();
    this.service.OTAAccessFormData.enclosure_id = this.enclosure_id;
    this.ngxSmartModalService.getModal('onetimeaccess').open();
  }
  openDeleteEncModal() {
    this.isDelEncLoading = false;
    this.deleteEncForm.resetForm();
    this.ngxSmartModalService.getModal('deleteencl').open();
  }
  openGrantAccessModal() {
    this.ngxSmartModalService.getModal('grantenclosureaccess').open();
  }

  getEnclosureDetail(eid) {
    this.service.getEncViewById(eid).subscribe((res) => {
      if (res['status'] == 1) {
        this.enclosure_details = res['data'];
        console.log(this.enclosure_details, "this is enclosure_details");
        this.userDoorOpeningMethodType = this.enclosure_details.actuator_type ? this.enclosure_details.actuator_type : 1;
        this.heightPercentage = Number(100 - Number(res['data']['available_height']));
        this.isDataLoaded = true;
        this.getCalenderWithTimezone(res['data']['timezone']);
        this.in_forwarded_deliveries = res['enc_data_count']['incoming_count'];
        this.out_forwarded_deliveries = res['enc_data_count']['outgoing_count'];
        this.shared_access_count = res['enc_data_count']['shared_access_count'];
        this.temp_type = res['data'].temp_type == "CL" ? 'C' : 'F';
        this.space_type = res['data'].space_type == "CM" ? 'cm' : 'inch';
      }
      else {
        this.router.navigate(['app/enclosure-list'])
      }
    })
  }

  sharedEnclosureList() {
    this.router.navigate(['/app/shared-access/' + btoa(this.enclosure_details.enclosure_id) + '/' + btoa(this.enclosure_details.user_own_access)]);
  }
  forwardedDeliveriesList(type, len) {
    this.router.navigate(['/app/forward-delivery-list/' + btoa(this.enclosure_id) + '/' + btoa(this.enclosure_details.user_own_access) + '/' + btoa(type) + '/' + btoa(this.enclosure_details.owner_user_id)]);
  }
  parcelDeliveryDetails(edata) {
    this.router.navigate(['/app/parcel-delivery-details/' + btoa(this.enclosure_id)]);
  }
  viewAccessControl() {
    this.router.navigate(['/app/access-control/' + btoa(this.enclosure_id)]);
  }
  viewLiveTracking() {
    this.router.navigate(['/app/live-tracking/' + btoa(this.enclosure_id) + '/' + btoa(this.enclosure_details.user_own_access)]);
  }
  viewLiveTrackingIcon() {
    this.router.navigate(['/app/live-tracking-icon/' + btoa(this.enclosure_id) + '/' + btoa(this.enclosure_details.user_own_access)]);
  }
  userDoorHistory() {
    this.router.navigate(['/app/user-door-history/' + btoa(this.enclosure_id)]);
  }
  goToActuatorLogs() {
    this.router.navigate(['/app/actuator-logs/' + btoa(this.enclosure_id)]);
  }

  addSecondaryAccess(form?: NgForm) {
    if (!form.valid) {
      this.isSecondarySubmit = true;
      return;
    }
    else {
      this.isSecondaryLoading = true;

      form.value.start_time = new Date(form.value.start_time).getTime();
      form.value.end_time = new Date(form.value.end_time).getTime();
      if (form.value.start_time.toString().length == 13) {
        form.value.start_time = form.value.start_time / 1000;

      }
      if (form.value.end_time.toString().length == 13) {
        form.value.end_time = form.value.end_time / 1000;
      }
      form.value.enclosure_id = this.enclosure_id;
      this.service.addSecondaryUser(form.value)
        .pipe(
          tap(x => console.log('tap', x)),
          finalize(() => {
          })
        ).subscribe((res) => {
          if (res['status'] == 1) {
            this.toastr.success(res['msg'], '');
            this.ngxSmartModalService.getModal('secondaryaccess').close();
            this.getEnclosureDetail(this.enclosure_id);
          }
          else {
            this.isSecondaryLoading = false;
            this.toastr.error(res['msg'], '');
          }
        })
    }
  }

  addTempAfterChecked() {
    this.addTempDetail.check_duration = 0;
    this.isTemporaryLoading = true;

    this.service.addTemporaryAccess(this.addTempDetail).subscribe((secRes) => {
      if (secRes['status'] == 1) {
        this.isTemporaryLoading = true;
        this.toastr.success(secRes['msg'], '');
        this.ngxSmartModalService.getModal('temporaryaccess').close();
        this.ngxSmartModalService.getModal('existingTempDetail').close();
        this.getEnclosureDetail(this.enclosure_id);
      }
      else {
        this.ngxSmartModalService.getModal("existingTempDetail").close();
        this.isTemporaryLoading = false;
        this.toastr.error(secRes['msg'], '');
      }
    });
  }

  addOTAAfterChecked() {
    this.addOTADetail.check_duration = 0;
    this.service.addOTAUser(this.addOTADetail).subscribe((secRes) => {
      if (secRes['status'] == 1) {
        this.isOTALoading = true;
        this.toastr.success(secRes['msg'], '');
        this.ngxSmartModalService.getModal('onetimeaccess').close();
        this.ngxSmartModalService.getModal('existingOTADetail').close();
        this.getEnclosureDetail(this.enclosure_id);
      }
      else {
        this.ngxSmartModalService.getModal("existingOTADetail").close();
        this.isOTALoading = false;
        this.toastr.error(secRes['msg'], '');
      }
    });
  }
  currentTime: any = '';
  getCurrentTime() {
    this.enclosure_details.timezone = this.enclosure_details.timezone || 'America/Indiana/Indianapolis';
    this.current = moment().tz(this.enclosure_details.timezone).format('yy, M, DD, H, mm, ss').split(",", 6);
    this.currentTime = new Date(new Date(this.current[0], this.current[1] - 1, this.current[2], this.current[3], this.current[4], this.current[5]));
  }
  addTemporaryAccess(form?: NgForm) {
    this.getCurrentTime();
    this.existingTempAccess = [];
    if (!form.valid) {
      this.isTemporarySubmit = true;
      return;
    }
    else {
      this.isTemporaryLoading = true;

      form.value.start_time = new Date(this.service.TemporaryAccessFormData.start_time.value).getTime();
      form.value.end_time = new Date(this.service.TemporaryAccessFormData.end_time.value).getTime();
      if (form.value.start_time.toString().length == 13) {
        form.value.start_time = form.value.start_time / 1000;
      }
      if (form.value.end_time.toString().length == 13) {
        form.value.end_time = form.value.end_time / 1000;
      }
      if (form.value.start_time < this.currentTime.getTime() / 1000) {
        this.toastr.error('You can not select start time before the current time, Please check your start time', '');
        this.isTemporaryLoading = false;
        return;
      }
      if (form.value.end_time - form.value.start_time < 0) {
        this.toastr.error('End time is invalid', '');
        this.isTemporaryLoading = false;

        return;
      }
      else if (form.value.end_time - form.value.start_time < 3600 * 6) {
        this.toastr.error('Select minimum 6 hour', '');
        this.isTemporaryLoading = false;

        return;
      }
      else if (form.value.end_time - form.value.start_time > 2592000) {
        this.toastr.error('Select maximum 30 days', '');
        this.isTemporaryLoading = false;

        return;
      }
      form.value.enclosure_id = this.enclosure_id;
      form.value.check_duration = 1;
      this.addTempDetail = form.value;
      this.service.addTemporaryAccess(form.value)
        .pipe(
          tap(x => console.log('tap', x)),
          finalize(() => {
            console.log('finalize');
          })
        ).subscribe((res) => {
          //   this.existingTemp == true;
          if (res['status'] == 1) {
            if (res['data']) {
              if (res['data'].length == 0) {
                this.isTemporaryLoading = true;
                form.value.check_duration = 0;
                this.service.addTemporaryAccess(form.value).subscribe((secRes) => {
                  if (secRes['status'] == 1) {
                    this.isTemporaryLoading = true;
                    this.toastr.success(secRes['msg'], '');
                    this.ngxSmartModalService.getModal('temporaryaccess').close();
                    this.getEnclosureDetail(this.enclosure_id);
                  }
                  else {
                    this.isTemporaryLoading = false;
                    this.toastr.error(secRes['msg'], '');
                  }
                });
              }
              else {
                this.isTemporaryLoading = false;
                this.existingTemp = true;
                if (res['data']) {
                  this.existingTempAccess = res['data'];
                }
                this.ngxSmartModalService.getModal('existingTempDetail').open();
              }
            }
            else {
              this.isTemporaryLoading = true;
              this.existingTemp = false;
              this.toastr.success(res['msg'], '');
              this.ngxSmartModalService.getModal('temporaryaccess').close();
              this.getEnclosureDetail(this.enclosure_id);
            }
          }
          else {
            this.isTemporaryLoading = false;
            this.toastr.error(res['msg'], '');
          }
        })
    }
  }
  public startTimeError = false;
  public endTimeError = false;
  addOTAAccess(form?: NgForm) {
    form.value.start_time = new Date(this.service.OTAAccessFormData.start_time.value).getTime();
    form.value.end_time = new Date(this.service.OTAAccessFormData.end_time.value).getTime();
    this.existingTempAccess = [];
    if (!form.value.start_time) {
      this.startTimeError = true;
    }
    if (!form.value.end_time) {
      this.endTimeError = true;
    }
    if (!form.valid) {
      if (!this.service.OTAAccessFormData.user_mobile || this.service.OTAAccessFormData.user_mobile == '') {
        this.phoneError = true;
      }
      this.isOTASubmit = true;
      return;
    }
    else {
      if (!this.startTimeError && !this.endTimeError) {
        this.getCurrentTime();
        this.isOTALoading = true;
        if (form.value.start_time.toString().length == 13) {
          form.value.start_time = form.value.start_time / 1000;
        }
        if (form.value.end_time.toString().length == 13) {
          form.value.end_time = form.value.end_time / 1000;
        }
        if (form.value.start_time < this.currentTime.getTime() / 1000) {
          this.toastr.error('You can not select start time before the current time, Please check your start time', '');
          this.isOTALoading = false;
          return;
        }
        if (form.value.end_time - form.value.start_time < 0) {
          this.toastr.error('Due time is invalid', '');
          this.isOTALoading = false;
          return;
        }
        else if (form.value.end_time - form.value.start_time < 900) {
          this.toastr.error('Select minimum 15 minutes', '');
          this.isOTALoading = false;
          return;
        }
        else if (form.value.end_time - form.value.start_time > 86400) {
          this.toastr.error('Select maximum 1 day', '');
          this.isOTALoading = false;
          return;
        }
        else {

        }
        form.value.enclosure_id = this.enclosure_id;
        form.value.check_duration = 1;
        this.addOTADetail = form.value;
        //   form.value.user_country_code = this.service.OTAAccessFormData.user_mobile.countryCode;
        form.value.user_mobile = this.service.OTAAccessFormData.user_mobile;
        this.isOTALoading = true;
        console.log(form.value, "form.value outside");
        
        this.service.addOTAUser(form.value)
          .pipe(
            tap(x => console.log('tap', x)),
            finalize(() => {
              console.log('finalize');
            })
          ).subscribe((res) => {
            this.existingOTA == true;
            if (res['status'] == 1) {
              if (res['data']) {
                if (res['data'].length == 0) {
                  form.value.check_duration = 0;
                  this.isOTALoading = true;
                  console.log(form.value, "form.value>>>>>>>>>");
                  
                  this.service.addOTAUser(form.value).subscribe((secRes) => {
                    if (secRes['status'] == 1) {
                      this.toastr.success(secRes['msg'], '');
                      this.isOTALoading = true;
                      this.ngxSmartModalService.getModal('onetimeaccess').close();
                      this.getEnclosureDetail(this.enclosure_id);
                    }
                    else {
                      this.isOTALoading = false;
                      this.toastr.error(secRes['msg'], '');
                    }
                  });
                }
                else {
                  this.isOTALoading = false;
                  this.existingOTA = true;
                  var len = 0;
                  if (res['data']) {
                    this.existingOTAAccess = res['data'];
                  }
                  this.ngxSmartModalService.getModal('existingOTADetail').open();
                }
              }
              else {
                this.isOTALoading = true;
                this.existingOTA = false;
                this.toastr.success(res['msg'], '');
                this.ngxSmartModalService.getModal('onetimeaccess').close();
                this.getEnclosureDetail(this.enclosure_id);
              }
            }
            else {
              this.isOTALoading = false;
              this.toastr.error(res['msg'], '');
            }
          })
      }
    }
  }

  deleteEnclosure(form?: NgForm) {
    if (!form.valid) {
      this.isDelEncSubmit = true;
      this.isDelEncLoading = false;
      return;
    }
    else {
      this.isDelEncLoading = true;
      if (form.value.type_delete == 'DELETE') {
        this.service.deleteEnclosure(atob(this.route.snapshot.params.eid))
          .pipe(
            tap(x => console.log('tap', x)),
            finalize(() => {
              console.log('finalize');
            })
          ).subscribe((res) => {
            this.ngxSmartModalService.getModal('deleteencl').close();
            if (res['status'] == 1) {
              this.toastr.success(res['msg'], '');
            }
            else {
              this.isDelEncLoading = false;
              this.toastr.error(res['msg'], '');
            }
            setTimeout(() => {
              this.router.navigate(['/app/enclosure-list']);
            }, 3000);
          });
      }
      else if (form.value.type_delete != 'DELETE') {
        this.isDelEncLoading = false;
        this.toastr.error('Please enter DELETE keyword in input!', '');
      }
    }
  }

  deleteEnclosureModelClose() {
    this.ngxSmartModalService.close('deleteencl');
  }

  openForwardModal() {
    this.forwardedEncTimezone = '';
    this.isForwardLoading = false;
    this.addForwardForm.resetForm();
    this.ngxSmartModalService.getModal('enclosuresettings').close();
    this.ngxSmartModalService.getModal('forwarddelivery').open();
  }

  addForward(form?: NgForm) {
    if (form.value.forward_enclosure_id == this.enclosure_id) {
      this.toastr.error('You can not configure the forward delivery to your own device', '');
      this.isForwardLoading = false;
      return;
    }
    if (!form.valid) {
      this.isForwardSubmit = true;
      this.isForwardLoading = false;
      return;
    }
    else {
      this.isForwardLoading = true;
      form.value.forward_start_time = new Date(this.service.ForwardDeliveryFormData.forward_start_time.value).getTime();
      form.value.forward_end_time = new Date(this.service.ForwardDeliveryFormData.forward_end_time.value).getTime();
      if (form.value.forward_start_time.toString().length == 13) {
        form.value.forward_start_time = form.value.forward_start_time / 1000;
      }
      if (form.value.forward_end_time.toString().length == 13) {
        form.value.forward_end_time = form.value.forward_end_time / 1000;
      }
      form.value.enclosure_id = this.enclosure_id;
      this.service.addForwardDelivery(form.value).subscribe((res) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.getEnclosureDetail(this.enclosure_id);
          this.ngxSmartModalService.getModal('forwarddelivery').close();
          this.ngOnInit();
        }
        else {
          this.isForwardLoading = false;
          this.toastr.error(res['msg'], '');
        }
      })
    }
  }

  stopBuzzer(edata) {
    if (edata['buzzer_status'] == 1 && edata['internet_connectivity'] == 1) {
      this.service.stopBuzzer(edata).subscribe((res) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.getEnclosureDetail(this.enclosure_id);
          this.ngxSmartModalService.getModal('enclosuresettings').close();
        }
        else {
          this.toastr.error(res['msg'], '');
        }
      })
    }
    else {
      if (edata['internet_connectivity'] == 0) {
        this.toastr.error('Internet connection lost', '');
      }
      else {
        this.toastr.error('Buzzer is already stopped', '');
      }
    }
  }

  onUserDoorOpeningMethodChange(event:any){
    console.log(event.value);
    // userDoorOpeningMethodType = 1 means Automatic and userDoorOpeningMethodType means manual
    if (event.value == 1) { 
      this.userDoorOpeningMethodType = 1; 
    } 
    else if (event.value == 2) {
      this.userDoorOpeningMethodType = 2; 
    }
  }

  userDoorOpenClose(edata) {
    console.log(edata);
    edata['actuator_type'] = this.userDoorOpeningMethodType; //passing opening door method
    if (edata['user_door_status'] == 0 && edata['internet_connectivity'] == 1) {
      this.service.openUserDoor(edata).subscribe((res) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.getEnclosureDetail(this.enclosure_id);
          this.ngxSmartModalService.getModal('enclosuresettings').close();
        }
        else {
          this.toastr.error(res['msg'], '');
        }
      })
    } else if (edata['user_door_status'] == 1 && edata['internet_connectivity'] == 1) {
      this.service.closeUserDoor(edata).subscribe((res) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.getEnclosureDetail(this.enclosure_id);
          this.ngxSmartModalService.getModal('enclosuresettings').close();
        }
        else {
          this.toastr.error(res['msg'], '');
        }
      })
    }
    else {
      if (edata['internet_connectivity'] == 0) {
        this.toastr.error('Internet connection lost', '');
      }
      else {
        this.toastr.error('Door is already unlocked', '');
      }
    }
  }

  droneDoorOpenClose(edata) {
    console.log(edata);

    if (edata['drone_door_status'] == 0 && edata['internet_connectivity'] == 1) {
      this.service.openDroneDoor(edata).subscribe((res) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.getEnclosureDetail(this.enclosure_id);
          this.ngxSmartModalService.getModal('enclosuresettings').close();
        }
        else {
          this.toastr.error(res['msg'], '');
        }
      })
    } else if (edata['drone_door_status'] == 1 && edata['internet_connectivity'] == 1) {
      this.service.closeDroneDoor(edata).subscribe((res) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.getEnclosureDetail(this.enclosure_id);
          this.ngxSmartModalService.getModal('enclosuresettings').close();
        }
        else {
          this.toastr.error(res['msg'], '');
        }
      })
    }
    else {
      if (edata['internet_connectivity'] == 0) {
        this.toastr.error('Internet connection lost', '');
      }
      else {
        this.toastr.error('Door is already unlocked', '');
      }
    }
  }

  changeLEDStatus(val) {
    if (this.enclosure_details.internet_connectivity == 1) {
      this.enclosure_details.led_mode = 'none';
      this.enclosure_details.led_color = 'none';
      this.service.EnclosureListFormData.led_color = 'none';
      this.service.EnclosureListFormData.led_mode = 'none';
      this.service.EnclosureListFormData.updated_by = this.loggedInUserId;

      val = !val;
      this.enclosure_details.led_status = val;
      if (val == 0) {
        this.service.updateLEDDetail({ led_status: val, enclosure_id: this.enclosure_id, led_color: 'none', led_mode: 'none',updated_by:this.loggedInUserId }).subscribe(res => {
          if (res['status'] == 1) {
            this.toastr.success(res['msg'], '');
            this.getEnclosureDetail(this.enclosure_id);
          }
          else {
            this.toastr.error(res['msg'], '');
          }
        });
      }
    }
    else {
      this.toastr.error('Internet connection lost', '');
    }
  }

  changeLedColor(val) {
    if (this.enclosure_details.internet_connectivity == 1) {
      if (!this.enclosure_details.led_mode || this.enclosure_details.led_mode == 'none') {
        this.enclosure_details.led_mode = 'steady';
        this.service.EnclosureListFormData.led_mode = this.enclosure_details.led_mode;
      }
      this.service.updateLEDDetail({ enclosure_id: this.enclosure_id, led_color: val, led_status: this.enclosure_details.led_status, led_mode: this.enclosure_details.led_mode,updated_by:this.loggedInUserId }).subscribe(res => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.getEnclosureDetail(this.enclosure_id);
        }
        else {
          this.toastr.error(res['msg'], '');
        }
      });
    }
    else {
      this.toastr.error('Internet connection lost', '');
    }
  }

  changeLedMode(val) {
    if (this.enclosure_details.internet_connectivity == 1) {
      this.service.updateLEDDetail({ enclosure_id: this.enclosure_id, led_mode: val, led_status: this.enclosure_details.led_status, led_color: this.enclosure_details.led_color ,updated_by:this.loggedInUserId}).subscribe(res => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.getEnclosureDetail(this.enclosure_id);
        }
        else {
          this.toastr.error(res['msg'], '');
        }
      });
    }
    else {
      this.toastr.error('Internet connection lost', '');
    }
  }

  setReminder(val) {
    this.service.setReminder({ enclosure_id: this.enclosure_id, enclosure_reminder: val }).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        this.getEnclosureDetail(this.enclosure_id);
      }
      else {
        this.toastr.error(res['msg'], '');
      }
    });
  }

  numberOnly(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  updateEnclosure(form?: NgForm) {
    form.value.updated_by = this.loggedInUserId;
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    else {
      this.isLoading = true;
      form.value.enclosure_id = this.service.EnclosureListFormData.enclosure_id;
      this.service.updateEnclosure(form.value)
        .pipe(
          tap(x => console.log('tap', x)),
          finalize(() => {
            console.log('finalize');
          })
        )
        .subscribe((res) => {
          if (res['status'] == 1) {
            this.toastr.success(res['msg'], '');
            this.getEnclosureDetail(this.enclosure_id);
            this.ngxSmartModalService.getModal('addenclosure').close();
          }
          else {
            this.isLoading = false;
            this.toastr.error(res['msg'], '');
          }
        })
    }
  }


  closeExistingModal() {
    this.ngxSmartModalService.getModal('existingTempDetail').close()
    this.ngxSmartModalService.getModal('temporaryaccess').close()
  }
  closeExistingOTAModal() {
    this.ngxSmartModalService.getModal('existingOTADetail').close()
    this.ngxSmartModalService.getModal('onetimeaccess').close()
  }
  toggleMinDate(evt: any) {
    if (evt.checked) {
      this._setMinDate();
    } else {
      this.minDate = null;
    }
  }
  toggleMaxDate(evt: any) {
    if (evt.checked) {
      this._setMaxDate();
    } else {
      this.maxDate = null;
    }
  }
  closePicker() {
    this.picker.cancel();
  }
  private _setMinDate() {
    //const now = new Date();
  }
  private _setMaxDate() {
    const now = new Date();
  }
  resetSecondaryForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.SecondaryAccessFormData = {
      user_name: '',
      user_email: '',
      enclosure_id: '',//this.enclosure_id
    };
  }
  resetTemporaryForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.TemporaryAccessFormData = {
      user_name: '',
      user_email: '',
      enclosure_id: '',//this.enclosure_id,
      start_time: '',
      end_time: ''
    };
  }
  resetOTAForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.OTAAccessFormData = {
      user_mobile: '',
      enclosure_id: '',//this.enclosure_id,
      start_time: '',
      end_time: ''
    };
  }
  reseEncDelForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.DeleteEncFormData = {
      del_enclosure_id: '',
      type_delete: ''
    };
  }
  resetForwardForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.ForwardDeliveryFormData = {
      enclosure_id: '',
      forward_enclosure_id: '',
      forward_user_email: '',
      forward_start_time: new Date(),
      forward_end_time: ''
    };
  }
  resetEnclosureReminderForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.EnclosureReminderFormData = {
      enclosure_id: '',
      enclosure_reminder: ''
    };
  }



  resetEncForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.EnclosureListFormData = {
      enclosure_id: '',
      enclosure_name: '',
      address_line_a: '',
      address_line_b: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      led_status: '',
      led_color: '',
      led_mode: '',
      enclosure_latitude: '',
      enclosure_longitude: '',
      _id: '',
      updated_by:''
    };
  }

  closeExistingTempModal() {
    this.ngxSmartModalService.getModal('existingTempDetail').close()
    this.ngxSmartModalService.getModal('temporaryaccess').close()
  }

  getCalenderWithTimezone(timezone) {
    this.forwardedEncTimezone = timezone;
    this.forwardTimeFormat = moment().tz(timezone).format('yy, M, DD, H, mm, ss').split(",", 6);

    const now = new Date(this.forwardTimeFormat[0], this.forwardTimeFormat[1] - 1, this.forwardTimeFormat[2], this.forwardTimeFormat[3], this.forwardTimeFormat[4], this.forwardTimeFormat[5]);
    this.minDate = new Date(new Date(this.forwardTimeFormat[0], this.forwardTimeFormat[1] - 1, this.forwardTimeFormat[2], this.forwardTimeFormat[3], this.forwardTimeFormat[4], this.forwardTimeFormat[5]));
    this.minDate.setDate(now.getDate() - 0);

    this.service.ForwardDeliveryFormData.forward_start_time = new FormControl(new Date(this.forwardTimeFormat[0], this.forwardTimeFormat[1] - 1, this.forwardTimeFormat[2], this.forwardTimeFormat[3], this.forwardTimeFormat[4], this.forwardTimeFormat[5]));
    this.service.ForwardDeliveryFormData.forward_end_time = new FormControl(new Date(this.forwardTimeFormat[0], this.forwardTimeFormat[1] - 1, this.forwardTimeFormat[2], this.forwardTimeFormat[3], this.forwardTimeFormat[4], this.forwardTimeFormat[5]));
  }
  getTimezone(event, forwardedEnc) {
    if (forwardedEnc.length == 11) {
      this.service.getEnclosureTimezone(forwardedEnc).subscribe(res => {
        if (res['status'] == 1) {
          this.getCalenderWithTimezone(res['data'].timezone);
        }
        else {
          this.service.ForwardDeliveryFormData.forward_enclosure_id = '';
          this.toastr.error(res['msg'], '');
        }
      });
    }
  }

  openVideoStreamingModal(  ) {
    this.ngxSmartModalService.getModal('videoStreaming').open();
    this.videoStreamingForm.reset();
    this.issSubmit = false;
  }

  VideoStreaming() {
    if (this.videoStreamingForm.invalid) {
      this.issSubmit = true;
      return;
    } else {
      let reqObj = {
        "enclosure_id": this.enclosure_id,
        "streaming_interval": this.videoStreamingForm.value.timer,
        "resolution": this.videoStreamingForm.value.resolution
      }
      this.service.sharedData = this.videoStreamingForm.value.timer;
      this.isLoading = true;
      this.service.VideoStreaming(reqObj).subscribe((res: any) => {
        if (res['status'] == 1) {
          // this.toastr.success(res['msg'], '');
          this.videoStreamingForm.reset();
          this.router.navigate(['/app/live-video-streaming/' + btoa(this.enclosure_id)]);
          this.ngxSmartModalService.getModal('videoStreaming').close();
        }
        else {
          this.isLoading = false;
          this.toastr.error(res['msg'], '');
        }
      })
    }
  }

  RefreshFirmware() {
    this.service.RefreshFirmware(this.enclosure_id).subscribe((res: any) => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
      }
      else {
        this.isLoading = false;
        this.toastr.error(res['msg'], '');
      }
    })
  }
}
