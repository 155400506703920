import { Injectable } from '@angular/core';
import { EnclosureLEDColor, SecondaryAccess, TemporaryAccess, OneTimeAccess, DeleteEnc, ForwardDelivery, EnclosureReminder, EnclosureList } from './enclosure-details.modal';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class EnclosureDetailsService {

  SecondaryAccessFormData: SecondaryAccess;
  TemporaryAccessFormData: TemporaryAccess;
  OTAAccessFormData: OneTimeAccess;
  DeleteEncFormData: DeleteEnc;
  ForwardDeliveryFormData: ForwardDelivery;
  EnclosureReminderFormData: EnclosureReminder;
  EnclosureLEDColorFormData: EnclosureLEDColor;
  EnclosureListFormData: EnclosureList;

  sharedData: string;

  constructor(
    private http: HttpClient
  ) { }

  deleteEnclosure(id) {
    return this.http.delete(environment.api_url + '/enclosures/' + id);
  }
  updateEnclosure(data) {
    return this.http.put(environment.api_url + '/enclosures/' + data.enclosure_id, data);
  }
  getEncViewById(id) {
    return this.http.get(environment.api_url + '/enclosures/' + id);
  }
  updateLEDDetail(data) {
    return this.http.put(environment.api_url + '/enclosures/led/' + data.enclosure_id, data);
  }
  addSecondaryUser(data) {
    return this.http.post(environment.api_url + '/access/secondary', data);
  }
  addTemporaryAccess(data) {
    return this.http.post(environment.api_url + '/access/temporary', data);
  }
  addOTAUser(data) {
    return this.http.post(environment.api_url + '/access/one-time', data);
  }
  addForwardDelivery(data) {
    return this.http.post(environment.api_url + '/forward-deliveries', data);
  }
  openUserDoor(edata) {
    return this.http.put(environment.api_url + '/dashboard/open-door/' + edata.enclosure_id, edata);
  }
  closeUserDoor(edata) {
    return this.http.put(environment.api_url + '/dashboard/close-door/' + edata.enclosure_id, edata);
  }
  openDroneDoor(edata) {
    return this.http.put(environment.api_url + '/dashboard/open-drone-door/' + edata.enclosure_id, edata);
  }
  closeDroneDoor(edata) {
    return this.http.put(environment.api_url + '/dashboard/close-drone-door/' + edata.enclosure_id, edata);
  }
  stopBuzzer(edata) {
    return this.http.put(environment.api_url + '/dashboard/stop-buzzer/' + edata.enclosure_id, edata);
  }
  setReminder(edata) {
    return this.http.put(environment.api_url + '/dashboard/reminder/' + edata.enclosure_id, edata);
  }
  getEnclosureAndParcelDetails(id) {
    return this.http.get(environment.api_url + '/enclosures/parcel/' + id);
  }
  getEnclosureTimezone(id) {
    return this.http.get(environment.api_url + '/enclosures/timezone/' + id);
  }
  VideoStreaming(data) {
    return this.http.post(environment.api_url + '/enclosures/access-video-streaming', data);
  }
  RefreshFirmware(id) {
    return this.http.get(environment.api_url + '/enclosures/refresh_data/' + id);
  }
}
