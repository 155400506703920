<div class="container-fluid">
    <div class="main mx-2 mb-4 h-100">
        <div class="row">
            <div class="col-12">
                <h1 class="title-1 title-border-bottom mt-2">
                    <button class="back-btn" (click)="backClicked()"><span
                            class="material-icons">keyboard_arrow_left</span></button>
                    <em class="app-card-icon app-card-icon-title">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path fill="#0E48CA"
                                d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                        </svg>
                    </em>
                    Change Password
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="app-card d-sm-inline-flex w-auto">
                    <div class="app-card-body">
                        <form #changePasswordForm="ngForm" (ngSubmit)="changePassword(changePasswordForm)"
                            class="auth-form changepassword-form"
                            [mustMatch]="['new_user_password','new_user_confirm_password']">
                            <label>Old Password <sup>*</sup></label>
                            <mat-form-field floatLabel="never" appearance="standard">
                                <input maxlength="25" matInput placeholder="Ex. qk*%yEn8Q" [type]="hide ? 'password' : 'text'"
                                    name="old_user_password" #old_user_password="ngModel"
                                    [(ngModel)]="service.ChangePasswordFormData.old_user_password"
                                    placeholder="Ex. abc@gmail.com"
                                    [ngClass]="{'is-invalid': !old_user_password.valid && (old_user_password.dirty || old_user_password.touched || isSubmit)}"
                                    required>
                                <mat-error
                                    *ngIf="!old_user_password.valid && (old_user_password.dirty || old_user_password.touched || isSubmit )">
                                    Please enter old password
                                </mat-error>
                                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                                </mat-icon>
                            </mat-form-field>
                            <label>New Password <sup>*</sup></label>
                            <mat-form-field floatLabel="never" appearance="standard">
                                <input minlength="6"
                                    pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*"
                                    maxlength="25" matInput placeholder="Ex. qk*%yEn8Q"
                                    [type]="nhide ? 'password' : 'text'" name="new_user_password"
                                    #new_user_password="ngModel"
                                    [(ngModel)]="service.ChangePasswordFormData.new_user_password"
                                    [ngClass]="{'is-invalid': !new_user_password.valid && (new_user_password.dirty || new_user_password.touched || isSubmit)}"
                                    required>
                                <mat-error *ngIf="!new_user_password.valid && (new_user_password.dirty ||isSubmit )">
                                    <div *ngIf="new_user_password.errors.required">
                                        Please enter new password
                                    </div>
                                    <div *ngIf="new_user_password.errors.minlength">
                                        Have at least 6 characters
                                    </div>
                                    <div
                                        *ngIf="!new_user_password.errors.minlength && new_user_password.errors.pattern">
                                        Include at least 1 uppercase letter, 1 lowercase letter, 1 number or 1 special
                                        char
                                    </div>
                                </mat-error>
                                <mat-icon matSuffix (click)="nhide = !nhide">{{nhide ? 'visibility_off' : 'visibility'}}
                                </mat-icon>
                            </mat-form-field>
                            <label>Confirm Password <sup>*</sup></label>
                            <mat-form-field floatLabel="never" appearance="standard">
                                <input maxlength="25" (blur)="onKey($event,service.ChangePasswordFormData.new_user_password)" matInput
                                placeholder="Ex. qk*%yEn8Q" [type]="chide ? 'password' : 'text'"
                                name="new_user_confirm_password" #new_user_confirm_password="ngModel"
                                [(ngModel)]="service.ChangePasswordFormData.new_user_confirm_password"
                                [ngClass]="{'is-invalid': !new_user_confirm_password.valid && (new_user_confirm_password.dirty || new_user_confirm_password.touched || isSubmit)}"
                                required>
                                <mat-error *ngIf="new_user_confirm_password.invalid">
                                    <div *ngIf="new_user_confirm_password.errors.required">Confirm Password is required</div>
                                    <div *ngIf="new_user_confirm_password.errors.mustMatch">Passwords must match with new password</div>
                                </mat-error>
                                    <mat-icon matSuffix (click)="chide = !chide">{{hide ? 'visibility_off' : 'visibility'}}
                                    </mat-icon>
                            </mat-form-field>
                            <button [disabled]="isLoading" class="auth-btn mt-1">Update</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>