<div class="container-fluid">
  <div class="main mx-2 mb-4">
    <div class="row">
      <div class="col-12">
        <div class="title-wrapper title-border-bottom mt-2">
          <h1 class="title-1">
            <button class="back-btn" (click)="backClicked()">
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
            User Door History
          </h1>
          <div class="sub-title-wrapper">
            <div class="encl-name-subtitle">{{ enclosure_id }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="app-card">
          <div class="app-card-body">
            <div class="table-responsive">
              <mat-form-field floatLabel="never" appearance="standard" class="search-filter">
                <span class="material-icons">search</span>
                <input matInput (keyup)="applyFilter($event)" placeholder="Search by Access Control Name, Primary Key & Secondary Key" #input />
              </mat-form-field>
              <table mat-table [dataSource]="dataSource" matSort class="app-table dashboard-app-table" aria-describedby="Device History">
                <ng-container matColumnDef="position">
                  <th scope="col" mat-header-cell *matHeaderCellDef>No.</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{ this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="access_control_name">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Access Control Name</th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.access_control_name">{{ element.access_control_name }}</span>
                    <span *ngIf="!element?.access_control_name">-</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="primary_auth_type">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Primary Auth</th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.primary_auth_type?.name">{{ element.primary_auth_type.name }}</span>
                    <span *ngIf="!element?.primary_auth_type?.name">-</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="secondary_auth_type">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Secondary Auth</th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.secondary_auth_type">{{ element.secondary_auth_type.name }}</span>
                    <span *ngIf="!element?.secondary_auth_type">-</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="timestamp">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Captured Time</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.timestamp * 1000 | date : "d MMMM, y h:mm a" }}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="access_status">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Access Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.access_status == 1" class="access-card primary-access">Access Granted</span>
                    <span *ngIf="element?.access_status == 2" class="access-card deleted-access">Access Denied</span>
                    <span *ngIf="!element?.access_status">-</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="available_height">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Available Height</th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.available_height">
                      {{ this.space_type == 'CM' ? element?.available_height : this.space_type == 'cm' ? element?.available_height : (element?.available_height * 0.39370).toFixed(2) }}
                      <span>{{ space_type }}</span>
                    </span>
                    <span *ngIf="!element?.available_height">-</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="actuator_type">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>User Door Opening</th>
                <td mat-cell *matCellDef="let element" style="width:200px;">
                  <span *ngIf="element?.actuator_type == 1" class="text-wrap text-break">Automatic</span>
                  <span *ngIf="element?.actuator_type == 2" class="text-wrap text-break">Manual</span>
                  <span *ngIf="(element?.actuator_type == 3) || (!element?.actuator_type)">-</span>
                </td>
              </ng-container>
                <ng-container matColumnDef="actions">
                  <th scope="col" mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex">
                      <button title="View Details" class="btn blue-btn" (click)="ViewHistoryModal(element)">
                        <span class="material-icons">visibility</span>
                      </button>
                      <button title="View Captured Image" class="btn blue-btn" (click)="UserDoorImageModal(element)">
                        <span class="material-icons">collections</span>
                      </button>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
              <div class="drone-nodata-box" *ngIf="dataSourceEmpty">
                <div class="drone-nodata-box-wrap">
                  <div class="drone-nodata-box-cover">
                    <div class="drone-nodata-cover">
                      <span class="material-icons">error_outline</span>
                      <p class="drone-nodata-text">No records found!</p>
                    </div>
                  </div>
                </div>
              </div>
              <mat-paginator [pageSize]="10" class="app-paginator" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons> </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- View User Door Modal -->
  <ngx-smart-modal #viewUserDoorModal [identifier]="'viewUserDoorModal'" [dismissable]="false" customClass="nsm-dialog-animation-ttb nsm-centered modal viewenclosure-modal">
    <h2 class="modal-title">View User Door</h2>
    <div class="modal-form addenclosure-form viewenclosure-form">
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">person</span>Device Id</div>
          <div class="viewuser-box-subtitle">{{ singleHistoryData?.enclosure_id }}</div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">person</span>Access Control Name</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleHistoryData?.access_control_name">{{ singleHistoryData.access_control_name }}</span>
            <span *ngIf="!singleHistoryData?.access_control_name">-</span>
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">date_range</span>Captured Time</div>
          <div class="viewuser-box-subtitle">
            {{ singleHistoryData?.timestamp * 1000 | date : "d MMM, y h:mm a" }}
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">height</span>Available Height</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleHistoryData?.available_height">
              {{ this.space_type == 'CM' ? singleHistoryData?.available_height : this.space_type == 'cm' ? singleHistoryData?.available_height : (singleHistoryData?.available_height * 0.39370).toFixed(2) }}
              <span>{{ space_type }}</span>
            </span>
            <span *ngIf="!singleHistoryData?.available_height">-</span>
          </div>
        </div>
      </div>
      <div class="modal-input fullwidth" *ngIf="singleHistoryData?.access_status == 2">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">height</span>Denial Reason</div>
          <div class="viewuser-box-subtitle">{{singleHistoryData?.reason}}</div>
        </div>
      </div>
      <div class="modal-input" *ngIf="singleHistoryData?.access_status == 1">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">height</span>Matched Primary Auth Type</div>
          <div class="viewuser-box-subtitle">{{ singleHistoryData?.primary_auth_type.name }}</div>
        </div>
      </div>
      <div class="modal-input" *ngIf="singleHistoryData?.access_status == 1">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">check_circle</span>Matched Primary Auth Key</div>
          <div class="viewuser-box-subtitle">{{ singleHistoryData?.primary_auth_key }}</div>
        </div>
      </div>
      <div class="modal-input" *ngIf="singleHistoryData?.access_status == 2">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">height</span>Attempted Primary Auth Type</div>
          <div class="viewuser-box-subtitle">
            {{(singleHistoryData?.primary_auth_key == "") ? "-" : singleHistoryData?.primary_auth_type?.name}}
            <!-- <span *ngIf="singleHistoryData?.primary_auth_type?.name">{{ singleHistoryData?.primary_auth_type.name }}</span>
            <span *ngIf="!singleHistoryData?.primary_auth_type?.name">-</span> -->
          </div>
        </div>
      </div>
      <div class="modal-input" *ngIf="singleHistoryData?.access_status == 2">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">check_circle</span>Attempted Primary Auth Key</div>
          <div class="viewuser-box-subtitle">
            {{(singleHistoryData?.primary_auth_key == "") ? singleHistoryData?.primary_input : singleHistoryData?.primary_auth_key}}
            <!-- <span *ngIf="singleHistoryData?.primary_input">{{ singleHistoryData?.primary_input }}</span>
            <span *ngIf="!singleHistoryData?.primary_input">-</span> -->
          </div>
        </div>
      </div>
      <div class="modal-input" *ngIf="singleHistoryData?.access_status == 1">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">height</span>Matched Secondary Auth Type</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleHistoryData?.secondary_auth_type">{{ singleHistoryData?.secondary_auth_type.name }}</span>
            <span *ngIf="!singleHistoryData?.secondary_auth_type">-</span>
          </div>
        </div>
      </div>
      <div class="modal-input" *ngIf="singleHistoryData?.access_status == 1">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">check_circle</span>Matched Secondary Auth Key</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleHistoryData?.secondary_auth_key">{{ singleHistoryData?.secondary_auth_key }}</span>
            <span *ngIf="!singleHistoryData?.secondary_auth_key">-</span>
          </div>
        </div>
      </div>
      <div class="modal-input" *ngIf="singleHistoryData?.access_status == 2">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">height</span>Attempted Secondary Auth Type</div>
          <div class="viewuser-box-subtitle">
            {{(singleHistoryData?.secondary_auth_type == null) ? "-" : singleHistoryData?.secondary_auth_type.name}}
            <!-- <span *ngIf="singleHistoryData?.secondary_auth_type">{{ singleHistoryData?.secondary_auth_type.name }}</span>
            <span *ngIf="!singleHistoryData?.secondary_auth_type">-</span> -->
          </div>
        </div>
      </div>
      <div class="modal-input" *ngIf="singleHistoryData?.access_status == 2">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">check_circle</span>Attempted Secondary Auth Key</div>
          <div class="viewuser-box-subtitle">
            {{(singleHistoryData?.secondary_auth_type == null) ? "none" : singleHistoryData?.secondary_input}}
            <!-- <span *ngIf="singleHistoryData?.secondary_input">{{ singleHistoryData?.secondary_input }}</span>
            <span *ngIf="!singleHistoryData?.secondary_input">-</span> -->
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">person</span>User Door Opening</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleHistoryData?.actuator_type == 1">Automatic</span>
            <span *ngIf="singleHistoryData?.actuator_type == 2">Manual</span>
            <span *ngIf="(singleHistoryData?.actuator_type == 3) || (!singleHistoryData?.actuator_type)">-</span>
          </div>
        </div>
      </div>
    </div>
  </ngx-smart-modal>
  <!-- User Door Image Modal -->
  <ngx-smart-modal #userDoorImageModal [identifier]="'userDoorImageModal'" [dismissable]="false" customClass="nsm-dialog-animation-ttb nsm-centered modal viewenclosure-modal user-door-image">
    <h2 class="modal-title">User Door Image</h2>
    <div class="modal-form addenclosure-form viewenclosure-form">
      <img src="{{ image_logging_url }}{{ singleHistoryData?.image_path }}" alt="" class="w-100 h-100 object-fit-contain mb-3" />
    </div>
  </ngx-smart-modal>
</div>
