<div class="container-fluid">
  <div class="main mb-4">
    <div class="row">
      <div class="col-12">
        <div class="title-wrapper title-border-bottom mt-2">
          <h1 class="title-1">
            <button class="back-btn" (click)="backClicked()">
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
            Live View
          </h1>
          <div *ngIf="isStreamContinuous" class="d-flex align-items-center mr-2">
            <img src="../../assets/images/timer.svg" alt="timer" class="mr-1">
            <span class="mr-2">Remaining Time: </span>
            <span class="timer">{{ minutes }}m {{ seconds }}s</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-eq-height mb-4 position-relative">
    <div *ngIf="isStreamAvailable || isStreamContinuous" class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
      <video id="my-video" class="video-js video-container" preload="auto">
        <source src="your-m3u8-url.m3u8" type="application/x-mpegURL" />
      </video>
    </div>
    <div *ngIf="!isStreamAvailable" class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
      <div>An error occurred during your live streaming playback. Please restart to continue.</div>
    </div>
    <div *ngIf="!isStreamContinuous" class="stream-continuous">
      Please wait until the stream is coming...
    </div>
  </div>
</div>
