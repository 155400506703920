import { Component, OnInit, HostListener } from '@angular/core';
import { NavService } from '../nav.service';
import { AuthService } from '../auth/auth.service';
import { NotificationsService } from '../notifications/notifications.service';
import { Router } from '@angular/router';
import { EventEmitterService } from '../event_emmiter_service';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
import jwt_decode from "jwt-decode";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html'
})
export class TopNavComponent implements OnInit {
  isSticky: boolean = false;
  public selectedTempLatest: string;
  public selectedTemp: string;
  public selectedSpaceLatest: string;
  public selectedSpace: string;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 250;
  }
  public userData: any = '';
  public temp_val: any = '';
  public space_val: any = '';
  public notification_count: Number = 10;
  public temp: any;
  public space: any;

  constructor(public notifService: NotificationsService, private toastr: ToastrService, public service: AuthService, public navService: NavService, public router: Router, public eventEmitterService: EventEmitterService, public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit(): void {
    this.getLoggedinUser();
    this.service.metricsTempFormData = {
      temperature: 'CL',
    };
    this.service.metricsSpaceFormData = {
      space: 'CM',
    };
    this.notifService.getNotificationCount().subscribe(res => {
      if (res['status'] == 1) {
        this.notification_count = res['length'];
      }
    });
    this.eventEmitterService.invokeTopNavComponent.subscribe((data: any) => {
      this.userData.user_name = data.user_name;
    });
    this.eventEmitterService.invokeNotificationComponent.subscribe(() => {
      let numVal = Number(this.notification_count);
      this.notification_count = numVal - 1;
      if (this.notification_count < 0) {
        this.notification_count = 0;
      }
    });
  }

  getLoggedinUser() {
    this.service.getLoggedinUser().subscribe(res => {
      if (res['status'] == 1) {
        const token = sessionStorage.getItem('token');
        var tokenData = jwt_decode(token);
        this.userData = res['data'];
        this.userData.user_last_login = tokenData['user_last_login'];
        this.service.UpdateProfileFormData = this.userData;
      }
      else {
        this.toastr.error(res['msg'], '');
        sessionStorage.removeItem('token');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 3000);
      }
    });
  }

  doLogout() {
    this.router.navigate(['/login']);
    sessionStorage.removeItem('token');
  }
  getMetricsDetails() {
    this.ngxSmartModalService.getModal('metricsSettings').open()
    this.selectedSpaceLatest = "CM";
    this.selectedTempLatest = "CL";
    this.service.getMetricsDetails().subscribe(res => {
      if (res['status'] == 1) {
        if (res['data'].length == 0) {
          this.selectedSpaceLatest = "CM";
          this.selectedTempLatest = "CL";
        } else {
          this.selectedSpaceLatest = res['data'][0].space;
          this.selectedTempLatest = res['data'][0].temperature;
          if (this.selectedSpaceLatest == "CM") {
            this.space_val = 'cm';
          } else {
            this.space_val = 'inch';
          }
        }
      }
      else {
        this.toastr.error(res['msg'], '');
      }
    });
  }
  getTempDetails() {
    this.ngxSmartModalService.getModal('metricsTemp').open()
    this.service.metricsTempFormData.temperature = this.selectedTempLatest;
  }
  getSpaceDetails() {
    this.ngxSmartModalService.getModal('metricsSpace').open()
    this.service.metricsSpaceFormData.space = this.selectedSpaceLatest;
  }
  updateTempMetrics(form?: NgForm) {
    form.value.type = "TEMP";
    form.value.value = form.value.temperature;
    this.service.updateMetricDetils(form.value).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        this.ngxSmartModalService.getModal('metricsSettings').close()
        this.ngxSmartModalService.getModal('metricsTemp').close()
        window.location.reload();
      }
      else {
        this.toastr.error(res['msg'], '');
      }
    });
  }
  updateSpaceMetrics(form?: NgForm) {
    form.value.type = "SPACE";
    form.value.value = form.value.space;
    this.service.updateMetricDetils(form.value).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        this.ngxSmartModalService.getModal('metricsSettings').close()
        this.ngxSmartModalService.getModal('metricsSpace').close();
        window.location.reload();
      }
      else {
        this.toastr.error(res['msg'], '');
      }
    });
  }
}
