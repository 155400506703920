<div class="container-fluid">
  <div class="main mx-2 mb-4">
    <div class="row">
      <div class="col-12">
        <div class="title-wrapper title-border-bottom mt-2">
          <h1 class="title-1">
            <button class="back-btn" (click)="backClicked()">
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
            <em class="app-card-icon app-card-icon-title">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="16"
                height="19.5"
                viewBox="0 0 16 19.5"
                style="enable-background: new 0 0 16 19.5"
                xml:space="preserve"
              >
                <style type="text/css">
                  .st0 {
                    fill: #0E48CA;
                  }
                </style>
                <path
                  class="st0"
                  d="M8,19.5c1.1,0,2-0.9,2-2H6C6,18.6,6.9,19.5,8,19.5z M14,13.5v-5c0-3.1-1.6-5.6-4.5-6.3V1.5C9.5,0.7,8.8,0,8,0
                                S6.5,0.7,6.5,1.5v0.7C3.6,2.9,2,5.4,2,8.5v5l-2,2v1h16v-1L14,13.5z"
                />
              </svg>
            </em>
            Notification Settings
          </h1>
        </div>
      </div>
    </div>

    <mat-tab-group
      class="notification-tab"
      (selectedTabChange)="onTabChanged($event)"
    >
      <mat-tab label="App Setting">
        <div class="notification-settings-wrapper">
          <form
            #appNotiFormData="ngForm"
            (ngSubmit)="updateAppNoti(appNotiFormData)"
          >
            <div class="notification-settings-box">
              <div class="notification-settings-box-top">
                <div class="notification-settings-select-wrap">
                  <h2 class="notification-settings-title">
                    Application Notifications
                  </h2>
                  <div class="notification-settings-selectall">
                    <label class="notification-container">
                      <input
                        (change)="
                          select_all(
                            FormData.app_notification_select_all,
                            'app'
                          )
                        "
                        type="checkbox"
                        name="app_notification_select_all"
                        #app_notification_select_all="ngModel"
                        [(ngModel)]="FormData.app_notification_select_all"
                        [disabled]="!FormData.app_notification"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <span class="notification-settings-selectall-text"
                      >Select All</span
                    >
                  </div>
                </div>
                <section>
                  <mat-slide-toggle
                    (change)="appChangeToggle(FormData.app_notification)"
                    [checked]="FormData.app_notification"
                    name="app_notification"
                    #app_notification="ngModel"
                    [(ngModel)]="FormData.app_notification"
                  >
                  </mat-slide-toggle>
                </section>
              </div>
              <div
                class="notification-settings-list-section"
                [ngClass]="{
                  'notification-settings-list-section-disabled-app':
                    FormData.app_notification == false
                }"
              >
                <ul>
                  <li *ngFor="let check of appNoti">
                    <label class="notification-container">
                      <input
                        type="checkbox"
                        #el
                        (change)="appOnCheckBox(el, check.tnm_type)"
                        [checked]="check.isChecked"
                        [disabled]="!FormData.app_notification"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label>
                      {{ check.tnm_title }}
                    </label>
                  </li>
                  <li>
                    <button
                      type="submit"
                      class="apply-btn"
                      [disabled]="
                        !FormData.app_notification ||
                        !FormData.app_notification_btn
                      "
                    >
                      Apply App Notification
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </mat-tab>
      <mat-tab label="Email Setting">
        <div class="notification-settings-wrapper">
          <form
            #emailNotiFormData="ngForm"
            (ngSubmit)="updateEmailNoti(emailNotiFormData)"
          >
            <div class="notification-settings-box">
              <div class="notification-settings-box-top">
                <div class="notification-settings-select-wrap">
                  <h2 class="notification-settings-title">
                    Email Notifications
                  </h2>
                  <div class="notification-settings-selectall">
                    <label class="notification-container">
                      <input
                        (change)="
                          select_all(
                            FormData.email_notification_select_all,
                            'email'
                          )
                        "
                        type="checkbox"
                        name="email_notification_select_all"
                        #email_notification_select_all="ngModel"
                        [(ngModel)]="FormData.email_notification_select_all"
                        [disabled]="!FormData.email_notification"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <span class="notification-settings-selectall-text"
                      >Select All</span
                    >
                  </div>
                </div>
                <section>
                  <mat-slide-toggle
                    (change)="emailChangeToggle(FormData.email_notification)"
                    [checked]="FormData.email_notification"
                    name="email_notification"
                    #email_notification="ngModel"
                    [(ngModel)]="FormData.email_notification"
                  >
                  </mat-slide-toggle>
                </section>
              </div>
              <div
                class="notification-settings-list-section"
                [ngClass]="{
                  'notification-settings-list-section-disabled-email':
                    FormData.email_notification == false
                }"
              >
                <ul>
                  <li *ngFor="let check of emailNoti">
                    <label class="notification-container">
                      <input
                        type="checkbox"
                        #ela
                        (change)="emailOnCheckBox(ela, check.tnm_type)"
                        [checked]="check.isChecked"
                        [disabled]="!FormData.email_notification"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label>
                      {{ check.tnm_title }}
                    </label>
                  </li>
                  <li>
                    <button
                      type="submit"
                      class="apply-btn"
                      [disabled]="
                        !FormData.email_notification ||
                        !FormData.email_notification_btn
                      "
                    >
                      Apply Email Notification
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </mat-tab>
      <mat-tab label="SMS Setting">
        <div class="notification-settings-wrapper">
          <form
            #smsNotiFormData="ngForm"
            (ngSubmit)="updateSmsNoti(smsNotiFormData)"
          >
            <div class="notification-settings-box">
              <div class="notification-settings-box-top">
                <div class="notification-settings-select-wrap">
                  <h2 class="notification-settings-title">SMS Notifications</h2>
                  <div class="notification-settings-selectall">
                    <label class="notification-container">
                      <input
                        (change)="
                          select_all(
                            FormData.sms_notification_select_all,
                            'sms'
                          )
                        "
                        type="checkbox"
                        name="sms_notification_select_all"
                        #sms_notification_select_all="ngModel"
                        [(ngModel)]="FormData.sms_notification_select_all"
                        [disabled]="!FormData.sms_notification"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <span class="notification-settings-selectall-text"
                      >Select All</span
                    >
                  </div>
                </div>
                <section>
                  <mat-slide-toggle
                    (change)="smsChangeToggle($event)"
                    [checked]="FormData.sms_notification"
                    name="sms_notification"
                  >
                  </mat-slide-toggle>
                </section>
              </div>
              <div
                class="notification-settings-list-section"
                [ngClass]="{
                  'notification-settings-list-section-disabled-sms':
                    FormData.sms_notification == false
                }"
              >
                <ul>
                  <li *ngFor="let check of smsNoti">
                    <label class="notification-container">
                      <input
                        type="checkbox"
                        #elb
                        (change)="smsOnCheckBox(elb, check.tnm_type)"
                        [checked]="check.isChecked"
                        [disabled]="!FormData.sms_notification"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label>
                      {{ check.tnm_title }}
                    </label>
                  </li>
                  <li>
                    <button
                      type="submit"
                      class="apply-btn"
                      [disabled]="
                        !FormData.sms_notification ||
                        !FormData.sms_notification_btn
                      "
                    >
                      Apply SMS Notification
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
