import { Component, OnInit, ViewChild } from '@angular/core';
import { ActuatorLogsService } from './actuator-logs.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-actuator-logs',
  templateUrl: './actuator-logs.component.html',
})

export class ActuatorLogsComponent implements OnInit {

  public image_logging_url = environment.image_logging_url;

  public enclosure_id: any;
  public totalLogs: number;
  public pageSize = 10;
  public pageIndex = 0;

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['position', 'status', 'captured_time'];

  constructor(
    public route: ActivatedRoute,
    private _location: Location,
    public actuatorLogsService: ActuatorLogsService,
    public ngxSmartModalService: NgxSmartModalService,
  ) { }

  ngOnInit(): void {

    this.enclosure_id = atob(this.route.snapshot.params.eid);
    this.getActuatorLogsDetails();
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getActuatorLogsDetails() {
    this.actuatorLogsService.getActuatorLogsHistory(this.enclosure_id, this.pageIndex + 1, this.pageSize)    .subscribe((res: any) => {
      const logs = res.msg.map((log, index) => ({
        position: (this.pageIndex * this.pageSize) + index + 1,  // Set the correct position number
        status: this.getStatusDescription(log.actuator_status),
        captured_time: new Date(log.created_at * 1000)  // Convert from UNIX timestamp
      }));
      this.dataSource = new MatTableDataSource(logs);
      this.totalLogs = res.totalLogs;
      this.dataSource.sort = this.sort;
    });
  }

  getStatusDescription(status: number): string {
    const statusDescriptions = {
      1: 'Target Position Reached',
      2: 'Stop Command Received',
      3: 'Hardware Undervoltage',
      4: 'Hardware Stop',
      5: 'Illegal Position',
      6: 'Illegal Velocity',
      7: 'Illegal Acceleration',
      8: 'Current Cutoff Out',
      9: 'Current Cutoff In',
      10: 'Undervoltage Detected',
      11: 'Overvoltage Detected',
      12: 'Encoder Error',
      13: 'Slip Error (Actuator blocked or problems following position profile)',
      14: 'Temperature Exceeded',
      15: 'Home Learning',
      16: 'Botted',
      17: 'Is Running',
      18: 'Timeout (MODBUS response timeout setting issue)',
      19: 'Bus Error'
    };
    return statusDescriptions[status] || 'Unknown Status';
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    console.log(this.pageIndex,);
    
    this.pageSize = event.pageSize;
    this.getActuatorLogsDetails();  // Fetch new data when page changes
  }

  backClicked() {
    this._location.back();
  }
}
