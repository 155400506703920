import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AccessControlService } from '../access-control/access-control.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-access-control',
  templateUrl: './access-control.component.html'
})

export class AccessControlComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator; // for mat-paginator
  @ViewChild('addEnclosureTypeForm', { static: true }) addEnclosureTypeForm: NgForm;
  @ViewChild('editEnclosureTypeForm', { static: true }) editEnclosureTypeForm: NgForm;

  searchTerm: string;
  _id: any;
  p_id: any;
  checkList: any;
  secondaryDisable: any;
  primaryDisable: any;
  public singleAccessControlData: any;
  public enclosure_id: any;
  public dataSourceEmpty: any = false;
  public isSubmit: boolean = false;
  public isLoading: boolean = false;
  public checkSecAuth: boolean = false;
  public checkFiniteUses: boolean = false;
  public enclosure_details: any = {};
  primaryTypeList: any[] = [];
  secondaryTypeList: any[] = [];
  displayedColumns: string[] = ['position', 'access_control_name', 'primary_auth_type', 'secondary_auth_type', 'start_date', 'end_date', 'uses_remaining', 'actuator_type', 'actions'];
  dataSource: MatTableDataSource<any>;
  addAccessControlForm: FormGroup;
  editAccessControlForm: FormGroup;
  public minDate = new Date();
  checkDate: boolean = false;
  timezone: any;

  tooltipContent: any = `Bluetooth: Only six sets of hexadecimal letters, separated by either "-" or ":", are permissible.\nKeypad: Minimum one digit is required.\nBarcode: There is no restriction on size, and characters are also permitted.\nRFID: Minimum four digits are required.`;

  constructor(
    private _location: Location,
    public service: AccessControlService,
    public ngxSmartModalService: NgxSmartModalService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public router: Router,
    private socket: Socket
  ) { }

  ngOnInit(): void {
    this.enclosure_id = atob(this.route.snapshot.params.eid);
    this.GetAccessControl();
    this.getEnclosureDetail(this.enclosure_id);
    this.socket.on('receive_data_from_device', (device) => {
      if (device['enclosure_id'] && device['enclosure_id'] == this.enclosure_id) {
        if (device['type'] == 'internet_connection_lost') {
          this.enclosure_details.internet_connectivity = 0;
        }
        else {
          this.enclosure_details.internet_connectivity = 1;
        }
      }
    });
    this.addAccessControlForm = new FormGroup({
      access_control_name: new FormControl('', [Validators.required, Validators.pattern(`^[A-Za-z0-9\s. ]+$`)]),
      primary_auth_type: new FormControl('', [Validators.required]),
      primary_auth_key: new FormControl('', [Validators.required]),
      isSecondary: new FormControl(false),
      secondary_auth_type: new FormControl('', [Validators.required]),
      secondary_auth_key: new FormControl('', [Validators.required]),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
      finite_uses: new FormControl('', [Validators.required]),
      uses_remaining: new FormControl('', [Validators.required, Validators.min(1)]),
      actuator_type: new FormControl('', [Validators.required]),
    });
    this.editAccessControlForm = new FormGroup({
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
      finite_uses: new FormControl('', [Validators.required]),
      uses_remaining: new FormControl('', [Validators.required, Validators.min(1)]),
      actuator_type: new FormControl('', [Validators.required]),
    });
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  ngOnDestroy() {
    this.socket.removeAllListeners();
  }

  backClicked() {
    this._location.back();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.dataSourceEmpty = true;
    }
    else {
      this.dataSourceEmpty = false;
    }
  }

  getEnclosureDetail(eid) {
    this.service.getEncViewById(eid).subscribe((res) => {
      if (res['status'] == 1) {
        this.enclosure_details = res['data'];
      }
      else {
        this.router.navigate(['app/enclosure-list'])
      }
    })
  }

  GetAccessControlType() {
    this.service.GetAccessControlType().subscribe(res => {
      this.secondaryTypeList = res['accessTypeList'];
      this.primaryTypeList = res['accessTypeList'].filter((element: any) => element.name !== 'Bluetooth');
    });
  }

  GetAccessControl() {
    this.service.GetAccessControl(this.enclosure_id).subscribe(res => {
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = res['data'];
      if (res['data'].length == 0) {
        this.dataSourceEmpty = true;
      }
      else {
        this.dataSourceEmpty = false;
      }
      this.dataSource.data = res['data'].map(element => {
        return {
          ...element,
          primary_auth_type: element.primary_auth_type[0]?.name || '-',
          secondary_auth_type: element.secondary_auth_type[0]?.name || '-',
        };
      });
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  ViewAccessControlModal(content: any) {
    this._id = content._id;
    this.GetAccessControlById();
    this.ngxSmartModalService.getModal('viewAccessControlModal').open();
  }

  GetAccessControlById() {
    this.service.GetAccessControlById(this._id).subscribe(res => {
      if (res['status'] == 1) {
        this.singleAccessControlData = res['data'];
        this.editAccessControlForm.patchValue({
          start_date: moment(this.singleAccessControlData.start_date * 1000).format(),
          end_date: moment(this.singleAccessControlData.end_date * 1000).format(),
          finite_uses: this.singleAccessControlData.finite_uses,
          uses_remaining: this.singleAccessControlData.uses_remaining,
          actuator_type: this.singleAccessControlData.actuator_type ? (this.singleAccessControlData.actuator_type).toString() : ""
        });        
        if (this.editAccessControlForm.value.finite_uses == 'Y') {
          this.checkFiniteUses = true;
        }
      }
      else {
        this.GetAccessControl();
      }
    });
  }

  AddAccessControlModal() {
    this.GetAccessControlType();
    this.ngxSmartModalService.getModal('addAccessControlModal').open();
    this.addAccessControlForm.reset();
    this.secondaryDisable = '';
    this.primaryDisable = '';
    this.isSubmit = false;
  }

  AddAccessControl() {
    if (this.checkSecAuth === false) {
      this.addAccessControlForm.controls["isSecondary"].setValue(false);
      this.addAccessControlForm.get("secondary_auth_type").reset();
      this.addAccessControlForm.controls["secondary_auth_type"].clearValidators();
      this.addAccessControlForm.controls["secondary_auth_type"].setErrors(null);
      this.addAccessControlForm.controls["secondary_auth_type"].setValue('');
      this.addAccessControlForm.get("secondary_auth_key").reset();
      this.addAccessControlForm.controls["secondary_auth_key"].clearValidators();
      this.addAccessControlForm.controls["secondary_auth_key"].setErrors(null);
      this.addAccessControlForm.controls["secondary_auth_key"].setValue('');
    } else {
      this.addAccessControlForm.controls["secondary_auth_type"].setValidators([Validators.required]);
      this.addAccessControlForm.controls["secondary_auth_key"].setValidators([Validators.required]);

      if (this.addAccessControlForm.value.secondary_auth_type === '6512a0cebb4b467e12f6a765') { // Validation Check for Bluetooth
        this.secondaryValidation(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/);
      } else if (this.addAccessControlForm.value.secondary_auth_type === '6512a0e2bb4b467e12f6a768') { // Validation Check for Keypad
        this.secondaryValidation(/^\d{1,}$/);
      } else if (this.addAccessControlForm.value.secondary_auth_type === '6512a0ecbb4b467e12f6a76b') { // Validation Check for RFID
        this.secondaryValidation(/^\d{4,}$/);
      } else { // Validation Check for Barcode
        this.secondaryValidation(/^[0-9A-Za-z]+$/);
      }
    }

    if (this.checkFiniteUses === false) {
      this.addAccessControlForm.get("uses_remaining").reset();
      this.addAccessControlForm.controls["uses_remaining"].clearValidators();
      this.addAccessControlForm.controls["uses_remaining"].setErrors(null);
    } else {
      this.addAccessControlForm.controls["uses_remaining"].setValidators([Validators.required, Validators.min(1)]);
    }

    if (this.addAccessControlForm.value.primary_auth_type === '6512a0e2bb4b467e12f6a768') { // Validation Check for Keypad
      this.primaryValidation(/^\d{1,}$/);
    } else if (this.addAccessControlForm.value.primary_auth_type === '6512a0ecbb4b467e12f6a76b') { // Validation Check for RFID
      this.primaryValidation(/^\d{4,}$/);
    } else { // Validation Check for Barcode
      this.primaryValidation(/^[0-9A-Za-z]+$/);
    }

    if (this.addAccessControlForm.invalid) {
      this.isSubmit = true;
      if ((new Date(this.addAccessControlForm.value.end_date).getTime() / 1000) < (new Date(this.addAccessControlForm.value.start_date).getTime() / 1000)) {
        this.checkDate = true;
      }
      else {
        this.checkDate = false;
      }
      return;
    } else {
      let reqObj = {
        "enclosure_id": this.enclosure_id,
        "access_control_name": this.addAccessControlForm.value.access_control_name,
        "primary_auth_type": this.addAccessControlForm.value.primary_auth_type,
        "primary_auth_key": this.addAccessControlForm.value.primary_auth_key,
        "isSecondary": this.addAccessControlForm.value.isSecondary ? this.addAccessControlForm.value.isSecondary : false,
        "secondary_auth_type": this.addAccessControlForm.value.isSecondary ? this.addAccessControlForm.value.secondary_auth_type : null,
        "secondary_auth_key": this.addAccessControlForm.value.isSecondary ? this.addAccessControlForm.value.secondary_auth_key : '',
        "start_date": new Date(this.addAccessControlForm.value.start_date).getTime() / 1000,
        "end_date": new Date(this.addAccessControlForm.value.end_date).getTime() / 1000,
        "finite_uses": this.addAccessControlForm.value.finite_uses,
        "uses_remaining": this.addAccessControlForm.value.uses_remaining ? this.addAccessControlForm.value.uses_remaining : '',
        "actuator_type": this.addAccessControlForm.value.actuator_type,
      }
      this.isLoading = true;
      this.service.AddAccessControl(reqObj).subscribe((res: any) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.addAccessControlForm.reset();
          this.ngxSmartModalService.getModal('addAccessControlModal').close();
          this.GetAccessControl();
        }
        else {
          this.isLoading = false;
          this.toastr.error(res['msg'], '');
        }
      })
    }
  }

  EditAccessControlModal(content: any) {
    this._id = content._id;
    if (content.parent_id == null) {
      this.p_id = content._id;
    } else {
      this.p_id = content.parent_id
    }
    this.ngxSmartModalService.getModal('editAccessControlModal').open();
    this.editAccessControlForm.reset();
    this.isSubmit = false;
    this.GetAccessControlType();
    this.GetAccessControlById();
  }

  EditAccessControl() {
    if (this.checkFiniteUses === false) {
      this.editAccessControlForm.get("uses_remaining").reset();
      this.editAccessControlForm.controls["uses_remaining"].clearValidators();
      this.editAccessControlForm.controls["uses_remaining"].setErrors(null);
    } else {
      this.editAccessControlForm.controls["uses_remaining"].setValidators([Validators.required, Validators.min(1)]);
    }
    if (this.editAccessControlForm.invalid) {
      if ((new Date(this.editAccessControlForm.value.end_date).getTime() / 1000) < (new Date(this.editAccessControlForm.value.start_date).getTime() / 1000)) {
        this.checkDate = true;
      }
      else {
        this.checkDate = false;
      }
      this.isSubmit = true;
      return;
    } else {
      let reqObj = {
        "enclosure_id": this.enclosure_id,
        "start_date": new Date(this.editAccessControlForm.value.start_date).getTime() / 1000,
        "end_date": new Date(this.editAccessControlForm.value.end_date).getTime() / 1000,
        "finite_uses": this.editAccessControlForm.value.finite_uses,
        "uses_remaining": this.editAccessControlForm.value.uses_remaining ? this.editAccessControlForm.value.uses_remaining : '',
        "actuator_type": this.editAccessControlForm.value.actuator_type,
        "parent_id": this.p_id
      }
      this.isLoading = true;
      this.service.EditAccessControl(reqObj, this._id).subscribe((res: any) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.editAccessControlForm.reset();
          this.ngxSmartModalService.getModal('editAccessControlModal').close();
          this.GetAccessControl();
        }
        else {
          this.isLoading = false;
          this.toastr.error(res['msg'], '');
          this.GetAccessControl();
        }
      })
    }
  }

  DeleteAccessControlModal(content: any) {
    this._id = content._id;
    this.ngxSmartModalService.getModal('deleteAccessControlModal').open();
  }

  DeleteAccessControl() {
    let reqObj = {
      enclosure_id: this.enclosure_id
    }
    this.service.DeleteAccessControl(reqObj, this._id).subscribe((result: any) => {
      if (result['status'] == 1) {
        this.toastr.success(result['msg'], '');
        this.GetAccessControl();
        this.ngxSmartModalService.getModal('deleteAccessControlModal').close();
      } else {
        this.toastr.error(result['msg'], '');
        this.GetAccessControl();
        this.ngxSmartModalService.getModal('deleteAccessControlModal').close();
      }
    });
  }

  CancelModal() {
    this.ngxSmartModalService.getModal('deleteAccessControlModal').close();
  }

  isSecondaryCheck(event: any) {
    if (event.checked == true) {
      this.checkSecAuth = true;
      this.addAccessControlForm.controls["secondary_auth_type"].setValidators([Validators.required]);
      this.addAccessControlForm.controls["secondary_auth_key"].setValidators([Validators.required]);
    } else {
      this.checkSecAuth = false;
      this.addAccessControlForm.get("secondary_auth_type").reset();
      this.addAccessControlForm.controls["secondary_auth_type"].clearValidators();
      this.addAccessControlForm.controls["secondary_auth_type"].setErrors(null);
      this.addAccessControlForm.get("secondary_auth_key").reset();
      this.addAccessControlForm.controls["secondary_auth_key"].clearValidators();
      this.addAccessControlForm.controls["secondary_auth_key"].setErrors(null);
      this.primaryDisable = '';
    }
  }

  isFiniteCheck(event: any) {
    if (event.value == 'Y') {
      this.checkFiniteUses = true;
      this.addAccessControlForm.controls["uses_remaining"].setValidators([Validators.required, Validators.min(1)]);
      this.editAccessControlForm.controls["uses_remaining"].setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.addAccessControlForm.get("uses_remaining").reset();
      this.addAccessControlForm.controls["uses_remaining"].clearValidators();
      this.addAccessControlForm.controls["uses_remaining"].setErrors(null);
      this.editAccessControlForm.get("uses_remaining").reset();
      this.editAccessControlForm.controls["uses_remaining"].clearValidators();
      this.editAccessControlForm.controls["uses_remaining"].setErrors(null);
    }
  }

  viewAccessControlHistory() {
    this.router.navigate(['/app/access-control-history/' + btoa(this.enclosure_id) + '/' + btoa('all')]);
  }

  viewAccessControlHistoryById(content: any) {
    if (content.parent_id) {
      this.router.navigate(['/app/access-control-history/' + btoa(this.enclosure_id) + '/' + btoa(content.parent_id)]);
    } else {
      this.router.navigate(['/app/access-control-history/' + btoa(this.enclosure_id) + '/' + btoa(content._id)]);
    }
  }

  onPrimaryChange(event: any) {
    this.secondaryDisable = event.value;
  }

  onSecondaryChange(event: any) {
    this.primaryDisable = event.value;
  }

  primaryValidation(pattern: any) {
    this.addAccessControlForm.get('primary_auth_key').setValidators([Validators.required, Validators.pattern(pattern)]);
    this.addAccessControlForm.get('primary_auth_key').updateValueAndValidity();
  }

  secondaryValidation(pattern: any) {
    this.addAccessControlForm.get('secondary_auth_key').setValidators([Validators.required, Validators.pattern(pattern)]);
    this.addAccessControlForm.get('secondary_auth_key').updateValueAndValidity();
  }

  bluetoothFormat() {
    if (this.addAccessControlForm.value.secondary_auth_type === '6512a0cebb4b467e12f6a765') { // Validation Check for Bluetooth
      let input = this.addAccessControlForm.get('secondary_auth_key');
      let value: string = input.value;
      input.setValue(value.toUpperCase().replace(/[^0-9a-fA-F]/g, '').replace(/(\w{2})(?=\w)/g, '$1:'), { emitEvent: false });
    }
  }
}
