/**
 * @Author: Jitesh Prajapati <jiteshprajapati>
 * @Date:   2021-03-18T15:28:19+05:30
 * @Email:  jitesh.prajapati@teksun.com
 * @Filename: parcel-delivery.component.ts
 * @Last modified by:   Jitesh_Prajapati
 * @Last modified time: 2021-03-24T10:56:07+05:30
 * @Copyright: Teksun
 */



import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { ParcelDeliveryService } from './parcel-delivery.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Socket } from 'ngx-socket-io';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import jwt_decode from "jwt-decode";
import { Location } from '@angular/common';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-parcel-delivery',
  templateUrl: './parcel-delivery.component.html'
})
export class ParcelDeliveryComponent implements OnInit {
  public userData: any;
  constructor(
    public route: ActivatedRoute,
    private socket: Socket,
    public router: Router,
    public service: ParcelDeliveryService,
    private toastr: ToastrService,
    private _location: Location, public ngxSmartModalService: NgxSmartModalService

  ) { }
  public encname: any;
  public enclosure_id: any;
  public dataSourceEmpty: any = false;

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator; // for mat-paginator
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['position', 'delivery_id', 'parcel_point_origin', 'parcel_status', 'actions'];

  ngOnInit(): void {
    const token = sessionStorage.getItem('token');
    if (token) {
      this.userData = jwt_decode(token);
    }
    this.enclosure_id = atob(this.route.snapshot.params.eid);
    this.getAll();
    this.socket.on('receive_data_from_cloud', (cloud) => {
      if (cloud.type && cloud.type.includes('req_validate_params')) {
        if (cloud.parcel_status == 2) {
          this.approval(cloud.parcel_id, 1);
        }
        else {
          this.approval(cloud.parcel_id, 0);
        }
      }
      if (cloud.type && cloud.type.includes('req_open_drone_door')) {
        if (cloud.drone_door_status == 1) {
          this.droneDoorOpened(cloud.parcel_id, 1);
        }
        else {
          this.droneDoorOpened(cloud.parcel_id, 0);
        }
      }
      if (cloud.type && cloud.type.includes('req_parcel_delivered_or_not')) {
        if (cloud.parcel_status == 1) {
          this.parcelDelivered(cloud.parcel_id, 1);
        }
        else {
          this.parcelDelivered(cloud.parcel_id, 0);
        }
      }
      this.getAll();
    })
  }
  backClicked() {
    this._location.back();
  }
  public specificUserData: any;
  openParcelModal(element) {
    this.ngxSmartModalService.getModal('viewparcel').open();
    this.specificUserData = element;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.dataSourceEmpty = true;
    } else {
      this.dataSourceEmpty = false;
    }
  }

  goToEncSettings() {
    this.router.navigate(['/app/enclosure-details/' + this.route.snapshot.params['eid']]);
  }

  public alldata = [];
  getAll() {
    this.service.getAll(this.enclosure_id).subscribe(res => {
      this.alldata = res['data'];
      if (res['status'] == 1) {
        if (res['data'].length == 0) {
          if (res['data'].length == 0) {
            this.dataSourceEmpty = true;
          }
        }
        this.dataSource = new MatTableDataSource();
        this.dataSource.data = res['data'];
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  droneDoorOpened(oid, status) {
    this.service.sendDroneDoorStatus({ parcel_id: oid, status: status }).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        this.getAll();
      }
    });
  }
  approval(oid, status) {
    this.service.deliveryApproval({ parcel_id: oid, status: status }).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        this.getAll();
      }
    });
  }
  parcelDelivered(oid, status) {
    this.service.parcelDelivered({ parcel_id: oid, status: status }).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        this.getAll();
      }
    });
  }
}
