<div class="auth resetpassword response">
    <div class="auth-wrap">
        <div class="auth-block">
            <h1 class="auth-title" [ngClass]="(forwardDetail.status == 0)?'error':'success'">{{msg}}</h1>
            <span *ngIf="forwardDetail && forwardDetail != ''">
                <p class="auth-text">
                    Device id:
                    <strong>{{forwardDetail.data?.enclosure_id}}</strong>
                </p>
                <p class="auth-subtext" *ngIf="forwardDetail.data?.request_status == 0">Status: 
                    <span class="auth-subinner-text res-pending">Pending</span>
                </p>
                <p class="auth-subtext" *ngIf="forwardDetail.data?.request_status == 1">Status: 
                    <span class="auth-subinner-text res-accepted">Accepted</span>
                </p>
                <p class="auth-subtext" *ngIf="forwardDetail.data?.request_status == 2">Status: 
                    <span class="auth-subinner-text res-rejected">Rejected</span>
                </p>
                <p class="auth-subtext" *ngIf="forwardDetail.data?.request_status == 3">Status: 
                    <span class="auth-subinner-text res-completed">Completed</span>
                </p>
                <p class="auth-subtext" *ngIf="forwardDetail.data?.request_status == 4">Status: 
                    <span class="auth-subinner-text res-deleted">Deleted</span>
                </p>
                <p class="auth-duration">From: 
                    <strong>{{forwardDetail.data?.forward_start_time*1000 | date:'d MMM, y h:mm a'}}</strong>
                </p>
                <p class="auth-duration">Until: 
                    <strong>{{forwardDetail.data?.forward_end_time*1000 | date:'d MMM, y h:mm a'}}</strong>
                </p>
            </span>
        </div>
    </div>
</div>
