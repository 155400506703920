{{ errMsg }}
<div class="auth resetpassword">
  <div class="auth-wrap">
    <div class="auth-block">
      <div class="auth-logo">
        <img src="assets/images/logo-dronedek.png" alt="" />
      </div>
      <h2 class="auth-title">Reset Your Password</h2>
      <p class="auth-subtitle">
        You are changing password of account linked with following email
        address.
        <span class="auth-email">{{ email }}</span>
      </p>
      <form
        #resetPwdForm1="ngForm"
        (ngSubmit)="doReset(resetPwdForm1)"
        class="auth-form changepassword-form"
        [mustMatch]="['user_password', 'cpassword']"
      >
        <label>New Password <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input
            minlength="6"
            pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*"
            maxlength="25"
            matInput
            placeholder="Ex. qk*%yEn8Q"
            [type]="pwdHide ? 'password' : 'text'"
            name="user_password"
            #user_password="ngModel"
            [(ngModel)]="service.resetPasswordFormData.user_password"
            [ngClass]="{
              'is-invalid':
                !user_password.valid &&
                (user_password.dirty || user_password.touched || isSubmit)
            }"
            required
          />
          <mat-error
            *ngIf="!user_password.valid && (user_password.dirty || isSubmit)"
          >
            <div *ngIf="user_password.errors.required">
              Please enter new password
            </div>
            <div *ngIf="user_password.errors.minlength">
              Have at least 6 characters
            </div>
            <div
              *ngIf="
                !user_password.errors.minlength && user_password.errors.pattern
              "
            >
              Include at least 1 uppercase letter, 1 lowercase letter, 1 number
              or 1 special char
            </div>
          </mat-error>
          <mat-icon matSuffix (click)="pwdHide = !pwdHide"
            >{{ pwdHide ? "visibility_off" : "visibility" }}
          </mat-icon>
        </mat-form-field>
        <label>Confirm Password <sup>*</sup></label>
        <mat-form-field floatLabel="never" appearance="standard">
          <input
            (blur)="onKey($event, service.resetPasswordFormData.user_password)"
            matInput
            placeholder="Ex. qk*%yEn8Q"
            [type]="cnfPwdHide ? 'password' : 'text'"
            name="cpassword"
            #cpassword="ngModel"
            [(ngModel)]="service.resetPasswordFormData.cpassword"
            [ngClass]="{
              'is-invalid':
                !cpassword.valid &&
                (cpassword.dirty || cpassword.touched || isSubmit)
            }"
            required
          />
          <mat-error *ngIf="cpassword.invalid" class="invalid-feedback">
            <div *ngIf="cpassword.errors.required">
              Confirm Password is required
            </div>
            <div *ngIf="cpassword.errors.mustMatch">
              Passwords must match with new password
            </div>
          </mat-error>
          <mat-icon matSuffix (click)="cnfPwdHide = !cnfPwdHide"
            >{{ cnfPwdHide ? "visibility_off" : "visibility" }}
          </mat-icon>
        </mat-form-field>
        <button [disabled]="isLoading" class="auth-btn mt-1">Update</button>
      </form>
    </div>
  </div>
</div>
