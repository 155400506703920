/**

@Author: Nidhi Bhatt
@Date: 2021-03-09T15:24:52+05:30
@Email: nidhi.bhatt@teksun.com
@Last modified by: Nidhi Bhatt
@Last modified time: 2021-03-18T16:47:47+05:30
@Copyright: Teksun */
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventEmitterService } from '../event_emmiter_service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styles: [
  ]
})

export class ProfileComponent implements OnInit {
  hide = true;
  nhide = true;
  chide = true;
  isSubmit: true;
  isLoading: boolean = false;
  public phoneError: any = false;
  separateDialCode = true;

  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  countrySortName: any = 'US';
  public phno: any;
  public userMobile: any;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });

  constructor(private _location: Location, private toastr: ToastrService, public service: AuthService, public router: Router, public eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {
    this.resetForm();
    this.getLoggedinUser();
  }
  resetForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.UpdateProfileFormData = {
      user_name: '',
      user_mobile: '',
      user_email: '',
      user_country_code: ''
    };
  }
  backClicked() {
    this._location.back();
  }
  checkNumber(event: any, phoneNumber) {
    if (phoneNumber != null) {
      let Data: any = phoneNumber;
      //https://stackoverflow.com/questions/54899200/which-event-will-work-in-ngx-intl-tel-input-in-angular
      if (Data.internationalNumber != '') {
        this.service.UpdateProfileFormData.user_mobile = Data.internationalNumber;
      }
    }
  }
  getLoggedinUser() {
    this.service.getLoggedinUser().subscribe(res => {
      if (res['status'] == 1) {
        let uData = res['data'];
        if (res['data'].user_mobile == undefined) {
          res['data'].user_mobile = ''
        }
        this.service.UpdateProfileFormData.user_email = uData.user_email;
        this.service.UpdateProfileFormData.user_name = uData.user_name;
        this.userMobile = uData.user_mobile;
        this.service.UpdateProfileFormData.user_mobile = this.userMobile;
        this.service.UpdateProfileFormData.user_country_code = uData.user_country_code;
      } else {
        this.toastr.error(res['msg'], '');
        sessionStorage.removeItem('token');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 3000);
      }
    });
  }
  updateProfile(phoneInvalid, form?: NgForm) {
    if (phoneInvalid == true) {
      this.phoneError = true;
      this.isLoading = false;
      return;
    }
    else {
      this.phoneError = false;
    }
    if (!form.valid) {
      this.isSubmit = true;
      this.isLoading = false;
      return;
    }
    else {
      this.isLoading = true;
      form.value.user_country_code = form.value.user_mobile.countryCode;
      form.value.user_mobile = form.value.user_mobile.number;
      this.service.updateProfile(form.value).subscribe((res) => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.isLoading = false;
          this.eventEmitterService.onProfileUpdate(form.value);
        }
        else {
          this.isLoading = false;
          this.toastr.error(res['msg'], '');
        }
      })
    }
  }
}