import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { AccessControlHistoryService } from '../access-control-history/access-control-history.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-access-control-history',
  templateUrl: './access-control-history.component.html'
})

export class AccessControlHistoryComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator; // for mat-paginator
  _id: any;
  loggeedinUserId: any;
  deletedTypeId: any;
  checkList: any;
  public singleAccessControlData: any;
  public enclosure_id: any;
  public parent_id: any;
  nameCheck = false;
  public dataSourceEmpty: any = false;
  public enclosure_details: any = {};
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['position', 'access_control_name', 'primary_auth_type', 'secondary_auth_type', 'status', 'start_date', 'end_date', 'uses_remaining', 'actuator_type', 'actions'];

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private _location: Location,
    public service: AccessControlHistoryService,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (atob(this.route.snapshot.params.pid) == 'all') {
      this.enclosure_id = atob(this.route.snapshot.params.eid);
      this.AccessControlHistory();
    }
    else {
      this.parent_id = atob(this.route.snapshot.params.pid);
      this.AccessControlHistoryById();
    }
  }

  backClicked() {
    this._location.back();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.dataSourceEmpty = true;
    }
    else {
      this.dataSourceEmpty = false;
    }
  }

  AccessControlHistory() {
    this.service.AccessControlHistory(this.enclosure_id).subscribe(res => {
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = res['data'];
      if (res['data'].length == 0) {
        this.dataSourceEmpty = true;
      }
      else {
        this.dataSourceEmpty = false;
      }
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  AccessControlHistoryById() {
    this.service.AccessControlHistoryById(this.parent_id).subscribe(res => {
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = res['data'];
      if (res['data'].length == 0) {
        this.dataSourceEmpty = true;
      }
      else {
        this.dataSourceEmpty = false;
      }
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  ViewAccessControlModal(content: any) {
    this._id = content._id;
    this.GetAccessControlById();
    this.ngxSmartModalService.getModal('viewAccessControlModal').open();
  }

  GetAccessControlById() {
    this.service.GetAccessControlById(this._id).subscribe(res => {
      if (res['status'] == 1) {
        this.singleAccessControlData = res['data'];
      }
      else {
        this.AccessControlHistory();
      }
    });
  }
}
