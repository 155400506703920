import { Component, OnInit } from '@angular/core';
import { AuthService } from './../auth.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: [
  ]
})
export class RegisterComponent implements OnInit {

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  countrySortName: any = 'US';
  public phno: any;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });
  public submitted: boolean = false;

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  onSubmit() {
    this.submitted = true;
    if (this.phoneForm.valid) {
      this.submitted = false;
    }
  }

  reset() {
    this.phoneForm.reset();
    this.submitted = false;
  }

  hide = true;
  chide = true;
  public isSubmit: boolean;
  confirmPwdErr: any = false;
  public isLoading: boolean = false;

  constructor(public service: AuthService, public router: Router, private toastr: ToastrService) { }
  ngOnInit(): void {
    this.resetForm();
  }

  public phoneError: any = false;
  doRegister(phoneInvalid, form?: NgForm) {
    if (phoneInvalid == true) {
      this.phoneError = true;
      this.isLoading = false;
    }
    else {
      this.phoneError = false;
    }
    if (!form.valid || form.value.user_password != form.value.user_confirm_password) {
      if (form.value.user_password != form.value.user_confirm_password) {
        this.confirmPwdErr = true;
      }
      else {
        this.confirmPwdErr = false;
      }
      this.isSubmit = true;
      this.isLoading = false;
      return;
    }
    else {
      this.isLoading = true;
      if (form.value.user_mobile.number) {
        form.value.user_country_code = form.value.user_mobile.countryCode;
        form.value.user_mobile = form.value.user_mobile.number;
      }
      form.value.register_from = 'web';
      this.service.doRegister(form.value).subscribe((res) => {
        if (res['status'] == 1) {
          this.isLoading = false;
          this.toastr.success(res['msg'], '');
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 3000);
        }
        else {
          this.isLoading = false;
          this.toastr.error(res['msg'], '');
        }
        this.isSubmit = false;
      })
    }
  }
  checkNumber(event: any, phoneNumber) {
    if (phoneNumber != null) {
      let Data: any = phoneNumber;
      //https://stackoverflow.com/questions/54899200/which-event-will-work-in-ngx-intl-tel-input-in-angular                 
      if (Data.internationalNumber != '') {
        this.service.RegisterFormData.user_mobile = Data.internationalNumber;
      }
    }
  }
  onKey(event: any, pwd) { // without type info
    if (event.target.value && event.target.value != '') {
      if (event.target.value === pwd) {
        this.confirmPwdErr = false;
      } else {
        this.confirmPwdErr = true;
      }
    }
  }
  resetForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.RegisterFormData = {
      user_email: '',
      user_password: '',
      user_name: '',
      user_confirm_password: '',
      user_mobile: '',
      terms_condition: ''
    };
  }
}
