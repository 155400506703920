import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedAccessService {
  constructor(private http: HttpClient) { }
 // tblType == 1 history and 2 is temp tbl
  getAccessViewByUserid(id,tblType) {
    return this.http.get(environment.api_url + '/access/'+id+'/'+tblType);
  }
  deleteAccess(temp_id){
    return this.http.delete(environment.api_url + '/access/'+temp_id);
  }
  permanentDeleteAccess(temp_id,uaccessid,uaccess){
    return this.http.delete(environment.api_url + '/access/permanent/'+temp_id+'/'+uaccessid+'/'+uaccess);
  }
  checkOTP(data){
    return this.http.post(environment.api_url + '/access/one-time/verify-otp', data);
  }
  checkOtaEnclosure(eid){
    return this.http.get(environment.api_url + '/access/one-time/verify-link/'+eid);
  }
}


