import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ForwardDeliveryListService } from '../forward-delivery-list/forward-delivery-list.service';
import { AuthService } from '../auth/auth.service';
import { EnclosureDetailsService } from '../enclosure-details/enclosure-details.service';

@Component({
  selector: 'app-forward-delivery-list',
  templateUrl: './forward-delivery-list.component.html',
  styles: [
  ]
})
export class ForwardDeliveryListComponent implements OnInit {
  @ViewChild('deleteOutgoingForwardForm', { static: true }) deleteOutgoingForwardForm: NgForm;


  constructor(private toastr: ToastrService, public ngxSmartModalService: NgxSmartModalService, private _location: Location, public authService: AuthService, public router: Router, public service: ForwardDeliveryListService, public serviceEnc: EnclosureDetailsService, public route: ActivatedRoute,) { }
  public enclosure_id: any;
  public user_access: any;
  public type: any;
  public type_delivery: any;
  public owneruid: any;
  public enclosure_details: any;
  public forwarded_deliveries: any;
  public isDelForwardSubmit: boolean = false;
  public isDelForwardLoading: boolean = false;
  public out_forwarded_deliveries: any;
  public in_forwarded_deliveries: any;

  ngOnInit(): void {
    this.enclosure_id = atob(this.route.snapshot.params.eid);
    this.user_access = atob(this.route.snapshot.params.access);
    this.type = atob(this.route.snapshot.params.type);
    if (this.type == 'Incoming') {
      this.type_delivery = 'Incoming';
    } else {
      this.type_delivery = 'Outgoing';
    }

    this.owneruid = atob(this.route.snapshot.params.owneruid);
    this.getEnclosureDetail(this.enclosure_id);
    /*if(this.type == 'Incoming'){
      this.getInForwardedDeliveries(this.enclosure_id);
    }else{
      this.getOutForwardedDeliveries(this.enclosure_id);
    }*/
    this.getForwardedDeliveries(this.enclosure_id);
    //this.getOutForwardedDeliveries(this.enclosure_id);
    this.reseOutgoingForwardForm();
    this.getLoggedinUser();
  }
  backClicked() {
    this._location.back();
  }
  getLoggedinUser() {
    this.authService.getLoggedinUser().subscribe(res => {
      if (res['status'] == 1) {
        let uData = res['data'];
        this.authService.UpdateProfileFormData = uData;
      }
      else {
        this.toastr.error(res['msg'], '');
        sessionStorage.removeItem('token');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 3000);
      }
    });
  }
  public del_id: any;
  openDeleteForwardModal(id) {
    this.del_id = id;
    this.deleteOutgoingForwardForm.resetForm();
    this.ngxSmartModalService.getModal('deleteoutgoingforward').open();
  }
  //Start history
  forwardedDeliveriesList(type, len) {
    this.router.navigate(['/app/forward-delivery-list/history/' + btoa(this.enclosure_id) + '/' + btoa(this.user_access) + '/' + btoa(type) + '/' + btoa(this.owneruid)]);
  }
  // getInForwardedDeliveries(eid) {
  //   console.log(this.enclosure_details, "enc detail");
  //   //console.log('/app//' + btoa(this.enclosure_id) + '/' + btoa(this.user_access) + '/' + btoa(this.enclosure_details.enclosure_name),"ENC ATOB");
  //   const send_data = { enclosure_id: eid, owner_user_id: this.owneruid };
  //   this.serviceEnc.getInForwardedDeliveries(send_data).subscribe((res) => {
  //     console.log(res,"===========================================Inforward",res['data'].length)
  //     if (res['status'] == 1) {
  //       this.in_forwarded_deliveries = res['data'].length;
  //       this.forwarded_deliveries=res['data'].length;
  //     }
  //     else {
  //       this.in_forwarded_deliveries = 0;
  //       this.forwarded_deliveries=res['data'].length;

  //     }
  //   })
  // }
  // getOutForwardedDeliveries(eid) {
  //   const send_data = { enclosure_id: eid, owner_user_id: this.owneruid };
  //   this.serviceEnc.getOutForwardedDeliveries(send_data).subscribe((res) => {
  //     console.log(res,"===========================================Outforward",res['data'].length)

  //     if (res['status'] == 1) {
  //       this.out_forwarded_deliveries = res['data'].length;
  //       this.forwarded_deliveries=res['data'].length;
  //     }
  //     else {
  //       this.out_forwarded_deliveries = 0;
  //       this.forwarded_deliveries=res['data'].length;

  //     }
  //   })
  // }
  //End History
  getEnclosureDetail(eid) {
    this.serviceEnc.getEncViewById(eid).subscribe((res) => {
      if (res['status'] == 1) {
        this.enclosure_details = res['data'];
      }
    })
  }
  getForwardedDeliveries(eid) {
    const send_data = { enclosure_id: eid, owner_user_id: (this.owneruid), forward_type: 1 };
    /*
    forward_type 1 forward_delivery_mst
    forward_type 2 forward_delivery_msts_tmps

    */
    this.service.getForwardedDeliveries(send_data, this.type).subscribe((res) => {
      if (res['status'] == 1) {
        if (res['data'].length > 0) {
          this.forwarded_deliveries = res['data'];
        }
        else {
          this.forwarded_deliveries = [];
        }
      }
      else {
        this.forwarded_deliveries = [];
      }
    })
  }
  deleteDelivery(form?: NgForm) {
    if (!form.valid) {
      this.isDelForwardSubmit = true;
      this.isDelForwardLoading = false;
      return;
    }
    else {
      this.isDelForwardLoading = true;
      if (form.value.type_delete == 'DELETE') {
        this.service.deleteForwarding({ _id: this.del_id }).subscribe((res) => {
          this.ngxSmartModalService.getModal('deleteoutgoingforward').close();
          if (res['status'] == 1) {
            this.isDelForwardLoading = false;
            this.toastr.success(res['msg'], '');
            this.getForwardedDeliveries(this.enclosure_id);
          }
          else {
            this.isDelForwardLoading = false;
            this.toastr.error(res['msg'], '');
          }
        });
      }
      else if (form.value.type_delete != 'DELETE') {
        this.toastr.error('Please enter DELETE keyword in input!', '');
        this.isDelForwardLoading = false;
        return
      }
    }

  }
  reseOutgoingForwardForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.DeleteForwardingFormData = {
      type_delete: ''
    };
  }
  public pendingDetail: any;
  openPendingInfo(data) {
    this.pendingDetail = data;
    this.ngxSmartModalService.getModal('pendingInfo').open();
  }
}
