<mat-toolbar class="header">
  <button mat-icon-button id="menu" (click)="navService.openNav()">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="d-flex">
    <div class="header-wrap">
      <div class="header-profile-img">
        <img src="assets/images/profile.png" alt="">
      </div>
      <button mat-button [matMenuTriggerFor]="beforeMenu" class="header-username-btn">
        <div class="header-username-wrap">
          <span id="login_name">{{userData?.user_name}}</span>
          <span id="login_name1" *ngIf="userData?.user_last_login != 0">
            Last Login - {{userData?.user_last_login*1000 | date:'hh:mm a d MMM, y'}}
          </span>
        </div>
        <span class="material-icons">keyboard_arrow_down</span>
      </button>
      <mat-menu #beforeMenu="matMenu" xPosition="before" class="header-matmenu">
        <button mat-menu-item routerLink="/app/profile">
          <span class="material-icons">person</span>
          My Profile
        </button>
        <button mat-menu-item routerLink="/app/change-password">
          <span class="material-icons">https</span>
          Change Password
        </button>
        <button mat-menu-item routerLink="/app/notification-settings">
          <span class="material-icons">settings</span>
          Notification Settings
        </button>
        <button mat-menu-item (click)="getMetricsDetails()">
          <span class="material-icons">thermostat</span>
          Metrics Settings
        </button>
        <button mat-menu-item routerLink="/app/help">
          <span class="material-icons">help</span>
          Help
        </button>
        <button mat-menu-item (click)="ngxSmartModalService.getModal('logoutModal').open()">
          <span class="material-icons">logout</span>
          Logout
        </button>
      </mat-menu>
    </div>
    <div class="header-wrap">
      <button class="header-btn notification-btn mr-2" routerLink="/app/notifications">
        <img src="assets/images/notification.svg" alt="">
        <sup>{{notification_count}}</sup>
      </button>
    </div>
  </div>
</mat-toolbar>

<ngx-smart-modal #logoutModal identifier="logoutModal" [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal logout-modal">
  <h2 class="modal-logout-title">Logout</h2>
  <p class="modal-logout-text">You will be returned to the login screen.</p>
  <div class="modal-logout-bottom">
    <button (click)="doLogout()" class="btn-logout yes mr-2 pt-2">Yes</button>
    <button (click)="logoutModal.close()" class="btn-logout cancel pt-2">Cancel</button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal #metricsSettings identifier="metricsSettings" [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal metrics-modal">
  <h2 class="modal-title">Metrics Settings</h2>
  <form class="modal-form">
    <div class="modal-input modal-input-cursor" (click)="getTempDetails()">
      <label><em class="app-info-icon"><img src="assets/images/metrics-temperature.svg" alt=""></em> Temperature</label>
      <button>
        <span class="metrics-info"><sup>0</sup>{{selectedTempLatest | TempStatus:1:selectedSpaceLatest}}</span>
        <img src="assets/images/arrow.svg" alt="">
      </button>
    </div>
    <div class="modal-input modal-input-cursor" (click)="getSpaceDetails()">
      <label><em class="app-info-icon"><img src="assets/images/metrics-available-space.svg" alt=""></em> Available
        Space</label>
      <button>
        <span class="metrics-info">{{space_val}}</span>
        <img src="assets/images/arrow.svg" alt="">
      </button>
    </div>
  </form>
</ngx-smart-modal>

<ngx-smart-modal #metricsTemp identifier="metricsTemp" [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal metrics-modal">
  <h2 class="modal-title">Temperature</h2>
  <form class="modal-form" #metricsTempFormData="ngForm" (ngSubmit)="updateTempMetrics(metricsTempFormData)">
    <div class="modal-radio">
      <mat-radio-group aria-label="Select an option" class="modal-radio-group" #temperature="ngModel" name="temperature"
        [(ngModel)]="service.metricsTempFormData.temperature">
        <mat-radio-button value="CL"><sup>0</sup>C</mat-radio-button>
        <mat-radio-button value="FH"><sup>0</sup>F</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="modal-radio-buttons">
      <button class="modal-radio-btn modal-radio-save-btn">Save</button>
      <a class="modal-radio-btn modal-radio-cancel-btn" (click)="metricsTemp.close()">Cancel</a>
    </div>
  </form>
</ngx-smart-modal>

<ngx-smart-modal #metricsSpace identifier="metricsSpace" [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal metrics-modal">
  <h2 class="modal-title">Available Height</h2>
  <form class="modal-form" #metricsSpaceFormData="ngForm" (ngSubmit)="updateSpaceMetrics(metricsSpaceFormData)">
    <div class="modal-radio">
      <mat-radio-group aria-label="Select an option" class="modal-radio-group" #space="ngModel" name="space"
        [(ngModel)]="service.metricsSpaceFormData.space">
        <mat-radio-button value="CM">cm </mat-radio-button>
        <mat-radio-button value="IN">inch </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="modal-radio-buttons">
      <button class="modal-radio-btn modal-radio-save-btn">Save</button>
      <a class="modal-radio-btn modal-radio-cancel-btn" (click)="metricsSpace.close()">Cancel</a>
    </div>
  </form>
</ngx-smart-modal>
