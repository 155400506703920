/**
 * @Author: Jitesh Prajapati <Jitesh_Prajapati>
 * @Date:   2021-03-18T15:28:19+05:30
 * @Email:  jitesh.prajapati@teksun.com
 * @Filename: get_forward_delivery_status_pipe.ts
 * @Last modified by:   Jitesh_Prajapati
 * @Last modified time: 2021-03-24T10:29:05+05:30
 * @Copyright: Teksun INC <https://teksun.com/>
 */



import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'forwardDeliveryStatus' })

export class GetForwardDeliveryStatusPipe implements PipeTransform {

    transform(value: any) {
        if (value || value == 0) {
            if (value == 0) {
                return 'Pending';
            } else if (value == 1) {
                return 'Accepted';
            }
            else if (value == 2) {
                return 'Rejected';
            }
            else if (value == 3) {
                return 'Completed';
            }
            else if (value == 4) {
                return 'Deleted';
            }
            else if (value == 5) {
                return 'Auto Expired';
            }
            else if (value == 6) {
                return 'Default Expired';
            }
            else if (value == 7) {
                return 'On Going';
            }
            else if (value == 8) {
                return 'Auto Cancelled';
            }
            else {
                return '----';
            }
        } else {
            return '----';
        }

    }
}
