import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexLayoutModule } from "@angular/flex-layout";
import { NavService } from "./nav.service";
import { AppRoutingModule } from './app-routing.module';
import { MatRadioModule } from '@angular/material/radio';
import { AppComponent } from './app.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { DefaultComponent } from './default/default.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoverslideComponent } from './auth/coverslide/coverslide.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderService } from './services/loader.service';
import { AuthGuard } from './auth/auth-guard';
import { LoaderInterceptor } from './loader-interceptors/loader-interceptor.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { BatteryPercentageComponent } from './percentage-bar/percentage-bar.component';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { LiveTrackingComponent } from './live-tracking/live-tracking.component';
import { LiveTrackingIconComponent } from './live-tracking-icon/live-tracking-icon.component';

import { SharedAccessComponent } from './shared-access/shared-access.component';
import { SharedAccessHistoryComponent } from './shared-access-history/shared-access-history.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { AuthInterceptor } from './ensure';
import { EnclosureListComponent } from './enclosure-list/enclosure-list.component';
import { ForwardDeliveryResponseComponent } from './forward-delivery-response/forward-delivery-response.component';
import { UserverifyComponent } from './userverify/userverify.component';
import { OtaAccessComponent } from './ota-access/ota-access.component';
import { OtaAccessEncComponent } from './ota-access-enc/ota-access-enc.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';

import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { EnclosureDetailsComponent } from './enclosure-details/enclosure-details.component';
import { ForwardDeliveryListComponent } from './forward-delivery-list/forward-delivery-list.component';
import { ForwardDeliveryListHistoryComponent } from './forward-delivery-list-history/forward-delivery-list-history.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { GetUserAccessNamePipe } from './pipes/get_user_access_name_pipe';
import { GetForwardDeliveryStatusPipe } from './pipes/get_forward_delivery_status_pipe';
import { GetParcelDeliveryPipe } from './pipes/get_parcel_delivery.pipe';
import { TempStatus } from './pipes/get_temp.pipe';
import { parcelStatusPipe } from './pipes/parcel-status.pipe';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ToastrModule } from 'ngx-toastr';
import { ParcelDeliveryComponent } from './parcel-delivery/parcel-delivery.component';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { LoaderComponent } from './loader/loader.component'
import { environment } from '../environments/environment';
import { MapComponent } from './map/map.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MustMatchDirective } from './_helper/must-match.directive';
import { AccessControlComponent } from './access-control/access-control.component';
import { AccessControlHistoryComponent } from './access-control-history/access-control-history.component';
import { LiveVideoStreamingComponent } from './live-video-streaming/live-video-streaming.component';
import { UserDoorHistoryComponent } from './user-door-history/user-door-history.component';
import { ActuatorLogsComponent } from './actuator-logs/actuator-logs.component';

const config: SocketIoConfig = { url: environment.host, options: { 
    // withCredentials: true,
    path: environment.socket_path,
  } 
};

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
  declarations: [
    AppComponent,
    TopNavComponent,
    MenuListItemComponent,
    DefaultComponent,
    CoverslideComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    LiveTrackingComponent,
    LiveTrackingIconComponent,
    SharedAccessComponent,
    SharedAccessHistoryComponent,
    NotificationsComponent,
    ProfileComponent,
    ChangePasswordComponent,
    EnclosureListComponent,
    ResetpasswordComponent,
    EnclosureDetailsComponent,
    ForwardDeliveryListComponent,
    ForwardDeliveryListHistoryComponent,
    ForwardDeliveryResponseComponent,
    UserverifyComponent,
    OtaAccessComponent,
    OtaAccessEncComponent,
    GetUserAccessNamePipe,
    GetForwardDeliveryStatusPipe,
    GetParcelDeliveryPipe,
    TempStatus,parcelStatusPipe,
    ParcelDeliveryComponent,
    NotificationSettingsComponent,
    LoaderComponent,
    BatteryPercentageComponent,
    MapComponent,
    MustMatchDirective,
    AccessControlComponent,
    AccessControlHistoryComponent,
    LiveVideoStreamingComponent,
    UserDoorHistoryComponent,
    ActuatorLogsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatSelectModule,
    MatRadioModule,
    MatSlideToggleModule, MatTabsModule,
    FlexLayoutModule,
    FormsModule, ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSmartModalModule.forRoot(),
    MatTooltipModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatDatepickerModule,
    NgxMatMomentModule,
    NgxIntlTelInputModule,
    NgxMaskModule.forRoot(maskConfig),
    MatFormFieldModule,
    MatNativeDateModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      disableTimeOut: false,
      closeButton: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    SocketIoModule.forRoot(config)
  ],
  providers: [
    NgxSmartModalService,
    NavService,
    LoaderService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      // useClass: LoaderInterceptor
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      // useClass: LoaderInterceptor
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }