import { Component, OnInit } from '@angular/core';
import { MapService } from "./map.service";
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  constructor(public map: MapService) { }
  ngOnInit() {
    this.map.buildMap()
  }
}
