<div class="container-fluid" *ngIf="userMobile || userMobile == ''">
  <div class="main mx-2 mb-4 h-100">
    <div class="row">
      <div class="col-12">
        <h1 class="title-1 title-border-bottom mt-2">
          <button class="back-btn" (click)="backClicked()">
            <span class="material-icons">keyboard_arrow_left</span>
          </button>
          <em class="app-card-icon app-card-icon-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                fill="#0E48CA"
                d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
              />
            </svg>
          </em>
          Profile
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="app-card d-sm-inline-flex w-auto">
          <div class="app-card-body">
            <form
              #profileForm="ngForm"
              (ngSubmit)="
                updateProfile(
                  profileForm.form.controls['user_mobile']?.invalid,
                  profileForm
                )
              "
              class="auth-form changepassword-form"
            >
              <label>Email<sup>*</sup></label>
              <mat-form-field floatLabel="never" appearance="standard">
                <input
                  style="background: #f9f8f8"
                  maxlength="64"
                  matInput
                  readonly
                  name="user_email"
                  #user_email="ngModel"
                  [(ngModel)]="service.UpdateProfileFormData.user_email"
                />
              </mat-form-field>
              <label>Full Name<sup>*</sup></label>
              <mat-form-field floatLabel="never" appearance="standard">
                <input
                  minlength="3"
                  pattern="^[^\s].+[^\s]$"
                  maxlength="25"
                  matInput
                  placeholder="Ex. John Doe"
                  name="user_name"
                  #user_name="ngModel"
                  [(ngModel)]="service.UpdateProfileFormData.user_name"
                  [ngClass]="{
                    'is-invalid':
                      !user_name.valid &&
                      (user_name.dirty || user_name.touched || isSubmit)
                  }"
                  required
                />
                <mat-error
                  *ngIf="
                    !user_name.valid &&
                    (user_name.dirty || user_name.touched || isSubmit)
                  "
                >
                  <div *ngIf="user_name.errors.required">
                    Please enter full name
                  </div>
                  <div *ngIf="user_name.errors.minlength">
                    Have at least 3 characters
                  </div>
                  <div *ngIf="user_name.errors.maxlength">
                    name vvvvshould be maximum 25 characters
                  </div>
                  <div
                    *ngIf="
                      !user_name.errors.minlength && user_name.errors.pattern
                    "
                  >
                    You can not enter blank space after or before the input
                    value
                  </div>
                </mat-error>
              </mat-form-field>
              <label>Contact Number<sup>*</sup></label>
              <div class="phoneform phoneform-relative">
                <ngx-intl-tel-input
                  [cssClass]="'custom'"
                  [separateDialCode]="false"
                  [enableAutoCountrySelect]="false"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="
                    service.UpdateProfileFormData.user_country_code
                  "
                  [maxLength]="15"
                  [tooltipField]="TooltipLabel.Name"
                  [phoneValidation]="true"
                  name="user_mobile"
                  [(ngModel)]="service.UpdateProfileFormData.user_mobile"
                  required
                  (ngModelChange)="
                    checkNumber(
                      $event,
                      service.UpdateProfileFormData.user_mobile
                    )
                  "
                >
                </ngx-intl-tel-input>
                <div class="ngx-error">
                  <strong
                    *ngIf="
                      !(
                        profileForm.form.controls['user_mobile']?.invalid ==
                          true && phoneError == true
                      ) &&
                      profileForm.form.controls['user_mobile']?.touched &&
                      profileForm.form.controls['user_mobile']?.invalid == true
                    "
                    >Please enter valid contact number</strong
                  >
                  <strong
                    *ngIf="
                      profileForm.form.controls['user_mobile']?.invalid ==
                        true && phoneError == true
                    "
                    >Please enter valid contact number</strong
                  >
                </div>
              </div>
              <button [disabled]="isLoading" class="auth-btn">Update</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
