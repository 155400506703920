import { Component, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { VERSION } from '@angular/material/core';
import { NavItem } from '../nav-item';
import { NavService } from '../nav.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DefaultComponent implements AfterViewInit {

  @ViewChild('appDrawer') appDrawer: ElementRef;
  version = VERSION;
  navItems: NavItem[] = [
    {
      displayName: 'My Devices',
      iconName: 'list.svg',
      route: 'app/enclosure-list',
    },
  ];

  constructor(private navService: NavService) {
  }

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }
}