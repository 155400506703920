<div class="auth">
  <div class="auth-wrap">
    <div class="auth-block">
      <div class="auth-img"><img src="assets/images/arrive-map.png" alt="" /></div>
    </div>
    <div class="auth-block">
      <div class="auth-logo">
        <img src="assets/images/arrive_logo_black.png" alt="Logo" width="260" />
      </div>
      <h2 class="auth-title text-left w-100">Forgot your password? Enter your email below:</h2>
      <form
        #forgotPasswordForm="ngForm"
        (ngSubmit)="sendEmail(forgotPasswordForm)"
        class="auth-form align-self-start"
      >
        <div class="auth-form-body auth-form-login-body">
          <div class="auth-form-inner-body auth-form-login-inner-body">
            <label>Email Address <sup>*</sup></label>
            <mat-form-field floatLabel="never" appearance="standard">
              <input
                maxlength="64"
                pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                type="email"
                matInput
                placeholder="Ex. abc@gmail.com"
                name="user_email"
                #user_email="ngModel"
                [(ngModel)]="service.ForgotPasswordFormData.user_email"
                placeholder="Ex. abc@gmail.com"
                [ngClass]="{
                  'is-invalid':
                    !user_email.valid &&
                    (user_email.dirty || user_email.touched || isSubmit)
                }"
                required
              />
              <mat-error
                *ngIf="
                  !user_email.valid &&
                  (user_email.dirty || user_email.touched || isSubmit)
                "
              >
                <div *ngIf="user_email.errors.required">
                  Please enter email address
                </div>
                <div *ngIf="user_email.errors.pattern">
                  Enter valid email address
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <button [disabled]="isLoading" class="auth-btn">
          Send Password Recovery Link
        </button>
      </form>
      <p>Already have an account? <button routerLink="/login">Login</button></p>
    </div>
  </div>
</div>
