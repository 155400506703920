/**
 * @Author: Nidhi Bhatt
 * @Date:   2021-03-16T17:40:46+05:30
 * @Email:  nidhi.bhatt@teksun.com
 * @Last modified by:   Nidhi Bhatt
 * @Last modified time: 2021-03-18T16:48:42+05:30
 * @Copyright: Teksun
 */



import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { NotificationsService } from '../notifications/notifications.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styles: [
  ]
})
export class NotificationSettingsComponent implements OnInit {

  @ViewChild('appNotiFormData', { static: true }) appNotiFormData: NgForm;
  @ViewChild('emailNotiFormData', { static: true }) emailNotiFormData: NgForm;
  @ViewChild('smsNotiFormData', { static: true }) smsNotiFormData: NgForm;

  smsNotiCheckBox: boolean = false;
  public userData: any = '';

  constructor(
    public authService: AuthService,
    public notiService: NotificationsService,
    private toastr: ToastrService,
    public router: Router,
    private loaderService: LoaderService,
    private _location: Location,
  ) { }

  ngOnInit(): void {
    this.getAllNotification();
  }

  public FormData = { 'app_notification': true, 'app_notification_btn': true, 'email_notification': true, 'email_notification_btn': true, 'sms_notification': true, 'sms_notification_btn': true, 'app_notification_select_all': true, 'email_notification_select_all': true, 'sms_notification_select_all': true };
  getUserProfile() {
    this.getAllLogged();
  }
  getAllLogged() {
    this.authService.getLoggedinUser().subscribe(res => {
      if (res['status'] == 1) {
        this.userData = res['data'];
        this.FormData.app_notification = res['data']['app_notification'];
        this.FormData.email_notification = res['data']['email_notification'];
        this.FormData.sms_notification = res['data']['sms_notification'];

        //Button Event
        (res['data']['app_notification_data'].length > 0) ? this.FormData.app_notification_btn = true : this.FormData.app_notification_btn = false;
        (res['data']['email_notification_data'].length > 0) ? this.FormData.email_notification_btn = true : this.FormData.email_notification_btn = false;
        (res['data']['sms_notification_data'].length > 0) ? this.FormData.sms_notification_btn = true : this.FormData.sms_notification_btn = false;

        //Select All Button Event
        (res['data']['app_notification_data'].length == this.appNotiLength) ? this.FormData.app_notification_select_all = true : this.FormData.app_notification_select_all = false;
        (res['data']['email_notification_data'].length == this.emailNotiLength) ? this.FormData.email_notification_select_all = true : this.FormData.email_notification_select_all = false;
        (res['data']['sms_notification_data'].length == this.smsNotiLength) ? this.FormData.sms_notification_select_all = true : this.FormData.sms_notification_select_all = false;
        let getAppNotiData = res['data']['app_notification_data'];
        this.appNoti.forEach(element => {
          let checkIndex = getAppNotiData.indexOf(element.tnm_type);
          if (checkIndex > -1) { element.isChecked = true; } else { element.isChecked = false; }
        });

        let getEmailNotiData = res['data']['email_notification_data'];
        this.emailNoti.forEach(element => {
          let checkIndex = getEmailNotiData.indexOf(element.tnm_type);
          if (checkIndex > -1) { element.isChecked = true; } else { element.isChecked = false; }
        });

        let getSMSNotiData = res['data']['sms_notification_data'];
        this.smsNoti.forEach(element => {
          let checkIndex = getSMSNotiData.indexOf(element.tnm_type);
          if (checkIndex > -1) { element.isChecked = true; } else { element.isChecked = false; }
        });
      }
    });
  }

  backClicked() {
    this._location.back();
  }

  onTabChanged(event) {
    if (event.index == 0) {
      this.loaderService.isLoading.next(false);
      this.getAllLogged();
    }
    else if (event.index == 1) {
      this.loaderService.isLoading.next(false);
      this.getAllLogged();
    }
    else if (event.index == 2) {
      this.loaderService.isLoading.next(false);
      this.getAllLogged();
    }
  }

  public appNoti: any;
  public emailNoti: any;
  public smsNoti: any;
  public appNotiLength: Number;
  public emailNotiLength: Number;
  public smsNotiLength: Number;
  getAllNotification() {
    this.notiService.allNotification().subscribe(res => {
      if (res['status'] == 1) {
        this.appNoti = res['data']['App_data'];
        this.emailNoti = res['data']['Email_data'];
        this.smsNoti = res['data']['Sms_data'];
        this.appNotiLength = res['data']['App_data_length'];
        this.emailNotiLength = res['data']['Email_data_length'];
        this.smsNotiLength = res['data']['Sms_data_length'];
        this.getUserProfile();
      }
      else {
        this.toastr.error(res['msg'], '');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 3000);
      }
    });
  }

  updateAppNoti(FromData: NgForm) {

    let appData = [];
    this.appNoti.forEach(element => {
      if (element.isChecked == true) {
        appData.push(element.tnm_type);
      }
    });
    FromData.value.notification_status = FromData.value.app_notification;
    FromData.value.notification_data = appData;
    FromData.value.type = 'app';
    this.notiService.updateNoti(FromData.value).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
      }
      else {
        this.toastr.error(res['msg'], '');
      }
    });
  }

  appOnCheckBox(e: HTMLInputElement, id: number) {
    const index = this.appNoti.findIndex(_ => _.tnm_type === id);
    if (!(index > -1)) return;
    this.appNoti[index].isChecked = e.checked;
    let count = 0;
    this.appNoti.forEach(element => {
      if (element.isChecked == true) {
        count++;
      }
    });
    if (count == 0) {
      this.FormData.app_notification_btn = false;
      this.FormData.app_notification_select_all = false;
    }
    else if (count == this.appNotiLength) {
      this.FormData.app_notification_select_all = true;
    }
    else {
      this.FormData.app_notification_btn = true;
      this.FormData.app_notification_select_all = false;
    }
  }

  appChangeToggle(value) {
    this.notiService.updateNotiToggle({ type: 'app', notification_status: value }).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        this.FormData.app_notification = value;
      }
      else {
        this.toastr.error(res['msg'], '');
      }
    });
  }

  select_all(event, type) {
    if (type == 'app') {
      if (event == true) {
        this.appNoti.forEach(element => {
          element.isChecked = true;
        });
      }
      else {
        this.appNoti.forEach(element => {
          element.isChecked = false;
        });
      }
      let getSelectedAppcount = 0;
      this.appNoti.forEach(element => { if (element.isChecked == true) { getSelectedAppcount++; } });
      (getSelectedAppcount > 0) ? this.FormData.app_notification_btn = true : this.FormData.app_notification_btn = false;
    }

    if (type == 'email') {
      if (event == true) {
        this.emailNoti.forEach(element => {
          element.isChecked = true;
        });
      }
      else {
        this.emailNoti.forEach(element => {
          element.isChecked = false;
        });
      }
      let getSelectedEmailcount = 0;
      this.emailNoti.forEach(element => { if (element.isChecked == true) { getSelectedEmailcount++; } });
      (getSelectedEmailcount > 0) ? this.FormData.email_notification_btn = true : this.FormData.email_notification_btn = false;
    }

    if (type == 'sms') {
      if (event == true) {
        this.smsNoti.forEach(element => {
          element.isChecked = true;
        });
      }
      else {
        this.smsNoti.forEach(element => {
          element.isChecked = false;
        });
      }
      let getSelectedSmscount = 0;
      this.smsNoti.forEach(element => { if (element.isChecked == true) { getSelectedSmscount++; } });
      (getSelectedSmscount > 0) ? this.FormData.sms_notification_btn = true : this.FormData.sms_notification_btn = false;
    }

  }

  updateEmailNoti(FromData: NgForm) {
    let emailData = [];
    this.emailNoti.forEach(element => {
      if (element.isChecked == true) {
        emailData.push(element.tnm_type);
      }
    });
    FromData.value.notification_status = FromData.value.email_notification;
    FromData.value.notification_data = emailData;
    FromData.value.type = 'email';
    this.notiService.updateNoti(FromData.value).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
      }
      else {
        this.toastr.error(res['msg'], '');
      }
    });
  }

  emailOnCheckBox(e: HTMLInputElement, id: number) {
    const index = this.emailNoti.findIndex(_ => _.tnm_type === id);
    if (!(index > -1)) return;
    this.emailNoti[index].isChecked = e.checked;
    let count = 0;
    this.emailNoti.forEach(element => {
      if (element.isChecked == true) {
        count++;
      }
    });
    if (count == 0) {
      this.FormData.email_notification_btn = false;
      this.FormData.email_notification_select_all = false;
    }
    else if (count == this.emailNotiLength) {
      this.FormData.email_notification_select_all = true;
    }
    else {
      this.FormData.email_notification_btn = true;
      this.FormData.email_notification_select_all = false;
    }
  }

  emailChangeToggle(value) {
    this.notiService.updateNotiToggle({ type: 'email', notification_status: value }).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        this.FormData.email_notification = value;
      }
      else {
        this.toastr.error(res['msg'], '');
      }
    });
  }

  updateSmsNoti(FromData: NgForm) {
    let smsData = [];
    this.smsNoti.forEach(element => {
      if (element.isChecked == true) {
        smsData.push(element.tnm_type);
      }
    });
    FromData.value.notification_status = this.FormData.sms_notification;
    FromData.value.notification_data = smsData;
    FromData.value.type = 'sms';
    this.notiService.updateNoti(FromData.value).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
      }
      else {
        this.toastr.error(res['msg'], '');
      }
    });
  }

  smsOnCheckBox(e: HTMLInputElement, id: number) {
    const index = this.smsNoti.findIndex(_ => _.tnm_type === id);
    if (!(index > -1)) return;
    this.smsNoti[index].isChecked = e.checked;
    let count = 0;
    this.smsNoti.forEach(element => {
      if (element.isChecked == true) {
        count++;
      }
    });
    if (count == 0) {
      this.FormData.sms_notification_btn = false;
      this.FormData.sms_notification_select_all = false;
    }
    else if (count == this.smsNotiLength) {
      this.FormData.sms_notification_select_all = true;
    }
    else {
      this.FormData.sms_notification_btn = true;
      this.FormData.sms_notification_select_all = false;
    }
  }

  smsChangeToggle(e) {
    if (!this.FormData.sms_notification) {
      if (this.userData.user_mobile && this.userData.user_mobile != undefined) {
        this.FormData.sms_notification = !this.FormData.sms_notification;
        this.notiService.updateNotiToggle({ type: 'sms', notification_status: this.FormData.sms_notification }).subscribe(res => {
          if (res['status'] == 1) {
            this.toastr.success(res['msg'], '');
            this.FormData.sms_notification = this.FormData.sms_notification;
          }
          else {
            this.toastr.error(res['msg'], '');
          }
        });
      } else {
        e.source.checked = false;
        this.toastr.warning('Add your mobile number in your profile first!', '', { timeOut: 5000 });
        setTimeout(() => {
          this.router.navigate(['app/profile']);
        }, 4000);
      }
    } else {
      this.FormData.sms_notification = !this.FormData.sms_notification;
      this.notiService.updateNotiToggle({ type: 'sms', notification_status: this.FormData.sms_notification }).subscribe(res => {
        if (res['status'] == 1) {
          this.toastr.success(res['msg'], '');
          this.FormData.sms_notification = this.FormData.sms_notification;
        }
        else {
          this.toastr.error(res['msg'], '');
        }
      });
    }
  }
}
