import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';
import { NotificationsService } from '../notifications/notifications.service';
import { LoaderService } from '../services/loader.service';
import { EventEmitterService } from '../event_emmiter_service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styles: [
  ]
})
export class NotificationsComponent implements AfterViewInit {

  displayedNotificationColumns: string[] = ['message', 'date'];
  dataSourceNotificationData = new MatTableDataSource();
  constructor(
    private _location: Location,
    public service: NotificationsService,
    private loaderService: LoaderService,
    public eventEmitterService: EventEmitterService,
    public router: Router,
  ) { }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public noti_list: [];
  ngOnInit() {
    this.service.getnotifications().subscribe((res) => {
      if (res['status'] == 1) {
        this.dataSourceNotificationData.data = res['data'];
      }
    })
  }

  ngAfterViewInit() {
    this.dataSourceNotificationData.sort = this.sort;
    this.dataSourceNotificationData.paginator = this.paginator;
  }

  backClicked() {
    this._location.back();
  }

  public new_data = [];
  readNotification(data) {
    if (data.read == 0) {
      this.redirection(data);
      this.loaderService.isLoading.next(false);
      this.service.readNotification(data._id).subscribe((res) => {
        if (res['status'] == 1) {
          this.eventEmitterService.NotificationCount();
          let obj = this.dataSourceNotificationData.data.find(o => o['_id'] === data['_id']);
          obj['read'] = 1;
          this.dataSourceNotificationData.data = this.dataSourceNotificationData.data;
        }
      })
    }
    else {
      this.redirection(data);
    }
  }

  redirection(data) {
    this.loaderService.isLoading.next(false);
    switch (data.type) {
      case 'fm_low_battery': {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        break;
      }
      case 'fault_enc': {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        break;
      }
      case 'fm_error_drone_door': {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        break;
      }
      case 'fm_error_user_door': {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        break;
      }
      ///////
      case 'fm_error_user_door_lock': {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        break;
      }
      case 'fm_error_drone_door_lock': {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        break;
      }
      case 'fm_error_snow_melt_heater': {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        break;
      }
      case 'drone_out_delivery': {
        this.router.navigate(['/app/parcel-delivery-details/' + btoa(data.enclosure_id)]);
        //statements; 
        break;
      }
      case 'open_door_5_min': {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        break;
      }
      case 'enc_stack_full': {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        break;
      }
      case 'delivery_received': {
        this.router.navigate(['/app/parcel-delivery-details/' + btoa(data.enclosure_id)]);
        //statements; 
        break;
      }
      case 'enc_user_door_open': {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        break;
      }
      case 'enc_drone_door_open': {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        break;
      }
      case 'theft_detection_forcefully_door': {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        break;
      }
      default: {
        this.router.navigate(['/app/enclosure-details/' + btoa(data.enclosure_id)]);
        //statements; 
        break;
      }
    }
  }
}