import { Injectable } from '@angular/core';
import { EnclosureList} from '../enclosure-list/enclosure-list.modal';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EnclosureListService {
  EnclosureListFormData: EnclosureList;

  constructor(private http: HttpClient) { }
  getEnclosureListByUserId() {
    return this.http.get(environment.api_url + '/enclosures/');
  }
  addEclosure(data) {
    // data.enclosure_latitude='23.000000';
    // data.enclosure_longitude='23.000000';
      return this.http.post(environment.api_url + '/enclosures/', data);
  }
  openEdoor(edata){
    return this.http.put(environment.api_url + '/dashboard/open-door/'+edata.enclosure_id, edata);
  }
  closeEdoor(edata) {
    return this.http.put(environment.api_url + '/dashboard/close-door/' + edata.enclosure_id, edata);
  }
}
