<div class="auth resetpassword verified">
    <div class="auth-wrap">
        <div class="auth-block">

            <div *ngIf="msg?.status == 1" class="status-verified">
                <div class="auth-icon">
                    <span class="material-icons">verified_user</span>
                </div>
                <h1 class="auth-title">Email Verified</h1>
                <p class="auth-subtitle">Thanks for your Registration</p>
                <p class="auth-verify-title">Your email address has been verified successfully.
                    <span class="auth-email">Your account has been activated.</span>
                </p>
            </div>

            <div *ngIf="msg?.status == 0" class="status-unverified">
                <div class="auth-icon">
                    <span class="material-icons icon-red">error</span>
                </div>
                <p class="auth-subtitle">{{msg?.msg}}</p>
            </div>
        </div>
    </div>
</div>

