export const environment = {
  production: false,
  api_url:'https://user-api.dronedek.com/api/v1',
  host: 'https://user-api.dronedek.com',
  login_page:'https://user-api.dronedek.com/',
  Delivery_login : 'https://delivery.dronedek.com/',
  SuperAdmin_login:'https://superadmin.dronedek.com/',
  token_expire: 'Token has been expired! please try again',
  socket_path:'/socket.io',
  mapbox: {
    accessToken: 'U2FsdGVkX1+06CZPsejrs0NnI1vBqT5RRkNKX2lGz0bJkMwhDcuLmeBKkVv+tSSIsb4MXRiQhIbTQ+98+un7jgUTXHkDFZYGkF5tPxq5RVnY12T2pt70i8z9cdAJNuEa6Yjz8yKlaAB3XeekF/UJwj1lBOyzRu/Cf/3fkx00LBo='
  },
  image_logging_url: 'https://drondekimageaccess.s3.us-east-2.amazonaws.com/access_image_logs/',
  video_streaming_url: 'https://mediasrv.dronedek.com/stream/hls/'
};