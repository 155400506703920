import * as CryptoJS from 'crypto-js';
export class Message {
  static enc(text: any) {
    const enc = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      '12345678901234561234567890123456'
    ).toString();
    // return ciphertext
    return enc;
  }

  static dec(cipher: string) {
    const text = CryptoJS.AES.decrypt(
      cipher,
      '12345678901234561234567890123456'
    );
    // return plaintext JSON
    return JSON.parse(text.toString(CryptoJS.enc.Utf8));
  }

}
