import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ForwardDeliveryListService } from '../forward-delivery-list/forward-delivery-list.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-forward-delivery-response',
  templateUrl: './forward-delivery-response.component.html'
})
export class ForwardDeliveryResponseComponent implements OnInit {
  loading: boolean;

  constructor(private loaderService: LoaderService, private toastr: ToastrService, public route: ActivatedRoute, public router: Router, public service: ForwardDeliveryListService) {
    this.loaderService.isLoading.subscribe((val: boolean) => {
      this.loading = val;
    });
  }
  public msg: string;
  public loginUrl: any = environment.host + "/login";
  public approvalStatus: any = '';
  public forwardDetail: any = '';
  ngOnInit() {
    var token = this.route.snapshot.params.token;
    var eid = this.route.snapshot.params.enclosure_id;
    var status = this.route.snapshot.params.status;
    const expire_time = atob(this.route.snapshot.params.expire_time);
    const epochNow = Math.round((new Date).getTime() / 1000);
    if (epochNow < parseInt(expire_time)) {
      if (atob(status) == 'Accepted') {
        this.approvalStatus = 'Accepted';
      }
      else {
        this.approvalStatus = 'Rejected';
      }
      this.service.forwardDeliveryResponse(eid, status, token).subscribe(res => {
        const token = sessionStorage.getItem('token');
        if (res['detail'] == 'tk_used' || res['detail'] == 'tk_exp' || res['detail'] == 'success') {
          this.forwardDetail = res;
        }
        if (res['status'] == 1) {
          this.msg = res['msg'];
          this.toastr.success(res['msg'], '');
        } else {
          this.msg = res['msg'];
          this.toastr.error(res['msg'], '');
        }
      });
    } else {
      this.msg = environment.token_expire;
      this.toastr.error(environment.token_expire, '');
    }
  }
}
