<div class="container-fluid">
  <div class="main mx-2 mb-4">
    <div class="row">
      <div class="col-12">
        <div class="title-wrapper title-border-bottom mt-2">
          <h1 class="title-1">
            <button class="back-btn" (click)="backClicked()">
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
            <em class="app-card-icon app-card-icon-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="13.655"
                viewBox="0 0 18 13.655"
              >
                <defs>
                  <style>
                    .a {
                      fill: #e63946;
                    }
                  </style>
                </defs>
                <g transform="translate(0 -73.862)">
                  <g transform="translate(0 73.862)">
                    <path
                      class="a"
                      d="M1.862,73.862a1.862,1.862,0,1,0,1.862,1.862A1.862,1.862,0,0,0,1.862,73.862Zm0,4.965A1.862,1.862,0,1,0,3.724,80.69,1.862,1.862,0,0,0,1.862,78.827Zm0,4.966a1.862,1.862,0,1,0,1.862,1.862A1.862,1.862,0,0,0,1.862,83.793Zm4.966-6.828h9.931a1.241,1.241,0,0,0,0-2.483H6.828a1.241,1.241,0,1,0,0,2.483Zm9.931,2.483H6.828a1.241,1.241,0,1,0,0,2.483h9.931a1.241,1.241,0,0,0,0-2.483Zm0,4.966H6.828a1.241,1.241,0,1,0,0,2.483h9.931a1.241,1.241,0,1,0,0-2.483Z"
                      transform="translate(0 -73.862)"
                    />
                  </g>
                </g>
              </svg>
            </em>
            {{ type_delivery }} Forward Delivery
          </h1>
          <div class="sub-title-wrapper">
            <button
              (click)="
                forwardedDeliveriesList('Out Going', out_forwarded_deliveries)
              "
              *ngIf="type == 'Out Going'"
              class="view-btn"
            >
              <span class="material-icons">history</span>
              <span class="pt-1">View History</span>
            </button>
            <button
              (click)="
                forwardedDeliveriesList('Incoming', in_forwarded_deliveries)
              "
              *ngIf="type == 'Incoming'"
              class="view-btn"
            >
              <span class="material-icons">history</span>
              <span class="pt-1">View History</span>
            </button>
            <div class="encl-name-subtitle">
              {{ enclosure_details?.enclosure_name }}
            </div>
            <div class="title-access">
              <span
                class="access-card"
                [ngClass]="{
                  'access-green': user_access == 1,
                  'access-orange': user_access == 2,
                  'access-purple': user_access == 3,
                  'access-gray': user_access == 4
                }"
              >
                {{ user_access | accessNamePipe: user_access }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-eq-height">
      <div
        class="col-xl-3 col-lg-4 col-md-6 col-sm-6 app-box"
        *ngFor="let fwData of forwarded_deliveries"
      >
        <div class="app-card forwarddeliverylist-card">
          <div class="app-card-top forwarddeliverylist-card-top">
            <h2 class="app-card-title">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-end
                  flex-wrap
                  text-wrap
                "
              >
                <div class="text-title">{{ fwData?.user_name }}</div>
                <button
                  *ngIf="fwData?.request_status == 0"
                  class="warning-icon-btn"
                  (click)="openPendingInfo(fwData)"
                >
                  <span class="material-icons">warning_amber</span>
                </button>

                <button
                  (click)="openDeleteForwardModal(fwData.fwd_id)"
                  class="delete-icon-btn"
                  *ngIf="
                    fwData?.dType == 'OUT' &&
                    (fwData?.request_status == 1 ||
                      fwData?.request_status == 0 ||
                      fwData?.request_status == 7)
                  "
                >
                  <span class="material-icons">delete_outline</span>
                </button>
              </div>
              <div
                class="d-flex justify-content-between align-items-end flex-wrap"
              >
                <span>{{ fwData?.user_email }}</span>
                <p class="forwarddeliverylist-id" *ngIf="type == 'Incoming'">
                  {{ fwData?.enclosure_id }}
                </p>
                <p class="forwarddeliverylist-id" *ngIf="type != 'Incoming'">
                  {{ fwData?.forward_enclosure_id }}
                </p>
              </div>
            </h2>
          </div>
          <div class="app-card-body">
            <div class="enclosurelist-card-body">
              <div class="forwarddeliverylist-date">
                <!-- 30 Oct, 2020 -->
                <div class="forwarddeliverylist-date-wrap">
                  <span
                    ><img src="assets/images/calendar.svg" alt="" /><strong
                      class="clr-green"
                      >From</strong
                    ></span
                  >
                  {{
                    fwData?.forward_start_time * 1000 | date: "d MMM, y h:mm a"
                  }}
                </div>
                <div class="forwarddeliverylist-date-wrap mt-0">
                  <span
                    ><img src="assets/images/calendar.svg" alt="" /><strong
                      class="clr-red"
                      >Until</strong
                    ></span
                  >
                  {{
                    fwData?.forward_end_time * 1000 | date: "d MMM, y h:mm a"
                  }}
                </div>
                <div class="forwarddeliverylist-requested-date-wrap">
                  <span
                    ><img src="assets/images/calendar.svg" alt="" />
                    <strong class="clr-info">Requested</strong></span
                  >
                  {{ fwData?.created_at * 1000 | date: "d MMM, y h:mm a" }}
                </div>
              </div>
              <p class="forwarddeliverylist-id-status"
                [ngClass]="[
                  fwData.request_status == 0 ? 'pending' : '',
                  fwData.request_status == 1 ? 'accepted' : '',
                  fwData.request_status == 2 ? 'rejected' : '',
                  fwData.request_status == 3 ? 'completed' : '',
                  fwData.request_status == 4 ? 'deleted' : '',
                  fwData.request_status == 5 ? 'auto-expired' : '',
                  fwData.request_status == 6 ? 'default-expired' : '',
                  fwData.request_status == 7 ? 'ongoing' : '',
                  fwData.request_status == 8 ? 'auto-cancelled' : ''
                ]">
                <em class="material-icons">
                  {{
                    fwData?.request_status == 0
                      ? "priority_high"
                      : fwData?.request_status == 1
                      ? "how_to_reg"
                      : fwData?.request_status == 2
                      ? "person_add_disabled"
                      : fwData?.request_status == 3
                      ? "done"
                      : fwData?.request_status == 4
                      ? "delete_outline"
                      : fwData?.request_status == 5
                      ? "error_outline"
                      : fwData?.request_status == 6
                      ? "error_outline"
                      : fwData?.request_status == 7
                      ? "query_builder"
                      : fwData?.request_status == 8
                      ? "block"
                      : ""
                  }}</em>
                <span class="pt-1">{{ fwData?.request_status | forwardDeliveryStatus }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-12 drone-nodata-box mt-3 mx-3"
        *ngIf="forwarded_deliveries?.length == 0"
      >
        <div class="drone-nodata-box-wrap">
          <div class="drone-nodata-box-cover">
            <div class="drone-nodata-cover">
              <span class="material-icons">error_outline</span>
              <p class="drone-nodata-text">
                No <strong>{{ type_delivery }}</strong> forward delivery exist!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-smart-modal
  #deleteoutgoingforward
  [identifier]="'deleteoutgoingforward'"
  [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal delete-modal"
>
  <p class="modal-text">
    Do you really want to delete this out going forward delivery?
  </p>
  <form #deleteOutgoingForwardForm="ngForm" class="modal-form delete-form">
    <div class="modal-input">
      <mat-form-field floatLabel="never" appearance="standard">
        <input
          matInput
          placeholder="You have to write DELETE keyword here"
          name="type_delete"
          #type_delete="ngModel"
          [(ngModel)]="service.DeleteForwardingFormData.type_delete"
          [ngClass]="{
            'is-invalid':
              !type_delete.valid &&
              (type_delete.dirty || type_delete.touched || isDelForwardSubmit)
          }"
          required
        />
        <mat-error
          *ngIf="
            !type_delete.valid &&
            (type_delete.dirty || type_delete.touched || isDelForwardSubmit)
          "
        >
          Please enter DELETE keyword in input.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-center delete-bottom">
      <button
        [disabled]="isDelForwardLoading"
        class="delete-btns yes-btn mr-2"
        (click)="deleteDelivery(deleteOutgoingForwardForm)"
      >
        Yes
      </button>
      <button
        class="delete-btns no-btn"
        (click)="ngxSmartModalService.getModal('deleteoutgoingforward').close()"
      >
        No
      </button>
    </div>
  </form>
</ngx-smart-modal>

<ngx-smart-modal
  #pendingInfo
  [identifier]="'pendingInfo'"
  [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal warning-modal"
>
  <h2 class="warning-modal-title">
    <span class="material-icons">warning_amber</span>Pending Request
  </h2>

  <p *ngIf="type_delivery == 'incoming'" class="warning-modal-text">
    Request mail has been sent to your email on
    <strong class="warning-modal-info">{{
      pendingDetail?.created_at * 1000 | date: "d MMM y, hh:mm a"
    }}</strong>
    <span class="warning-span-text"
      >and it is valid
      <strong class="warning-modal-info-highlight">until</strong></span
    >
    <strong class="warning-modal-info">{{
      pendingDetail?.expire_valid * 1000 | date: "d MMM y, hh:mm a"
    }}</strong>
    to approve or reject the request.
  </p>

  <p *ngIf="type_delivery != 'incoming'" class="warning-modal-text">
    Request mail has been sent to {{ pendingDetail?.user_email }} on 
    <span class="warning-modal-info">
      {{ pendingDetail?.created_at * 1000 | date: "d MMM y, hh:mm a" }}
    </span>
    and it is valid until
    <span class="warning-modal-info">
      {{ pendingDetail?.expire_valid * 1000 | date: "d MMM y, hh:mm a" }}
    </span>
    to approve or reject the request.
  </p>
</ngx-smart-modal>
