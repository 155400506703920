<div class="container-fluid">
  <div class="main mx-2 mb-4">
    <div class="row">
      <div class="col-12">
        <div class="title-wrapper title-border-bottom mt-2">
          <h1 class="title-1">
            <button class="back-btn" (click)="backClicked()">
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
            Access Control History
          </h1>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-eq-height">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="app-card app-card-100 mt-0">
        <div class="app-card-body">
          <div class="table-responsive">
            <mat-form-field appearance="standard" class="search-filter">
              <span class="material-icons">search</span>
              <input matInput (keyup)="applyFilter($event)" placeholder="Search by Access Control Name" #input />
            </mat-form-field>
            <table mat-table [dataSource]="dataSource" matSort class="app-table dashboard-app-table" aria-describedby="myTable">
              <ng-container matColumnDef="position">
                <th scope="col" mat-header-cell *matHeaderCellDef>No.</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize }}
                </td>
              </ng-container>
              <ng-container matColumnDef="access_control_name">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Access Control Name</th>
                <td mat-cell *matCellDef="let element" style="width:200px;">
                  <span *ngIf="element?.access_control_name" class="text-wrap text-break">{{ element.access_control_name }}</span>
                  <span *ngIf="!element?.access_control_name">-</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="primary_auth_type">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Primary Type</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.primary_auth_type["name"] }}
                </td>
              </ng-container>
              <ng-container matColumnDef="secondary_auth_type">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Secondary Type</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.secondary_auth_type">{{ element?.secondary_auth_type.name }}</span>
                  <span *ngIf="!element?.secondary_auth_type">-</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element">
                  <span class="access-card secondary-access" *ngIf="element.status == 2">Deactivated</span>
                  <span class="access-card deleted-access" *ngIf="element.status == 3">Deleted</span>
                  <span class="access-card edited-access" *ngIf="element.status == 4">Edited</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="start_date">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.start_date * 1000 | date : "d MMMM, y h:mm a" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="end_date">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.end_date * 1000 | date : "d MMMM, y h:mm a" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="uses_remaining">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Uses Remaining</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="(element?.uses_remaining || (element?.uses_remaining == 0)) && (element?.uses_remaining != -1)">{{ element?.uses_remaining }}</span>
                  <span *ngIf="element?.uses_remaining == -1">Unlimited</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="actuator_type">
                <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>User Door Opening</th>
                <td mat-cell *matCellDef="let element" style="width:200px;">
                  <span *ngIf="element?.actuator_type == 1" class="text-wrap text-break">Automatic</span>
                  <span *ngIf="element?.actuator_type == 2" class="text-wrap text-break">Manual</span>
                  <span *ngIf="!element?.actuator_type">-</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th scope="col" mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <button title="View Access Control" class="btn blue-btn" (click)="ViewAccessControlModal(element)">
                      <span class="material-icons">visibility</span>
                    </button>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div class="drone-nodata-box" *ngIf="dataSourceEmpty">
              <div class="drone-nodata-box-wrap">
                <div class="drone-nodata-box-cover">
                  <div class="drone-nodata-cover">
                    <span class="material-icons">error_outline</span>
                    <p class="drone-nodata-text">No records found!</p>
                  </div>
                </div>
              </div>
            </div>
            <mat-paginator [pageSize]="5" class="app-paginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons> </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- View Access Control Modal -->
  <ngx-smart-modal #viewAccessControlModal [identifier]="'viewAccessControlModal'" [dismissable]="false" customClass="nsm-dialog-animation-ttb nsm-centered modal viewenclosure-modal">
    <h2 class="modal-title">View Access Control</h2>
    <div class="modal-form addenclosure-form viewenclosure-form">
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">person</span>Device Id</div>
          <div class="viewuser-box-subtitle">{{ singleAccessControlData?.enclosure_id }}</div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">person</span>Access Control Name</div>
          <div class="viewuser-box-subtitle" *ngIf="singleAccessControlData?.access_control_name">{{ singleAccessControlData?.access_control_name }}</div>
          <div class="viewuser-box-subtitle" *ngIf="!singleAccessControlData?.access_control_name">-</div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">height</span>Primary Auth Type</div>
          <div class="viewuser-box-subtitle">{{ singleAccessControlData?.primary_auth_type.name }}</div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">check_circle</span>Primary Auth Key</div>
          <div class="viewuser-box-subtitle">{{ singleAccessControlData?.primary_auth_key }}</div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">height</span>Secondary Auth Type</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleAccessControlData?.secondary_auth_type">{{ singleAccessControlData?.secondary_auth_type.name }}</span>
            <span *ngIf="!singleAccessControlData?.secondary_auth_type">-</span>
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">check_circle</span>Secondary Auth Key</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleAccessControlData?.secondary_auth_key">{{ singleAccessControlData?.secondary_auth_key }}</span>
            <span *ngIf="!singleAccessControlData?.secondary_auth_key">-</span>
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">date_range</span>Start Date</div>
          <div class="viewuser-box-subtitle">
            {{ singleAccessControlData?.start_date * 1000 | date : "d MMM, y h:mm a" }}
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">date_range</span>End Date</div>
          <div class="viewuser-box-subtitle">
            {{ singleAccessControlData?.end_date * 1000 | date : "d MMM, y h:mm a" }}
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">person</span>Finite Uses</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleAccessControlData?.finite_uses == 'Y'">Yes</span>
            <span *ngIf="singleAccessControlData?.finite_uses == 'N'">No</span>
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">description</span>Uses Remaining</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="(singleAccessControlData?.uses_remaining || (singleAccessControlData?.uses_remaining == 0)) && (singleAccessControlData?.uses_remaining != -1)">{{ singleAccessControlData?.uses_remaining }}</span>
            <span *ngIf="singleAccessControlData?.uses_remaining == -1">Unlimited</span>
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">person</span>User Door Opening</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleAccessControlData?.actuator_type == 1">Automatic</span>
            <span *ngIf="singleAccessControlData?.actuator_type == 2">Manual</span>
            <span *ngIf="!singleAccessControlData?.actuator_type">-</span>
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">person</span>Status</div>
          <div class="viewuser-box-subtitle">
            <span *ngIf="singleAccessControlData?.status == 2">Deactivated</span>
            <span *ngIf="singleAccessControlData?.status == 3">Deleted</span>
            <span *ngIf="singleAccessControlData?.status == 4">Edited</span>
          </div>
        </div>
      </div>
      <div class="modal-input">
        <div class="viewuser-box">
          <div class="viewuser-box-title"><span class="material-icons">description</span>Access Method</div>
          <div class="viewuser-box-subtitle">{{ singleAccessControlData?.access_method }}</div>
        </div>
      </div>
    </div>
  </ngx-smart-modal>
</div>
