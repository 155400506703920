<div class="container-fluid">
    <div class="main mx-2 mb-4">
        <div class="row">
            <div class="col-12">
                <div class="title-wrapper title-border-bottom mt-2">
                    <h1 class="title-1">
                        <button class="back-btn" (click)="backClicked()"><span
                                class="material-icons">keyboard_arrow_left</span></button>
                        <em class="app-card-icon app-card-icon-title">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13.655" viewBox="0 0 18 13.655">
                                <defs>
                                    <style>
                                        .a {
                                            fill: #0E48CA;
                                        }
                                    </style>
                                </defs>
                                <g transform="translate(0 -73.862)">
                                    <g transform="translate(0 73.862)">
                                        <path class="a"
                                            d="M1.862,73.862a1.862,1.862,0,1,0,1.862,1.862A1.862,1.862,0,0,0,1.862,73.862Zm0,4.965A1.862,1.862,0,1,0,3.724,80.69,1.862,1.862,0,0,0,1.862,78.827Zm0,4.966a1.862,1.862,0,1,0,1.862,1.862A1.862,1.862,0,0,0,1.862,83.793Zm4.966-6.828h9.931a1.241,1.241,0,0,0,0-2.483H6.828a1.241,1.241,0,1,0,0,2.483Zm9.931,2.483H6.828a1.241,1.241,0,1,0,0,2.483h9.931a1.241,1.241,0,0,0,0-2.483Zm0,4.966H6.828a1.241,1.241,0,1,0,0,2.483h9.931a1.241,1.241,0,1,0,0-2.483Z"
                                            transform="translate(0 -73.862)" />
                                    </g>
                                </g>
                            </svg>
                        </em>
                        Shared Access</h1>
                    <div class="sub-title-wrapper">
                        <button (click)="sharedAccessList()" class="view-btn">
                            <span class="material-icons">history</span>
                            <span class="pt-1">View History</span>
                        </button>
                        <div class="encl-name-subtitle">{{enclosure_details?.enclosure_name}}</div>
                        <div class="title-access">
                            <span class="access-card" [ngClass]="{'access-green':user_access == 1, 'access-orange':user_access == 2,'access-purple':user_access == 3,'access-gray':user_access == 4}"> {{user_access | accessNamePipe:user_access}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="app-card">
                    <div class="app-card-body">
                        <div class="table-responsive">
                            <mat-form-field floatLabel="never" appearance="standard" class="search-filter">
                                <span class="material-icons">search</span>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                            </mat-form-field>
                                <table mat-table [dataSource]="dataSource" matSort class="app-table dashboard-app-table"
                                aria-describedby="User ">
                                <ng-container matColumnDef="user_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="myId10"> Name </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="!element?.user_name" class="table-span">---</span>
                                        <span *ngIf="element?.user_name" class="table-span">{{element?.user_name}}
                                        </span></td>
                                </ng-container>

                                <ng-container matColumnDef="user_email">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="myId11"> Email / Mobile </th>
                                    <td mat-cell *matCellDef="let element"> {{element?.user_email}} {{element?.user_mobile}} </td>
                                </ng-container>
                                <ng-container matColumnDef="user_access">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="myId12"> Name </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="access-card" [ngClass]="{'primary-access':element.user_access == 1, 'secondary-access':element.user_access == 2,'temporary-access':element.user_access == 3,'onetime-access':element.user_access == 4}"> {{element.user_access | accessNamePipe:element.user_access}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="duration">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="myId13"> Duration </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="duration-card access-card primary-access"
                                            *ngIf="element.user_access == 1">---</span>
                                        <span class="duration-card access-card secondary-access"
                                            *ngIf="element.user_access == 2">
                                            ---</span>
                                        <span class="duration-card access-card temporary-access"
                                            *ngIf="element.user_access == 3">
                                            <span><strong>From:</strong> {{element?.start_time*1000 | date:'d MMM, y h:mm a'}}</span>
                                            <span><strong>Until:</strong> {{element?.end_time*1000 | date:'d MMM, y h:mm a'}}</span>
                                        </span>
                                        <span class="duration-card access-card onetime-access"
                                            *ngIf="element.user_access == 4">
                                            <span><strong>From:</strong> {{element?.start_time*1000 | date:'d MMM, y h:mm a'}}</span>
                                            <span><strong>Until:</strong> {{element?.end_time*1000 | date:'d MMM, y h:mm a'}}</span>
                                        </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="myId14"> Status </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="access-card secondary-access" *ngIf="element.access_status == 0">
                                            Inactive</span>
                                        <span class="access-card primary-access" *ngIf="element.access_status == 1">
                                            Active</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="myId15"> Action </th>
                                    <td mat-cell *matCellDef="let element">
                                        <button *ngIf="element.user_access != 1 && (user_access == 1 || (user_access == 2 && element?.user_access == 3 ) || (user_access == 2 && element?.user_access == 4 ))" class="btn-table btn-delete" (click)="openDeleteModal(element)"> <span class="material-icons">delete</span></button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <div class="drone-nodata-box" *ngIf="dataSourceEmpty">
                                <div class="drone-nodata-box-wrap">
                                    <div class="drone-nodata-box-cover">
                                        <div class="drone-nodata-cover">
                                            <span class="material-icons">error_outline</span>
                                            <p class="drone-nodata-text">No records found!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-paginator *ngIf="!dataSourceEmpty" class="app-paginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                            </mat-paginator> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-smart-modal #deleteencl [identifier]="'deleteencl'" [dismissable]="false"
    customClass="nsm-dialog-animation-ttb nsm-centered modal delete-modal">
    <p class="modal-text">Do you really want to delete this access?</p>
    <div class="d-flex justify-content-center delete-bottom">
        <button [disabled]="isLoading" class="delete-btns yes-btn mr-2" (click)="deleteAccess(accessDetail)">Yes</button>
        <button class="delete-btns no-btn" (click)="ngxSmartModalService.getModal('deleteencl').close()">No</button>
    </div>
</ngx-smart-modal>