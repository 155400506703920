<div class="container-fluid">
    <div class="main mx-2 mb-4">
        <div class="row">
            <div class="col-12">
                <div class="title-wrapper title-border-bottom mt-2">
                    <h1 class="title-1">
                        <button class="back-btn" (click)="backClicked()"><span
                                class="material-icons">keyboard_arrow_left</span></button>
                        <em class="app-card-icon app-card-icon-title">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.239" height="20.063"
                                viewBox="0 0 18.239 20.063">
                                <defs>
                                    <style>
                                        .a {
                                            fill: #83E349;
                                        }
                                    </style>
                                </defs>
                                <g transform="translate(-23.272)">
                                    <g transform="translate(23.272)">
                                        <path class="a"
                                            d="M40.6,14.591h-.912V7.3A7.3,7.3,0,0,0,25.1,7.3v7.3h-.912a.912.912,0,1,0,0,1.824h4.56a3.648,3.648,0,0,0,7.3,0H40.6a.912.912,0,1,0,0-1.824Zm-8.208,3.648a1.826,1.826,0,0,1-1.824-1.824h3.648A1.826,1.826,0,0,1,32.392,18.239Zm5.472-3.648H26.92V7.3a5.472,5.472,0,0,1,10.944,0Z"
                                            transform="translate(-23.272)" />
                                    </g>
                                </g>
                            </svg>
                        </em>
                        Notifications
                    </h1>
                </div>
            </div>
        </div>

        <div class="warning-alert">
            <span class="material-icons">warning_amber</span>
            <p class="warning-alert-text"><strong>Warning:</strong>The read notification will be permanently deleted after 72 hours of arrival time!.</p>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="app-card">
                    <div class="app-card-body"> 
                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSourceNotificationData" matSort
                                class="app-table notification-table"  aria-describedby="notifications">
                                <ng-container matColumnDef="message">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="myId1"> Message </th>
                                    <td class="notification-message" mat-cell *matCellDef="let element"
                                        [ngClass]="{'notification-message-disabled': element.read == 1}"
                                        (click)="readNotification(element)">
                                        <div class="notification-block">
                                            <span class="notification-title">{{element.title}}</span>
                                            <span class="notification-info">{{element.info}}</span>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="myId2"> Date & Time </th>
                                    <td class="notification-message" mat-cell *matCellDef="let element"
                                        [ngClass]="{'notification-message-disabled': element.read == 1}"
                                        (click)="readNotification(element)">
                                        <div class="notification-block">
                                            <span class="notification-date-info">{{element.created_at * 1000 | date:'EEEE, h:mm a - d MMMM y'}}</span>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedNotificationColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedNotificationColumns;"></tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[20, 30, 50]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>