import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthGuard } from './auth/auth-guard';
import { DefaultComponent } from './default/default.component';
import { CoverslideComponent } from './auth/coverslide/coverslide.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { UserverifyComponent } from './userverify/userverify.component';
import { ForwardDeliveryResponseComponent } from './forward-delivery-response/forward-delivery-response.component';
import { LiveTrackingIconComponent } from './live-tracking-icon/live-tracking-icon.component';
import { SharedAccessComponent } from './shared-access/shared-access.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EnclosureListComponent } from './enclosure-list/enclosure-list.component';
import { EnclosureDetailsComponent } from './enclosure-details/enclosure-details.component';
import { ForwardDeliveryListComponent } from './forward-delivery-list/forward-delivery-list.component';
import { ForwardDeliveryListHistoryComponent } from './forward-delivery-list-history/forward-delivery-list-history.component';
import { ParcelDeliveryComponent } from './parcel-delivery/parcel-delivery.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { SharedAccessHistoryComponent } from './shared-access-history/shared-access-history.component';
import { OtaAccessComponent } from './ota-access/ota-access.component';
import { OtaAccessEncComponent } from './ota-access-enc/ota-access-enc.component';
import { MapComponent } from './map/map.component';
import { AccessControlComponent } from './access-control/access-control.component';
import { AccessControlHistoryComponent } from './access-control-history/access-control-history.component';
import { LiveVideoStreamingComponent } from './live-video-streaming/live-video-streaming.component';
import { UserDoorHistoryComponent } from './user-door-history/user-door-history.component';
import { ActuatorLogsComponent } from './actuator-logs/actuator-logs.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  // { path: '**', component: LoginComponent },
  { path: '', component: CoverslideComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'app/verification/:token/:expire_time', component: UserverifyComponent },
  { path: 'app/forward/delivery/response/:token/:enclosure_id/:status/:expire_time', component: ForwardDeliveryResponseComponent },
  { path: 'app/reset-password/:token/:expire_time', component: ResetpasswordComponent },
  { path: 'app/one-time-access', component: OtaAccessComponent },
  { path: 'app/one-time-access/:id', component: OtaAccessEncComponent },
  {
    path: '', component: DefaultComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'app/map', component: MapComponent },
      { path: 'app/live-tracking/:eid/:access', component: LiveTrackingIconComponent },
      { path: 'app/live-tracking-icon/:eid/:access', component: LiveTrackingIconComponent },
      { path: 'app/shared-access/:eid/:access', component: SharedAccessComponent },
      { path: 'app/shared-access-history/:eid/:access', component: SharedAccessHistoryComponent },
      { path: 'app/notifications', component: NotificationsComponent },
      { path: 'app/notification-settings', component: NotificationSettingsComponent },
      { path: 'app/profile', component: ProfileComponent },
      { path: 'app/change-password', component: ChangePasswordComponent },
      { path: 'app/enclosure-list', component: EnclosureListComponent },
      { path: 'app/enclosure-details/:eid', component: EnclosureDetailsComponent },
      { path: 'app/forward-delivery-list/:eid/:access/:type/:owneruid', component: ForwardDeliveryListComponent },
      { path: 'app/forward-delivery-list/history/:eid/:access/:type/:owneruid', component: ForwardDeliveryListHistoryComponent },
      { path: 'app/parcel-delivery-details/:eid', component: ParcelDeliveryComponent },
      { path: 'app/access-control/:eid', component: AccessControlComponent },
      { path: 'app/access-control-history/:eid/:pid', component: AccessControlHistoryComponent },
      { path: 'app/user-door-history/:eid', component: UserDoorHistoryComponent },
      { path: 'app/actuator-logs/:eid', component: ActuatorLogsComponent },
      { path: 'app/live-video-streaming/:eid', component: LiveVideoStreamingComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
