/**
 * @Author: Jitesh Prajapati <jiteshprajapati>
 * @Date:   2021-03-18T15:28:19+05:30
 * @Email:  jitesh.prajapati@teksun.com
 * @Filename: parcel-delivery.service.ts
 * @Last modified by:   Jitesh_Prajapati
 * @Last modified time: 2021-03-24T10:58:33+05:30
 * @Copyright: Teksun
 */



import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParcelDeliveryService {

  constructor(
    public http: HttpClient
  ) { }

  getAll(data) {
    return this.http.get(environment.api_url + '/parcel-delivery/get-parcel-by-enclosure/' + data);
  }
  parcelDelivered(data) {
    return this.http.post(environment.api_url + '/parcel-delivery/delivered', data);
  }
  deliveryApproval(data) {
    return this.http.post(environment.api_url + '/parcel-delivery/new-request', data);
  }
  sendDroneDoorStatus(data) {
    return this.http.post(environment.api_url + '/parcel-delivery/open-drone-door/response', data);
  }


  //   userreject(data){
  //     return this.http.post(environment.api_url+'/parcel-delivery/user-reject',data);
  //   }

  //   authApproveRejectuser(data){
  //     return this.http.post(environment.api_url+'/parcel-delivery/auth-approve-reject',data);
  //   }

  //   unlockDoorByMobile(data){
  //     return this.http.post(environment.api_url+'/parcel-delivery/unlock-user-door-mobile',data);
  //   }
}
