import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { LiveTrackingService } from '../live-tracking/live-tracking.service';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { EnclosureDetailsService } from '../enclosure-details/enclosure-details.service';
import { ToastrService } from 'ngx-toastr';
import { Message } from '../_helper/message';

@Component({
  selector: 'app-live-tracking',
  templateUrl: './live-tracking.component.html'
})
export class LiveTrackingComponent implements OnInit {
  public enclosure_id: any;
  public user_access: any;
  public enclosure_name: any;
  public canvas: any;
  map: mapboxgl.Map;
  public destination = [72.588549, 23.097404];
  public origin = [72.5971428, 23.1053682];

  public rts: any;
  public point: any;
  public arc: any = [];
  public counter = 0;
  public layer_id = 0;
  public steps = 1;
  public mkr: any;
  public isAddressSubmit: boolean = false;
  public isLatLangSubmit: boolean = false;
  public isDeliverySubmit: boolean = false;
  public toAddressLayer: any;
  public lineLayer: any;
  public fromAddressLayer: any;
  public routeLayer: any;
  public pointLayer: any;
  public currentZoom: any = 9;
  public distance: any = 0;
  public lastZoom: any;
  public kmStep: any = 500;


  public addressJson: any = { 'attribution': '', 'features': [], 'query': [], 'type': '' };
  public latlngJson: any = { 'attribution': '', 'features': [], 'query': [], 'type': '' };
  Mapbox_accessToken_URL = "";

  constructor(
    public route: ActivatedRoute,
    private _location: Location,
    public service: LiveTrackingService,
    private httpClient: HttpClient,
    public serviceEnclosure: EnclosureDetailsService,
    public router: Router,
    private toastr: ToastrService,
  ) {
    this.Mapbox_accessToken_URL = Message.dec(environment.mapbox.accessToken)
  }

  ngOnInit(): void {
    // let enc=new EnclosureListComponent();
    // //console.log(enc,"ENCCCCCCCCCC");
    // let liveTrack=enc.liveTracking();
    // //console.log(liveTrack,"LiveTrack");
    // this.toastr.error(liveTrack,'')
    // this.map.buildMap();
    this.enclosure_id = atob(this.route.snapshot.params.eid);
    this.user_access = atob(this.route.snapshot.params.access);

    const send_data = { enclosure_id: this.enclosure_id };
    this.serviceEnclosure.getEncViewById(send_data.enclosure_id).subscribe((res) => {
      //console.log(res['data'], "getEncViewById");
      if (res['status'] == 1) {
        this.enclosure_name = res['data'].enclosure_name;
      }
      else {
        this.router.navigate(['app/enclosure-list'])
      }
    })

    this.resetAddressForm();
    this.resetLatlangForm();
    this.resetDeliveryForm();
    //this.loadMap();

    setTimeout(() => {
      this.loadMap();

    }, 0);
   // this.getZip();
  }
  async getZip(){
    let url='https://zip.getziptastic.com/v2/IN/396510';
    const data = await this.httpClient.get(url).toPromise();
    console.log(data,"zipcode");

  }

  resetAddressForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.LiveTrackingAddressFormData = {
      street_address: '',
    };
  }
  resetLatlangForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.LiveTrackingLatLangFormData = {
      latitude: '23.100320',
      longitude: '72.602770',
    };
  }
  resetDeliveryForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.service.LiveTrackingDeliveryFormData = {
      start_address: '4D Square Mall, Moje, Motera, Ahmedabad, Gujarat 380006',
      end_address: 'Visat Petrol Pump, Ravipark Society, Sabarmati, Ahmedabad, Gujarat 380005',
    };
  }

  async getLatLongFromAddress(form?: NgForm) {
    if (!form.valid) {
      this.isAddressSubmit = true;
      return;
    }
    else {
      this.isAddressSubmit = false;
      //console.log(form.value.street_address, "Street_Address");
      let getAddress = await this.getAddress(form.value.street_address);
      this.addressJson = getAddress;
      //console.log(getAddress)
      console.log(this.addressJson.features[0].center,"Cneter latlong");
      //console.log(getAddress, "GetAddress");
      /*
        this.service.getLatLongFromAddress(form.value.street_address).subscribe((res) => {
          //console.log(res.features[0].center, "ELSE");
          //alert(res.features[0].center);
        })
      */
    }
  }
  async getAddressFromLatLang(form?: NgForm) {

    if (!form.valid) {
      this.isLatLangSubmit = true;
      return;
    }
    else {
      this.isLatLangSubmit = false;
      let apiUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + form.value.longitude + ',' + form.value.latitude + '.json?access_token=' + this.Mapbox_accessToken_URL;
      const data = await this.httpClient.get(apiUrl).toPromise();
      this.latlngJson = data;


      // let addressValid= 'https://www.smartystreets.com/products/us-rooftop-geocoding?address-type=us-street-components&street=3301 South Greenfield Rd&street2=#409&city=Gilbert&state=AZ&zipcode=85297';
      // const data1 = await this.httpClient.get(addressValid).toPromise();
      // console.log("data11111",data1)

      this.service.testingDataForFirmwareTeam(form.value).subscribe((res) => {

      });
    }
  }
  async setLiveTracking(form?: NgForm) {
    if (!form.valid) {
      this.isDeliverySubmit = true;
      return;
    }
    else {
      this.isDeliverySubmit = false;
      let start_address = await this.getAddress(form.value.start_address);
      this.addressJson = start_address;
      this.origin = this.addressJson.features[0].center;
      let end_address = await this.getAddress(form.value.end_address);
      this.addressJson = end_address;
      this.destination = this.addressJson.features[0].center;
      setTimeout(() => {
        this.clearMap();
        this.map.setZoom(16);
        this.fly();
      }, 1000);
    }
  }

  async getAddress(address) {
    let apiUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + address + '.json?access_token=' + this.Mapbox_accessToken_URL;
    const data = await this.httpClient.get(apiUrl).toPromise();
    return data;//JSON.stringify(data);
  }

  startDrone() {
    this.clearMap();
    this.map.setZoom(16);
    this.fly();
  }
  clearMap() {
    if (this.map.getLayer(this.toAddressLayer) != undefined) {
      this.map.removeLayer(this.toAddressLayer);
    }
    if (this.map.getLayer(this.lineLayer) != undefined) {
      this.map.removeLayer(this.lineLayer);
    }
    if (this.map.getLayer(this.fromAddressLayer) != undefined) {
      this.map.removeLayer(this.fromAddressLayer);
    }
    if (this.map.getSource(this.routeLayer) != undefined) {
      this.map.removeSource(this.routeLayer);
    }
    if (this.map.getSource(this.pointLayer) != undefined) {
      this.map.removeSource(this.pointLayer);
    }
  }
  loadMap() {

    (mapboxgl as any).accessToken = this.Mapbox_accessToken_URL;
    this.map = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/streets-v10',//mapbox://styles/mapbox/light-v10
      center: [this.origin[0], this.origin[1]], // starting position
      zoom: this.currentZoom, // starting zoom,
      pitch: 60, // pitch in degrees
      bearing: -60, // bearing in degrees
      antialias: true

    });
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.FullscreenControl());

    // create a HTML element for each feature




     //'mapbox://styles/mapbox/light-v10', for 3D map loading
    /*
    this.map.on('load', () => {
      var layers = this.map.getStyle().layers;

      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol') {// && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }
      this.map.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#aaa',

          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 0.6
        }
      },
        labelLayerId
      );
    });
    */
    //end 3d map

    //this.createMarker(72.8311, 21.1702);

    //this.fly();
    // Add zoom and rotation controls to the map.


  }
  fly() {
    this.toAddressLayer = Math.floor(Math.random() * 111111).toString();
    this.fromAddressLayer = Math.floor(Math.random() * 111111).toString();
    this.routeLayer = Math.floor(Math.random() * 111111).toString();
    this.pointLayer = Math.floor(Math.random() * 111111).toString();
    this.lineLayer = Math.floor(Math.random() * 111111).toString();
    //console.log("Layer Id toAddressLayer", this.toAddressLayer, "fromAddressLayer", this.fromAddressLayer, "routeLayer", this.routeLayer, "pointLayer", this.pointLayer, "lineLayer", this.lineLayer);
    this.rts = {
      'type': 'FeatureCollection',
      'features': [
        {
          'type': 'Feature',
          'geometry': {
            'type': 'LineString',
            'coordinates': [this.origin, this.destination]
          }
        }
      ]
    };
    // A simple line from origin to destination.
    this.point = {
      'type': 'FeatureCollection',
      'features': [
        {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'Point',
            'coordinates': this.origin
          }
        }
      ]
    };









    // Calculate the distance in kilometers between route start/end point.
    var lineDistance = turf.length(this.rts.features[0]);
    var options = {
      units: 'kilometers'
    }; // units can be degrees, radians, miles, or kilometers, just be sure to change the units in the text box to match.
    this.distance = turf.distance(this.origin, this.destination, {
      units: 'kilometers'
    });
    //console.log(this.distance, "Distance");
    this.steps = this.distance.toFixed(3) * this.kmStep;
    var meter=1000;
    var totalMeter=this.distance*meter*100;
    var speed=20;//per hour speed
    var interval=(this.distance/speed);
    let duration=this.calculateTime(interval);
    //console.log(this.steps);
    let dayToMil=duration.d*86400000;
    let hrToMil=duration.h*3600000;
    let minToMil=duration.m*1800000;
    //console.log(dayToMil,hrToMil,minToMil,"Mili");
    var convertMilisecond=dayToMil+hrToMil+minToMil;
    console.log(convertMilisecond);
    console.log(this.distance,"this.distance");
    console.log(meter,"meter");
    console.log(totalMeter,"totalMeter");
    console.log(speed,"speed");
    console.log(interval,"interval");
    console.log(convertMilisecond,"convertMilisecond");
    console.log(duration,"SplitTime");
    console.log(this.steps,"Walk Step");
    // Number of steps to use in the arc and animation, more steps means
    // a smoother arc and animation, but too many steps will result in a
    // low frame rate
    var arc = [];
    var arcTime = [];
    var cnt=1;
    // Draw an arc between the `origin` & `destination` of the two points
    for (var i = 0; i < lineDistance; i += lineDistance / this.steps) {
      var segment = turf.along(this.rts.features[0], i);
      arc.push(segment.geometry.coordinates);
      cnt++;
      arcTime.push({latlang:segment.geometry.coordinates,cnt:cnt,time:Math.round(new Date().getTime())});
    }
    arc.push(this.destination);
    console.log(arcTime,"ArcTime");

    this.rts.features[0].geometry.coordinates = arc;

    this.counter = 0;

    this.map.fitBounds([[this.origin[0], this.origin[1]], [this.destination[0], this.destination[1]]], {
      padding: 100
    });
    //this.map.on('load',  ()=> {
    // Add a single point to the map
    this.map.addSource(this.routeLayer, {
      'type': 'geojson',
      'data': this.rts
    });

    this.map.addSource(this.pointLayer, {
      'type': 'geojson',
      'data': this.point
    });
    this.map.addLayer({
      'id': this.lineLayer,
      'source': this.routeLayer,
      'type': 'line',
      'paint': {
        'line-width': 4,
        'line-color': '#007cbf'
      }
    });
    this.lastZoom = this.map.getZoom();
    this.map.on('zoom', () => {
      this.currentZoom = this.map.getZoom();
      if (this.currentZoom > this.lastZoom) {
        //console.log("zoom in", this.currentZoom, this.lastZoom, Math.ceil(this.currentZoom), "Floor");
        this.map.setZoom(Math.ceil(this.currentZoom));
      } else {
        // zoom out
        //console.log("zoom out", this.currentZoom, this.lastZoom, Math.floor(this.currentZoom), "Ceil");
        this.map.setZoom(Math.floor(this.currentZoom));
      }
      this.lastZoom = this.currentZoom;
    });
    /*this.map.addLayer({
     'id': 'point',
     'source': 'point',
     'type': 'symbol',
       'layout': {
         'icon-image': 'airport-15',
         'icon-rotate': ['get', 'bearing'],
         'icon-rotation-alignment': 'map',
         'icon-allow-overlap': true,
         'icon-ignore-placement': true
       }
   });*/
    //console.log("Realeasd Drone")
    var elms1 = document.createElement('p');
    elms1.className = 'markerend';
    new mapboxgl.Marker(elms1)
    .setLngLat([this.origin[0], this.origin[1]])
    .addTo(this.map);
    var elms2 = document.createElement('p');
    elms2.className = 'markerstart';
    new mapboxgl.Marker(elms2)
    .setLngLat([this.destination[0], this.destination[1]])
    .addTo(this.map);
    this.animate(this.counter);

    //});
    //this.map.addControl(new mapboxgl.NavigationControl());

  }
  calculateTime(numberOfHours){
    var Days=Math.floor(numberOfHours/24);
    var Remainder=numberOfHours % 24;
    var Hours=Math.floor(Remainder);
    var Minutes=Math.floor(60*(Remainder-Hours));
    return({"d":Days,"h":Hours,"m":Minutes})
  }

  animate(counter?): void {

    ////console.log(this.rts,"Routes",typeof(this.rts));
    ////console.log(this.rts.features,"Feture");
    ////console.log(this.rts.features[0].geometry.coordinates,"Feture[0]");
    var start = this.rts.features[0].geometry.coordinates[counter >= this.steps ? counter - 1 : counter];
    ////console.log(start,"START");
    var end = this.rts.features[0].geometry.coordinates[counter >= this.steps ? counter : counter + 1];
    if (!start || !end) return;
    ////console.log(end,"END");

    var el = document.createElement('div');
    var removeIcon = document.getElementsByClassName('markerIcon');
    var markerstart = document.getElementsByClassName('markerstart');
    var markerend = document.getElementsByClassName('markerend');
    ////console.log(el,"1 Element counter",counter,removeIcon);
    if (removeIcon.length != 0) {
      ////console.log(removeIcon,"RemoveICon");
      removeIcon[0].remove();
      //this.map.flyTo({center:[end[0],end[1]]});

      let lastZoom = this.map.getZoom();

      ////console.log(zoomLevel,Math.ceil(zoomLevel),Math.round(zoomLevel));
      //Math.ceil(zoomLevel)

      //this.map.fitBounds([[end[0],end[1]],[end[0],end[1]]], {padding: 100});
      this.map.setCenter(start);
      /*
            //console.log(start,"START");
            //console.log(zoomLevel,"Zoom level");*/
    }
    ////console.log(el,"2 Element counter",counter);



    // make a marker for each feature and add to the map
    el.className = 'markerIcon';
    el.style.backgroundImage = 'url(assets/images/drone-map.svg)';
    el.style.width = '60px';
    el.style.height = '60px';
    this.mkr = new mapboxgl.Marker(el)
      .setLngLat([end[0], end[1]])
      .addTo(this.map);



    /*const marker = new mapboxgl.Marker({}).setLngLat([end[0],end[1]])
      .addTo(this.map);*/


    // Update point geometry to a new position based on counter denoting
    // the index to access the arc
    this.point.features[0].geometry.coordinates = this.rts.features[0].geometry.coordinates[counter];

    // Calculate the bearing to ensure the icon is rotated to match the route arc
    // The bearing is calculated between the current point and the next point, except
    // at the end of the arc, which uses the previous point and the current point
    this.point.features[0].properties.bearing = turf.bearing(turf.point(start), turf.point(end));

    // Update the source with this new data
    //this.map.getSource('point').setData(this.point);

    // Request the next frame of animation as long as the end has not been reached

    console.log(this.counter,this.steps,"==============================",Math.round(new Date().getTime()));

    if (this.counter < this.steps) {
      //window.requestAnimationFrame(animate);
      requestAnimationFrame((time) => this.animate(this.counter));

      // //console.log("CAll again");
      //this.animate();

    } else {
      //console.log("Drone Arrieved");
    }
    this.counter = this.counter + 1;



  }

  createMarker(lng: number, lat: number) {
    /*this.map.on('load',  ()=> {
      // Add a single point to the map
      this.map.addSource('point', {
      'type': 'geojson',
      'data': this.geojson
      });
    });*/

    const marker = new mapboxgl.Marker({
      color: "#FFFFFF",
      draggable: true
    }).setLngLat([lng, lat])
      .addTo(this.map);
    marker.on('drag', () => {
      //console.log(marker.getLngLat())
    })
  }
  backClicked() {
    this._location.back();
  }
}
