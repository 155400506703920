import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'battery-percentage',
  templateUrl: './percentage-bar.component.html'
})
export class BatteryPercentageComponent {
  @Input() public color: string;
  @Input() public percentage: any;

  arrayColor = [];
  totalPin = 100;
  pinColor = '#efefed';

  constructor() { }

  ngOnInit() {
    this.renderArrayColor();
  }

  renderArrayColor() {
    const part = 100 / this.totalPin;
    let currentLevel = 0 + part;
    for (let i = 0; i < this.totalPin; i++) {
      if (this.percentage >= currentLevel) {
        this.arrayColor.push({ full: true, color: this.color, width: '1px', class: "gray" });
        currentLevel += part;
      } else {
        const newWidth = ((this.percentage - currentLevel + part) * 1) / 20;
        this.arrayColor.push({
          full: false,
          color: this.pinColor,
          width: newWidth + 'px',
          class: "white"
        });
        for (let j = i + 1; j < this.totalPin; j++) {
          this.arrayColor.push({
            full: true,
            color: this.pinColor,
            width: '1px',
            class: "white"
          });
        }
        break;
      }
    }
  }
}
