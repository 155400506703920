import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserDoorHistoryService } from './user-door-history.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-user-door-history',
  templateUrl: './user-door-history.component.html'
})

export class UserDoorHistoryComponent implements OnInit {

  public image_logging_url = environment.image_logging_url;

  public enclosure_id: any;
  public historyList = [];
  public singleHistoryData: any;
  public space_type: any;

  public dataSourceEmpty: any = false;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator; // for mat-paginator
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['position', 'access_control_name', 'primary_auth_type', 'secondary_auth_type', 'timestamp', 'access_status', 'available_height', 'actuator_type', 'actions'];

  constructor(
    public route: ActivatedRoute,
    private _location: Location,
    public service: UserDoorHistoryService,
    public ngxSmartModalService: NgxSmartModalService,
  ) { }

  ngOnInit(): void {
    this.enclosure_id = atob(this.route.snapshot.params.eid);
    this.getUserDoorHistory();
    this.getEnclosureDetail(this.enclosure_id);
  }

  backClicked() {
    this._location.back();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.dataSourceEmpty = true;
    } else {
      this.dataSourceEmpty = false;
    }
  }

  getUserDoorHistory() {
    this.service.getUserDoorHistory(this.enclosure_id).subscribe(res => {
      this.historyList = res['data'];
      if (res['status'] == 1) {
        if (res['data'].length == 0) {
          if (res['data'].length == 0) {
            this.dataSourceEmpty = true;
          }
        }
        this.dataSource = new MatTableDataSource();
        this.dataSource.data = res['data'];
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  ViewHistoryModal(content: any) {
    this.singleHistoryData = content;
    this.ngxSmartModalService.getModal('viewUserDoorModal').open();
  }

  UserDoorImageModal(content: any) {
    this.singleHistoryData = content;
    this.ngxSmartModalService.getModal('userDoorImageModal').open();
  }

  getEnclosureDetail(eid: any) {
    this.service.getEncViewById(eid).subscribe((res) => {
      if (res['status'] == 1) {
        this.space_type = res['data'].space_type == "CM" ? 'cm' : 'inch';
      }
      else {}
    })
  }

}
