import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedAccessService } from '../shared-access/shared-access.service';
import { EnclosureDetailsService } from '../enclosure-details/enclosure-details.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { NgForm, FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

export interface PeriodicElement {
  user_name: string;
  user_email: string;
  user_access: any;
  duration: any;
  deleted: any;
  status: any;
  action: any;
}

const ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: 'app-shared-access-history',
  templateUrl: './shared-access-history.component.html',
  styles: [
  ]
})
export class SharedAccessHistoryComponent implements OnInit {

  displayedColumns: string[] = ['user_name', 'user_email', 'user_access', 'duration', 'access_status', 'deleted', 'action'];
  public dataSourceEmpty: any = false;
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public dateControl = new FormControl(new Date(2021, 9, 4, 5, 6, 7));
  public dateControlMinMax = new FormControl(new Date());

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];
  currentDate: any = new Date();

  public listColors = ['primary', 'accent', 'warn'];
  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];

  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('temporaryAccessForm', { static: true }) temporaryAccessForm: NgForm;
  @ViewChild('OTAAccessForm', { static: true }) OTAAccessForm: NgForm;

  public enclosure_id: any;
  public user_access: any;
  public enclosure_details: any;
  public isSecondaryLoading: any = false;
  public isTemporaryLoading: any = false;
  public isOTALoading: any = false;
  public isSecondarySubmit: any = false;
  public isTemporarySubmit: any = false;
  public isOTASubmit: any = false;
  public isOTASublit: any = false;
  public existingTempAccess: any;
  public addTempDetail: any;
  public addOTADetail: any;

  public existingTemp: any = false;
  public existingOTA: any = false;
  public existingOTAAccess: any = [];
  public phoneError: any = false;
  //mobile
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  countrySortName: any = 'US';
  public phno: any;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });
  constructor(private _location: Location, private toastr: ToastrService, public encDetailService: EnclosureDetailsService, public service: SharedAccessService, public router: Router, public route: ActivatedRoute, public ngxSmartModalService: NgxSmartModalService,) { }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.enclosure_id = atob(this.route.snapshot.params.eid);
    this.user_access = atob(this.route.snapshot.params.access);
    this.getAccessViewByUid(this.enclosure_id);
    this.getEnclosureDetail(this.enclosure_id);
    this.resetSecondaryForm();
    this.resetTemporaryForm();
    this.resetOTAForm();
    const now = new Date();
    this.minDate = new Date();
    this.minDate.setDate(now.getDate() - 0);
  }
  backClicked() {
    this._location.back();
  }
  getEnclosureDetail(eid) {
    const send_data = { enclosure_id: eid };
    this.encDetailService.getEncViewById(eid).subscribe((res) => {
      if (res['status'] == 1) {
        this.enclosure_details = res['data'];
      } else {
        this.router.navigate(['/app/enclosure-list']);
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
  }
  getAccessViewByUid(eid) {
    // arg 2 = history tbl
    this.service.getAccessViewByUserid(eid, 2).subscribe((res) => {
      if (res['status'] == 1) {
        if (res['data'].length == 0) {
          if (res['data'].length == 0) {
            this.dataSourceEmpty = true;
          }
        }
        this.dataSource = new MatTableDataSource();
        this.dataSource.data = res['data'];
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.router.navigate(['/admin/dashboard']);
      }
    })
  }
  openReAssignModal(element) {
    this.isTemporaryLoading = false;
    if (element.user_access == 1) {
      this.toastr.error('You can not reassign to primary member!', '');
    }
    else if (element.user_access == 2 && (this.user_access != 1)) {
      this.toastr.error('Permission denied!', '');
    }
    else {
      if (element.user_access == 2) {
        this.encDetailService.SecondaryAccessFormData = element;
        this.isSecondaryLoading = false;
        this.ngxSmartModalService.getModal('secondaryaccess').open()
      }
      else if (element.user_access == 3) {
        this.temporaryAccessForm.resetForm();
        this.encDetailService.TemporaryAccessFormData.user_name = element.user_name;
        this.encDetailService.TemporaryAccessFormData.enclosure_id = element.enclosure_id;
        this.encDetailService.TemporaryAccessFormData.user_email = element.user_email;
        var format = moment().tz(this.enclosure_details.timezone).format('yy, M, DD, H, mm, ss').split(",", 6);
        this.encDetailService.TemporaryAccessFormData.start_time = new FormControl(new Date(Number(format[0]), Number(format[1]) - 1, Number(format[2]), Number(format[3]), Number(format[4]), Number(format[5])));
        this.encDetailService.TemporaryAccessFormData.end_time = new FormControl(new Date(Number(format[0]), Number(format[1]) - 1, Number(format[2]), Number(format[3]), Number(format[4]), Number(format[5])));
        this.ngxSmartModalService.getModal('temporaryaccess').open()

      }
      else if (element.user_access == 4) {
        this.OTAAccessForm.resetForm();
        this.encDetailService.OTAAccessFormData = element;
        var format = moment().tz(this.enclosure_details.timezone).format('yy, M, DD, H, mm, ss').split(",", 6);
        this.encDetailService.OTAAccessFormData.start_time = new FormControl(new Date(Number(format[0]), Number(format[1]) - 1, Number(format[2]), Number(format[3]), Number(format[4]), Number(format[5])));
        this.encDetailService.OTAAccessFormData.end_time = new FormControl(new Date(Number(format[0]), Number(format[1]) - 1, Number(format[2]), Number(format[3]), Number(format[4]), Number(format[5])));
        this.ngxSmartModalService.getModal('onetimeaccess').open()
      }
    }
  }
  addSecondaryAccess(form?: NgForm) {
    if (!form.valid) {
      this.isSecondarySubmit = true;
      return;
    }
    else {
      this.isSecondaryLoading = false;
      form.value.start_time = new Date(form.value.start_time).getTime();
      form.value.end_time = new Date(form.value.end_time).getTime();
      if (form.value.start_time.toString().length == 13) {
        form.value.start_time = form.value.start_time / 1000;
      }
      if (form.value.end_time.toString().length == 13) {
        form.value.end_time = form.value.end_time / 1000;
      }
      form.value.enclosure_id = this.enclosure_id;
      this.encDetailService.addSecondaryUser(form.value)
        .subscribe((res) => {
          if (res['status'] == 1) {
            this.toastr.success(res['msg'], '');
            this.ngxSmartModalService.getModal('secondaryaccess').close();
            this.getAccessViewByUid(this.enclosure_id);
          }
          else {
            this.toastr.error(res['msg'], '');
          }
        })
    }
  }

  addTemporaryAccess(form?: NgForm) {
    this.existingTempAccess = [];
    if (!form.valid) {
      this.isTemporarySubmit = true;
      return;
    }
    else {
      this.isTemporaryLoading = true;
      form.value.start_time = new Date(this.encDetailService.TemporaryAccessFormData.start_time.value).getTime();
      form.value.end_time = new Date(this.encDetailService.TemporaryAccessFormData.end_time.value).getTime();
      if (form.value.start_time.toString().length == 13) {
        form.value.start_time = form.value.start_time / 1000;
      }
      if (form.value.end_time.toString().length == 13) {
        form.value.end_time = form.value.end_time / 1000;
      }
      if (form.value.start_time < new Date().getTime() / 1000) {
        this.toastr.error('You can not select start time before the current time, Please check your start time', '');
        this.isTemporaryLoading = false;
        return;
      }
      if (form.value.end_time - form.value.start_time < 0) {
        this.toastr.error('End time is invalid', '');
        this.isTemporaryLoading = false;
        return;
      }
      else if (form.value.end_time - form.value.start_time < 3600 * 6) {
        this.toastr.error('Select minimum 6 hour', '');
        this.isTemporaryLoading = false;
        return;
      }
      else if (form.value.end_time - form.value.start_time > 2592000) {
        this.toastr.error('Select maximum 30 days', '');
        this.isTemporaryLoading = false;
        return;
      }
      form.value.enclosure_id = this.enclosure_id;
      form.value.check_duration = 1;
      this.addTempDetail = form.value;
      this.encDetailService.addTemporaryAccess(form.value)
        .subscribe((res) => {
          if (res['status'] == 1) {
            if (res['data']) {
              if (res['data'].length == 0) {
                this.isTemporaryLoading = true;
                form.value.check_duration = 0;
                this.encDetailService.addTemporaryAccess(form.value).subscribe((secRes) => {
                  if (secRes['status'] == 1) {
                    this.isTemporaryLoading = true;
                    this.toastr.success(secRes['msg'], '');
                    this.ngxSmartModalService.getModal('temporaryaccess').close();
                    this.getAccessViewByUid(this.enclosure_id);
                  }
                  else {
                    this.isTemporaryLoading = false;
                    this.toastr.error(secRes['msg'], '');
                  }
                });
              }
              else {
                this.isTemporaryLoading = false;
                this.existingTemp = true;
                if (res['data']) {
                  this.existingTempAccess = res['data'];
                }
                this.ngxSmartModalService.getModal('existingTempDetail').open();
              }
            }
            else {
              this.isTemporaryLoading = true;
              this.existingTemp = false;
              this.toastr.success(res['msg'], '');
              this.ngxSmartModalService.getModal('temporaryaccess').close();
              this.getAccessViewByUid(this.enclosure_id);
            }
          }
          else {
            this.isTemporaryLoading = false;
            this.toastr.error(res['msg'], '');
          }
        })
    }
  }

  public accessDetail: any;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.dataSourceEmpty = true;
    } else {
      this.dataSourceEmpty = false;
    }
  }
  openPerDeleteModal(element) {
    if (element.user_access == 1) {
      this.toastr.error('You can not delete primary member!', '');
    }
    else if (element.user_access == 2 && (this.user_access != 1)) {
      this.toastr.error('Permission denied!', '');
    }
    else {
      this.accessDetail = element;
      this.ngxSmartModalService.getModal('perDeleteAccess').open()
    }
  }

  permanentDeleteAccess(data) {
    this.service.permanentDeleteAccess(data.tmp_id, data.access_user_id, data.user_access).subscribe((res) => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        this.ngxSmartModalService.getModal('perDeleteAccess').close();
        this.getAccessViewByUid(this.enclosure_id);
      }
      else {
        this.toastr.error(res['msg'], '');
      }
      this.getAccessViewByUid(this.enclosure_id);
    })
  }

  resetSecondaryForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.encDetailService.SecondaryAccessFormData = {
      user_name: '',
      user_email: '',
      enclosure_id: '',//this.enclosure_id
    };
  }

  resetTemporaryForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.encDetailService.TemporaryAccessFormData = {
      user_name: '',
      user_email: '',
      enclosure_id: '',//this.enclosure_id,
      start_time: '',
      end_time: ''
    };
  }
  resetOTAForm(form?: NgForm) {
    if (form != null) form.resetForm();
    this.encDetailService.OTAAccessFormData = {
      user_mobile: '',
      enclosure_id: '',//this.enclosure_id,
      start_time: '',
      end_time: ''
    };
  }
  checkNumber(event: any, phoneNumber) {
    if (phoneNumber != null) {
      let Data: any = phoneNumber;
      //https://stackoverflow.com/questions/54899200/which-event-will-work-in-ngx-intl-tel-input-in-angular
      if (Data.internationalNumber != '') {
        this.encDetailService.OTAAccessFormData.user_mobile = Data.internationalNumber;
      }
    }
  }
  closeExistingTempModal() {
    this.ngxSmartModalService.getModal('existingTempDetail').close()
    this.ngxSmartModalService.getModal('temporaryaccess').close()
  }
  closeExistingOTAModal() {
    this.ngxSmartModalService.getModal('existingOTADetail').close()
    this.ngxSmartModalService.getModal('onetimeaccess').close()
  }
  addTempAfterChecked() {
    this.addTempDetail.check_duration = 0;
    this.encDetailService.addTemporaryAccess(this.addTempDetail).subscribe((secRes) => {
      if (secRes['status'] == 1) {
        this.isTemporaryLoading = true;
        this.toastr.success(secRes['msg'], '');
        this.ngxSmartModalService.getModal('temporaryaccess').close();
        this.ngxSmartModalService.getModal('existingTempDetail').close();
        this.getAccessViewByUid(this.enclosure_id);
      }
      else {
        this.ngxSmartModalService.getModal("existingTempDetail").close();
        this.isTemporaryLoading = false;
        this.toastr.error(secRes['msg'], '');
      }
    });
  }
  addOTAAfterChecked() {
    this.addOTADetail.check_duration = 0;
    this.encDetailService.addOTAUser(this.addOTADetail).subscribe((secRes) => {
      if (secRes['status'] == 1) {
        this.isOTALoading = true;
        this.toastr.success(secRes['msg'], '');
        this.ngxSmartModalService.getModal('onetimeaccess').close();
        this.ngxSmartModalService.getModal('existingOTADetail').close();
        this.getAccessViewByUid(this.enclosure_id);
      }
      else {
        this.ngxSmartModalService.getModal("existingOTADetail").close();
        this.isOTALoading = false;
        this.toastr.error(secRes['msg'], '');
      }
    });
  }
  addOTAAccess(form?: NgForm) {
    this.existingTempAccess = [];
    if (!form.valid) {
      this.isOTASubmit = true;
      return;
    }
    else {
      this.isOTALoading = true;
      var d = new Date(form.value.start_time);
      form.value.start_time = new Date(this.encDetailService.OTAAccessFormData.start_time.start_time.value).getTime();
      form.value.end_time = new Date(this.encDetailService.OTAAccessFormData.end_time.value).getTime();
      if (form.value.start_time.toString().length == 13) {
        form.value.start_time = form.value.start_time / 1000;
      }
      if (form.value.end_time.toString().length == 13) {
        form.value.end_time = form.value.end_time / 1000;
      }
      if (form.value.start_time < new Date().getTime() / 1000) {
        this.toastr.error('You can not select start time before the current time, Please check your start time', '');
        this.isOTALoading = false;
        return;
      }
      if (form.value.end_time - form.value.start_time < 0) {
        this.toastr.error('Due time is invalid', '');
        this.isOTALoading = false;
        return;
      }
      else if (form.value.end_time - form.value.start_time < 900) {
        this.toastr.error('Select minimum 15 minutes', '');
        this.isOTALoading = false;
        return;
      }
      else if (form.value.end_time - form.value.start_time > 86400) {
        this.toastr.error('Select maximum 1 day', '');
        this.isOTALoading = false;
        return;
      }
      else {

      }
      form.value.enclosure_id = this.enclosure_id;
      form.value.check_duration = 1;
      this.addOTADetail = form.value;
      this.encDetailService.addOTAUser(form.value)
        .subscribe((res) => {
          this.existingOTA == true;
          if (res['status'] == 1) {
            if (res['data']) {
              if (res['data'].length == 0) {
                form.value.check_duration = 0;
                this.isOTALoading = true;
                this.encDetailService.addOTAUser(form.value).subscribe((secRes) => {
                  if (secRes['status'] == 1) {
                    this.toastr.success(secRes['msg'], '');
                    this.isOTALoading = true;
                    this.ngxSmartModalService.getModal('onetimeaccess').close();
                    this.getAccessViewByUid(this.enclosure_id);
                  }
                  else {
                    this.isOTALoading = false;
                    this.toastr.error(secRes['msg'], '');
                  }
                });
              }
              else {
                this.isOTALoading = false;
                this.existingOTA = true;
                var len = 0;
                if (res['data']) {
                  this.existingOTAAccess = res['data'];
                }
                this.ngxSmartModalService.getModal('existingOTADetail').open();
              }
            }
            else {
              this.isOTALoading = true;
              this.existingOTA = false;
              this.toastr.success(res['msg'], '');
              this.ngxSmartModalService.getModal('onetimeaccess').close();
              this.getAccessViewByUid(this.enclosure_id);
            }
          }
          else {
            this.isOTALoading = false;
            this.toastr.error(res['msg'], '');
          }
        })
    }
  }
}
