/**
 * @Author: Jitesh Prajapati <Jitesh_Prajapati>
 * @Date:   2021-03-18T15:28:19+05:30
 * @Email:  jitesh.prajapati@teksun.com
 * @Filename: get_parcel_delivery.pipe.ts
 * @Last modified by:   Jitesh_Prajapati
 * @Last modified time: 2021-03-24T10:31:54+05:30
 * @Copyright: Teksun INC <https://teksun.com/>
 */



import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ParcelDeliveryStatus' })

export class GetParcelDeliveryPipe implements PipeTransform {

    transform(value: any, arg1: any) {
        if (value && !isNaN(value)) {
            if (value == 1) {
                return arg1 == 1 ? 'Accepted' : arg1 == 2 ? 'Delivery Request Accepted. Yay!' : arg1 == 3 ? 'accept-info' : arg1 == 4 ? 'done' : '';
            }
            else if (value == 2) {
                return arg1 == 1 ? 'Rejected' : arg1 == 2 ? 'Delivery Request Rejected.' : arg1 == 3 ? 'reject-info' : arg1 == 4 ? 'close' : '';
            }
            else if (value == 3) {
                return arg1 == 1 ? 'Released' : arg1 == 2 ? 'Drone Released. Yay!' : arg1 == 3 ? 'release-info' : arg1 == 4 ? 'launch' : '';
            }
            else if (value == 4) {
                return arg1 == 1 ? 'Scanned' : arg1 == 2 ? 'QR code scanned! Open drone door request has been sent to device.' : arg1 == 3 ? 'scan-info' : arg1 == 4 ? 'qr_code_scanner' : '';
            }
            else if (value == 5) {
                return arg1 == 1 ? 'Unlocked' : arg1 == 2 ? 'Drone door opened!' : arg1 == 3 ? 'notopen-info' : arg1 == 4 ? 'lock' : '';
            }
            else if (value == 6) {
                return arg1 == 1 ? 'Locked' : arg1 == 2 ? 'Drone door not able to open!' : arg1 == 3 ? 'notopen-info' : arg1 == 4 ? 'lock' : '';
            }
            else if (value == 7) {
                return arg1 == 1 ? 'Delivered' : arg1 == 2 ? 'Package Delivered Successfully. Yay!' : arg1 == 3 ? 'delivered-info' : arg1 == 4 ? 'done' : '';
            }
            else if (value == 8) {
                return arg1 == 1 ? 'Error' : arg1 == 2 ? 'Parcel is not delivered!' : arg1 == 3 ? 'error-info' : arg1 == 4 ? 'error_outline' : '';
            }
            else if (value == 9) {
                return arg1 == 1 ? 'Error' : arg1 == 2 ? 'Delivery Error!' : arg1 == 3 ? 'error-info' : arg1 == 4 ? 'error_outline' : '';
            }
            else {
                return '----';
            }
        } else {
            return '----';
        }
    }
}
