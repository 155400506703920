<!-- 
    https://docs.mapbox.com/mapbox-gl-js/example/animate-point-along-route/ 
    https://www.youtube.com/watch?v=gdKWeboPxgY
-->
<div class="container-fluid">
    <div class="main mx-2 mb-4">
        <div class="row">
            <div class="col-12">
                <div class="title-wrapper title-border-bottom mt-2">
                    <h1 class="title-1">
                        <button class="back-btn" (click)="backClicked()"><span
                                class="material-icons">keyboard_arrow_left</span></button>
                         Live Tracking
                    </h1>
                    <div class="grant-wrap">
                        <button class="delete-btn grant-btn" (click)="startDrone()">
                            <em class="delete-btn-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.592"
                                    viewBox="0 0 18 18.592">
                                    <defs>
                                        <style>
                                            .a {
                                                fill: #ffffff;
                                            }
                                        </style>
                                    </defs>
                                    <path class="a"
                                        d="M12.232,11.159l7.433,7.433H4.8Zm8.215-2.7v9.348l-7.433-7.433L14.5,8.892l-.085-.1c-.2-.245-.409-.5-.609-.765L4.017,17.811V2.531a.368.368,0,0,1,.368-.368h8.948A4.718,4.718,0,0,1,22.017,4.72,5.754,5.754,0,0,1,20.447,8.462ZM11.264,5.927H8.533v1.04h1.608a1.69,1.69,0,1,1-1.608-2.21,1.674,1.674,0,0,1,1.03.35l.634-.824a2.7,2.7,0,0,0-1.664-.566,2.73,2.73,0,1,0,2.73,2.73v-.52ZM20.912,4.72a3.615,3.615,0,0,0-7.23,0c0,3,3.394,3.861,3.394,7.436h.443C17.518,8.581,20.912,7.721,20.912,4.72ZM17.3,3.067a1.409,1.409,0,1,0,1.409,1.409A1.409,1.409,0,0,0,17.3,3.067Z"
                                        transform="translate(-4.017)" />
                                </svg>
                            </em>
                            <span>Drone</span><span>Live Tracking</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <form #streetaddress="ngForm" (ngSubmit)="getLatLongFromAddress(streetaddress)"
                    class="modal-form forwarddelivery-form">
                    <div class="modal-input">
                        <label>
                            Street Address<sup class="sup">*</sup></label>
                        <mat-form-field floatLabel="never" appearance="standard">
                            <input matInput placeholder="72 East, Athens,USA" name="street_address"
                                #street_address="ngModel"
                                [(ngModel)]="service.LiveTrackingAddressFormData.street_address"
                                [ngClass]="{'is-invalid': !street_address.valid && (street_address.dirty || street_address.touched || isAddressSubmit)}"
                                required>
                            <mat-error
                                *ngIf="!street_address.valid && (street_address.dirty || street_address.touched || isAddressSubmit )">
                                Please enter street address
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="d-flex justify-content-center">
                        <button [disabled]="isAddressSubmit" class="add-btn"><em class="mr-2"><img
                                    src="assets/images/temp-access.svg" alt=""></em> Get Latlong</button>
                    </div>
                </form>
            </div>
            <div class="col-md-2">
                <form #latlang="ngForm" (ngSubmit)="getAddressFromLatLang(latlang)"
                    class="modal-form forwarddelivery-form">
                    <div class="modal-input">
                        <label>
                            Latitude<sup class="sup">*</sup></label>
                        <mat-form-field floatLabel="never" appearance="standard">
                            <input matInput placeholder="Ex 27.0252" name="latitude" #latitude="ngModel"
                                [(ngModel)]="service.LiveTrackingLatLangFormData.latitude"
                                [ngClass]="{'is-invalid': !latitude.valid && (latitude.dirty || latitude.touched || isLatLangSubmit)}"
                                required>
                            <mat-error
                                *ngIf="!latitude.valid && (latitude.dirty || latitude.touched || isLatLangSubmit )">
                                Please enter street latitude
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="modal-input">
                        <label>
                            Longitude<sup class="sup">*</sup></label>
                        <mat-form-field floatLabel="never" appearance="standard">
                            <input matInput placeholder="Ex. 72.25456" name="longitude" #longitude="ngModel"
                                [(ngModel)]="service.LiveTrackingLatLangFormData.longitude"
                                [ngClass]="{'is-invalid': !longitude.valid && (longitude.dirty || longitude.touched || isLatLangSubmit)}"
                                required>
                            <mat-error
                                *ngIf="!longitude.valid && (longitude.dirty || longitude.touched || isLatLangSubmit )">
                                Please enter longitude
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="d-flex justify-content-center">
                        <button [disabled]="isLatLangSubmit" class="add-btn"><em class="mr-2"><img
                                    src="assets/images/temp-access.svg" alt=""></em> Get Address</button>
                    </div>
                </form>
            </div>
            <div class="col-md-2">
                <form #livetracking="ngForm" (ngSubmit)="setLiveTracking(livetracking)"
                    class="modal-form forwarddelivery-form">
                    <div class="modal-input">
                        <label>
                            Start Address<sup class="sup">*</sup></label>
                        <mat-form-field floatLabel="never" appearance="standard">
                            <input matInput placeholder="Ex. 72 East, Athens,USA" name="start_address"
                                #start_address="ngModel"
                                [(ngModel)]="service.LiveTrackingDeliveryFormData.start_address"
                                [ngClass]="{'is-invalid': !start_address.valid && (start_address.dirty || start_address.touched || isDeliverySubmit)}"
                                required>
                            <mat-error
                                *ngIf="!start_address.valid && (start_address.dirty || start_address.touched || isDeliverySubmit )">
                                Please enter street start_address
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="modal-input">
                        <label>
                            End Address<sup class="sup">*</sup></label>
                        <mat-form-field floatLabel="never" appearance="standard">
                            <input matInput placeholder="Ex. 72 East, Athens,USA" name="end_address"
                                #end_address="ngModel" [(ngModel)]="service.LiveTrackingDeliveryFormData.end_address"
                                [ngClass]="{'is-invalid': !end_address.valid && (end_address.dirty || end_address.touched || isDeliverySubmit)}"
                                required>
                            <mat-error
                                *ngIf="!end_address.valid && (end_address.dirty || end_address.touched || isDeliverySubmit )">
                                Please enter end_address
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="d-flex justify-content-center">
                        <button [disabled]="isDeliverySubmit" class="add-btn"><em class="mr-2"><img
                                    src="assets/images/temp-access.svg" alt=""></em> Set Live Tracking</button>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <div class="app-card">
                    <div class="app-card-body">
                        <div id="map" class="marker"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>