<div class="container-fluid">
  <div class="main mx-2 mb-4">
    <div class="row">
      <div class="col-12">
        <div class="title-wrapper title-border-bottom mt-2">
          <h1 class="title-1">
            <button class="back-btn" (click)="backClicked()">
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
            <em class="app-card-icon app-card-icon-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="13.655"
                viewBox="0 0 18 13.655"
              >
                <defs>
                  <style>
                    .a {
                      fill: #e63946;
                    }
                  </style>
                </defs>
                <g transform="translate(0 -73.862)">
                  <g transform="translate(0 73.862)">
                    <path
                      class="a"
                      d="M1.862,73.862a1.862,1.862,0,1,0,1.862,1.862A1.862,1.862,0,0,0,1.862,73.862Zm0,4.965A1.862,1.862,0,1,0,3.724,80.69,1.862,1.862,0,0,0,1.862,78.827Zm0,4.966a1.862,1.862,0,1,0,1.862,1.862A1.862,1.862,0,0,0,1.862,83.793Zm4.966-6.828h9.931a1.241,1.241,0,0,0,0-2.483H6.828a1.241,1.241,0,1,0,0,2.483Zm9.931,2.483H6.828a1.241,1.241,0,1,0,0,2.483h9.931a1.241,1.241,0,0,0,0-2.483Zm0,4.966H6.828a1.241,1.241,0,1,0,0,2.483h9.931a1.241,1.241,0,1,0,0-2.483Z"
                      transform="translate(0 -73.862)"
                    />
                  </g>
                </g>
              </svg>
            </em>
            Past {{ type_delivery }} Forward Delivery
          </h1>
          <div class="sub-title-wrapper">
            <div class="encl-name-subtitle">
              {{ enclosure_details?.enclosure_name }}
            </div>
            <div class="title-access">
              <span
                class="access-card"
                [ngClass]="{
                  'access-green': user_access == 1,
                  'access-orange': user_access == 2,
                  'access-purple': user_access == 3,
                  'access-gray': user_access == 4
                }"
              >
                {{ user_access | accessNamePipe: user_access }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="warning-alert">
      <span class="material-icons">warning_amber</span>
      <p class="warning-alert-text">
        <strong>Warning:</strong>Recently deleted/expired forward delivery will
        be permanently delete after 30 days!.
      </p>
    </div>
    <div class="row row-eq-height">
      <div
        class="col-xl-3 col-lg-4 col-md-6 col-sm-6 app-box"
        *ngFor="let fwData of forwarded_deliveries"
      >
        <div class="app-card forwarddeliverylist-card">
          <div class="app-card-top forwarddeliverylist-card-top">
            <h2 class="app-card-title">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-end
                  flex-wrap
                  text-wrap
                "
              >
                <div class="text-title">{{ fwData?.user_name }}</div>
                <div
                  class="table-btn-wrap forward-delivery-btn-wrap"
                  *ngIf="type == 'Out Going'"
                >
                  <button
                    class="btn-delivered btn-table"
                    (click)="openForwardDeliveryModal(fwData)"
                  >
                    <span class="svg-icon"
                      ><img src="assets/images/forward-access.svg" alt=""
                    /></span>
                  </button>
                  <button
                    class="btn-delete btn-table delete-icon-btn"
                    (click)="openPerDeleteModal(fwData.fwd_id)"
                  >
                    <span class="material-icons">delete_forever</span>
                  </button>
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-items-end flex-wrap"
              >
                <span>{{ fwData?.user_email }}</span>
                <p class="forwarddeliverylist-id" *ngIf="type == 'Incoming'">
                  {{ fwData?.enclosure_id }}
                </p>
                <p class="forwarddeliverylist-id" *ngIf="type != 'Incoming'">
                  {{ fwData?.forward_enclosure_id }}
                </p>
              </div>
            </h2>
          </div>
          <div class="app-card-body">
            <div class="enclosurelist-card-body">
              <div class="forwarddeliverylist-date">
                <div class="forwarddeliverylist-date-wrap">
                  <span
                    ><img src="assets/images/calendar.svg" alt="" /><strong
                      class="clr-green"
                      >From</strong
                    ></span
                  >
                  {{
                    fwData?.forward_start_time * 1000 | date: "d MMM, y h:mm a"
                  }}
                </div>
                <div class="forwarddeliverylist-date-wrap">
                  <span
                    ><img src="assets/images/calendar.svg" alt="" /><strong
                      class="clr-red"
                      >Until</strong
                    ></span
                  >
                  {{
                    fwData?.forward_end_time * 1000 | date: "d MMM, y h:mm a"
                  }}
                </div>
                <div class="forwarddeliverylist-requested-date-wrap">
                  <span
                    ><img src="assets/images/calendar.svg" alt="" /><strong
                      class="clr-info"
                      >{{ fwData?.request_status == 4 ? "Deleted" : "Expired" }}
                    </strong></span
                  >
                  {{ fwData?.updated_at * 1000 | date: "d MMM, y h:mm a" }}
                </div>
              </div>

              <p class="forwarddeliverylist-id-status"
                [ngClass]="[
                  fwData.request_status == 0 ? 'pending' : '',
                  fwData.request_status == 1 ? 'accepted' : '',
                  fwData.request_status == 2 ? 'rejected' : '',
                  fwData.request_status == 3 ? 'completed' : '',
                  fwData.request_status == 4 ? 'deleted' : '',
                  fwData.request_status == 5 ? 'auto-expired' : '',
                  fwData.request_status == 6 ? 'default-expired' : '',
                  fwData.request_status == 7 ? 'ongoing' : '',
                  fwData.request_status == 8 ? 'auto-cancelled' : ''
                ]">
                <em class="material-icons">
                  {{
                    fwData?.request_status == 0
                      ? "priority_high"
                      : fwData?.request_status == 1
                      ? "how_to_reg"
                      : fwData?.request_status == 2
                      ? "person_add_disabled"
                      : fwData?.request_status == 3
                      ? "done"
                      : fwData?.request_status == 4
                      ? "delete_outline"
                      : fwData?.request_status == 5
                      ? "error_outline"
                      : fwData?.request_status == 6
                      ? "error_outline"
                      : fwData?.request_status == 7
                      ? "query_builder"
                      : fwData?.request_status == 8
                      ? "block"
                      : ""
                  }}</em>
                <span class="pt-1">{{ fwData?.request_status | forwardDeliveryStatus }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-12 drone-nodata-box mt-3 mx-3"
        *ngIf="forwarded_deliveries?.length == 0"
      >
        <div class="drone-nodata-box-wrap">
          <div class="drone-nodata-box-cover">
            <div class="drone-nodata-cover">
              <span class="material-icons">error_outline</span>
              <p class="drone-nodata-text">
                No past {{ type_delivery }} forward delivery exist!!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-smart-modal
  #perDeleteAccess
  [identifier]="'perDeleteAccess'"
  [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal delete-modal"
>
  <p class="modal-text">
    Are you sure you want to permanently delete this history data?
  </p>
  <div class="d-flex justify-content-center delete-bottom">
    <button
      class="delete-btns yes-btn mr-2"
      (click)="permanentDeleteAccess(accessDetail)"
    >
      Yes
    </button>
    <button
      class="delete-btns no-btn"
      (click)="ngxSmartModalService.getModal('perDeleteAccess').close()"
    >
      No
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #forwarddelivery
  [identifier]="'forwarddelivery'"
  [dismissable]="false"
  customClass="nsm-dialog-animation-ttb nsm-centered modal forwarddelivery-modal"
>
  <h2 class="modal-title">Forward Delivery</h2>
  <form
    #addForwardForm="ngForm"
    (ngSubmit)="addForward(addForwardForm)"
    class="modal-form forwarddelivery-form"
  >
    <div class="modal-input">
      <label>
        <em>
          <img src="assets/images/forward-email-address.svg" alt="" />
        </em>
        Forwarded Email Address <sup>*</sup></label
      >
      <mat-form-field floatLabel="never" appearance="standard">
        <input
          maxlength="50"
          type="email"
          pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
          matInput
          placeholder="Ex. abc@gmail.com"
          name="forward_user_email"
          #forward_user_email="ngModel"
          [(ngModel)]="service.ForwardDeliveryFormData.forward_user_email"
          [ngClass]="{
            'is-invalid':
              !forward_user_email.valid &&
              (forward_user_email.dirty ||
                forward_user_email.touched ||
                isForwardSubmit)
          }"
          required
        />
        <mat-error
          *ngIf="
            !forward_user_email.valid &&
            (forward_user_email.dirty ||
              forward_user_email.touched ||
              isForwardSubmit)
          "
        >
          <div *ngIf="forward_user_email.errors.required">
            Please enter email address
          </div>
          <div *ngIf="forward_user_email.errors.pattern">
            Enter valid email address
          </div>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="modal-input">
      <label>
        <em>
          <img src="assets/images/encl-timezone.svg" alt="" />
        </em>
        Device Timezone<sup>*</sup></label
      >
      <div class="modal-input-subtext">{{ forwardedEncTimezone }}</div>
    </div>

    <div class="modal-input">
      <label>
        <em>
          <img src="assets/images/forward-enclosure-id.svg" alt="" />
        </em>
        Forwarded Device ID <sup>*</sup></label
      >
      <mat-form-field floatLabel="never" appearance="standard">
        <input
          readonly
          class="mask-input"
          type="text"
          [specialCharacters]="['-']"
          [dropSpecialCharacters]="false"
          mask="000-000-000"
          matInput
          placeholder="Ex. 00-14-22-01-23-45"
          name="forward_enclosure_id"
          #forward_enclosure_id="ngModel"
          [(ngModel)]="service.ForwardDeliveryFormData.forward_enclosure_id"
          [ngClass]="{
            'is-invalid':
              !forward_enclosure_id.valid &&
              (forward_enclosure_id.dirty ||
                forward_enclosure_id.touched ||
                isForwardSubmit)
          }"
          required
        />
        <mat-error
          *ngIf="
            !forward_enclosure_id.valid &&
            (forward_enclosure_id.dirty ||
              forward_enclosure_id.touched ||
              isForwardSubmit)
          "
        >
          Please enter device id
        </mat-error>
      </mat-form-field>
    </div>
    <div class="modal-input">
      <label>
        <em>
          <img src="assets/images/calendar.svg" alt="" />
        </em>
        Forward <span class="clr-green">From</span> <sup>*</sup></label
      >
      <mat-form-field floatLabel="never" appearance="standard">
        <input
          hidden
          matInput
          [ngxMatDatetimePicker]="pickertwo"
          placeholder="Ex. 12/15/2020, 4:05:19 PM"
          [min]="currentDate"
          [max]="maxDate"
          [disabled]="disabled"
          name="forward_start_time"
          #forward_start_time="ngModel"
          [(ngModel)]="service.ForwardDeliveryFormData.forward_start_time"
          [ngClass]="{
            'is-invalid':
              !forward_start_time.valid &&
              (forward_start_time.dirty ||
                forward_start_time.touched ||
                isForwardSubmit)
          }"
          required
        />
        <div class="date-block" (click)="pickertwo.open()">
          <span class="date-notinserted">Ex. 12/15/2020, 4:05 PM</span>
          <span class="date-inserted">{{
            service.ForwardDeliveryFormData.forward_start_time
              | date: "M/d/yy, h:mm a"
          }}</span>
        </div>
        <mat-error
          *ngIf="
            !forward_start_time.valid &&
            (forward_start_time.dirty ||
              forward_start_time.touched ||
              isForwardSubmit)
          "
        >
          Please select start time
        </mat-error>
        <mat-datepicker-toggle
          matSuffix
          [for]="pickertwo"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #pickertwo
          [showSpinners]="showSpinners"
          [showSeconds]="showSeconds"
          [stepHour]="stepHour"
          [stepMinute]="stepMinute"
          [stepSecond]="stepSecond"
          [touchUi]="touchUi"
          [color]="color"
          [enableMeridian]="true"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
    <div class="modal-input">
      <label>
        <em>
          <img src="assets/images/calendar.svg" alt="" />
        </em>
        Forward <span class="clr-red">Until</span> <sup>*</sup></label
      >
      <mat-form-field floatLabel="never" appearance="standard">
        <input
          hidden
          matInput
          [ngxMatDatetimePicker]="picker3"
          placeholder="Ex. 12/15/2020, 4:05:19 PM"
          [min]="currentDate"
          [max]="maxDate"
          [disabled]="disabled"
          name="forward_end_time"
          #forward_end_time="ngModel"
          [(ngModel)]="service.ForwardDeliveryFormData.forward_end_time"
          [ngClass]="{
            'is-invalid':
              !forward_end_time.valid &&
              (forward_end_time.dirty ||
                forward_end_time.touched ||
                isForwardSubmit)
          }"
          required
        />
        <div class="date-block" (click)="picker3.open()">
          <span class="date-notinserted">Ex. 12/15/2020, 4:05 PM</span>
          <span class="date-inserted">{{
            service.ForwardDeliveryFormData.forward_end_time
              | date: "M/d/yy, h:mm a"
          }}</span>
        </div>
        <mat-error
          *ngIf="
            !forward_end_time.valid &&
            (forward_end_time.dirty ||
              forward_end_time.touched ||
              isForwardSubmit)
          "
        >
          Please select end time
        </mat-error>
        <mat-datepicker-toggle
          matSuffix
          [for]="picker3"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker3
          [showSpinners]="showSpinners"
          [showSeconds]="showSeconds"
          [stepHour]="stepHour"
          [stepMinute]="stepMinute"
          [stepSecond]="stepSecond"
          [touchUi]="touchUi"
          [color]="color"
          [enableMeridian]="true"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-center">
      <button [disabled]="isForwardLoading" class="add-btn">
        Send Request
      </button>
    </div>
  </form>
</ngx-smart-modal>
