// my-loader.component.ts
import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnInit {

  loading: boolean;
  constructor(private loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((val: boolean) => {
      this.loading = val;
    });
  }
  ngOnInit() {
  }
}
