<div class="auth">
    <div class="auth-wrap auth-wrap-register">
        <div class="auth-block">
            <div class="auth-img"><img src="assets/images/arrive-map.png" alt=""></div>
        </div>
        <div class="auth-block">
            <div class="auth-logo">
                <img src="assets/images/arrive_logo_black.png" alt="Logo" width="260" />
            </div>
            <h2 class="auth-title text-left w-100">Enter your details to register your account or login below:</h2>
            <form #registerForm="ngForm"
                (ngSubmit)="doRegister(registerForm.form.controls['user_mobile']?.invalid,registerForm)"
                class="auth-form">
                <div class="auth-form-body auth-form-register-body">
                    <div class="auth-form-inner-body auth-form-register-inner-body">
                        <div class="auth-register-form-control">
                            <label>Full Name<sup>*</sup></label>
                            <mat-form-field floatLabel="never" appearance="standard">
                                <input minlength="3" pattern="^[^\s].+[^\s]$" maxlength="25" matInput placeholder="Ex. John Doe" name="user_name"
                                    #user_name="ngModel" [(ngModel)]="service.RegisterFormData.user_name"
                                    [ngClass]="{'is-invalid': !user_name.valid && (user_name.dirty || user_name.touched || isSubmit)}"
                                    required>
                                <mat-error
                                    *ngIf="!user_name.valid && (user_name.dirty || user_name.touched || isSubmit )">
                                    <div *ngIf="user_name.errors.required">
                                        Please enter full name
                                    </div>
                                    <div *ngIf="user_name.errors.maxlength">
                                        name should be maximum 25 characters
                                    </div>
                                    <div *ngIf="user_name.errors.minlength">
                                        Have at least 3 characters
                                    </div>
                                    <div *ngIf="!user_name.errors.minlength && user_name.errors.pattern">
                                        You can not enter blank space after or before the input value
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="auth-register-form-control">
                            <label>Email Address<sup>*</sup></label>
                            <mat-form-field floatLabel="never" appearance="standard">
                                <input maxlength="64" type="email"
                                    pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" matInput
                                    placeholder="Ex. abc@gmail.com" name="user_email" #user_email="ngModel"
                                    [(ngModel)]="service.RegisterFormData.user_email"
                                    [ngClass]="{'is-invalid': !user_email.valid && (user_email.dirty || user_email.touched || isSubmit)}"
                                    required>
                                <mat-error
                                    *ngIf="!user_email.valid && (user_email.dirty || user_email.touched || isSubmit )">
                                    <div *ngIf="user_email.errors.required">
                                        Please enter email address
                                    </div>
                                    <div *ngIf="user_email.errors.pattern">
                                        Enter valid email address
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="auth-form-inner-body auth-form-register-inner-body">
                        <div class="auth-register-form-control">
                            <label>Password<sup>*</sup></label>
                            <mat-form-field floatLabel="never" appearance="standard">
                                <input minlength="6"
                                    pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*"
                                    maxlength="25" matInput placeholder="Ex. qk*%yEn8Q"
                                    [type]="hide ? 'password' : 'text'" name="password" name="user_password"
                                    #user_password="ngModel" [(ngModel)]="service.RegisterFormData.user_password"
                                    [ngClass]="{'is-invalid': !user_password.valid && (user_password.dirty || user_password.touched || isSubmit)}"
                                    required>
                                <mat-error *ngIf="!user_password.valid && (user_password.dirty || isSubmit )">
                                    <div *ngIf="user_password.errors.required">
                                        Please enter password
                                    </div>
                                    <div *ngIf="user_password.errors.minlength">
                                        Have at least 6 characters
                                    </div>
                                    <div *ngIf="!user_password.errors.minlength && user_password.errors.pattern">
                                        Include at least 1 uppercase letter, 1 lowercase letter, 1 number or 1 special
                                        char
                                    </div>
                                </mat-error>

                                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                                </mat-icon>
                                <mat-icon matSuffix class="pass-info" matTooltip="Password must contain 6 to 25 characters which contain at
                            least one lowercase letter, one uppercase letter, one numeric digit, and one
                            special character
                        " matTooltipPosition="below">info</mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="auth-register-form-control">
                            <label>Confirm Password<sup>*</sup></label>
                            <mat-form-field floatLabel="never" appearance="standard">
                                <input maxlength="25" [type]="chide ? 'password' : 'text'"
                                    (blur)="onKey($event,service.RegisterFormData.user_password)" matInput
                                    name="user_confirm_password" #user_confirm_password="ngModel"
                                    [(ngModel)]="service.RegisterFormData.user_confirm_password"
                                    placeholder="Confirm Password"
                                    [ngClass]="{'is-invalid':(confirmPwdErr == true || confirmPwdErr == 'true') || (!user_confirm_password.valid && (user_confirm_password.dirty || user_confirm_password.touched || isSubmit))}"
                                    required>
                                <mat-icon matSuffix (click)="chide = !chide" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="chide" mat-icon-button>
                                    {{chide ? 'visibility_off' : 'visibility'}}
                                </mat-icon>

                                <div class="error confirm-error"
                                    *ngIf="(confirmPwdErr == true) && user_confirm_password.valid">
                                    Confirm password does not match with password
                                </div>

                                <mat-error
                                    *ngIf="!user_confirm_password.valid && (user_confirm_password.dirty || user_confirm_password.touched || isSubmit )">
                                    <div *ngIf="user_confirm_password.errors.required">
                                        Confirm password is required
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="auth-form-inner-body auth-form-register-inner-body">
                        <div class="auth-register-form-control">
                            <label>Contact Number<sup>*</sup></label>
                            <div class="phoneform">
                                <ngx-intl-tel-input [cssClass]="'custom'" [separateDialCode]="false"
                                    [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                                    [searchCountryFlag]="false"
                                    [selectFirstCountry]="false" [selectedCountryISO]="countrySortName" [maxLength]="20"
                                    [tooltipField]="TooltipLabel.Name" [phoneValidation]="true" name="user_mobile"
                                    [(ngModel)]="service.RegisterFormData.user_mobile" required (ngModelChange)="checkNumber($event,service.RegisterFormData.user_mobile)">
                                </ngx-intl-tel-input>
                            </div>
                            <div class="ngx-error">
                                <strong
                                    *ngIf="!(registerForm.form.controls['user_mobile']?.invalid == true && phoneError == true)&& (registerForm.form.controls['user_mobile']?.touched && registerForm.form.controls['user_mobile']?.invalid == true)">Please
                                    enter valid contact number</strong>
                                <strong
                                    *ngIf="registerForm.form.controls['user_mobile']?.invalid == true && phoneError == true">Please
                                    enter valid contact number</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="auth-form-bottom">
                    <mat-checkbox name="terms_condition" [(ngModel)]="service.RegisterFormData.terms_condition"
                        #terms_condition="ngModel"
                        [ngClass]="{'is-invalid': terms_condition?.invalid && (terms_condition.dirty || terms_condition.touched || isSubmit)}"
                        required>I agree to the <strong>Terms of Service</strong> and <strong>Privacy Policy</strong></mat-checkbox>
                    <mat-error
                        *ngIf="!terms_condition.valid && (terms_condition.dirty || terms_condition.touched || isSubmit )">
                        Please select terms & conditions
                    </mat-error>
                </div>
                <button [disabled]="isLoading"  class="auth-btn">Register</button>
            </form>
            <p>Already have an account? <button routerLink="/login">Login</button></p>
        </div>
    </div>
</div>
