/**
 * @Author: Jitesh Prajapati <Jitesh_Prajapati>
 * @Date:   2021-03-18T15:28:19+05:30
 * @Email:  jitesh.prajapati@teksun.com
 * @Filename: get_parcel_delivery.pipe.ts
 * @Last modified by:   Jitesh_Prajapati
 * @Last modified time: 2021-03-24T10:31:54+05:30
 * @Copyright: Teksun INC <https://teksun.com/>
 */



 import { Pipe, PipeTransform } from '@angular/core';

 @Pipe({ name: 'TempStatus' })
 
 export class TempStatus implements PipeTransform {
 
     transform(value: any,arg1: any) {
         //console.log(typeof(value),"val of forward status",value,arg1);
         if (value) {
 
             if (value == 'CL') {
                 return arg1== 1 ? 'C'  : arg1==2 ? 'celsius.svg' : '';
             }
             else if (value == 'FH') {
                 return arg1==1 ? 'F'  : arg1==2 ? 'fahrenheit.svg' :  '';
             } 
             else{
                 return '';
             }
         } else {
             //console.log(value,"no val");
             return '';
         }
     
 
     }
 }
 