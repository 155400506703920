import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }
  getnotifications() {
    return this.http.get(environment.api_url + '/notifications');
  }

  getNotificationCount(){
    return this.http.get(environment.api_url + '/notifications/count');
  }

  readNotification(id) {
    return this.http.put(environment.api_url + '/notifications/read/'+id,{});
  }

  allNotification() {
    return this.http.get(environment.api_url + '/notifications/settings');
  }

  updateNoti(data) {
    return this.http.put(environment.api_url + '/notifications/settings/'+data.type, data);
  }
  updateNotiToggle(data) {
    return this.http.put(environment.api_url + '/notifications/settings/master-control/'+data.type, data);
  }
}
