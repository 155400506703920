import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from '../../environments/environment';
import { FormControl } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../services/loader.service';


@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html'
})
export class ResetpasswordComponent implements OnInit {
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[2]);
  loading: boolean;
  constructor(private loaderService: LoaderService, private toastr: ToastrService, public service: AuthService, public route: ActivatedRoute, public router: Router) {
    this.loaderService.isLoading.subscribe((val: boolean) => {
      this.loading = val;
    });
  }
  errMsg: string;
  public email: any;
  confirmPwdErr: any = false;
  pwdHide = true;
  cnfPwdHide = true;
  isSubmit: boolean = false;
  isLoading: boolean = false;
  @ViewChild('resetPwdForm1', { static: true }) resetPwdForm1: NgForm;
  ngOnInit() {
    this.resetPwdForm();
    const resetToken = this.route.snapshot.params.token;
    const expire_time = atob(this.route.snapshot.params.expire_time);
    const epochNow = Math.round((new Date).getTime() / 1000);//(new Date).getTime()/1000;    
    if (epochNow < parseInt(expire_time)) {
      this.service.checkToken(resetToken).subscribe(res => {
        if (res['status'] == 1) {
          this.email = res['data']['user_email'];
        }
        else {
          this.toastr.error(res['msg'], '');
          setTimeout(() => {
            if (res['data'] && res['data']['user_type'] && res['data']['user_type'] == 1) {
              window.location.href = environment.login_page + '/super-admin';
            }
            else if (res['data'] && res['data']['user_type'] && res['data']['user_type'] == 3) {
              window.location.href = environment.login_page + '/delivery-service';
            }
            else {
              this.router.navigate(['/login']);
            }
          }, 2000);
        }
      });
    } else {
      this.toastr.error(environment.token_expire, '');//"Token has been expired! please try again";
      setTimeout(() => {
        this.router.navigate(['login']);
      }, 5000);
    }
  }
  onKey(event: any, pwd) { // without type info
    if (event.target.value && event.target.value != '') {
      if (event.target.value === pwd) {
        this.confirmPwdErr = false;
      } else {
        this.confirmPwdErr = true;
      }
    }
  }
  resetPwdForm(form?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.service.resetPasswordFormData = {
      cpassword: '',
      user_password: ''
    };
  }
  checkPassword(event: any, pwd) {
    if (pwd && pwd != '') {
      if (event.target.value === pwd) {
        this.confirmPwdErr = false;
      } else {
        this.confirmPwdErr = true;
      }
    }
  }
  doReset(form: NgForm) {
    if (!form.valid || form.value.user_password != form.value.cpassword) {
      this.isSubmit = true;
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    const token = this.route.snapshot.params.token;
    form.value.token_id = token;

    this.service.Reset(form.value).subscribe(res => {
      if (res['status'] == 1) {
        this.toastr.success(res['msg'], '');
        setTimeout(() => {
          if (res['data'] && res['data']['user_type'] && res['data']['user_type'] == 1) {
            window.location.href = environment.SuperAdmin_login + 'login';
          }
          else if (res['data'] && res['data']['user_type'] && res['data']['user_type'] == 3) {
            window.location.href = environment.Delivery_login + 'login';
            
          }
          else {
            this.router.navigate(['/login']);
          }
        }, 3000);
      }
      if (res['status'] == 0) {
        this.isLoading = false;
        this.toastr.error(res['msg'], '');
      }
    });
  }
}
