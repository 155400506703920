<div class="container-fluid">
  <div class="main mx-2 mb-4">
    <div class="row">
      <div class="col-12">
        <div class="title-wrapper title-border-bottom mt-2">
          <h1 class="title-1">
            Welcome,
            <span class="title1-sub">{{ login_user_name }}</span>
            <span class="title-datetime">{{
              currentDate | date: "EEEE, d MMMM y"
            }}</span>
          </h1>
          <button class="add-btn" (click)="openAddEncModal()">
            <em class="material-icons">add</em>
            <span class="pt-1">Add Device</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row row-eq-height app-card-list-wrapper">
      <div *ngFor="let item of encList; index as i" class="col-xl-3 col-lg-4 col-md-6 col-sm-6 app-box">
        <div class="app-card enclosurelist-card" [ngClass]="{
            enc_fault:
              item.enclosure_fault == '1' ||
              item.theft_detection == '1' ||
              item.internet_connectivity == 0
          }">
          <div class="app-card-top enclosurelist-card-top">
            <h2 class="app-card-title w-100">
              <div class="app-card-title-wrap">
                {{ item.enclosure_name }}
                <span *ngIf="
                    item.enclosure_fault == '1' ||
                    item.theft_detection == '1' ||
                    item.internet_connectivity == 0
                  "><img src="assets/images/warning.svg" alt="" /></span>
              </div>
              <span>{{ item.enclosure_id }}</span>
              <button class="app-forward-btn" *ngIf="item.forward_start_time" (click)="viewForwardModal(item)">
                <span class="material-icons"><img src="assets/images/forward.svg" alt="" /></span>
                <span class="app-forward-btn-text">Forwarded</span>
              </button>
            </h2>
            <button class="app-card-settings-icon" (click)="
                enclosureMoreDetail(
                  item.enclosure_id,
                  item.user_access,
                  item.user_id
                )
              ">
              <em class="app-card-icon app-card-icon-right">
                <span class="material-icons settings-icon"><img src="assets/images/setting-arrow.svg" alt="" /></span>
              </em>
            </button>
          </div>
          <div class="app-card-doorstatus-wrap">
            <h3 class="app-card-doorstatus-title">
              <span>User Door</span>
            </h3>
            <div class="app-card-doorstatus-inner">
              <button (click)="doorOpenClose(item)" [ngClass]="
                  item?.user_door_status == 1
                    ? 'door_statusclosed'
                    : 'door_statusopen'
                ">
                <span *ngIf="item?.user_door_status == 1"></span>
                <span *ngIf="item?.user_door_status == 0"></span>
              </button>
              <div class="app-card-doorstatus-subtitle">
                <span class="app-doorstatus-info green" *ngIf="item?.user_door_status == 1"><img src="assets/images/lock-opened.svg" alt="" />Unlock</span>
                <span class="app-doorstatus-info red" *ngIf="item?.user_door_status == 0"><img src="assets/images/locked-green.svg" alt="" />Lock</span>
              </div>
            </div>
          </div>
          <div class="app-card-encllistinfo-wrap">
            <div class="app-card-info app-card-encllist-info mb-1">
              <label><em class="app-info-icon"><img src="assets/images/owner.svg" alt="" /></em>Owner</label>
              <span>{{ item.user_name }}</span>
            </div>
            <div class="app-card-info app-card-encllist-info mb-0">
              <label><em class="app-info-icon"><img src="assets/images/email-id.svg" alt="" /></em>Email</label>
              <span>{{ item.user_email }}</span>
            </div>
          </div>

          <div class="app-card-body border-top-none">
            <div class="enclosurelist-card-body">
              <div class="enclosurelist-card-wrapper">
                <h2 class="app-card-title">
                  <span>Parcel Status</span>
                  <span *ngIf="item.parcel_delivery_id != '0'">{{
                    item.parcel_delivery_id
                  }}</span>
                </h2>
                <div (click)="
                    deliveryDetail(
                      item.enclosure_id,
                      item.user_access,
                      item.user_id,
                      item.enclosure_name
                    )
                  " class="enclosurelist-delivery-status shipped-status">
                  {{ item.parcel_status | parcelStatus }}
                </div>
              </div>
              <div class="d-flex align-items-center mt-3">
                <div class="enclosurelist-status" [ngClass]="[
                    item.user_access == 1 ? 'primary-access primary-access-size' : '',
                    item.user_access == 2 ? 'secondary-access' : '',
                    item.user_access == 3 ? 'temporary-access' : '',
                    item.user_access == 4 ? 'onetime-access' : ''
                  ]">
                  {{ item.user_access | accessNamePipe: item.user_access }}
                </div>
                <button class="enclosurelist-detail-btn" (click)="
                    enclosureMoreDetail(
                      item.enclosure_id,
                      item.user_access,
                      item.user_id
                    )
                  ">
                  More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="encList && encList.length == 0" class="col-md-12 drone-nodata-box">
        <div class="drone-nodata-box-wrap">
          <div class="drone-nodata-box-cover">
            <div class="drone-nodata-cover">
              <span class="material-icons">error_outline</span>
              <p class="drone-nodata-text">No Device Added Yet</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-smart-modal #addenclosure [identifier]="'addenclosure'" [dismissable]="false" customClass="nsm-dialog-animation-ttb nsm-centered modal addenclosure-modal address-add-enclosure">
  <h2 class="modal-title">{{ addUpdateModal }}</h2>
  <form #addEnclosureForm="ngForm" (ngSubmit)="addEnclosure(addEnclosureForm)" class="modal-form addenclosure-form">
    <input hidden matInput name="_id" #_id="ngModel" [(ngModel)]="service.EnclosureListFormData._id" />
    <div class="modal-input add-input-50">
      <label>
        <em>
          <img src="assets/images/enclosure-name.svg" alt="" />
        </em>
        Device Name<sup>*</sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input minlength="3" maxlength="30" pattern="^[^\s].+[^\s]$" matInput placeholder="Ex. Dan's Device" name="enclosure_name" #enclosure_name="ngModel" [(ngModel)]="service.EnclosureListFormData.enclosure_name" [ngClass]="{
            'is-invalid':
              !enclosure_name.valid &&
              (enclosure_name.dirty || enclosure_name.touched || isSubmit)
          }" required />
        <mat-error *ngIf="
            !enclosure_name.valid &&
            (enclosure_name.dirty || enclosure_name.touched || isSubmit)
          ">
          <div *ngIf="enclosure_name.errors.required">
            Please enter device name
          </div>
          <div *ngIf="enclosure_name.errors?.minlength">
            Have at least 3 characters.
          </div>
          <div *ngIf="enclosure_name.errors?.maxlength">
            Maximum of 25 characters.
          </div>
          <div *ngIf="
              !enclosure_name.errors.minlength && enclosure_name.errors.pattern
            ">
            You can not enter blank space after or before the input value
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="modal-input modal-input-space modal-input-spacebetween add-input-50" [ngClass]="{ 'enc-disabled': addUpdateModal != 'Add Device' }">
      <label>
        <em><img src="assets/images/enclosure-id.svg" alt="" /></em>Device ID<sup>*</sup>
      </label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input [dropSpecialCharacters]="false" mask="000-000-000" matInput placeholder="Ex. 123-456-789" name="enclosure_id" #enclosure_id="ngModel" [(ngModel)]="service.EnclosureListFormData.enclosure_id" [ngClass]="{
            'is-invalid':
              !enclosure_id.valid &&
              (enclosure_id.dirty || enclosure_id.touched || isSubmit)
          }" required />

        <mat-error *ngIf="
            !enclosure_id.valid &&
            (enclosure_id.dirty || enclosure_id.touched || isSubmit)
          ">
          <div *ngIf="enclosure_id.errors.required">
            Please enter device id
          </div>

          <div *ngIf="
              !enclosure_id.errors.minlength && enclosure_id.errors.pattern
            ">
            You can not enter blank space after or before the input value
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="modal-input modal-address-title w-100">
      <label>
        <em>
          <img src="assets/images/street-address.svg" alt="" /> </em>Enter Address
      </label>
    </div>
    <div class="modal-input modal-address-padding-bottom">
      <label>Address Line 1<sup>*</sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input minlength="3" pattern="^[^\s].+[^\s]$" maxlength="200" matInput placeholder="Ex. 123 Main Street" name="address_line_a" #address_line_a="ngModel" [(ngModel)]="service.EnclosureListFormData.address_line_a" [ngClass]="{
            'is-invalid':
              !address_line_a.valid &&
              (address_line_a.dirty || address_line_a.touched || isSubmit)
          }" required />
        <mat-error *ngIf="
            !address_line_a.valid &&
            (address_line_a.dirty || address_line_a.touched || isSubmit)
          ">
          <div *ngIf="address_line_a.errors.required">
            Please enter address line 1
          </div>
          <div *ngIf="address_line_a.errors.minlength">
            Have at least 3 characters
          </div>
          <div *ngIf="
              !address_line_a.errors.minlength && address_line_a.errors.pattern
            ">
            You can not enter blank space after or before the input value
          </div>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="modal-input modal-input-space modal-input-spacebetween modal-address-padding-bottom">
      <label>Address Line 2<sup></sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input pattern="^[^\s].+[^\s]$" maxlength="200" matInput placeholder="Ex. Apt # or Suite #" name="address_line_b" #address_line_b="ngModel" [(ngModel)]="service.EnclosureListFormData.address_line_b" />
      </mat-form-field>
    </div>
    <div class="modal-input modal-input-space modal-address-padding-bottom">
      <label>City<sup>*</sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input minlength="3" pattern="^[^\s].+[^\s]$" maxlength="200" matInput placeholder="Ex. Indianapolis" name="city" #city="ngModel" [(ngModel)]="service.EnclosureListFormData.city" [ngClass]="{
            'is-invalid':
              !city.valid && (city.dirty || city.touched || isSubmit)
          }" required />
        <mat-error *ngIf="!city.valid && (city.dirty || city.touched || isSubmit)">
          <div *ngIf="city.errors.required">Please enter city</div>
          <div *ngIf="city.errors.minlength">Have at least 3 characters</div>
          <div *ngIf="!city.errors.minlength && city.errors.pattern">
            You can not enter blank space after or before the input value
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="modal-input modal-input-spacebetween modal-address-padding-bottom">
      <label>State<sup>*</sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input minlength="2" pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="200" matInput placeholder="Ex. Michigan" name="state" #state="ngModel" [(ngModel)]="service.EnclosureListFormData.state" [ngClass]="{
            'is-invalid':
              !state.valid && (state.dirty || state.touched || isSubmit)
          }" required />
        <mat-error *ngIf="!state.valid && (state.dirty || state.touched || isSubmit)">
          <div *ngIf="state.errors.required">Please enter state</div>
          <div *ngIf="state.errors.minlength">Have at least 2 characters</div>
          <div *ngIf="!state.errors.minlength && state.errors.pattern">
            You can not enter blank space after or before the input value
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="modal-input modal-input-space modal-address-padding-bottom">
      <label>Country<sup>*</sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input minlength="3" pattern="^[^\s].+[^\s]$" maxlength="200" matInput placeholder="Ex. United States" name="country" #country="ngModel" [(ngModel)]="service.EnclosureListFormData.country" [ngClass]="{
            'is-invalid':
              !country.valid && (country.dirty || country.touched || isSubmit)
          }" required />
        <mat-error *ngIf="
            !country.valid && (country.dirty || country.touched || isSubmit)
          ">
          <div *ngIf="country.errors.required">Please enter country</div>
          <div *ngIf="country.errors.minlength">Have at least 3 characters</div>
          <div *ngIf="!country.errors.minlength && country.errors.pattern">
            You can not enter blank space after or before the input value
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="modal-input modal-input-spacebetween modal-input-space modal-address-padding-bottom">
      <label>Zipcode<sup>*</sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input (keypress)="numberOnly($event)" pattern="^[0-9]{0,}$" minlength="4" maxlength="8" matInput placeholder="Ex. 656379" name="zipcode" #zipcode="ngModel" [(ngModel)]="service.EnclosureListFormData.zipcode" [ngClass]="{
            'is-invalid':
              !zipcode.valid && (zipcode.dirty || zipcode.touched || isSubmit)
          }" required />
        <mat-error *ngIf="
            !zipcode.valid && (zipcode.dirty || zipcode.touched || isSubmit)
          ">
          <div *ngIf="zipcode.errors.required">Please enter zipcode</div>
          <div *ngIf="zipcode.errors.pattern || zipcode.errors.minlength">
            Enter valid zipcode
          </div>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="modal-input modal-address-padding-bottom">
      <label>Device Latitude<sup></sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input pattern="-?\d{1,3}\.\d+" minlength="6" maxlength="20" matInput placeholder="23.0283" name="enclosure_latitude" #enclosure_latitude="ngModel" [(ngModel)]="service.EnclosureListFormData.enclosure_latitude" [ngClass]="{
            'is-invalid':
              !enclosure_latitude.valid && (enclosure_latitude.dirty || enclosure_latitude.touched || isSubmit)
          }" required/>
        <mat-error *ngIf="
            !enclosure_latitude.valid && (enclosure_latitude.dirty || enclosure_latitude.touched || isSubmit)
          ">
          <div *ngIf="enclosure_latitude.errors.required">Please enter device latitude</div>
          <div *ngIf="enclosure_latitude.errors.minlength">Have at least 6 characters</div>
          <div *ngIf="!enclosure_latitude.errors.minlength && enclosure_latitude.errors.pattern">
            Please Enter Valide Device Latitude
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="modal-input modal-input-spacebetween modal-input-space modal-address-padding-bottom">
      <label>Device Longitude<sup></sup></label>
      <mat-form-field floatLabel="never" appearance="standard">
        <input minlength="6" maxlength="20" pattern="-?\d{1,3}\.\d+" matInput placeholder="23.0283" name="enclosure_longitude" #enclosure_longitude="ngModel" [(ngModel)]="service.EnclosureListFormData.enclosure_longitude" [ngClass]="{
            'is-invalid':
              !enclosure_longitude.valid && (enclosure_longitude.dirty || enclosure_longitude.touched || isSubmit)
          }" required/>
        <mat-error *ngIf="
            !enclosure_longitude.valid && (enclosure_longitude.dirty || enclosure_longitude.touched || isSubmit)
          ">
            <div *ngIf="enclosure_longitude.errors.required">Please enter device longitude</div>
          <div *ngIf="enclosure_longitude.errors.minlength">Have at least 6 characters</div>
          <div *ngIf="!enclosure_longitude.errors.minlength && enclosure_latitude.errors.pattern">
            Please Enter Valide Device Longitude
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <span class="modal-btn-update">
      <div class="d-block justify-content-center">
        <button [disabled]="isLoading" class="add-btn">
          <em class="material-icons">add</em>
          <span class="pt-1">{{ addUpdateModal }}</span>
        </button>
      </div>
    </span>
  </form>
</ngx-smart-modal>
<ngx-smart-modal #viewForward [identifier]="'viewForward'" [dismissable]="false" customClass="nsm-dialog-animation-ttb nsm-centered modal forward-modal">
  <h2 class="modal-title">Forward</h2>
  <div class="modal-forward-box">
    <div class="forward-block">
      <div class="forward-encl-title">
        <span class="forward-encl-subtitle">Device ID:</span>
        <span class="forward-encl-subtext">{{ enc_id }}</span>
      </div>
      <div class="forward-encl-title">
        <span class="forward-encl-subtitle">Forwarded ID:</span>
        <span class="forward-encl-subtext">{{ forward_enclosure_id }}</span>
      </div>

      <p class="forward-encl-title" *ngIf="request_status == 0">
        <span class="forward-encl-subtitle">Status:</span>
        <span class="forward-encl-subtext">Pending</span>
      </p>
      <p class="forward-encl-title" *ngIf="request_status == 1">
        <span class="forward-encl-subtitle">Status:</span>
        <span class="forward-encl-subtext">Coming Soon</span>
      </p>
      <p class="forward-encl-title" *ngIf="request_status == 7">
        <span class="forward-encl-subtitle">Status:</span>
        <span class="forward-encl-subtext">Ongoing</span>
      </p>
    </div>
    <div class="forward-block forward-email-block">
      <div class="forward-encl-title">
        <span class="forward-encl-subtitle"><img src="assets/images/email-id.svg" alt="" /> Forwarded Email :
        </span>
        {{ forward_user_email }}
      </div>
    </div>
    <div class="forward-date-box">
      <div class="forward-date forward-start-date">
        <div class="forward-date-wrap">
          <span class="material-icons">date_range</span>
          <span class="forward-date-title">Forward <strong>From</strong></span>
        </div>
        <span class="forward-date-info">{{
          forward_start_time * 1000 | date: "d MMM, y h:mm a"
        }}</span>
      </div>
      <div class="forward-date forward-end-date">
        <div class="forward-date-wrap">
          <span class="material-icons">date_range</span>
          <span class="forward-date-title">Forward <strong>Until</strong></span>
        </div>
        <span class="forward-date-info">{{
          forward_end_time * 1000 | date: "d MMM, y h:mm a"
        }}</span>
      </div>
    </div>
  </div>
</ngx-smart-modal>
