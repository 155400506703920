import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as mapboxgl from 'mapbox-gl';
import { LiveTrackingAddress,LiveTrackingLatLang ,LiveTrackingDelivery} from './live-tracking.modal';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Message } from '../_helper/message';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LiveTrackingService {
  LiveTrackingAddressFormData: LiveTrackingAddress;
  LiveTrackingLatLangFormData: LiveTrackingLatLang;
  LiveTrackingDeliveryFormData: LiveTrackingDelivery;
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';
  lat = 45.899977;
  lng = 6.172652;
  zoom = 12
  Mapbox_accessToken_URL = "";
 
  constructor(private http: HttpClient) {
    this.Mapbox_accessToken_URL = Message.dec(environment.mapbox.accessToken)
   // mapboxgl.accessToken = this.Mapbox_accessToken_URL;
  }
  /*
  lat lang to address 
  Latitude and Longitude to address
  URL : https://api.mapbox.com/geocoding/v5/mapbox.places/"+Longitude+','+Latitude+'".json?access_token=ACCESS_TOKEN
  URL : https://api.mapbox.com/geocoding/v5/mapbox.places/73.1320,20.8529.json?access_token=pk.eyJ1IjoiYXJjaGl0Z2hldGl5YSIsImEiOiJja2tub2poYTgwaXJuMm9yd2k4dWZscDJwIn0.bY_lkJgQ2gnEy3Od-xthJQ

  Address to Latitude and Longitude 
  URl : https://api.mapbox.com/geocoding/v5/mapbox.places/'+ADDRESSS+'.json?access_token=ACCESS_TOKEN
  URl : https://api.mapbox.com/geocoding/v5/mapbox.places/surat,india.json?access_token=pk.eyJ1IjoiYXJjaGl0Z2hldGl5YSIsImEiOiJja2tub2poYTgwaXJuMm9yd2k4dWZscDJwIn0.bY_lkJgQ2gnEy3Od-xthJQ
  */
  getLatLongFromAddress(address){
    //const promise = this.http.get(this.apiUrl).toPromise();
    //console.log(promise);    
    const promise = this.http.get('https://api.mapbox.com/geocoding/v5/mapbox.places/'+address+'.json?access_token='+this.Mapbox_accessToken_URL).toPromise();   
    promise.then((data)=>{
      return data;
      console.log("Promise resolved with: " + JSON.stringify(data));
    }).catch((error)=>{
      return error;
      console.log("Promise rejected with " + JSON.stringify(error));
    });
  }
  getAddressFromLatLang(latitude,longitude){ 
  return this.http.get('https://api.mapbox.com/geocoding/v5/mapbox.places/'+longitude+','+latitude+'.json?access_token='+this.Mapbox_accessToken_URL); 
  }
  buildMap() {
    this.map = new mapboxgl.Map({
      container: 'map-container',
      style: this.style,
      zoom: this.zoom,
      center: [this.lng, this.lat]
    })
    this.map.addControl(new mapboxgl.NavigationControl());
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  testingDataForFirmwareTeam(data){
    return this.http.post(environment.api_url + '/parcel-delivery/send-latlong-for-testing-purpose-fwteam', data);
  }


}
